import { Resource } from "../resource";
import { User } from "../user/user";

export class ProductPromotion extends Resource {
    ageFrom: number | null | undefined;
    ageTo: number | null | undefined;
    created: string | null | undefined;
    expired: string | null | undefined;
    dailyBudget: number | null | undefined;
    durationDays: number | null | undefined;
    expires: string | null | undefined;
    gender: string | null | undefined;
    balance: number | null | undefined;
    numberOfComments: number | null | undefined;
    numberOfFeedsViews: number | null | undefined;
    numberOfFilterViews: number | null | undefined;
    numberOfFollows: number | null | undefined;
    numberOfHits: number | null | undefined;
    numberOfLikes: number | null | undefined;
    numberOfProductHits: number | null | undefined;
    numberOfProfileHits: number | null | undefined;
    numberOfSaves: number | null | undefined;
    productName: string | null | undefined;
    brandName: string | null | undefined;
    productId: string | null | undefined;
    specialPrice: number | null | undefined;
    status: string | null | undefined;
    uid: string | null | undefined;
    updated: number | null | undefined;
    ratio: string | null | undefined;
    totalBudget: number | null | undefined;
    totalRest: number | null | undefined;
    totalSpent: number | null | undefined;
    user: User | null | undefined;
    promotionBalance: number | null | undefined;
    image: string | null | undefined;

}
