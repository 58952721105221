import { Serializer } from '../serializer'
import { Priority } from './priority'


export class PrioritySerializer implements Serializer {
    fromJson(json: any) {
        const priority = new Priority()
        priority.id = json.id      
        priority.name = json.name        
        return priority
    }

    toJson(item: Priority) {
        const obj: any = {}
        return obj
    }
}
