import { departmentSerializer } from "../department/serializer";
import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { EmployeeProfile } from "./EmployeeProfile";

class EmployeeProfileSerializer implements Serializer {
  fromJson(json: any): EmployeeProfile {
    const epItem = new EmployeeProfile();
    epItem.id = json.id;
    epItem.birthDate = json.birth_date;
    if (json.department) epItem.department = departmentSerializer.fromJson(json.department);
    epItem.firstName = json.first_name;
    epItem.lastName = json.last_name;
    epItem.fullName = json.full_name;
    epItem.email = json.email;
    epItem.phone = json.phone;
    epItem.position = json.position;
    epItem.roles = json.roles;
    return epItem;
  }
  toJson(resource: Resource) {
    throw new Error("Method not implemented.");
  }
}

export const employeeProfileSerializer = new EmployeeProfileSerializer();
