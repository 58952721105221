import ModeEditOutlineOutlinedIcon from "@mui/icons-material/ModeEditOutlineOutlined";
import { Button, Divider, Grid, PaletteMode, Paper, Typography } from "@mui/material";
import { useEffect } from "react";
import { connect } from "react-redux";
import { EmployeeProfilePageProfile } from "../../Components/Profiles/EmployeeProfilePageProfile";
import { ReduxState } from "../../environment/environment";
import { QueryOptions } from "../../helpers/query.options";
import { employeeProfileService } from "../../models/EmployeeProfile/service";
import { User } from "../../models/user/user";
import { Palette } from "../../providers/ThemeProvider";

interface EmployeeProfilePageProps {
  mode: PaletteMode;
  palette: Palette;
  user: User;
}

const EmployeeProfilePage = (props: EmployeeProfilePageProps) => {
  const { user } = props;

  useEffect(() => {
    const opts = new QueryOptions();
    opts.id = user.email!;
    employeeProfileService.read(user.email!).then((e) => {
      
    });
  }, []);

  return (
    <Grid container padding={"60px 60px"} sx={{ backgroundColor: "background.default", height: "100vh",
marginBottom: "120px" }}>
      <Grid container component={Paper} elevation={2} sx={{ borderRadius: "16px", height: "376px" }}>
        <Grid justifyContent={"space-between"} container margin={"17.5px 20px"} alignItems={"center"} height={"65px"}>
          <Grid item xs md lg>
            <Typography variant="h4" fontSize={"22px"} width={"fit-content"}>
              Personal Information
            </Typography>
          </Grid>
          <Grid item xs md lg container justifyContent={"flex-end"}>
            <Button
              sx={{
                gap: 1,
                width: "98px",
                backgroundColor: "#443DF6",
                color: "white",
                borderRadius: "8px",
                "&.MuiButtonBase-root:hover": {
                  backgroundColor: "rgba(68, 100, 246, 1)",
                },
              }}
            >
              <ModeEditOutlineOutlinedIcon />
              Edit
            </Button>
          </Grid>
        </Grid>
        <Grid item lg={12} md={12} xs={12}>
          <Divider sx={{ borderBottomWidth: "2px" }} />
        </Grid>
        <Grid container height={"309px"} padding={"30px 24px 0px 24px"}>
          <Grid item lg={3} md={3} xs={12}>
            <EmployeeProfilePageProfile displayHeight={80} displayWidth={80} displayEmail={user.email!} displayName={user.fullName!} />
          </Grid>
          <Grid item lg={5} md={5} xs={12}>
            2
          </Grid>
          <Grid item lg={4} md={4} xs={12}>
            3
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { app, theme, auth } = state;
  return {
    mode: app.currentTheme,
    palette: theme.palette,
    user: auth.user,
  };
};

export default connect(mapStateToProps)(EmployeeProfilePage);
