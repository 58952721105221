import { Search } from "@mui/icons-material";
import Edit from "@mui/icons-material/Edit";
import { Box, IconButton, InputAdornment, ListSubheader, Menu, MenuItem, TextField } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { QueryOptions } from "../../helpers/query.options";
import { componentService } from "../../models/component/service";
import { Issue } from "../../models/issue/Issue";
import { Task } from "../../models/Task/Task";

export default function ShortcutForm({ getValues, app, getBy, appFilterer, iconSx }: any) {
  const [components, setComponents] = useState<any[]>([]);
  const [open, setOpen] = useState(false);

  const valueRef: any = useRef(null);

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const opn = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setOpen(false);
  };

  const loadComponents = (search: string) => {
    const options = new QueryOptions();
    options.pageSize = 1000;
    options.search = search;
    componentService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setComponents(r.current);
      }
    });
  };

  const searchOnChange = (search: string) => {
    setTimeout(() => loadComponents(search), 1000);
  };

  useEffect(() => {
    const listener = (event: any) => {
      event.preventDefault();
      valueRef.current !== null && valueRef.current?.focus();
    };
    open ? window.addEventListener("keydown", listener) : window.removeEventListener("keydown", listener);

    return () => {
      window.removeEventListener("keydown", listener);
    };
  }, [open]);

  return (
    <Box style={{ position: "absolute", top: 3, right: 3 }} justifyContent="right" display="flex">
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <Edit sx={{ ...iconSx }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={opn}
        onClose={handleClose}
        PaperProps={{
          sx: {
            height: "auto",
            width: "max-content" || "25ch",
          },
        }}
      >
        {appFilterer === "components" && (
          <ListSubheader
            sx={{
              backgroundColor: "background.default",
              backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
            }}
          >
            <TextField
              onFocus={() => {
                setOpen(true);
              }}
              inputRef={valueRef}
              focused={open}
              onClickCapture={(e) => {
                e.stopPropagation();
              }}
              size="medium"
              placeholder="Type to search..."
              fullWidth
              variant="outlined"
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start" style={{ height: "50px" }}>
                    <Search sx={{ ...iconSx }} />
                  </InputAdornment>
                ),
              }}
              onChange={(e) => searchOnChange(e.target.value)}
              onKeyDown={(e) => {
                if (e.key !== "Escape") {
                  // Prevents autoselecting item while typing (default Select behaviour)
                  e.stopPropagation();
                }
              }}
            />
          </ListSubheader>
        )}
        {(
          (app
            ? appFilterer === "components" && components.length
              ? components
              : app[appFilterer]?.length && app[appFilterer]
            : app[appFilterer]?.length && app[appFilterer]) || []
        )?.map((item: Task | Issue | any, index: number) => {
          return (
            <MenuItem
              classes={{ root: "{whiteSpace: unset, wordBreak: all}" }}
              key={index}
              id={item.id || item[getBy]?.id}
              value={item.id || item[getBy]?.id}
              onClick={(event: any) => {
                const [item] = app && app[appFilterer]?.filter((option: any) => `${option.id}` === event.target.id);
                if (item) {
                  getValues({ [getBy]: item });
                }
              }}
            >
              {item.name || item.full_name || item.fullName}
            </MenuItem>
          );
        })}
      </Menu>
    </Box>
  );
}
