import { Reason } from "../Reason/Reason";
import { reasonSerializer } from "../Reason/serializer";
import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { userSerializer } from "../user/serializer";
import { Review } from "./Review";

export class ReviewSerializer implements Serializer {
  fromJson(json: any): Review {
    const item = new Review();
    // item.comment = json.comment;
    item.created = json.created;
    item.id = json.id;
    item.order = json.order;
    // item.owner = json.owner;
    // item.product = json.product;
    item.rate = json.rate;
    item.status = json.status;
    if (json.reasons) item.reasons = json.reasons.map((item: Reason) => reasonSerializer.fromJson(item));
    else item.reasons = [];
    item.ownerEmail = userSerializer.fromJson(json.owner).email;
    return item;
  }
  toJson(resource: Resource) {
    throw new Error("Method not implemented.");
  }
}

export const reviewSerializer = new ReviewSerializer();
