import { Add, Edit, Visibility } from "@mui/icons-material";
import HorizontalRuleRoundedIcon from "@mui/icons-material/HorizontalRuleRounded";
import { Box, ClickAwayListener, IconButton, ListItemButton, ListItemText, ListSubheader, Menu, MenuItem, MenuList, Popper } from "@mui/material";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect } from "react-redux";
import { App } from "../environment/environment";
import { Address } from "../models/Address/Address";
import { setSnack } from "../models/application/applicationActions";
import { User } from "../models/user/user";
import AddressFormDialog from "./Dialogs/AddressFormDialog";
import { TypographyItem } from "./Typography/TypographyItem";

interface InnerAddressListProps {
  address?: Address;
  index?: number;
  handleDialog: (state: boolean) => void;
  setTitle: Dispatch<SetStateAction<string>>;
  setAddress: Dispatch<SetStateAction<Address | null>>;
  setMenuItemIndex: Dispatch<SetStateAction<string | null>>;
  menuItemIndex: string | null;
  handleClose: () => void;
}

const InnerAddressList = (props: InnerAddressListProps) => {
  const { address, index, handleDialog, setTitle, setAddress, handleClose, setMenuItemIndex, menuItemIndex } = props;

  const [anchorElInner, setAnchorElInner] = useState<null | HTMLElement>(null);
  const openInner = Boolean(anchorElInner);

  const handleOpenInner = () => {
    const elemt = document.getElementById(`innerHover${index}`);
    setMenuItemIndex((prev) => (prev ? null : `innerHover${index}`));
    setAnchorElInner(elemt);
  };

  const handleCloseInner = () => {
    setAnchorElInner(null);
  };

  interface addressListProps {
    name: string;
    value: string;
  }

  const addressList: addressListProps[] = [
    { name: "Address Name", value: address?.addressName! },
    { name: "Country", value: address?.country?.name! },
    { name: "City", value: address?.city?.name! },
    { name: "Street", value: address?.street! },
    { name: "Entrance", value: address?.entrance! },
    { name: "Floor", value: address?.floor! },
    { name: "Apartment", value: address?.apartment! },
    { name: "First Name", value: address?.firstName! },
    { name: "Last Name", value: address?.lastName! },
    { name: "Building ID", value: address?.buildingId! },
    { name: "Google Place ID", value: address?.googlePlaceId! },
    { name: "Personal ID", value: address?.personalId! },
    { name: "UID", value: address?.uid! },
    { name: "Nova Poshta Warehouse", value: address?.novaPoshtaWarehouse?.name! },
    { name: "Comment", value: address?.comment! },
    { name: "Is Main", value: String(address?.isMain!) },
    { name: "Phone", value: address?.phone! },
    { name: "Postal Code", value: address?.postalCode! },
    { name: "Recipient", value: address?.recipient! },
    { name: "Type", value: address?.type! },
  ];

  return (
    <Box>
      <MenuItem
        itemProp=""
        sx={{
          cursor: "default",
          position: "relative",
          display: "flex",
          justifyContent: "space-between",
          gap: "10px",
          backgroundColor: `innerHover${index}` === menuItemIndex ? "rgba(0, 0, 0, 0.1)" : "transparent",
          "&:hover": {
            backgroundColor: `innerHover${index}` === menuItemIndex ? "rgba(0, 0, 0, 0.1)" : "rgba(0, 0, 0, 0.04)",
          },
        }}
      >
        <Box
          id={`innerHover${index}`}
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
          }}
        />
        <Box>
          <IconButton onClick={handleOpenInner}>
            <Visibility />
          </IconButton>
          <IconButton
            onClick={() => {
              handleDialog(true);
              setTitle("Update Address");
              setAddress(address!);
              handleClose();
            }}
          >
            <Edit />
          </IconButton>
        </Box>
        <TypographyItem text={address?.addressName ? address?.addressName : `Address ${index! + 1}`} fontColor="background.primary" />
      </MenuItem>
      <Popper anchorEl={anchorElInner!} open={openInner} placement="left-start" sx={{ zIndex: 1000 }}>
        <ClickAwayListener onClickAway={handleCloseInner}>
          <MenuList
            sx={{
              backgroundColor: "background.default",
              backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {addressList
              ?.filter((item) => item.value)
              .map((item: addressListProps, idx: number) => (
                <MenuItem
                  key={idx}
                  sx={{
                    cursor: "default",
                    textAlign: "left",
                    gap: "30px",
                    color: "text.primary",
                  }}
                >
                  <ListItemText sx={{ width: "fit-content", whiteSpace: "nowrap" }}>{item.name}</ListItemText>
                  <Box
                    sx={{
                      width: "100%",
                      boxSizing: "border-box",
                      padding: "0px 10px",
                      display: "flex",
                      justifyContent: "flex-end",
                      gap: "10px",
                      alignItems: "center",
                    }}
                  >
                    <HorizontalRuleRoundedIcon />
                    {item.value}
                  </Box>
                </MenuItem>
              ))}
          </MenuList>
        </ClickAwayListener>
      </Popper>
    </Box>
  );
};

interface AddressDetailsComponentProps {
  addresses?: Address[];
  setSnack?: any;
  app?: App;
  item?: User | null;
  setSubmitted?: any;
  addressInnerLists?: any;
  handleSearchOnChange?: any;
}

const AddressDetailsComponent = (props: AddressDetailsComponentProps) => {
  const { setSnack, app, item, setSubmitted } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [address, setAddress] = useState<Address | null>(null);
  const [addresses, setAddresses] = useState<Address[]>([]);
  const [title, setTitle] = useState("Create New Address");
  const [menuItemIndex, setMenuItemIndex] = useState<string | null>(null);

  const open = Boolean(anchorEl);

  useEffect(() => {
    setAddresses(item?.addresses);
  }, [item?.id]);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setMenuItemIndex(null);
  };

  const handleDialog = (state: boolean) => setDialogOpen(state);

  const onAlert = (text: string) => setSnack({ ...app!.snack, open: true, text: text });

  return (
    <Box>
      <MenuItem onClick={handleClick}>
        <ListItemText>
          <TypographyItem text={"click for more"} fontSize={"12px"} />
        </ListItemText>
      </MenuItem>
      <Menu
        anchorEl={anchorEl}
        open={open}
        sx={{ zIndex: 999 }}
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
            backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
          },
        }}
        onClose={handleClose}
      >
        <MenuList
          sx={{
            overflowY: "auto",
            padding: 0,
          }}
        >
          <ListSubheader
            sx={{
              padding: "15px",
              backgroundColor: "background.default",
              backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
            }}
          >
            <ListItemButton
              sx={{ position: "sticky" }}
              onClick={() => {
                handleDialog(true);
                setTitle("Create New Address");
                setAddress(null);
              }}
            >
              <ListItemText>Add New Address</ListItemText>
              <Add />
            </ListItemButton>
          </ListSubheader>
          {addresses?.map((address: Address, index: number) => (
            <Box key={index}>
              <InnerAddressList
                address={address}
                index={index}
                handleDialog={handleDialog}
                setTitle={setTitle}
                setAddress={setAddress}
                handleClose={handleClose}
                menuItemIndex={menuItemIndex}
                setMenuItemIndex={setMenuItemIndex}
              />
            </Box>
          ))}
        </MenuList>
      </Menu>
      <AddressFormDialog
        open={dialogOpen}
        handleDialog={handleDialog}
        address={address}
        userId={item?.id}
        onAlert={onAlert}
        setSubmitted={setSubmitted}
        title={title}
      />
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { setSnack })(AddressDetailsComponent);
