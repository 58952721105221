import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

export const ConfirmationDialog = (props: any) => {
  const { text, callback, open, title } = props;

  return (
    <Dialog open={open}>
      <DialogTitle
        sx={{
          backgroundColor: "background.default",
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
          color: "grey",
        }}
      >
        {title ? title.toUpperCase() : "ავტორიზაცია"}
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "background.default",
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
        }}
      >
        <DialogContentText>
          <div style={{ fontFamily: "arial" }}>{text}</div>
        </DialogContentText>
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "background.default",
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
        }}
      >
        <Button
          onClick={() => {
            callback(false);
          }}
          color="secondary"
        >
          Cancel
        </Button>
        <Button
          onClick={() => {
            callback(true);
          }}
          color="secondary"
          autoFocus
        >
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  );
};
