import { Autocomplete, Button, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, ListItem, MenuItem, Select, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { useState } from "react";
import { validationSchemas } from "../../helpers/validations";
import { Priority } from "../../models/priority/priority";
import { User } from "../../models/user/user";
import { Status, Version } from "../../models/Version/Version";
import { TypographyItem } from "../Typography/TypographyItem";

const CustomListItem = (props: any) => {
  const { element, option } = props;

  const [hover, setHover] = useState(false);

  return (
    <ListItem
      key={option?.id}
      sx={{
        display: "flex",
        flexDirection: "column",
        wordBreak: "break-word",
        justifyContent: "center",
        backgroundColor: hover ? "rgba(0,0,0,.3)" : "transparent",
      }}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...element}
    >
      <TypographyItem sx={{ textAlign: "left", width: "100%" }} text={option.name?.length ? option?.name : "No Name"} />
    </ListItem>
  );
};

interface TasksFormProps {
  item: any;
  getValues: any;
  issues: any;
  app: any;
  handleDialog: any;
}

export default function TasksForm({ item, getValues, issues, app, handleDialog }: TasksFormProps) {
  const InitVals = {
    name: "",
    description: "",
    issue: "",
    status: "",
    assignee: "",
    version: "",
    priority: "",
    minutesEstimated: "",
    minutesSpent: "",
  };

  return (
    <Formik
      validationSchema={validationSchemas([], []).taskSchema}
      initialValues={
        item
          ? {
              ...item,
            }
          : InitVals
      }
      onSubmit={getValues}
    >
      {({ values, handleSubmit, setFieldValue, handleBlur, handleChange, errors, touched }: any) => (
        <Form
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <DialogTitle>ახალი ტასკი</DialogTitle>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth>
                <Autocomplete
                  freeSolo
                  id="name"
                  options={issues}
                  value={values.issue}
                  getOptionLabel={(option) => option.name || ""}
                  onChange={(event, value) => {
                    setFieldValue("issue", value);
                  }}
                  renderOption={(props, option) => {
                    return <CustomListItem element={props} option={option} key={option.id} />;
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      error={errors.issue && touched.issue ? true : false}
                      label={errors.issue && touched.issue ? "აირჩიე საკითხი" : "აირჩიე საკითხი"}
                      helperText={errors.issue && touched.issue ? errors.issue : null}
                      variant="outlined"
                    />
                  )}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.name && touched.name ? true : false}
                label={errors.name && touched.name ? "დასახელება" : "დასახელება"}
                helperText={errors.name && touched.name ? errors.name : null}
                name="name"
                margin="dense"
                variant="outlined"
                id="name"
                defaultValue={values?.name}
                type="name"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                error={errors.description && touched.description ? true : false}
                label={errors.description && touched.description ? "აღწერა" : "აღწერა"}
                helperText={errors.description && touched.description ? errors.description : null}
                name="description"
                multiline={true}
                margin="dense"
                variant="outlined"
                id="Description"
                defaultValue={values.description}
                type="description"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={"შესრულების საავარაუდო დრო. min."}
                name="minutesEstimated"
                margin="dense"
                variant="outlined"
                id="minutesEstimated"
                defaultValue={values.minutesEstimated}
                type="minutesEstimated"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={6}>
              <TextField
                label={"შესრულების რეალური დრო. min."}
                name="minutesSpent"
                multiline={true}
                margin="dense"
                variant="outlined"
                id="minutesSpent"
                defaultValue={values.minutesSpent}
                type="minutesSpent"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>

            <Grid item xs={6}>
              <FormControl fullWidth error={errors.status && touched.status ? true : false} variant="outlined">
                <InputLabel id="issueStatus">{errors.status && touched.status ? "სტატუსი" : "სტატუსი"}</InputLabel>
                <Select
                  id="issueStatus"
                  label="სტატუსი"
                  name="IssueStatus"
                  variant="outlined"
                  value={values.status?.id || ""}
                  onChange={(event, value) => {
                    const [item] = app?.statuses?.filter((option: any) => `${option.id}` === event.target.value);
                    if (item) {
                      setFieldValue("status", item);
                    }
                  }}
                >
                  {app?.statuses?.map((item: Status, index: string) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.status && touched.status ? errors.status : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth error={errors.assignee && touched.assignee ? true : false} variant="outlined">
                <InputLabel id="" sx={{ fontSize: 13 }}>
                  {errors.status && touched.status ? "პასუხისმგებელი" : "პასუხისმგებელი"}
                </InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  name="assignee"
                  id="assignee"
                  label="პასუხისმგებელი"
                  value={values.assignee?.id || ""}
                  onChange={(event, value) => {
                    const [item] = app?.assignee?.filter((option: any) => option.id === event.target.value);
                    if (item) {
                      setFieldValue("assignee", item);
                    }
                  }}
                >
                  {app?.assignee?.map((item: User, index: string) => {
                    return (
                      <MenuItem key={index} value={item.id as string}>
                        {item.fullName}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.assignee && touched.assignee ? errors.assignee : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" error={errors.version && touched.version ? true : false}>
                <InputLabel id="" sx={{ fontSize: 13 }}>
                  {errors.version && touched.version ? "ვერსია" : "ვერსია"}
                </InputLabel>
                <Select
                  sx={{ width: "100%" }}
                  name="version"
                  id="version"
                  label="ვერსია"
                  value={values?.version?.id || ""}
                  onChange={(event, value) => {
                    const [item] = app?.version?.filter((option: any) => `${option.id}` === event.target.value);
                    if (item) {
                      setFieldValue("version", item);
                    }
                  }}
                >
                  {app?.version?.map((item: Version, index: string) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.version && touched.version ? errors.version : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth variant="outlined" error={errors.priority && touched.priority ? true : false}>
                <InputLabel id="" sx={{ fontSize: 13 }}>
                  {errors.priority && touched.priority ? "პრიორიტეტი" : "პრიორიტეტი"}
                </InputLabel>
                <Select
                  name="priority"
                  id="priority"
                  label="პრიორიტეტი"
                  value={values.priority?.id || ""}
                  onChange={(event, value) => {
                    const [item] = app?.priorities?.filter((option: any) => `${option.id}` === event.target.value);
                    if (item) {
                      setFieldValue("priority", item);
                    }
                  }}
                >
                  {app?.priorities?.map((item: Priority, index: string) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.priority && touched.priority ? errors.priority : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item container lg={6} gap={"10px"}>
              <Button onClick={() => handleDialog(false)}>გამოსვლა</Button>
              <Button
                type="submit"
                sx={{
                  backgroundColor: "#4169E1",
                  color: "white",
                }}
                variant="contained"
              >
                დამახსოვრება
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
