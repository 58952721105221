import { Resource } from "../resource";
import { User } from "../user/user";

export class Status extends Resource {
  uid: string | any;
  name: string | any;
  description: string | any;
  is_enabled: Boolean | any;
  created: Date | any;
  user: User | any;
}
