import CloseIcon from "@mui/icons-material/Close";
import { Box, Grid } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import ChatComponent from "../ChatComponents/MessageComponents/ChatComponent";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const ChatDialog = (props: any) => {
  const { open, setOpen, onOpen, chat, chatPer, auth, messageDeleteActionHandler, messageSenderUpdaterHandler } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullScreen={true} open={open} sx={{ minHeight: window.innerHeight }} onClose={handleClose} TransitionComponent={Transition}>
      <Grid container item sx={{ position: "relative", height: "100%" }}>
        <Box
          sx={{
            borderRadius: 20,
            width: 40,
            height: 40,
            right: 10,
            top: 20,
            position: "absolute",
          }}
        >
          <CloseIcon onClick={handleClose} sx={{ fontSize: 30 }} />
        </Box>
        <ChatComponent
          chat={chat}
          chatPer={chatPer}
          messageDeleteActionHandler={messageDeleteActionHandler}
          currUserName={auth?.user?.fullName}
          messageSenderUpdaterHandler={messageSenderUpdaterHandler}
          onOpen={onOpen}
        />
      </Grid>
    </Dialog>
  );
};
