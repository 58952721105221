import { Serializer } from '../serializer'
import { Component } from './component'


export class ComponentSerializer implements Serializer {
    fromJson(json: any) {
        const component = new Component()
        component.name = json.name
        component.id = json.id
        component.sort = json.sort
        component.description = json.description
        return component
    }

    toJson(item: Component) {
        const obj: any = {
            name: item.name
        }
        return obj
    }
}
