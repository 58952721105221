import { Grid } from "@mui/material";
import { Link, useHistory } from "react-router-dom";
import { environment } from "../../environment/environment";
import { TypographyItem } from "../Typography/TypographyItem";

export const UserProfileShoppingStatsCard = (props: any) => {
  const { itemCount, monetaryValue, sold = false, userProfile, filter } = props;

  const history = useHistory();
  return (
    <Link
      onClick={(e) => (!userProfile?.isActive ? e.preventDefault() : () => {})}
      target={"_blank"}
      style={{
        margin: "0px 15px",
        textDecoration: "none",
        maxWidth: "max-content",
        minWidth: "40%",
        height: "100%",
        padding: "5px 5px",
        background: sold ? "#e54f58" : "#55b17f",
        borderRadius: "10px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-around",
        textAlign: "left",
        boxSizing: "content-box",
      }}
      to={{ pathname: "/orders", search: environment.baseQueryParams + `&status=paid&${filter}=${userProfile?.email}` }}
    >
      <Grid item flex={1.5}>
        <TypographyItem text={sold ? "SOLD" : "BOUGHT"} fontSize={19} color="white" fontWeight="400" />
      </Grid>
      <Grid item flex={1} sx={{ wordBreak: "keep-all" }}>
        <TypographyItem text={`Items : ${itemCount}`} fontSize={19} color="white" fontWeight="400" />
      </Grid>
      <Grid item flex={1} sx={{ wordBreak: "keep-all" }}>
        <TypographyItem
          sx={{ wordBreak: "keep-all", display: "inline-block" }}
          text={`Value : ${monetaryValue} ₾`}
          fontSize={19}
          color="white"
          fontWeight="400"
        />
      </Grid>
    </Link>
  );
};
