// Generated by https://quicktype.io

import { Resource } from "../resource";

export class Badge extends Resource {
    chat_count:      number | undefined;
    message:         string | undefined;
    user_chat_count: number | undefined;
}

