import { DialogActions, Grid, TextField } from "@material-ui/core"
import Button from "@material-ui/core/Button"
import { Form, Formik, ErrorMessage } from "formik"
import { validationSchemas } from "../../helpers/validations"

// interface LoginFormProps {

// }

const LoginForm = (props: any) => {
    const {
        dialogMode,
        tokenMode,
        wrongCredentials,
        setWrongCredentials,
        getValues
    } = props

    return (
        <Formik
            validationSchema={validationSchemas([],[]).login}
            initialValues={{ personalId: '', password: '' }}
            onSubmit={getValues}>
            {({ values, handleSubmit, setFieldValue, handleChange, handleBlur }: any) => (
                <Form onSubmit={handleSubmit}>
                    <Grid container >
                        <Grid item lg={12}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="username-field"
                                label={'ელ-ფოსტა'}
                                variant="outlined"
                                type="text"
                                name="personalId"
                                onChange={(event) => {
                                    setWrongCredentials(false)
                                    handleChange(event)
                                }}
                                onBlur={handleBlur}
                                value={values.email}
                            />
                            <ErrorMessage name="personalId" >
                                {msg => <span className="error">{msg}</span>}
                            </ErrorMessage>

                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="password-field"
                                label={'პაროლი'}
                                variant="outlined"
                                type="password"
                                name="password"
                                onChange={(event) => {
                                    setWrongCredentials(false)
                                    handleChange(event)
                                }}
                                onBlur={handleBlur}
                                value={values.password}
                            />
                            <ErrorMessage name="password" >
                                {msg => <span className="error">{msg}</span>}
                            </ErrorMessage>

                        </Grid>
                        {tokenMode && <Grid item lg={12}>
                            <TextField
                                style={{ width: '90%', marginTop: 10 }}
                                id="token-field"
                                value={values.token}
                                label={'მიღებული 4 ნიშნა კოდი'}
                                variant="outlined"
                                type="number"
                                name="token"
                                onChange={(event) => {
                                    setFieldValue("token", event.target.value)
                                }}
                                onBlur={handleBlur}
                            />
                        </Grid>}
                        {wrongCredentials && <span className="error">
                            მონაცემები არასწორია</span>}
                    </Grid>
                    <DialogActions>
                        {dialogMode &&
                            <Button color="primary">
                                გაუქმება
                            </Button>

                        }
                        <Button color="primary"
                            type="submit">
                            შესვლა
                        </Button>
                    </DialogActions>
                </Form>
            )}
        </Formik>)

}

export default LoginForm
