import AccountCircleIcon from "@material-ui/icons/AccountCircle";
import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { environment } from "../../../environment/environment";
import { TypographyItem } from "../../Typography/TypographyItem";

const UsernameComponent = (props: any) => {
  const { username, imageLink, onOpen } = props;

  const [image, setImage] = useState<string | any>(null);

  useEffect(() => {
    if (imageLink) {
      setImage(environment.usertImages + imageLink + `.${"jpg" || "png"}`);
    }
  }, [imageLink]);
  return (
    <Grid
      item
      container
      onClick={onOpen}
      id="header"
      sx={{
        height: "64px",
        borderBottom: "solid 1px lightgrey",
        backgroundColor: "background.default",
      }}
    >
      <Grid
        item
        container
        alignItems="center"
        xs={12}
        gap={"10px"}
        style={{
          height: "fit-content",
          padding: "10px",
        }}
      >
        <Grid
          item
          style={{
            height: "40px",
            width: "40px",
            borderRadius: "20px",
            backgroundColor: "grey",
            contain: "content",
          }}
        >
          {imageLink ? (
            <Box
              component={"img"}
              style={{ width: "100%", height: "100%", objectFit: "cover" }}
              src={image}
              alt=""
              id="user"
              onError={() => {
                setImage(null);
              }}
            />
          ) : (
            <AccountCircleIcon style={{ fontSize: "40px", color: "pink" }} />
          )}
        </Grid>
        <TypographyItem fontSize={18} text={username} fontWeight="500" />
      </Grid>
    </Grid>
  );
};

export default UsernameComponent;
