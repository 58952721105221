import { Resource } from "../resource"

export class ChatMessage extends Resource {
    owner: string | any
    text: string | any
    original_message: string | undefined
    reply_user: string | undefined
    portal_user: any | any
    created: Date | any
    uid: string | undefined
}
export class Chat extends Resource {
    user: any | any
    messages: ChatMessage[] | any
    id: string | any
    read: boolean | undefined

}

export class ChatMessageUpdate extends Resource {
    text: string | undefined | null
    newText: string | undefined | null
}