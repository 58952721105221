import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { PasswordChange } from "./PasswordChange";

class PasswordChangeSerializer implements Serializer {
  fromJson(json: any): Resource {
    throw new Error("Not Allowerd Method.");
  }
  toJson(item: PasswordChange) {
    const itm = {
      param_name: item.paramName,
      param_new_value: item.paramNewValue,
    };

    return itm;
  }
}

export const passwordChangeSerializer = new PasswordChangeSerializer();
