import { Resource } from "../resource"

export class Category extends Resource {
    name: string | null | undefined
    description: string | null | undefined
    created: string | null | undefined
    nameText: string | null | undefined;
    uid: string | null | undefined;
    value: string | null | undefined;
    ;
} 