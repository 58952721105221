import { Brand } from './Brand';
import { Resource } from '../resource';
import { Serializer } from './../serializer';

export class BrandSerializer implements Serializer {
    fromJson(json: any) {
        const brand = new Brand()
        brand.id = json.id
        brand.name = json.name
        return brand
    }

    toJson(resource: Resource) {
        return resource
    }
}

export const brandSerializer = new BrandSerializer()