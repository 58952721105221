import { AccountCircle } from "@mui/icons-material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Badge, BadgeProps, Box, Button, createTheme, Divider, Grid, IconButton, Menu, Stack, styled, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useCallback, useEffect, useState } from "react";
import { connect } from "react-redux";
import UserContextMenuDialog from "../Dialogs/UserContextMenuDialog";
import UserContextMenuProfile from "../Profiles/UserContextMenuProfile";

const PopupMenuForUserContextMenu = (props: any) => {
  const { mode, logoutHandler, currentUser } = props;

  const displayImageSrc = "";
  const [image, setImage] = useState<string | any>(null);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const open = Boolean(anchorEl);

  useEffect(() => {
    if (displayImageSrc) {
      setImage(() => {
        if (displayImageSrc === "null") return null;
        else return displayImageSrc;
      });
    }

    return () => {
      setImage(null);
    };
  }, [displayImageSrc]);

  const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
    "& .MuiBadge-badge": {
      right: 12.3,
      top: 40,
      padding: "0 4px",
      color: "text.primary",
      width: 0,
    },
  }));

  const handleClick = (item: HTMLElement) => {
    setAnchorEl(item);
  };

  const handleChangePasswordButtonClick = () => {
    setDialogOpen(true);
    setAnchorEl(null);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const displayImage = useCallback(
    (image: string, displayHeight: number, displayWidth: number | string, setImage: Function) => {
      return image ? (
        <IconButton aria-label={"account"} sx={{ padding: 0 }}>
          <StyledBadge
            overlap="circular"
            badgeContent={
              <KeyboardArrowDownIcon
                sx={{
                  "& .MuiSvgIcon-root": {
                    width: "15px",
                  },
                }}
              />
            }
          >
            <Box
              component={"img"}
              onError={() => setImage(null)}
              src={image}
              sx={{
                height: `${displayHeight}px`,
                width: `${displayWidth}px`,
                objectFit: "cover",
                cursor: "pointer",
              }}
            />
          </StyledBadge>
        </IconButton>
      ) : (
        <IconButton onClick={() => handleClick(document.getElementById("badgeItem")!)} aria-label={"account"} sx={{ padding: 0 }}>
          <StyledBadge
            overlap="circular"
            badgeContent={
              <KeyboardArrowDownIcon
                sx={{
                  "&.MuiSvgIcon-root": {
                    fontSize: "15px",
                    color: "grey",
                  },
                }}
              />
            }
          >
            <AccountCircle
              id="badgeItem"
              sx={{
                padding: 0,
                fontSize: `${displayWidth}px`,
                color: "white",
                cursor: "pointer",
                "&.MuiSvgIcon-root": {
                  minWidth: "50px",
                  minHeight: "50px",
                },
              }}
            />
          </StyledBadge>
        </IconButton>
      );
    },
    [displayImageSrc]
  );

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
      error: {
        main: "#DC143C",
      },
    },
    components: {
      MuiBadge: {
        styleOverrides: {
          badge: {
            border: `2px solid ${mode === "dark" ? grey[800] : grey[600]}`,
            backgroundColor: "lightgray",
          },
        },
      },
      MuiOutlinedInput: {
        styleOverrides: {
          root: {
            "& fieldset": {
              borderRadius: "8px",
            },
          },
        },
      },
    },
    typography: {
      fontSize: 12,
    },
  });

  return (
    <ThemeProvider theme={localTheme}>
      <Box>
        {displayImage(displayImageSrc, 50, 50, setImage)}
        <Menu
          PaperProps={{
            sx: {
              minWidth: "40px",
              left: 0,
              marginLeft: "21px",
              width: "fit-content",
            },
          }}
          MenuListProps={{
            sx: {},
          }}
          anchorOrigin={{
            horizontal: "center",
            vertical: "bottom",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          onClose={handleClose}
          open={open}
          anchorEl={anchorEl}
        >
          <Stack divider={<Divider />} direction={"column"}>
            <Grid container direction={"row"} spacing={0} padding={1.5}>
              <UserContextMenuProfile displayHeight={50} displayWidth={50} displayName={currentUser?.fullName} displayEmail={currentUser?.email} />
            </Grid>
            <Grid container direction={"column"} spacing={0} padding={1.5} gap={1.5}>
              <Grid item lg={12}>
                <Button
                  onClick={handleChangePasswordButtonClick}
                  fullWidth
                  sx={{
                    textTransform: "none",
                    backgroundColor: "rgba(237, 236, 250, 1)",
                    borderRadius: "8px",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "rgba(237, 220, 250, 1)",
                    },
                    color: "#443DF6",
                  }}
                >
                  Change Password
                </Button>
              </Grid>
              <Grid item lg={12}>
                <Button
                  fullWidth
                  sx={{
                    textTransform: "none",
                    backgroundColor: "rgba(68, 61, 246, 1)",
                    borderRadius: "8px",
                    "&.MuiButtonBase-root:hover": {
                      backgroundColor: "rgba(68, 100, 246, 1)",
                    },
                    color: "white",
                  }}
                  onClick={logoutHandler}
                >
                  Log out
                </Button>
              </Grid>
            </Grid>
          </Stack>
        </Menu>
      </Box>
      <Box display={"none"}>
        <UserContextMenuDialog open={dialogOpen} setOpen={setDialogOpen} />
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: any) => {
  return {
    mode: state.app.currentTheme,
    currentUser: state.auth.user,
  };
};

export default connect(mapStateToProps)(PopupMenuForUserContextMenu);
