import { Resource } from "../resource"
import { User } from "../user/user"

export class Comments extends Resource {
    children: Children | any
    created: string | any
    id: string | any
    is_enabled: boolean | any
    parent: any | any
    task: any | any
    text: string | any
    user: User | any
}

export class Children extends Resource {
    children: Children | any
    created: string | any
    id: string | any
    is_enabled: boolean | any
    text: string | any
    user: string | any
}