import { Serializer } from "../serializer";
import { Status } from "./status";


export class StatusSerializer implements Serializer {
  fromJson(json: any) {
    const status = new Status();
    status.uid = json.uid;
    status.id = json.id;
    status.name = json.name;
    status.is_enabled = json.is_enabled;
    status.user = json.user;
    status.created = json.created;
    return status;
  }

  toJson(item: Status) {
    const obj: any = {
      name: item.name,
      id: item.id,
      uid: item.uid,
      is_enabled: item.is_enabled,
    };
    return obj;
  }
}
