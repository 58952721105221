import { ResourceProvider } from './../../providers/resource.provider';
import { City } from './City';
import { citySerialier } from './serialzer';

export class CityProvider extends ResourceProvider<City> {
    constructor() {
        super(
            "rest_city",
            citySerialier
        )
    }
}

export const cityService = new CityProvider()