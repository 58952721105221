import { SnackbarOrigin } from "@material-ui/core"
import { Category } from "../category/category"
import { Component } from "../component/component"
import { Features } from "../features/features"
import { Issue, IssueType } from "../issue/Issue"
import { Priority } from "../priority/priority"
import { Project } from "../project/project"
import { Status } from "../status/status"
import { TestStatus } from "../testing/TestObject"
import { User } from "../user/user"
import { Version } from "../Version/Version"

export const SET_USER_LIST = "SET_USER_LIST"
export const SET_SNACK_BAR = "SET_SNACK_BAR"
export const SET_USERNAME = "SET_USERNAME"
export const SET_COUNTER = "SET_COUNTER"
export const SET_CATEGORIES = "SET_CATEGORIES"
export const SET_ISSUE_TYPES = "SET_ISSUE_TYPES"
export const SET_PROJECTS = "SET_PROJECTS"
export const SET_PRIORITIES = "SET_PRIORITIES"
export const SET_STATUSES = "SET_STATUSES"
export const SET_COMPONENTS = "SET_COMPONENTS"
export const SET_SNACK = "SET_SNACK"
export const SET_ISSUES = "SET_ISSUE"
export const SET_ASSIGNEE = "SET_ASSIGNEE"
export const SET_VERSION = "SET_VERSION"
export const SET_FEATURES = "SET_FEATURES"
export const SET_TEST_STATUSES = "SET_TEST_STATUSES"
export const SET_THEME = "SET_THEME"
export const SET_CHAT_BADGE = "SET_CHAT_BADGE"
export const SET_COUNTRY = "SET_COUNTRY"


export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}


export const setUserList = (users: any) => {
    return { type: SET_USER_LIST, content: { users } }
}
export const setCountryName = (country: string) => {
    return { type: SET_COUNTRY, content: { country } }
}
export const setChatBadge = (badgeCount: number) => {
    return { type: SET_CHAT_BADGE, content: { badgeCount } }
}

export const setTheme = (currentTheme: string) => {
    return { type: SET_THEME, content: { currentTheme} } 
}

export const setSnackBar = (snackBar: State) => {
    return { type: SET_SNACK_BAR, content: { snackBar } }
}
export const setUsername = (username: string) => {
    return { type: SET_USERNAME, content: { username } }
}
export const setCounter = (counter: string) => {
    return { type: SET_COUNTER, content: { counter } }
}
export const setCategories = (categories: Category[]) => {
    return { type: SET_CATEGORIES, content: { categories } }
}
export const setTestStatuses = (testStatuses: TestStatus[]) => {
    return { type: SET_TEST_STATUSES, content: { testStatuses } }
}
export const setIssueTypes = (issueTypes: IssueType[]) => {
    return { type: SET_ISSUE_TYPES, content: { issueTypes } }
}
export const setProjects = (projects: Project[]) => {
    return { type: SET_PROJECTS, content: { projects } }
}
export const setSnack = (snack: any) => {
    return { type: SET_SNACK, content: { snack } }
}
export const setPriorities = (priorities: Priority[]) => {
    return { type: SET_PRIORITIES, content: { priorities } }
}
export const setComponents = (components: Component[]) => {
    return { type: SET_COMPONENTS, content: { components } }
}
export const setStatuses = (statuses: Status[]) => {
    return { type: SET_STATUSES, content: { statuses } }
}
export const setIssues = (issues: Issue[]) => {
    return { type: SET_ISSUES, content: { issues } }
}
export const setAssignee = (assignee: User[]) => {
    return { type: SET_ASSIGNEE, content: { assignee } }
}
export const setVersion = (version: Version[]) => {
    return { type: SET_VERSION, content: { version } }
}
export const setFeatures = (features: Features[]) => {
    return { type: SET_FEATURES, content: { features } }
}