import { ResourceProvider } from "../../providers/resource.provider";
import { IssueFilter, OrderFilter, ReviewFilter, TaskFilter, UserFilter } from "./Filter";
import {
  IssueFilterSerializer,
  OrderFilterSerializer,
  reviewFilterSerializer,
  TaskFilterSerializer,
  UsersFilterSerializer,
  VersionFilterSerializer
} from "./serializer";

export class TaskFilterProvider extends ResourceProvider<TaskFilter> {
  constructor() {
    super("rest_task_filter", new TaskFilterSerializer());
  }
}

export class IssueFilterProvider extends ResourceProvider<IssueFilter> {
  constructor() {
    super("rest_issue_filter", new IssueFilterSerializer());
  }
}

export class VersionFilterProvider extends ResourceProvider<IssueFilter> {
  constructor() {
    super("rest_version_filter", new VersionFilterSerializer());
  }
}
export class OrderFilterProvider extends ResourceProvider<OrderFilter> {
  constructor() {
    super("rest_order_filter", new OrderFilterSerializer());
  }
}
export class UsersFilterProvider extends ResourceProvider<UserFilter> {
  constructor() {
    super("rest_user_filter", new UsersFilterSerializer());
  }
}

export class ProductReviewFilterProvider extends ResourceProvider<ReviewFilter> {
  constructor() {
    super("rest_product_review_filter", reviewFilterSerializer);
  }
}

export class ServiceReviewFilterProvider extends ResourceProvider<ReviewFilter> {
  constructor() {
    super("rest_service_review_filter", reviewFilterSerializer);
  }
}

export const versionFilterService = new VersionFilterProvider();

export const taskFilterService = new TaskFilterProvider();

export const issueFilterService = new IssueFilterProvider();

export const orderFilterService = new OrderFilterProvider();

export const userFilterService = new UsersFilterProvider();

export const productReviewFilterService = new ProductReviewFilterProvider();

export const serviceReviewFilterService = new ServiceReviewFilterProvider();
