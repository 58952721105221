import { Box, Button, TextareaAutosize } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { Comments } from "../../models/comments/Comments";
import { commentService } from "../../models/comments/service";

export const CommentsTextField = (props: any) => {
  const CommentStyle = makeStyles(() => ({
    notFocused: {
      width: "1px",
      height: "1px",
      position: "absolute",
      top: 44,
      left: "50%",
      background: palette.text.primary,
      transform: "scaleX(0)",
      transition: "all 0s",
    },

    focused: {
      width: "1px",
      height: "2px",
      position: "absolute",
      top: 43.5,
      left: "50%",
      background: palette.text.primary,
      transform: `scaleX(${window.innerWidth})`,
      transition: "transform 0.4s",
    },
  }));

  const { palette, muiTheme, id, name, width = "90%", setOpen = () => {}, componentIs = "comment", getComments, open } = props;

  const classes = CommentStyle();

  const [focused, setFocused] = useState(false);

  const [cancel, setCancel] = useState(true);

  const [written, setWritten] = useState("");

  const sendTest = () => {
    const comment = new Comments();
    comment.text = written;
    componentIs === "comment" ? (comment.task = id) : (comment.task = null);
    componentIs !== "comment" ? (comment.parent = id) : (comment.parent = null);
    commentService.create(comment).then((r: any) => {
      if (r) {
        getComments();
      }
    });
  };

  const setBlur = () => {
    document.getElementById("field")?.blur();
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        setOpen(() => {
          sessionStorage.setItem("commentOpenState", JSON.stringify(false));
          return false;
        });
        written.length && sendTest();
        event.preventDefault();
        written.length && setWritten("");
        written.length && setCancel(true);
        written.length && setFocused(false);
        written.length && setBlur();
      }
    };
    focused ? document.addEventListener("keydown", listener) : document.removeEventListener("keydown", listener);

    return () => {
      document.removeEventListener("keydown", listener);
    };
    //eslint-disable-next-line
  }, [focused, written]);

  useEffect(() => {
    open && setCancel(false);

    //eslint-disable-next-line
  }, [open]);

  return (
    <Box style={{ width: width, height: "auto", position: "relative", contain: "content" }}>
      <TextareaAutosize
        id="field"
        placeholder={`Add a ${name}...`}
        style={{
          border: "none",
          outline: "none",
          width: "100%",
          height: "40px",
          background: palette.background.default,
          color: palette.text.primary,
          fontSize: "1em",
          resize: "none",
          fontFamily: "sans-serif",
        }}
        onFocus={() => {
          setFocused(true);
          setCancel(false);
        }}
        onBlur={() => setFocused(false)}
        onChange={(event: any) => {
          setWritten(event.target.value);
        }}
        value={written}
      />
      <Box style={{ width: "100%", height: "0.5px", background: palette.text.primary, position: "absolute", top: 44 }}></Box>
      <Box className={focused ? classes.focused : classes.notFocused}></Box>
      <Box className="buttonsContainer" style={{ width: "100%", height: "auto", display: cancel ? "none" : "flex", justifyContent: "flex-end" }}>
        <Box display="flex" alignItems="center" sx={{ margin: "7px 0 7px 0" }}>
          <Button
            style={{ borderRadius: 0, marginRight: "15px" }}
            onClick={() => {
              setCancel(true);
              setWritten("");
              setOpen(() => {
                sessionStorage.setItem("commentOpenState", JSON.stringify(false));
                return false;
              });
            }}
          >
            CANCEL
          </Button>
          <Button
            style={{ borderRadius: 0, background: written ? "grey" : "lightgrey" }}
            disabled={written ? false : true}
            onClick={() => {
              setWritten("");
              setCancel(true);
              sendTest();
              setOpen(() => {
                sessionStorage.setItem("commentOpenState", JSON.stringify(false));
                return false;
              });
            }}
          >
            {name.toUpperCase()}
          </Button>
        </Box>
      </Box>
    </Box>
  );
};
