import { createTheme, PaletteMode } from "@mui/material";
import { amber, grey } from "@mui/material/colors";
import { createContext, useEffect, useState } from "react";
import { connect } from "react-redux";
import { store } from "../redux/store";

interface Primary {
  main: typeof amber[300];
  grey: typeof grey[500] | typeof grey[300];
}

interface Background {
  default: typeof grey[900] | string;
  paper: typeof grey[800] | typeof grey[600];
  paperLight: typeof grey[600] | typeof grey[500];
  smth: typeof grey[800] | typeof grey[200];
}

interface Text {
  primary: typeof grey[900] | string;
  secondary: typeof grey[500] | typeof grey[800];
}

export interface Palette {
  primary: Primary;
  background: Background;
  text: Text;
}

const setThemeToStore = (mode: string) => {
  let palette: Palette = {
    primary: {
      ...amber,
      ...(mode === "dark"
        ? {
            main: amber[300],
            grey: grey[500],
          }
        : {
            main: amber[300],
            grey: grey[300],
          }),
    },
    ...(mode === "dark"
      ? {
          background: {
            default: grey[900],
            paper: grey[800],
            paperLight: grey[600],
            smth: grey[800],
          },
        }
      : {
          background: {
            default: grey[100],
            paper: grey[600],
            paperLight: grey[500],
            smth: grey[200],
          },
        }),
    text: {
      ...(mode === "dark"
        ? {
            primary: "#fff",
            secondary: grey[500],
          }
        : {
            primary: grey[900],
            secondary: grey[800],
          }),
    },
  };

  const theme: unknown = createTheme({
    palette: {
      mode: (localStorage.getItem("paletteState") as PaletteMode) || "light",
    },
  });

  store.dispatch({
    type: "SET_PALETTE",
    payload: {
      palette: palette,
      theme: mode,
      muiTheme: theme,
    },
  });
};

export const PaletteProvider = createContext(() => {});

export function ThemeProvider(props: any) {
  const { app } = props;

  const [mode, setMode] = useState(localStorage.getItem("paletteState"));

  useEffect(() => {
    setMode(app?.currentTheme ? app.currentTheme : localStorage.getItem("paletteState"));
  }, [app?.currentTheme]);

  useEffect(() => {
    setThemeToStore(mode!);
  }, [mode]);

  return <>{props.children}</>;
}

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
  };
};

export default connect(mapStateToProps, {})(ThemeProvider);
