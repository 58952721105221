import { makeStyles, Theme } from "@material-ui/core";
import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useEffect, useState } from "react";

function Pagination(props: any) {
  const { palette, count = 400, pageSize = 20, setPageIndex, pageIndex, paginationTitle = "item count" } = props;
  const [arrayOfPages, setArrayOfPages] = useState([1]);
  const [arrOfCurrButtons, setArrOfCurrButtons] = useState<any[]>([]);
  const styleProps = { color: palette.text.primary };
  const classes = useStyles(styleProps);

  useEffect(() => {
    if (count) {
      const n: any[] = [];
      for (let i = 1; i <= Math.ceil(count / pageSize); i++) {
        n.push(i);
      }
      setArrayOfPages(n);
    }
  }, [count]);

  const paginationLogic = () => {
    let tempNumberOfPages: any[] = [...arrOfCurrButtons];
    let dotsInitial = "...";
    let dotsLeft = "...";
    let dotsRight = "...";

    if (arrayOfPages?.length < 8) {
      tempNumberOfPages = arrayOfPages;
    } else if (pageIndex >= 1 && pageIndex <= 3) {
      tempNumberOfPages = [1, 2, 3, 4, dotsInitial, arrayOfPages?.length];
    } else if (pageIndex === 4) {
      const sliced = arrayOfPages.slice(0, 5);
      tempNumberOfPages = [...sliced, dotsInitial, arrayOfPages?.length];
    } else if (pageIndex > 4 && pageIndex < arrayOfPages?.length - 3) {
      const sliced1 = arrayOfPages.slice(pageIndex - 3, pageIndex);
      const sliced2 = arrayOfPages.slice(pageIndex, pageIndex + 2);
      tempNumberOfPages = [1, dotsLeft, ...sliced1, ...sliced2, dotsRight, arrayOfPages?.length];
    } else if (pageIndex === arrayOfPages?.length - 3) {
      const sliced = arrayOfPages.slice(arrayOfPages?.length - 5);
      tempNumberOfPages = [1, dotsLeft, ...sliced];
    } else if (pageIndex > arrayOfPages?.length - 3) {
      const sliced = arrayOfPages.slice(arrayOfPages?.length - 4, arrayOfPages?.length);
      tempNumberOfPages = [1, dotsLeft, ...sliced];
    }

    setArrOfCurrButtons(tempNumberOfPages);
  };

  useEffect(() => {
    paginationLogic();
  }, [pageIndex, arrayOfPages]);

  const theme = useTheme();

  const isMobile = useMediaQuery("(max-width:600px)")

  return (
    <Grid
      item
      container
      lg={12}
      xs={12}
      sx={{
        justifyContent: "center",
        [theme.breakpoints.down("md")]: {
          justifyContent: "flex-start",
        },
        [theme.breakpoints.down("sm")]: {
          justifyContent: "center",
        },
      }}
    >
      <Grid
        item
        container
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          [theme.breakpoints.down("sm")]: {
            flexDirection: "column",
          },
        }}
      >
        <Grid
          item
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Grid className={classes.items}>
            {paginationTitle}: <span>{count}</span>
          </Grid>
          <Grid className={classes.items}>
            pages: <span>{arrayOfPages.length}</span>
          </Grid>
        </Grid>
        <Grid
          item
          container={isMobile}
          sx={{
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("md")]: {
              display: "grid",
              gridTemplateColumns: `repeat(5, auto)`,
              overflow: "auto",
            },
          }}
        >
          <button
            disabled={pageIndex === 1 || pageIndex === 0 ? true : false}
            style={{ color: pageIndex === 1 || pageIndex === 0 ? "lightgray" : "" }}
            className={classes.periferals}
            onClick={() => setPageIndex(1)}
          >
            &laquo;
          </button>
          <button
            disabled={pageIndex === 1 || pageIndex === 0 ? true : false}
            style={{ color: pageIndex === 1 || pageIndex === 0 ? "lightgray" : "", marginLeft: "20px" }}
            className={classes.periferals}
            onClick={() => setPageIndex(pageIndex <= 1 ? pageIndex : pageIndex - 1)}
          >
            &lsaquo;
          </button>
          <Grid style={{ boxSizing: "border-box", display: "grid", gridTemplateColumns: `repeat(${arrOfCurrButtons.length + 2}, auto)` }}>
            {arrOfCurrButtons.map((item, index) => {
              return (
                <button
                  disabled={item === "..." ? true : false}
                  id="pages"
                  key={index}
                  className={item !== "..." ? (item === pageIndex || (pageIndex === 0 && index === 0) ? classes.current : classes.pages) : classes.dots}
                  onClick={() => setPageIndex(item)}
                  style={{ color: palette.text.primary }}
                >
                  {item}
                </button>
              );
            })}
          </Grid>
          <button
            disabled={pageIndex === arrayOfPages?.length ? true : false}
            style={{ color: pageIndex === arrayOfPages?.length ? "lightgray" : "", marginRight: "20px" }}
            className={classes.periferals}
            onClick={() => setPageIndex(pageIndex >= arrayOfPages?.length ? pageIndex : pageIndex === 0 ? pageIndex + 2 : pageIndex + 1)}
          >
            &rsaquo;
          </button>
          <button
            disabled={pageIndex === arrayOfPages?.length ? true : false}
            style={{ color: pageIndex === arrayOfPages?.length ? "lightgray" : "" }}
            className={classes.periferals}
            onClick={() => setPageIndex(arrayOfPages?.length)}
          >
            &raquo;
          </button>
        </Grid>
      </Grid>
    </Grid>
  );
}

export default Pagination;

const useStyles = makeStyles((theme: Theme) => ({
  periferals: (props: any) => ({
    padding: "10px",
    background: "transparent",
    fontSize: "1.5rem",
    fontWeight: "bolder",
    border: "none",
    cursor: "pointer",
    margin: "5px",
    color: props.color,
    width: "40px",
    height: "40px",
    lineHeight: "1px",
    textAlign: "center",
  }),
  pages: {
    padding: "10px",
    background: "none",
    fontSize: "1rem",
    fontWeight: "bolder",
    cursor: "pointer",
    height: "40px",
    minWidth: "40px",
    width: "fit-content",
    margin: "5px",
    border: "none",
    borderRadius: "20px",
    "&:hover": {
      width: "fit-content",
      background: "lightgrey",
      animation: `$hoverEffect 350ms ${theme.transitions.easing.easeInOut}`,
      animationFillMode: "forwards",
    },
  },
  dots: (props: any) => ({
    padding: "10px",
    background: "transparent",
    fontSize: "1rem",
    fontWeight: "bolder",
    height: "40px",
    width: "40px",
    margin: "5px",
    border: "none",
    color: props.color,
  }),
  current: {
    padding: "10px",
    margin: "5px",
    fontSize: "1rem",
    fontWeight: "bolder",
    cursor: "pointer",
    height: "40px",
    minWidth: "40px",
    width: "fit-content",
    border: "none",
    borderRadius: "20px",
    animation: `$currEffect 500ms ${theme.transitions.easing.easeInOut}`,
    animationFillMode: "forwards",
  },
  items: (props: any) => ({
    fontSize: "1rem",
    fontWeight: "bolder",
    color: props.color,
    padding: "10px 20px 10px 20px",
  }),
  "@keyframes currEffect": {
    "0%": {
      background: "none",
    },
    "100%": {
      background: "rgba(128,128,128, 0.4)",
      color: "white",
    },
  },
  "@keyframes hoverEffect": {
    "0%": {
      background: "transparent",
    },
    "100%": {
      background: "rgba(211,211,211, 0.4)",
    },
  },
}));
