export const checkPermissions = (getter: () => void, model: string, permissions: any) => {
    if (permissions.filter((curr: any) => curr.model === model || curr.model === "Permission").length) {
      return getter()
    }
    else return null
  }

export const permissionList = (auth: any, model: string) => {
    return auth?.user?.roles[0].permissions?.filter((e: any) => e.model === model)
      .map((e: any) => e.permissions)[0]
}

export const permValid = (perList: string[], perm: string) => {
    if (perList?.find((e: any) => e === perm)) return true;
    else return false;
  }