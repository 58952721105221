import { Box, Grid, Table } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { environment } from "../../environment/environment";
import { setSnack } from "../../models/application/applicationActions";
import { userService } from "../../models/user/service";
import { User } from "../../models/user/user";
import AddressDetailsComponent from "../AddressDetailsComponent";
import MainTableHeaderBodyContainer from "../MainTableHeaderBodyContainer";

const UsersTable = (props: any) => {
  const { tableName, handleSubmit, tableList, handleOrder, handleOnToggle, handleOnChange, selectedFilters, filterItems, tableStructureList } = props;

  const localList = JSON.parse(localStorage.getItem(tableName) || "{}");
  const [cachedList, setCachedList] = useState<{ key: string; list: any[] }>({ key: "", list: [] });
  const [filteredList, setFilteredList] = useState<any[]>(Object.keys(localList).length ? localList : tableStructureList);
  const [user, setUser] = useState<User | null>(null);

  const getOverallTrueFalseFromFilteredList = () => {
    return filteredList.filter((e) => e.enabled === true).length ? true : false;
  };

  const handleCachedList = (item: { key: string; list: any[] }) => {
    setCachedList(item);
  };

  const handleFilteredList = (state: any[]) => {
    setFilteredList(state);
  };

  return (
    <Box>
      <Grid item overflow={"overlay"}>
        <Table sx={{ width: getOverallTrueFalseFromFilteredList() ? "100%" : "fit-content" }}>
          <MainTableHeaderBodyContainer
            padding="checkbox"
            size="small"
            align="center"
            filterItems={filterItems}
            headerList={filteredList}
            handleOrder={handleOrder}
            handleOnToggle={handleOnToggle}
            handleCachedList={handleCachedList}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            cachedList={cachedList}
            handleFilteredList={handleFilteredList}
            handleSubmit={handleSubmit}
            updater={new User()}
            service={userService}
            copySuccessMessage={"Id copied successfully"}
            tableList={tableList}
            tableName={tableName}
            search={`${environment.baseQueryParams}&tab=logs`}
            pathname={"userDetails"}
            editToolVisible={false}
            customTableCellItems={[
              {
                title: "addresses",
                element: <AddressDetailsComponent />,
              },
            ]}
          />
        </Table>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { setSnack })(UsersTable);
