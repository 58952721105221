import { ResourceProvider } from "../../providers/resource.provider";
import { Status } from "../Version/Version";
import { StatusSerializer } from "./serializer";


export class statusProvider extends ResourceProvider<Status> {
    constructor() {
        super("rest_status", new StatusSerializer())
    }
}

export const statusService = new statusProvider()
