import * as React from "react";
import { MoreVert, EditRounded, DeleteRounded } from "@mui/icons-material";
import {
	MenuItem,
	Menu,
	IconButton,
	MenuList,
	ListItemIcon,
	Typography,
	ListItemText,
	Grid,
} from "@mui/material";
import { permValid } from "../../helpers/permCheckers";

export const DottedMenuForSingleMessage = (props: any) => {
	const {
		text,
		setEditMode,
		setEditText,
		chatPer,
		setUid,
		portalUser,
		item,
		currUserName,
		setConfirmation,
	} = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const checkPermission = (per: string) => {
		if (portalUser === currUserName) {
			return permValid(chatPer, per);
		} else return false;
	};

	return (
		<Grid item sx={{ height: "fit-content", padding: 0 }}>
			<IconButton
				aria-label="more"
				id="long-button"
				aria-controls={open ? "long-menu" : undefined}
				aria-expanded={open ? "true" : undefined}
				aria-haspopup="true"
				onClick={handleClick}
			>
				<MoreVert />
			</IconButton>
			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: { backgroundColor: "background.test" },
					style: {
						height: "auto",
						width: "25ch",
					},
				}}
			>
				<MenuList>
					<MenuItem
						disabled={!checkPermission("update")}
						onClick={() => {
							handleClose();
							setEditMode(true);
							setEditText(text);
							setUid(item?.uid);
						}}
					>
						<ListItemIcon>
							<EditRounded fontSize="small" />
						</ListItemIcon>
						<ListItemText>Edit</ListItemText>
						<Typography variant="body2" color="secondary">
							{checkPermission("update") ? "Enabled" : "Disabled"}
						</Typography>
					</MenuItem>
					<MenuItem
						disabled={!checkPermission("delete")}
						onClick={() => {
							handleClose();
							setConfirmation({
								text: "ნამდვილად გსურთ წაშლა?",
								open: true,
								title: "მესიჯის წაშლა",
								task: null,
							});
						}}
					>
						<ListItemIcon>
							<DeleteRounded fontSize="small" />
						</ListItemIcon>
						<ListItemText>Delete</ListItemText>
						<Typography variant="body2" color="secondary">
							{checkPermission("delete") ? "Enabled" : "Disabled"}
						</Typography>
					</MenuItem>
				</MenuList>
			</Menu>
		</Grid>
	);
};
