import { Box, CircularProgress } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { QueryOptions } from "../../helpers/query.options";
import { userProfileService } from "../../models/user/service";
import { DottedMenuForNewChatUser } from "../DottedMenuForNewChatUser";
import { UserListSearchComponent } from "../Search/UserListSearchComponent";
import { UserListItem } from "./UserListItem";

const UserListComponent = (props: any) => {
  const {
    chats,
    searchOnChange,
    loading = false,
    chat,
    setChatId,
    searchValue,
    setPageIndex,
    setChatSelectDirection,
    pageIndex,
    auth,
    search,
    palette,
  } = props;

  const Classes = ListStyles();
  const [init, setInit] = useState(true);
  const [open, setOpen] = useState(false);
  const valueRef: any = useRef(null);
  const valueRefNewUser: any = useRef(null);
  const [users, setUsers] = useState<any[]>([]);
  const [allUsers, setAllUsers] = useState<any[]>([]);
  const [openNewUser, setOpenNewUser] = useState(false);
  const [chatIndex, setChatIndex] = useState(0);
  const [loadedUsers, loadUsers] = useState<string | null>(null);

  useEffect(() => {
    if (loadedUsers) {
      const options = new QueryOptions();
      options.pageSize = 100;
      options.search = loadedUsers;
      userProfileService.listWithCounter(options).then((r) => {
        if (r?.current?.length) {
          setUsers(r.current);
          loadUsers(null);
        }
      });
    }
  }, [loadedUsers]);

  useEffect(() => {
    if (init) {
      const options = new QueryOptions();
      options.pageSize = 25;
      userProfileService.listWithCounter(options).then((r) => {
        if (r?.current?.length) {
          setAllUsers(r.current);
          setInit(false);
        }
      });
    }
  }, [init]);

  return (
    <Box sx={{ width: "100%", backgroundColor: "background.default", position: "relative" }}>
      <DottedMenuForNewChatUser
        openNewUser={openNewUser}
        setOpenNewUser={setOpenNewUser}
        valueRef={valueRefNewUser}
        users={users}
        allUsers={allUsers}
        loadUsers={loadUsers}
        searchOnChange={searchOnChange}
        setChatId={setChatId}
      />
      <UserListSearchComponent
        searchValue={searchValue}
        palette={palette}
        setOpen={setOpen}
        valueRef={valueRef}
        open={open}
        classes={Classes}
        searchOnChange={searchOnChange}
      />
      {!loading ? (
        <Box
          component={Virtuoso}
          sx={{ width: "100%", minWidth: "350px" }}
          style={{ overflow: "overlay", height: "calc(100% - 64px)" }}
          data={chats}
          endReached={() => {
            !search && chats.length >= 24 && setPageIndex({ ...pageIndex, index: pageIndex.index + 1 });
          }}
          itemContent={(index: number, item: any) => (
            <UserListItem
              index={index}
              item={item}
              onSelect={() => {
                setChatId(item?.user?.user_id, item?.user?.id);
                setChatIndex((current: any) => {
                  index >= current ? setChatSelectDirection("right") : setChatSelectDirection("left");
                  return index;
                });
              }}
              user={auth?.user}
              chat={chat}
            />
          )}
        />
      ) : (
        <Box
          sx={{
            minWidth: "350px",
            alignSelf: "center",
            color: "text.primary",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 400,
          }}
        >
          <CircularProgress color={"inherit"} />
        </Box>
      )}
    </Box>
  );
};

export default UserListComponent;

const ListStyles = makeStyles(() =>
  createStyles({
    root: {
      "& .MuiInputBase-root": {
        height: "50px",
        marginTop: "7px",
        marginBottom: "7px",
      },
    },
  })
);
