import { Search } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import { Box, InputAdornment, ListSubheader, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export const onSearch$: any = new Subject().pipe(debounceTime(1500));

export const UserListSearchComponent = (props: any) => {
  const { setOpen, valueRef, open, classes, searchOnChange, palette, searchValue } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    onSearch$.subscribe((debounced: string) => {
      searchOnChange(`${debounced}`);
    });
  }, []);
  useEffect(() => {
    if (searchValue) {
      setValue(searchValue);
    }
    if (searchValue === "") {
      setValue("");
    }
  }, [searchValue]);

  return (
    <ListSubheader
      onBlur={() => setOpen(false)}
      sx={{
        backgroundColor: "background.test",
      }}
    >
      <Box style={{ position: "relative" }}>
        <Box
          style={{
            position: "absolute",
            right: 10,
            top: 10,
            height: 40,
            paddingTop: 6,
            marginRight: -5,
            overflow: "hidden",
          }}
        ></Box>
        <TextField
          sx={{
            "& .MuiInputBase-root": {
              color: "text.primary",
              border: `1px text.primary solid`,
            },
            "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
              borderColor: "text.primary",
              borderWidth: "1px",
            },
            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
              borderColor: `text.primary`,
              borderWidth: "2px",
            },
            "& .MuiOutlinedInput-notchedOutline": {
              borderColor: "text.primary",
            },
          }}
          onFocus={() => {
            setOpen(true);
          }}
          inputRef={valueRef}
          focused={open}
          size="medium"
          placeholder="Type to search..."
          fullWidth
          className={classes.root}
          variant="outlined"
          InputProps={{
            startAdornment: (
              <InputAdornment position="start" sx={{ height: "50px", color: "text.primary" }}>
                <Search />
              </InputAdornment>
            ),
            endAdornment: (
              <InputAdornment position="end">
                <CloseIcon
                  sx={{
                    display: value?.length === 0 ? "none" : "",
                    cursor: "pointer",
                    fontSize: 26,
                    color: "text.primary",
                  }}
                  onClick={() => {
                    setValue("");
                    onSearch$.next("");
                  }}
                />
              </InputAdornment>
            ),
          }}
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
            onSearch$.next(e.target.value);
          }}
          onKeyDown={(e) => {
            if (e.key !== "Escape") {
              e.stopPropagation();
            }
          }}
        />
      </Box>
    </ListSubheader>
  );
};
