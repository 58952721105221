import { productPromotionSerializer } from './serializer';
import { ResourceProvider } from './../../providers/resource.provider';
import { ProductPromotion } from './ProductPromotion';

export class ProductPromotionService extends ResourceProvider<ProductPromotion> {
    constructor() {
        super(
            "rest_product_promotion",
            productPromotionSerializer
        )
    }
}

export const productPromotionService = new ProductPromotionService()