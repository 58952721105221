import { Resource } from "../resource";

export class TaskFilter extends Resource {
  assignee: IdName[] | any
  category: IdName[] | any
  component: IdName[] | any
  issue: IdName[] | any
  priority: IdName[] | any
  project: IdName[] | any
  reviewer: IdName[] | any
  status: IdName[] | any
  tester: IdName[] | any
  type: IdName[] | any
  user: IdName[] | any
  version: IdName[] | any
}

export class IssueFilter extends Resource {
  status: IdName[] | any
  type: IdName[] | any
  project: IdName[] | any
  category: IdName[] | any
  component: IdName[] | any
  user: IdName[] | any
}

export class VersionFilter extends Resource {
  uid: IdName[] | any
  name: IdName[] | any
  user: IdName[] | any
}

export class OrderFilter extends Resource {
  status: string | null | undefined;
  processingState: string | null | undefined;
  country: string | null | undefined;
  method: string | null | undefined;
  shippingType: string | null | undefined;
  packaging: string | null | undefined;
  coupon: string | null | undefined;
}

export class UserFilter extends Resource {
  successManagerId: string | null | undefined
  appVersion: string | null | undefined
}

export class IdName extends Resource {
  name: string | any;
}

export class ReviewFilter extends Resource {
  rate: number[] | null | undefined;
  reasons: IdName[] | any

}


