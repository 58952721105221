import { Resource } from '../resource'
import { Serializer } from '../serializer'
import { Chat, ChatMessage, ChatMessageUpdate } from './chatMessages'

export class ChatMessageSerializer implements Serializer {
    fromJson(json: any) {
        const message = new ChatMessage()
        message.text = json.text
        message.original_message = json.original_message
        message.created = json.created
        message.portal_user = json.portal_user
        message.owner = json.owner
        message.reply_user = json.reply_user
        message.uid = json.uid
        return message
    }

    toJson(message: ChatMessage): any {
        const obj: any = {}
        obj.id = message.id
        obj.text = message.text
        if (message.original_message)
            obj.original_message = message.original_message

        return obj
    }
}
export class ChatSerializer implements Serializer {
    fromJson(json: any) {
        const chat = new Chat()
        chat.id = json.id
        chat.user = json.user
        chat.read = json.read
        if (json.messages) {
            const serializer = new ChatMessageSerializer();
            chat.messages = json.messages.map((r: ChatMessage) => serializer.fromJson(r))
        }
        return chat
    }

    toJson(chat: Chat): any {
        const obj: any = {}
        if (chat?.messages) {
            const [msg] = chat.messages
            obj.id = msg.id
            obj.text = msg.text
        }
        if (chat?.user) {
            obj.user = chat.user
        }
        if (chat?.read) {
            obj.read = chat.read
        }
        if (chat?.id) {
            obj.id = chat?.id
        }
        return obj;
    }
}

export class ChatMessageUpdateSerializer implements Serializer {
    fromJson(json: any) {
        return json
    }
    toJson(item: ChatMessageUpdate) {
        const obj: any = {
            id: item.id
        }
        if (item.text) {
            obj.text = item.text
        }
        return obj
    }
}