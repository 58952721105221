import { Serializer } from '../serializer'
import { Category } from './category'


export class CategorySerializer implements Serializer {
    fromJson(json: any) {
        const category = new Category()
        category.name = json.name
        category.id = json.id
        category.description = json.description
        category.created = json.created
        category.nameText = json.name_text
        category.uid = json.uid
        category.value = json.value
        return category
    }

    toJson(item: Category) {
        const obj: any = {}
        
        return obj
    }
}
