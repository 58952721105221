import { FormControl, MenuItem, NativeSelect, Select } from "@mui/material";
import React from "react";
import { QueryOptions } from "../helpers/query.options";
import { SuccessManagerItem } from "../models/successManagers/SuccessManagers";

export const SuccessManagerSelect = (props: any) => {
	const filterByManagerId = (value: string | null | undefined = null) => {
		if (value) {
			return successManagersList.filter((e: any) => e?.id === value)[0]?.manager?.id;
		} else return "N/A";
	};

	const { successManagersList, successManagersFilterActionHandler, successManagerId } = props;
	return (
		<FormControl
			sx={{
				width: "100%",
				"& .MuiInputLabel-root.Mui-focused": {
					color: "text.primary",
				},
				"& .MuiInputLabel-root": {
					color: "text.primary",
				},
				"& .MuiInputBase-root": {
					color: "text.primary",
					border: `1px text.primary solid`,
				},
				"& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
					borderColor: "text.primary",
					borderWidth: "1px",
				},
				"& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
					borderColor: `text.primary`,
					borderWidth: "2px",
				},
			}}
			variant="standard"
		>
			<Select
				MenuProps={{
					sx: {
						"& .MuiPaper-root": {
							backgroundColor: "background.default",
						},
					},
				}}
				name="project"
				label="პროექტი"
				id="project"
				value={successManagerId || ""}
				renderValue={() => {
					if (successManagerId === null) return "All";
					else return filterByManagerId(successManagerId);
				}}
				onChange={(event) => {
					successManagersFilterActionHandler(event);
				}}
			>
				<MenuItem value={"every"}>{"All"}</MenuItem>
				{successManagersList?.map((item: SuccessManagerItem, index: string) => {
					return (
						<MenuItem key={index} value={item?.id}>
							{item.manager?.id ? item.manager?.id : "N/A"}
						</MenuItem>
					);
				})}
			</Select>
		</FormControl>
	);
};
