import { Serializer } from "../models/serializer";
import { Observable, of } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { Resource } from "../models/resource";
import { ajax } from "rxjs/ajax";
import { QueryOptions } from "../helpers/query.options";
import { environment } from "../environment/environment";
import { encode } from "base-64";
import { User } from "../models/user/user";
import { from } from "rxjs";
import axios from "axios";

const API_BASE_URL = environment.basePath;

export class ResourceProvider<T extends Resource> {
  constructor(public endpoint: string, public serializer: Serializer) { }

  private async getToken(): Promise<string> {
    const usr: any = localStorage.getItem("currentUser") ? localStorage.getItem("currentUser") : "{}"
    const user: User | null = await JSON.parse(usr);
    if (!user?.accessToken) {
      return "app";
    }
    return user.accessToken;
  }

  public list = async (options: QueryOptions) => new Promise((resolve, reject) => {
    axios.get(`${environment.basePath}/${this.endpoint}`).then((resp) => resolve(this.convertList(resp.data))).catch((error) => reject(error))
  })
  /* ;
  
  } */

  public async create(item: T): Promise<T> {
    const token = await this.getToken();

    const headers: any = {
      "Content-Type": "application/json",
    };
    const url = `${API_BASE_URL}/${this.endpoint}`;
    headers["Authorization"] = `Bearer ${token}`;
    const body = this.serializer.toJson(item);
    return ajax({
      url,
      method: "POST",
      headers: headers,
      body,
    })
      .pipe(
        map((data: { response: any; }) => {
          return this.convert(data.response);
        }),
        catchError((error) => {
          if ([401].indexOf(error.status) > -1) {
            this.realodApplication();
          }
          return of(error);
        })
      )
      .toPromise();
  }

  public async listAuth(queryOptions: QueryOptions): Promise<T> {
    const token = await this.getToken();
    const headers: any = {};
    headers['Authorization'] = `Bearer ${token}`;
    const url = `${API_BASE_URL}/${this.endpoint
      }?${queryOptions.toQueryString()}`
    return ajax({
      url,
      method: 'GET',
      headers,
    })
      .pipe(
        map((data: any) => {
          return this.convertList(data.response);
        }, catchError((error) => {
          return of(error);
        })),
      )
      .toPromise()
  }
  public async listDict(queryOptions: QueryOptions): Promise<T> {
    const token = await this.getToken();
    const headers: any = {};
    headers['Authorization'] = `Bearer ${token}`;
    const url = `${API_BASE_URL}/${this.endpoint
      }?${queryOptions.toQueryString()}`
    
    return ajax({
      url,
      method: 'GET',
      headers,
    })
      .pipe(
        map((data: any) => {
          return this.convertObj(data.response);
        }, catchError((error) => {
          return of(error);
        })),
      )
      .toPromise()
  }

  public async createForm(item: T): Promise<T> {
    const token = await this.getToken();
    const headers: any = {};
    const url = `${API_BASE_URL}/${this.endpoint}`;
    if (token === "app") {
      headers["CustomAuthorization"] =
        "Basic " + encode(environment.apiKey + ":" + environment.apiSecret);
    } else {
      headers["Authorization"] = "Basic " + encode(token + ":noToken");
    }
    item = this.serializer.toJson(item);
    const form = new FormData();
    for (const [key, value] of Object.entries(item)) {
      form.append(key, value);
    }

    const response: any = fetch(url, {
      method: "POST",
      mode: "cors",
      cache: "no-cache",
      credentials: "same-origin",
      headers,
      redirect: "follow",
      referrerPolicy: "no-referrer",
      body: form,
    }).then((resp) => { });
    const r = from(response) as any;
    return r.pipe(map((data: any) => this.serializer.fromJson(data) as T));
  }

  public async createWithFile(item: T): Promise<T> {
    const token = await this.getToken();
    item = this.serializer.toJson(item);
    const form = new FormData();
    for (const [key, value] of Object.entries(item)) {
      form.append(key, value);
    }

    const headers: any = {};
    if (token && token.length) {
      headers["Authorization"] = `Bearer ${token}`;
    }

    return ajax({
      url: `${API_BASE_URL}/${this.endpoint}`,
      method: "POST",
      headers,
      body: form,
    })
      .pipe(
        map((data: { response: { data: any; }; }) => this.serializer.fromJson(data.response.data) as T),
        catchError((error) => {
          if ([401].indexOf(error.status) > -1) {
            this.realodApplication();
          }
          return of(error);
        })
      )
      .toPromise();
  }

  public async update(item: T): Promise<T> {
    const token = await this.getToken();
    item = this.serializer.toJson(item);

    const form = new FormData();
    for (const [key, value] of Object.entries(item)) {
      form.append(key, value);
    }
    const headers: any = {};
    if (token && token.length) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return ajax({
      url: `${API_BASE_URL}/${this.endpoint}/${item.id}`,
      method: "PUT",
      headers: headers,
      body: form,
    })
      .pipe(
        map((data: { response: any; }) => this.serializer.fromJson(data.response)),
        catchError((error) => {
          if ([401].indexOf(error.status) > -1) {
            this.realodApplication();
          }
          return of(error);
        })
      )
      .toPromise();
  }
  public async updateNoForm(item: T): Promise<T> {
    const token = await this.getToken();
    const body = this.serializer.toJson(item);
    const headers: any = {
      "Content-Type": "application/json",
    };
    if (token && token.length) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return ajax({
      url: `${API_BASE_URL}/${this.endpoint}/${item.id}`,
      method: "PUT",
      headers: headers,
      body,
    })
      .pipe(
        map((data: { response: { data: any; }; }) => this.serializer.fromJson(data.response.data)),
        catchError((error) => {
          if ([401].indexOf(error.status) > -1) {
            this.realodApplication();
          }
          return of(error);
        })
      )
      .toPromise();
  }

  async read(id: string): Promise<T> {
    const token = await this.getToken();
    const headers: any = { Accept: "*/*", "Content-Type": "application/json" };
    if (token && token.length) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `${API_BASE_URL}/${this.endpoint}/${id}`;
    return ajax({
      url: url,
      method: "GET",
      headers,
    })
      .pipe(
        map((data: any) => {
          return this.serializer.fromJson(data.response) as T;
        }),
        catchError((error) => {
          if ([401].indexOf(error.status) > -1) {
            this.realodApplication();
          }
          return of(error);
        })
      )
      .toPromise();
  }
  

  realodApplication = () => {
    const user = new User();
    user.delete();
    window.location.href = "/";
  };

  async listWithCounter(queryOptions?: QueryOptions) {
    const token = await this.getToken();
    const headers: any = { Accept: "*/*", "Content-Type": "application/json" };
    if (token && token.length) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `${API_BASE_URL}/${this.endpoint
      }?${queryOptions?.toQueryString()}`;
    return ajax({ url: url, method: "GET", headers })
      .pipe(
        map((data: any) =>
          this.convertListWithCounter(data.response, this.endpoint)
        ),

        catchError((error) => {
          if ([401].indexOf(error.status) > -1) {
            this.realodApplication();
          }
          return of(error);
        })
      )
      .toPromise();
  }

  public async createAuth(item: T): Promise<T> {
    const token = await this.getToken();
    const headers: any = {
      'Content-Type': 'application/json',
    };
    const url = `${API_BASE_URL}/${this.endpoint}`;
    headers['Authorization'] = `Bearer ${token}`;
    const body = this.serializer.toJson(item);
    return ajax({
      url,
      method: 'POST',
      headers: headers,
      body,
    })
      .pipe(
        map((data: any) => this.convert(data.response.data)),
        catchError(error => {
          return of(error);
        }),
      )
      .toPromise();
  }

  async readListWithCounter(queryOptions: QueryOptions, id: string) {
    const token = await this.getToken();
    const headers: any = { Accept: "*/*", "Content-Type": "application/json" };
    if (token && token.length) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    const url = `${API_BASE_URL}/${this.endpoint
      }/${id}?${queryOptions?.toQueryString()}`;
    return ajax({ url: url, method: "GET", headers })
      .pipe(
        map((data: any) =>
          this.convertListWithCounter(data.response, this.endpoint)
        ),

        catchError((error) => {
          if ([401].indexOf(error.status) > -1) {
            this.realodApplication();
          }
          return of(error);
        })
      )
      .toPromise();
  }

  dict(queryOptions: QueryOptions): Observable<any> {
    return ajax({
      url: `${API_BASE_URL}/${this.endpoint}?${queryOptions.toQueryString()}`,
      method: "GET",
    }).pipe(
      map((data: any) => this.convertDict(data)),
      catchError((error) => {
        if (error.status === 401) {
          this.realodApplication();
        }
        return of(error);
      })
    );
  }

  async delete(id: string) {
    const token = await this.getToken();
    const headers: any = { Accept: "*/*", "Content-Type": "application/json" };
    if (token && token.length) {
      headers["Authorization"] = `Bearer ${token}`;
    }
    return ajax({
      url: `${API_BASE_URL}/${this.endpoint}/${id}`,
      method: "DELETE",
      headers,
    })
      .pipe(
        map((data: { response: { data: any; }; }) => this.serializer.fromJson(data.response.data)),
        catchError((error) => {
          if ([401].indexOf(error.status) > -1) {
            this.realodApplication();
          }
          return of(error);
        })
      )
      .toPromise();
  }

  private convert(data: any): any {
    return this.serializer.fromJson(data.data);
  }

  private convertList(data: any, endpoint = ""): any {
    return {
      results: data.map((item: any) => this.serializer.fromJson(item)),
    };
  }

  private convertListWithCounter(data: any, endpoint = ""): any {
    return {
      count: data.count,
      current: data.current.map((item: any) => this.serializer.fromJson(item)),
      next: data.next.map((item: any) => this.serializer.fromJson(item)),
      hasNext: data.has_next,
      readCount: data.read_count
    };
  }

  private convertDict(data: any): any {
    return {
      message: data.message,
      count: data.count,
      type: data.type,
      updated: data.updated,
      results: this.serializer.fromJson(data.data),
    };
  }
  private convertObj(data: any): any {
    return this.serializer.fromJson(data)
    
  }
}
