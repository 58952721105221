import { Serializer } from '../serializer'
import { Version } from './Version';


export class VersionSerializer implements Serializer {
    fromJson(json: any ) {
        const version = new Version()
        version.id = json.id
        version.uid = json.uid
        version.created = json.created
        version.name = json.name
        version.startDate = json.start_date
        version.releaseDate = json.release_date
        version.progress = json.progress
        version.is_enabled = json.is_enabled
        version.version_android = json.version_android
        version.version_ios = json.version_ios
        return version        
    }

    toJson(version: Version ) {
        const obj: any = {
            name: version.name,
            uid: version.uid,
            created: version.created,
            id: version.id,
            start_date: version.startDate,
            release_date: version.releaseDate,
            is_enabled: version.is_enabled,
            version_android: version.version_android,
            version_ios: version.version_ios
        }
        return obj
    }
}
