import { Edit, FileCopyOutlined, KeyboardArrowUp, Visibility } from "@mui/icons-material";
import { Box, createTheme, TableCell, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import _ from "lodash";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import "../App.css";
import { environment, ReduxState } from "../environment/environment";
import { getPriorityColor, getStatusColor, urlifyForTable } from "../helpers/utilities";
import { setSnack } from "../models/application/applicationActions";
import QuickChangeShortcut from "./Dialogs/QuickChangeShortcut";
import { ImageViewerMain } from "./ImageViewer/ImageViewerMain";
import ReviewReasonsComponent from "./ReviewReasonsComponent";
import { TypographyItem } from "./Typography/TypographyItem";

function Tablebody({
  tableItem,
  dependencyList,
  setSubmitted,
  updater,
  service,
  mode,
  app,
  setSnack,
  item,
  detailsLinkToolVisible,
  copyToolVisible,
  editToolVisible,
  handleEdit,
  copySuccessMessage,
  props,
  pathname,
  search = environment.baseQueryParams,
  iconSx,
}: any) {
  const getValue = (item: any) => {
    const value = String(
      !!tableItem[item.getBy] && !!tableItem[item.getBy]["name"]
        ? tableItem[item.getBy]["name"]
        : tableItem[item.getBy]?.full_name ||
            tableItem[item.getBy]?.fullName ||
            (_.get(tableItem, item.getBy) === undefined || _.get(tableItem, item.getBy) === null
              ? item.getBy === "image"
                ? "null.jpg"
                : "Not Provided"
              : _.get(tableItem, item.getBy))
    );
    return value.indexOf(".jpg" || ".png") > -1 ? value : value === "Not Provided" ? value : (urlifyForTable(value) as any);
  };

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const Tablecell = (index: number, value: any, getBy?: string) => {
    return (
      <TableCell
        key={index}
        sx={{
          textAlign: "center",
          width: "fit-content",
        }}
        align="center"
        padding="normal"
        size="small"
      >
        {getBy === "reasons" ? <ReviewReasonsComponent key={index} reasons={value} /> : value}
      </TableCell>
    );
  };

  const TablecellDynamic = (index: number, backgroundColor: any, color: any, item: any, value: any) => {
    return (
      <TableCell align="center" padding="normal" size="small" key={index} sx={{ backgroundColor: backgroundColor, contain: "strict" }}>
        <Box style={{ height: "100%", width: "100%", textAlign: "center" }}>
          <QuickChangeShortcut
            app={app}
            onAlert={onAlert}
            tooltip={getValue(item)}
            item={tableItem}
            setSubmitted={setSubmitted}
            updater={updater}
            service={service}
            getBy={item.getBy}
            appFilterer={item.appFilterer}
            iconSx={iconSx}
          />
          {item.getBy === "priority" ? <KeyboardArrowUp sx={{ ...iconSx, color: color }} /> : value}
        </Box>
      </TableCell>
    );
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
    typography: {
      fontSize: 12,
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 12,
          },
        },
      },
    },
  });

  const ToolTip = (item: any) => {
    return (
      <Tooltip
        title={
          item.getBy !== "image" ? (
            <Typography variant="h6" sx={{ lineHeight: "1.5rem", fontWeight: 700 }}>
              {getValue(item)}
            </Typography>
          ) : (
            ""
          )
        }
      >
        <Box sx={{ width: "100%", display: "flex", justifyContent: "center" }}>
          <Box
            style={{
              justifyContent: "center",
              display:
                item.getBy !== "description" &&
                item.getBy !== "created" &&
                item.getBy !== "issue" &&
                item.getBy !== "name" &&
                item.getBy !== "comment" &&
                item.getBy !== "productName"
                  ? "inline-flex"
                  : "-webkit-box",
              whiteSpace: "normal",
              wordBreak: "break-all",
              WebkitBoxOrient: "vertical",
              textOverflow: "ellipsis",
              overflow: "hidden",
              WebkitLineClamp: 2,
              fontSize: 12,
              margin: 0,
              height: "auto",
              width: item.width,
              textAlign: "center",
              padding: "0px 10px",
            }}
          >
            {getValue(item).length && getValue(item).indexOf("https://phubber.ge") > -1 ? (
              <Box sx={{ contain: "content", display: "flex", justifyContent: "center" }}>
                <ImageViewerMain displayImageSrc={getValue(item)} additionalImages={tableItem.images} />
              </Box>
            ) : getValue(item) === "Not Provided" ? (
              <TypographyItem sx={{ width: "100%" }} color={"red"} fontWeight={600} text={getValue(item)} fontSize={12} />
            ) : getValue(item).length && getValue(item).indexOf(".jpg") > -1 ? (
              <Box sx={{ contain: "content", display: "flex", justifyContent: "center" }}>
                <ImageViewerMain displayImageSrc={`https://phubber.ge/assets/imgs/products/${getValue(item)}`} additionalImages={tableItem.images} />
              </Box>
            ) : (
              getValue(item)
            )}
          </Box>
        </Box>
      </Tooltip>
    );
  };

  const tableBodyCells = () => {
    return dependencyList.map((item: any, index: number) => {
      if (item.enabled && item.appFilterer !== null) {
        switch (item && item.getBy) {
          case "priority":
            return TablecellDynamic(index, "", getPriorityColor(tableItem), item, ToolTip(item));
          case "status":
            return TablecellDynamic(index, getStatusColor(tableItem), "", item, ToolTip(item));
          default:
            return TablecellDynamic(index, "", "", item, ToolTip(item));
        }
      } else if (item.enabled) {
        return Tablecell(index, item.getBy === "reasons" ? tableItem.reasons : ToolTip(item), item.getBy);
      }
    });
  };

  return (
    <ThemeProvider theme={localTheme}>
      <TableCell {...props} sx={{ display: detailsLinkToolVisible ? "table-cell" : "none" }}>
        <Box
          sx={{ color: "text.primary" }}
          component={Link}
          to={{
            pathname: pathname === "versionDetails" ? "/versionDetails" : `/${pathname}/${item.id}`,
            search: pathname === "versionDetails" ? environment.baseQueryParams + "&tab=taskTable" + `&version=${item?.id}` : search,
          }}
        >
          <Visibility sx={{ ...iconSx, cursor: "pointer" }} />
        </Box>
      </TableCell>
      <TableCell {...props} sx={{ display: !detailsLinkToolVisible && !copyToolVisible && !editToolVisible ? "table-cell" : "none" }} />
      <TableCell sx={{ display: copyToolVisible ? "table-cell" : "none" }} {...props}>
        <Tooltip
          title={
            <Typography variant="h6" sx={{ lineHeight: "3rem", fontWeight: 700 }}>
              {`ID : ${item?.id}`}
            </Typography>
          }
        >
          <FileCopyOutlined
            onClick={() => {
              navigator.clipboard.writeText(item?.id);
              onAlert(`${copySuccessMessage}::success`);
            }}
            sx={{ ...iconSx, cursor: "pointer" }}
          />
        </Tooltip>
      </TableCell>
      <TableCell sx={{ display: editToolVisible ? "table-cell" : "none" }} {...props}>
        <Tooltip
          title={
            <Typography variant="h6" sx={{ lineHeight: "3rem", fontWeight: 700 }}>
              {`Edit`}
            </Typography>
          }
        >
          <Edit onClick={() => handleEdit(true, item)} sx={{ ...iconSx, cursor: "pointer" }} />
        </Tooltip>
      </TableCell>
      {tableBodyCells()}
    </ThemeProvider>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { app } = state;
  return {
    mode: app.currentTheme,
    app: app,
  };
};

export default connect(mapStateToProps, { setSnack })(Tablebody);
