import { ResourceProvider } from "../../providers/resource.provider";
import { Component } from "./component";
import { ComponentSerializer } from "./serializer";


export class ComponentProvider extends ResourceProvider<Component> {
    constructor() {
        super("rest_components", new ComponentSerializer())
    }
}


export const componentService = new ComponentProvider()
