import SettingsIcon from "@mui/icons-material/Settings";
import { Box, Checkbox, IconButton, ListItemText, Menu, MenuItem } from "@mui/material";
import React, { useCallback, useState } from "react";

export default function TableSettings(props: any) {
  const { list, setFilteredList, tableName } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const setToLocalStorage = (val: any) => {
    localStorage.setItem(tableName, JSON.stringify(val));
  };

  const setToState = () => {
    const filtered = JSON.parse(localStorage.getItem(tableName) || "{}");
    setFilteredList(filtered);
  };

  const handleSingleClick = (name: string) => {
    const filtered = list.map((item: any) => {
      if (item.name === name && item.enabled === true) return { ...item, enabled: false };
      else if (item.name === name && item.enabled === false) return { ...item, enabled: true };

      return item;
    });
    setToLocalStorage(filtered);
    setToState();
  };

  const handleAllClick = () => {
    const filtered = list.map((item: any) => {
      if (list.some((val: any) => val.enabled === false)) {
        return { ...item, enabled: true };
      } else return { ...item, enabled: false };
    });
    setToLocalStorage(filtered);
    setToState();
  };

  const RenderList = useCallback(() => {
    return list
      .filter((item: any) => item.name)
      .map((item: any, index: number) => (
        <MenuItem key={index} value={item.name} onClick={() => handleSingleClick(item.name)}>
          <Checkbox color="success" checked={item.enabled} />
          <ListItemText primary={item.name} sx={{ fontSize: 10, fontWeight: "bold" }} />
        </MenuItem>
      ));
    //eslint-disable-next-line
  }, [list]);

  return list?.length ? (
    <Box>
      <IconButton
        aria-label="more"
        id="long-button"
        aria-controls={open ? "long-menu" : undefined}
        aria-expanded={open ? "true" : undefined}
        aria-haspopup="true"
        onClick={handleClick}
      >
        <SettingsIcon sx={{ color: "white" }} />
      </IconButton>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
            height: "auto",
            width: "max-content" || "25ch",
          },
        }}
      >
        <MenuItem key={"remove"} value={""} onClick={() => handleAllClick()}>
          <Checkbox color="success" checked={list.some((val: any) => val.enabled === false) ? false : true} />
          <ListItemText primary={"Select All"} style={{ fontSize: 10 }} />
        </MenuItem>
        <RenderList />
      </Menu>
    </Box>
  ) : (
    <Box></Box>
  );
}
