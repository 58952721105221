import { Box, FormControl, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem, Select } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { testObjectservice } from "../../testing/service";
import { TestObject, TestStatus } from "../../testing/TestObject";
import { Version } from "../../Version/Version";
import { useTheme } from "@mui/material";

const TestingObjectItem = (props: any) => {
  const { item, statuses, onUpdate, user, id, evt } = props;
  const [selected, setSelected] = useState<TestStatus | null | string>("ss");
  const [loaded, setLoaded] = useState(false);
  const [object, setObject] = useState<TestObject>(new TestObject());

  useEffect(() => {
    if (item.testObject) {
      setSelected(item.testObject.status);
      setLoaded(true);
    } else {
      setSelected(null);
      setLoaded(true);
    }
  }, [id, item]);

  useEffect(() => {
    if (object.tester) toServer(object);
  }, [object]);

  const toServer = (testObject: TestObject) => {
    if (selected) {
      testObjectservice.update(testObject).then((resp: any) => {
        item.testObject = resp;
        onUpdate(item);
      });
    } else {
      testObjectservice.create(testObject).then((resp: any) => {
        item.testObject = resp;
        onUpdate(item);
      });
    }
  };

  const theme = useTheme();
  return loaded ? (
    <ListItem
      sx={{
        flexDirection: "row",
        [theme.breakpoints.down("sm")]: {
          flexDirection: "column",
        },
      }}
    >
      <ListItemText primary={item.name} sx={{ width: "auto" }} />
      <ListItemText secondary={item.description} sx={{ width: "auto" }} />
      <Box>
        <Formik
          enableReinitialize
          initialValues={{ status: selected ? selected : "To Do" }}
          onSubmit={(values) => {
            item.status = values.status;
            const testObject = new TestObject();
            testObject.status = values.status;
            onUpdate(item);
          }}
        >
          {({ values, handleSubmit, setFieldValue, handleChange, handleBlur, errors, validateForm }: any) => (
            <Form
              onSubmit={(values) => {
                handleSubmit(values);
              }}
            >
              <FormControl>
                <Select
                  id="status"
                  name="status"
                  value={values?.status?.id || "62bc3445996692fa3c9b610e"}
                  sx={{ pointerEvents: evt }}
                  variant={"standard"}
                  onChange={(event) => {
                    const [status] = statuses.filter((s: any) => s.id === event.target.value);
                    const testObject = new TestObject();
                    testObject.status = status;

                    const version = new Version();
                    version.id = id;
                    testObject.version = version;
                    testObject.tester = user;
                    testObject.testListItem = item;
                    if (selected) {
                      testObject.id = item.testObject.id;
                      testObject.tester = user;
                    }
                    setObject(testObject);
                    setFieldValue("status", status);
                  }}
                >
                  {statuses?.map((status: any, index: number) => {
                    return (
                      <MenuItem value={status.id} key={index}>
                        {status.name}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Form>
          )}
        </Formik>
      </Box>
    </ListItem>
  ) : (
    <Box></Box>
  );
};

export const AccordionList = (props: any) => {
  const { items, statuses, onUpdate, user, id, evt } = props;

  return (
    <List>
      {items.map((item: any, index: number) => {
        return <TestingObjectItem evt={evt} onUpdate={onUpdate} item={item} user={user} statuses={statuses} key={index} id={id} />;
      })}
    </List>
  );
};
