import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { QueryOptions } from "../../helpers/query.options";
import { attributeService } from "../../models/Attribute/service";
import { Brand } from "../../models/Brands/Brand";
import { brandService } from "../../models/Brands/service";
import { ProductOverride } from "../../models/Product/Product";
import { productOverrideService } from "../../models/Product/service";
import { ProductForm } from "../Forms/ProductForm";

export const ProductFormDialog = (props: any) => {
  const { open, handleDialog, product, onAlert, setSubmitted } = props;

  const [brandList, setBrandList] = useState<Brand[]>([]);
  const [depObject, setDepList] = useState<{ [key: string]: any }>({
    color: { key: "color", value: [] },
    category: { key: "category", value: [] },
    subcategory: { key: "subcategory", value: [] },
    size: { key: "size", value: [] },
    value: { key: "section", value: [] },
    condition: { key: "condition", value: [] },
    section: { key: "section", value: [] },
  });

  const formikRef: any = useRef();

  const handleSubmit = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  useEffect(() => {
    const options = new QueryOptions();
    brandService.listWithCounter(options).then((res: any) => {
      setBrandList(res.current);
    });

    return () => setBrandList([]);
  }, [open]);

  useEffect(() => {
    Object.entries(depObject).map(([key, value]) => {
      const options: any = new QueryOptions();
      options[key] = "1234";
      attributeService.listWithCounter(options).then((res) => {
        setDepList((prev) => {
          return { ...prev, [key]: { key: key, value: res.current } };
        });
      });
    });

    return () => setDepList([]);
  }, [open]);

  const postForm = (values: any) => {
    const prod = new ProductOverride();
    prod.id = product.id;
    prod.name = values.name;
    prod.brand = values.brand.id;
    prod.price = values.price;
    prod.specialPrice = values.specialPrice;
    prod.description = values.description;
    prod.stock = values.stock;
    prod.ownerPrice = Number(values.ownerPrice);
    prod.isEnabled = values.isEnabled === "Enabled" ? "true" : "false";
    prod.country = values?.country.toLowerCase();
    prod.section = values.section.id;
    prod.condition = values.condition.id;
    prod.category = values.category.id;
    prod.subcategory = values.subcategory.id;
    prod.size = values.size.id;
    prod.color = values.color.id;
    productOverrideService.create(prod).then((r) => {
      if (r?.name === "AjaxError") {
        onAlert(`product did not update::error`);
      } else {
        onAlert(`product updated successfully::success`);
        setSubmitted((prev: any) => !prev);
        handleDialog(false);
      }
    });
  };

  const getItems = (search: any) => {
    const options: any = new QueryOptions();
    options.pageSize = 25;
    if (search.searchValue?.length && search.id === "brand") {
      options.search = search.searchValue;
    }
    brandService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setBrandList(r.current);
      }
    });
    if (search.searchValue?.length && search.id !== "brand") {
      const options: any = new QueryOptions();
      options.search = search.searchValue;
      options[search.id] = "1234";
      attributeService.listWithCounter(options).then((res: any) => {
        setDepList((prev) => {
          return { ...prev, [search.id]: { key: search.id, value: res.current } };
        });
      });
    }
  };

  const lists = {
    brandList: brandList,
    categoryList: depObject.category?.value,
    subcategoryList: depObject.subcategory?.value,
    sizeList: depObject.size?.value,
    colorList: depObject.color?.value,
    sectionList: depObject.section?.value,
    conditionList: depObject.condition?.value,
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"md"}>
      <DialogTitle
        sx={{
          backgroundColor: "background.default",
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
        }}
      >
        Change Product Details
      </DialogTitle>
      <DialogContent
        sx={{
          backgroundColor: "background.default",
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
        }}
      >
        <Box onClick={() => handleDialog(false)} sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer", color: "text.primary" }}>
          <CloseIcon color="inherit" />
        </Box>
        <ProductForm product={product} postForm={postForm} searchOnChange={getItems} lists={lists} formikRef={formikRef} />
      </DialogContent>
      <DialogActions
        sx={{
          backgroundColor: "background.default",
          backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
        }}
      >
        <Button color="secondary" onClick={() => handleDialog(false)}>
          Exit
        </Button>
        <Button onClick={handleSubmit} color="secondary" type="submit" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};
