import CloseIcon from "@mui/icons-material/Close";
import { Box } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import React from "react";
import { UserProfileSection } from "../User/UserProfileSection";

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export const UserProfileDialog = (props: any) => {
  const { open, setOpen, userProfile, chat, userProfileUpdateHandler } = props;

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Dialog fullScreen={true} open={open} sx={{ minHeight: window.innerHeight }} onClose={handleClose} TransitionComponent={Transition}>
      <Box sx={{ margin: "0 auto", position: "relative", width: "100%" }}>
        <Box
          sx={{
            borderRadius: 20,
            width: 40,
            height: 40,
            right: 10,
            top: 20,
            position: "absolute",
            zIndex: 12000,
          }}
        >
          <CloseIcon onClick={handleClose} sx={{ fontSize: 30 }} />
        </Box>
        <UserProfileSection userProfile={userProfile} chat={chat} userProfileUpdateHandler={userProfileUpdateHandler} />
      </Box>
    </Dialog>
  );
};
