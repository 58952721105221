import { SxProps } from "@mui/material";
import { Dispatch, SetStateAction } from "react";
import { App } from "../../environment/environment";
import { Issue } from "../../models/issue/Issue";
import { IssueProvider } from "../../models/issue/service";
import { TaskProvider } from "../../models/Task/service";
import { Task } from "../../models/Task/Task";
import ShortcutForm from "../Forms/ShortcutForm";

interface FormDialogProps {
  item?: Issue | Task | any;
  app?: App;
  onAlert?: Function;
  setSubmitted?: Dispatch<SetStateAction<boolean>>;
  updater?: Issue | Task | any;
  service?: IssueProvider | TaskProvider | any;
  getBy?: string;
  appFilterer?: string;
  tooltip?: string;
  iconSx?: SxProps;
}

export default function QuickChangeShortcut(props: FormDialogProps) {
  const { item, app, onAlert = () => {}, setSubmitted = () => {}, updater, service, getBy, appFilterer, iconSx } = props;

  /**
   * Fucntions go here
   */

  const getValues = (values: any) => {
    values.issue ? (updater.issue = values.issue) : (updater.issue = item.issue);
    values.name ? (updater.name = values.name) : (updater.name = item.name);
    values.description ? (updater.description = values.description) : (updater.description = item.description);
    values.priority ? (updater.priority = values.priority) : (updater.priority = item.priority);
    values.status ? (updater.status = values.status) : (updater.status = item.status);
    values.version ? (updater.version = values.version) : (updater.version = item.version);
    values.project ? (updater.project = values.project) : (updater.project = item.project);
    values.component ? (updater.component = values.component) : (updater.component = item.component);
    values.category ? (updater.category = values.category) : (updater.category = item.category);
    values.type ? (updater.type = values.type) : (updater.type = item.type);
    values.assignee ? (updater.assignee = values.assignee) : (updater.assignee = item.assignee);

    if (item) {
      updater.id = item.id;
      service.update({ ...updater, id: item.id }).then((r: Issue | Task) => {
        if (r?.name === "AjaxError") {
          onAlert(`ველი ${updater.name} ვერ განახლდა::error`);
        } else {
          onAlert(`ველი ${updater.name} განახლდა წარმატებით::success`);
          setSubmitted((prev) => !prev);
        }
      });
    }
  };

  return <ShortcutForm  app={app} getValues={getValues} getBy={getBy} appFilterer={appFilterer} iconSx={iconSx} />;
}
