import { Close } from "@mui/icons-material";
import { Box, createTheme, Dialog, DialogContent, PaletteMode, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Dispatch, SetStateAction } from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../environment/environment";
import { versionService } from "../../models/Version/service";
import { Version } from "../../models/Version/Version";
import VersionsForm from "../Forms/VersionsForm";

interface VersionsFormDialogProps {
  setSubmitted: Dispatch<SetStateAction<boolean>>;
  item: Version;
  open: any;
  handleDialog: any;
  onAlert: any;
  mode: PaletteMode;
}

function VersionsFormDialog(props: VersionsFormDialogProps) {
  const { setSubmitted, item, open, handleDialog, onAlert, mode } = props;

  const getValues = (values: any) => {
    const ver = new Version();
    ver.uid = values.uid;
    ver.name = values.name;
    ver.startDate = values.startDate;
    ver.releaseDate = values.releaseDate;
    values.version_android === "" ? (ver.version_android = "NaN") : (ver.version_android = values.version_android);
    values.version_ios === "" ? (ver.version_ios = "NaN") : (ver.version_ios = values.version_ios);
    if (item) {
      ver.id = item.id;
      versionService.update({ ...ver, id: item.id }).then((r: any) => {
        if (r?.name === "AjaxError") {
          onAlert(`version didn't update::error`);
          handleDialog(false);
        } else {
          setSubmitted((prev) => !prev);
          onAlert(`ვერსია ${ver.name} განახლდა წარმატებით::success`);
          handleDialog(false);
        }
      });
    } else {
      versionService.create(ver).then((r: Version) => {
        onAlert(`ვერსია ${ver.name} დაემატა წარმატებით::success`);
        setSubmitted((prev) => !prev);
        handleDialog(false);
      });
    }
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
    typography: {
      fontSize: 13,
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 13,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={localTheme}>
      <Dialog open={open} fullWidth={true} maxWidth={"md"}>
        <DialogContent style={{ overflow: "hidden" }}>
          <Box onClick={() => handleDialog(false)} style={{ position: "absolute", right: 10, top: 20, cursor: "pointer" }}>
            <Close />
          </Box>
          <VersionsForm version={item} getValues={getValues} handleDialog={handleDialog} />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps, {})(VersionsFormDialog);
