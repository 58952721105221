import EventNoteIcon from "@mui/icons-material/EventNote";
import LocalPoliceOutlinedIcon from "@mui/icons-material/LocalPoliceOutlined";
import { Grid } from "@mui/material";
import Box from "@mui/material/Box";
import { useEffect, useState } from "react";
import { User } from "../../models/user/user";
import { TypographyItem } from "../Typography/TypographyItem";
import UserProfileInfoCard from "./UserProfileInfoCard";
import { UserProfileShoppingStatsCard } from "./UserProfileShoppingStatsCard";
import { UserProfileUpperSection } from "./UserProfileUpperSection";

export const UserProfileSection = (props: any) => {
  const { userProfile, userProfileUpdateHandler } = props;
  const [profile, setProfile] = useState<User | null>(null);

  useEffect(() => {
    setTimeout(() => {
      setProfile(userProfile);
    }, 400);
  }, [userProfile]);

  return (
    <Grid container direction={"column"} sx={{ paddingTop: "20px" }}>
      <Grid container spacing={0} flex={6.5}>
        <Grid container spacing={0} sx={{ width: "100%" }} display="flex" flexDirection="column" alignItems={"center"} item>
          <UserProfileUpperSection userProfile={profile} />
          <Grid
            item
            sx={{ width: "100%", height: "fit-content", overflow: "hidden", alignItems: "center", padding: "10px 0px" }}
            display="flex"
            flexDirection="row"
            alignItems={"top"}
            flex={3}
            justifyContent="space-between"
          >
            <UserProfileShoppingStatsCard
              userProfile={profile}
              filter={"seller"}
              itemCount={profile?.soldProducts ? profile?.soldProducts : 0}
              monetaryValue={profile?.soldProductsPrice ? profile?.soldProductsPrice : 0}
              sold={true}
            />

            <UserProfileShoppingStatsCard
              userProfile={profile}
              filter={"user"}
              itemCount={profile?.boughtProducts ? profile?.boughtProducts : 0}
              monetaryValue={profile?.boughtProductsPrice ? profile?.boughtProductsPrice : 0}
            />
          </Grid>
          <Grid container flex={2.5} display="flex" flexDirection="column" justifyContent="space-evenly" alignItems="left" paddingLeft="30px">
            <TypographyItem
              text={
                <Box component={"span"} style={{ display: "flex", alignItems: "center" }}>
                  <EventNoteIcon sx={{ fontSize: 25 }} />
                  <Box component={"span"} style={{ paddingLeft: "10px", fontWeight: "inherit" }}>
                    Joined: {new Date(profile?.created).toDateString()}
                  </Box>
                </Box>
              }
              fontSize={19}
              color="text.primary"
              fontWeight="500"
            />
            <TypographyItem
              text={
                <Box component={"span"} style={{ display: "flex", justifyContent: "top" }}>
                  <LocalPoliceOutlinedIcon sx={{ fontSize: 25, fontWeight: "inherit", padding: "5px 0px" }} />
                  <Box component={"span"} style={{ paddingLeft: "10px", textAlign: "left" }}>
                    Badges: {profile?.isFashionActivist ? "Fashion Activist," : ""} {profile?.isCreator ? "Culture Creator" : ""}
                  </Box>
                </Box>
              }
              fontSize={19}
              color="text.primary"
              fontWeight="500"
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container spacing={0} flex={3.5}>
        <UserProfileInfoCard userProfile={profile} userProfileUpdateHandler={userProfileUpdateHandler} />
      </Grid>
    </Grid>
  );
};
