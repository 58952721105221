import MenuIcon from "@mui/icons-material/Menu";
import {
  AppBar,
  Badge,
  BadgeProps,
  Box,
  BoxProps,
  createTheme,
  Drawer,
  FormControlLabel,
  Grid,
  IconButton,
  LinkProps,
  styled,
  Switch,
  Tab,
  TabProps,
  Tabs,
  Theme,
  ThemeProvider,
  Toolbar as TBar,
  ToolbarProps,
  ButtonProps,
  IconButtonProps,
  TabsProps,
  Hidden,
  GridProps,
} from "@mui/material";
import { grey } from "@mui/material/colors";
import React, { SyntheticEvent, useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { Link, NavLinkProps, useHistory } from "react-router-dom";
import { environment } from "../../environment/environment";
import { permissionList, permValid } from "../../helpers/permCheckers";
import { setTheme as setGlobalTheme } from "../../models/application/applicationActions";
import { User } from "../../models/user/user";
import { ConfirmationDialog } from "../Dialogs/ConfirmationDialog";
import { MobileMenu } from "../Menu/MobileMenu";
import PopupMenuForUserContextMenu from "../PopupMenus/PopupMenuForUserContextMenu";

const getWindowDimensions = () => {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
};

export const Toolbar = styled((props: ToolbarProps) => <TBar {...props} />)(({ theme }) => ({
  // [theme.breakpoints.down('sm')]: {
  //   paddingLeft: 0,
  // },
}));

export const ToolbarTabChat = styled((props: TabProps & NavLinkProps) => <Tab {...props} />)(({ theme }) => ({
  display: "inline-flex",
  color: "white",
  fontSize: "15px",
  "&.MuiButtonBase-root": {
    textTransform: "none",
    minHeight: "30px",
  },
  minWidth: "70px",
  overflow: "visible",
  opacity: 1,
}));

export const ToolbarTab = styled((props: TabProps & NavLinkProps) => <Tab {...props} />)(({ theme }) => ({
  color: "white",
  fontSize: "15px",
  "&.MuiButtonBase-root": {
    textTransform: "none",
  },
  minWidth: "70px",
  opacity: 1,
}));

export const ResponsiveBurgerMenu = styled((props: IconButtonProps) => <IconButton {...props} />)(({ theme }) => ({
  [theme.breakpoints.up("md")]: {
    display: "none",
  },
}));

const ResponseiveGrid = styled((props: GridProps & { responsivetype: "hidden" | "visible" }) => <Grid {...props} />)(({ theme, responsivetype }) => ({
  ...(responsivetype === "hidden" && {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  }),
  ...(responsivetype === "visible" && {
    [theme.breakpoints.up("sm")]: {
      display: "flex",
      justifyContent: "flex-end",
      gap: "70px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "flex",
      justifyContent: "space-between",
      paddingLeft: "30px",
    },
  }),
}));

export const StyledBadge = styled(Badge)<BadgeProps>(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -10,
    top: -24,
    border: `1px solid #ffffff`,
    padding: "0 4px",
  },
}));

const ToolbarComponent = (props: any) => {
  const { auth, mode, setGlobalTheme, app } = props;
  const history = useHistory();
  const location = useLocation();
  const [confirmation, setConfirmation] = React.useState({ text: "adasdhsj", open: false });
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions);
  type Anchor = "top" | "left" | "bottom" | "right";
  const [lang, setLang] = useState("geo");
  const [logoutText] = useState<any>({
    eng: "Do you really want to quit?",
    geo: "მართლა გსურთ გამოსვლა?",
  });
  const [versionPer] = useState(permissionList(auth, "Version"));
  const [taskPer] = useState(permissionList(auth, "Task"));
  const [issuePer] = useState(permissionList(auth, "Issue"));
  const [componentPer] = useState(permissionList(auth, "Component"));
  const [testingItemPer] = useState(permissionList(auth, "TestingItem"));
  const [testingObjectPer] = useState(permissionList(auth, "TestingObject"));
  const [value, setValue] = useState<string | boolean>("");

  useEffect(() => {
    window.addEventListener("resize", calculateWindowDimentions);
    return () => window.removeEventListener("resize", calculateWindowDimentions);
  }, []);

  const returnBadgeValue = useMemo(() => {
    console.log(app?.badgeCount);
    return app?.badgeCount;
  }, [app?.badgeCount]);

  const calculateWindowDimentions = () => {
    setWindowDimensions(getWindowDimensions);
  };

  const handleLogout = () => {
    setConfirmation({ text: logoutText[lang], open: true });
  };

  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });

  const toggleDrawer = (anchor: Anchor, open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
    if (event.type === "keydown" && ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: "#ffffff",
      },
      text: {
        primary: "#ffffff",
      },
    },
    components: {
      MuiToolbar: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? grey[800] : grey[600],
          },
        },
      },
    },
    typography: {
      fontSize: 12,
    },
  });

  const tabValueList = [
    { name: "main", id: "", sort: "created", validation: true },
    { name: "testing", id: "testing", sort: "created", validation: permValid(testingItemPer, "read") && permValid(testingObjectPer, "read") },
    { name: "versions", id: "versions", sort: "created", validation: permValid(versionPer, "read") },
    { name: "tasks", id: "tasks", sort: "created", validation: permValid(taskPer, "read") },
    { name: "issues", id: "issues", sort: "created", validation: permValid(issuePer, "read") },
    { name: "chat", id: "chat", sort: "created", validation: true },
    { name: "orders", id: "orders", sort: "created", validation: true },
    { name: "users", id: "users", sort: "created", validation: true },
    { name: "products", id: "products", sort: "uploaded", validation: true },
  ];

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  useEffect(() => {
    setValue(
      tabValueList.find((e) => e.id === location.pathname.slice(1, location.pathname.length)) ? location.pathname.slice(1, location.pathname.length) : false
    );
  }, [location]);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(prefers-color-scheme: dark)");
    localStorage.setItem("paletteState", mediaQuery.matches ? "dark" : "light");
    setGlobalTheme(mediaQuery.matches ? "dark" : "light");
    mediaQuery.addEventListener("change", (e: any) => {
      setGlobalTheme(e.matches ? "dark" : "light");
      localStorage.setItem("paletteState", mediaQuery.matches ? "dark" : "light");
    });
  }, []);

  return (
    <ThemeProvider theme={localTheme}>
      <Grid item sx={{ height: "fit-content", flexGrow: 1 }}>
        <AppBar position="static">
          <Toolbar>
            <ResponsiveBurgerMenu onClick={toggleDrawer("left", true)} edge="start" color="inherit">
              <MenuIcon style={{ color: "white" }} />
            </ResponsiveBurgerMenu>
            <Drawer anchor="left" open={state["left"]} onClose={toggleDrawer("left", false)}>
              {
                <MobileMenu
                  mode={mode}
                  redirect={(link: string) => {
                    history.push(link);
                    setState({ ...state, left: false });
                  }}
                  tabList={tabValueList}
                  value={value}
                  returnBadgeValue={returnBadgeValue}
                />
              }
            </Drawer>
            <Grid container justifyContent={"space-between"}>
              <ResponseiveGrid responsivetype="hidden" item lg xs>
                <Tabs
                  value={value}
                  onChange={handleChange}
                  aria-label="lab API tab example"
                  textColor="inherit"
                  sx={{
                    ".MuiTabs-indicator": {
                      backgroundColor: "text.primary",
                    },
                  }}
                >
                  {tabValueList.map((item, index) =>
                    item.id === "chat" ? (
                      <ToolbarTabChat
                        key={index}
                        label={
                          <StyledBadge
                            anchorOrigin={{
                              horizontal: "right",
                              vertical: "bottom",
                            }}
                            badgeContent={returnBadgeValue}
                            color="error"
                            overlap="circular"
                          >
                            {item.name}
                          </StyledBadge>
                        }
                        value={item.id}
                        component={Link}
                        to={{
                          pathname: `/${item.id}`,
                          search: "?country=georgia&pageIndex=1",
                        }}
                      />
                    ) : (
                      <ToolbarTab
                        key={index}
                        sx={{
                          display: !item.validation ? "none" : "inline-flex",
                        }}
                        label={item.name}
                        value={item.id}
                        component={Link}
                        to={{ pathname: `/${item.id}`, search: environment.baseQueryParams.replace("sort=created", `sort=${item.sort}`) }}
                      />
                    )
                  )}
                </Tabs>
              </ResponseiveGrid>
              <ResponseiveGrid responsivetype="visible" item lg xs>
                <FormControlLabel
                  sx={{ color: "#ffffff" }}
                  control={
                    <Switch
                      checked={mode === "dark" ? true : false}
                      onChange={(th) => {
                        setGlobalTheme(mode === "dark" ? "light" : "dark");
                      }}
                    />
                  }
                  label={mode.toUpperCase()}
                />
                <Box>
                  <PopupMenuForUserContextMenu logoutHandler={handleLogout} />
                </Box>
              </ResponseiveGrid>
            </Grid>
          </Toolbar>
          <ConfirmationDialog
            open={confirmation.open}
            text={confirmation.text}
            callback={(c: any) => {
              if (c) {
                const user = new User();
                user.logOut();
                setConfirmation({ text: "", open: false });
              } else {
                setConfirmation({ text: "", open: false });
              }
            }}
          />
        </AppBar>
      </Grid>
    </ThemeProvider>
  );
};
const mapStateToProps = (state: any) => {
  return {
    app: state.app,
    auth: state.auth,
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps, { setGlobalTheme })(ToolbarComponent);
