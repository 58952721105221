import { SetStateAction, useEffect, useRef } from "react";

export const useSearch = (
  setSearch: (value: SetStateAction<string>) => void,
  setSearchOnHold: (value: SetStateAction<string>) => void,
  searchOnHold: string,
  focused: boolean,
  setPageIndex?: any
) => {
  useEffect(() => {
    const listener = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        setSearch(searchOnHold)
        setPageIndex(1)
        event.preventDefault();

      }
    }
    focused ?
      document.addEventListener("keydown", listener) :
      document.removeEventListener("keydown", listener)

    return () => {
      document.removeEventListener("keydown", listener)
    }
    //eslint-disable-next-line
  }, [focused, searchOnHold])

  useEffect(() => {
    if (searchOnHold === "") {
      setSearch("")
      setSearchOnHold("")
    }
    //eslint-disable-next-line
  }, [searchOnHold])
}

export const useDateFilers = (
  reloadQueryOptions: (selectedFilters: any) => void,
  from: Date | null,
  to: Date | null,
  pageIndex: number,
  selectedFilters: any[]


) => {
  useEffect(() => {
    pageIndex && reloadQueryOptions(selectedFilters)
    //eslint-disable-next-line
  }, [from, to])
}

export const useOrder = (
  init: boolean,
  order: string,
  reloadQueryOptions: (selectedFilters: any) => void,
  selectedFilters: any[]
) => {
  useEffect(() => {
    init && reloadQueryOptions(selectedFilters)
  }, [order])
}

export const useDidMountEffect = (func: any, deps: any) => {
    const didMount = useRef(false);

    useEffect(() => {
        if (didMount.current) func();
        else didMount.current = true;
    }, deps);
}