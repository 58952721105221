import { Autocomplete, FormControl, Grid, InputLabel, ListItem, MenuItem, Paper, Select, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export const onSearch$: any = new Subject().pipe(debounceTime(800));

const CustomListItem = (props: any) => {
  const { element, option } = props;

  const [hover, setHover] = useState(false);

  return (
    <ListItem
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...element}
      style={{ backgroundColor: hover ? "rgba(0,0,0,.3)" : "transparent" }}
    >
      {option.name}
    </ListItem>
  );
};

export const AddressForm = (props: any) => {
  const { address, postForm, searchOnChange, novaPoshta, formikRef, country, city } = props;

  const [novaPoshtaInputValue, setNovaPoshtaInputValue] = useState("");
  const [cityInputValue, setCityInputValue] = useState("");
  const [countryInputValue, setCountryInputValue] = useState("");

  useEffect(() => {
    onSearch$.subscribe((debounced: string) => {
      searchOnChange(`${debounced}`);
    });
  }, []);

  const CustomPaper = (props: any) => {
    return (
      <Paper
        elevation={8}
        {...props}
        sx={{
          backgroundColor: "background.default",
          "& .MuiAutocomplete-option": {
            backgroundColor: "background.default",
          },
        }}
      />
    );
  };

  const initialValues = {
    addressName: "",
    country: "",
    city: "",
    street: "",
    entrance: "",
    floor: "",
    apartment: "",
    firstName: "",
    LastName: "",
    buildingId: "",
    googlePlaceId: "",
    personalId: "",
    novaPoshtaWarehouse: { id: "", sort: undefined, name: "" },
    comment: "",
    isMain: "false",
    phone: "",
    postalCode: "",
    Recipient: "",
    type: "",
  };

  interface FormStringList {
    id: string;
    name: string;
  }

  const formStringList: FormStringList[] = [
    { id: "addressName", name: "Address Name" },
    { id: "street", name: "Street" },
    { id: "entrance", name: "Entrance" },
    { id: "floor", name: "Floor" },
    { id: "apartment", name: "Apartment" },
    { id: "firstName", name: "First Name" },
    { id: "lastName", name: "Last Name" },
    { id: "buildingId", name: "Building ID" },
    { id: "googlePlaceId", name: "Google Place ID" },
    { id: "personalId", name: "Personal ID" },
    { id: "comment", name: "Comment" },
    { id: "phone", name: "Phone Number" },
    { id: "postalCode", name: "Postal Code" },
    { id: "recipient", name: "Recipient" },
    { id: "type", name: "Type" },
  ];

  return (
    <Formik
      innerRef={formikRef}
      initialValues={
        address
          ? {
              ...address,
            }
          : initialValues
      }
      onSubmit={postForm}
    >
      {({ values, setFieldValue, handleSubmit, handleChange, handleBlur }: any) => (
        <Form
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Grid container spacing={2} sx={{ padding: "20px" }} color="text.primary">
            <Grid item xs={12} lg={6}>
              <Autocomplete
                value={values.novaPoshtaWarehouse || ""}
                onChange={(event, value) => {
                  setFieldValue("novaPoshtaWarehouse", value);
                }}
                onInputChange={(event, newInputValue) => {
                  setNovaPoshtaInputValue(newInputValue);
                }}
                options={novaPoshta?.length ? novaPoshta : []}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => option.name !== value.name}
                PaperComponent={CustomPaper}
                inputValue={novaPoshtaInputValue}
                renderOption={(props, option) => {
                  return <CustomListItem element={props} option={option} key={option.name + option.id} />;
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    onChange={(evt) => onSearch$.next(evt.target.value)}
                    name="novaPoshtaWarehouse"
                    margin="dense"
                    variant="outlined"
                    id="novaPoshtaWarehouse"
                    label="Nova Poshta Warehouse"
                    type="novaPoshtaWarehouse"
                    fullWidth
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                value={values.country || ""}
                onChange={(event, value) => {
                  setFieldValue("country", value);
                }}
                onInputChange={(event, newInputValue) => {
                  setCountryInputValue(newInputValue);
                }}
                options={country?.length ? country : []}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => option.name !== value.name}
                PaperComponent={CustomPaper}
                inputValue={countryInputValue}
                renderOption={(props, option) => {
                  return <CustomListItem element={props} option={option} key={option.name} />;
                }}
                renderInput={(params) => (
                  <TextField {...params} name="country" margin="dense" variant="outlined" id="country" label="Country" type="country" fullWidth />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <Autocomplete
                value={values.city || ""}
                onChange={(event, value) => {
                  setFieldValue("city", value);
                }}
                onInputChange={(event, newInputValue) => {
                  setCityInputValue(newInputValue);
                }}
                options={city?.length ? city : []}
                getOptionLabel={(option) => option.name || ""}
                isOptionEqualToValue={(option, value) => option.name !== value.name}
                PaperComponent={CustomPaper}
                inputValue={cityInputValue}
                renderOption={(props, option) => {
                  return <CustomListItem element={props} option={option} key={option.name} />;
                }}
                renderInput={(params) => <TextField {...params} name="city" margin="dense" variant="outlined" id="city" label="City" type="city" fullWidth />}
              />
            </Grid>
            {formStringList.map((item: FormStringList, index: number) => (
              <Grid item xs={12} lg={6} key={index}>
                <TextField
                  name={item.id}
                  margin="dense"
                  variant="outlined"
                  id={item.id}
                  defaultValue={values[item.id]}
                  label={item.name}
                  type={item.id}
                  fullWidth
                  onChange={handleChange}
                  onBlur={handleBlur}
                />
              </Grid>
            ))}
            <Grid item xs={12} lg={6}>
              <FormControl variant="outlined" fullWidth>
                <InputLabel>Is Main ?</InputLabel>
                <Select id="isMain" name="isMain" label="Is Main ?" variant="outlined" value={values.isMain || ""} onChange={handleChange}>
                  {[true, false].map((item: boolean, index: number) => {
                    return (
                      <MenuItem key={index} value={String(item)}>
                        {String(item)}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
