import { Button, DialogTitle, Grid, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import { QueryOptions } from "../../helpers/query.options";
import { validationSchemas } from "../../helpers/validations";
import { versionService } from "../../models/Version/service";
import { Version } from "../../models/Version/Version";

interface VersionFormProps {
  version: Version;
  getValues: (val: any) => void;
  handleDialog: (val: boolean) => () => void;
}

export default function VersionsForm({ version, getValues, handleDialog }: VersionFormProps) {
  const [versions, setVersions] = useState<Version[]>([]);
  const [uid, setUid] = useState(version?.uid);

  const getVersions = () => {
    const options = new QueryOptions();
    versionService.listWithCounter(options).then((resp: any) => {
      setVersions(resp.current);
    });
  };
  useEffect(() => {
    getVersions();
  }, []);

  const testerValues = () => {
    const check = versions.map((x) => x.uid);
    if (version?.uid !== uid) {
      return check;
    } else if (version?.uid === uid) {
      return [];
    } else {
      return check;
    }
  };

  return (
    <Formik
      validationSchema={validationSchemas(testerValues(), []).versionSchema}
      initialValues={version ? { ...version } : { name: "", uid: "", startDate: "", releaseDate: "", version_android: "", version_ios: "" }}
      onSubmit={(val) => {
        getValues(val);
      }}
    >
      {({ values, handleSubmit, handleChange, setFieldValue, handleBlur, touched, errors }: any) => (
        <Form
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Grid item xs={6}>
            <DialogTitle>ახალი ვერსია</DialogTitle>
          </Grid>
          <Grid container spacing={2} style={{ padding: 30, width: "100%" }}>
            <Grid item lg={6}>
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                id="outlined-basic"
                label={"დასახელება"}
                value={values.name}
                variant="outlined"
                type="text"
                name="name"
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name && touched.name ? true : false}
                helperText={errors.name && touched.name ? errors.name : null}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                id="outlined-basic"
                label={"ID"}
                value={values.uid}
                variant="outlined"
                type="text"
                name="uid"
                onChange={(e: any) => {
                  setFieldValue("uid", e.target.value);
                  setUid(e.target.value);
                }}
                onBlur={handleBlur}
                error={errors.uid && touched.uid ? true : false}
                helperText={errors.uid && touched.uid ? errors.uid : null}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                id="outlined-basic"
                label={"ანდროიდ ვერსია"}
                value={values.version_android === "NaN" ? "" : values.version_android}
                variant="outlined"
                type="text"
                name="version_android"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item lg={6}>
              <TextField
                style={{ width: "100%", marginTop: 10 }}
                id="outlined-basic"
                label={"აიოესის ვერსია"}
                value={values.version_ios === "NaN" ? "" : values.version_ios}
                variant="outlined"
                type="text"
                name="version_ios"
                onChange={handleChange}
                onBlur={handleBlur}
              />
            </Grid>
            <Grid item lg={6} style={{ paddingTop: 17, alignContent: "space-between", flexDirection: "row" }}>
              <TextField
                style={{ marginRight: "4%", flexDirection: "column", width: "48%" }}
                id="date"
                label="დაწყების თარ."
                type="date"
                name="startDate"
                variant="outlined"
                value={values.startDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.startDate && touched.startDate ? true : false}
                helperText={errors.startDate && touched.startDate ? errors.startDate : null}
              />

              <TextField
                style={{ marginRight: 0, flexDirection: "column", width: "48%" }}
                id="date"
                label="გაშვების თარ."
                variant="outlined"
                type="date"
                name="releaseDate"
                value={values.releaseDate}
                onChange={handleChange}
                InputLabelProps={{
                  shrink: true,
                }}
                error={errors.releaseDate && touched.releaseDate ? true : false}
                helperText={errors.releaseDate && touched.releaseDate ? errors.releaseDate : null}
              />
            </Grid>
            <Grid item xs={7}></Grid>
            <Grid item xs={5} style={{ alignItems: "flex-end", alignContent: "flex-end", textAlign: "right" }}>
              <Button onClick={() => handleDialog(false)}>გამოსვლა</Button>
              <Button
                style={{
                  backgroundColor: "#4169E1",
                  color: "white",
                  alignSelf: "flex-end",
                  marginLeft: 30,
                }}
                type="submit"
              >
                დამახსოვრება
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}
