import { Component } from "../component/component"
import { Project } from "../project/project"
import { Resource } from "../resource"
import { User } from "../user/user"
import { TestObject } from "./TestObject"

export class TestListItem extends Resource {
  component: Component | any
  created: Date | any
  testObject: TestObject | any
  description: string | any
  id: string | any
  is_enabled: boolean | any
  name: string | any
  project: Project | any
  user: User | any
}
