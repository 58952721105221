import React from 'react';
import { Route, } from "react-router-dom";
import LoginPage from './pages/Auth/LoginPage';
import MainPage from './pages/MainPage';


const GuardedRoute = (props: any) => {
    const { component: Component, auth, access, ...rest } = props
    return  <Route {...rest} render={(props) => (
        auth === true
            ? access? <Component {...props} />: <MainPage {...props} />
            : <LoginPage {...props}/> 
    )} />
}

export default GuardedRoute;