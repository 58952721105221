import { ResourceProvider } from './../../providers/resource.provider';
import { Country } from './Country';
import { countrySerialier } from './serialzer';

export class CountryProvider extends ResourceProvider<Country> {
    constructor() {
        super(
            "rest_country",
            countrySerialier
        )
    }
}

export const countryService = new CountryProvider()