import { City } from './City';
import { Serializer } from './../serializer';
import { Resource } from '../resource';

export class CountrySerializer implements Serializer {
    fromJson(json: any) {
        const city = new City()
        city.name = json.name
        city.id = json.id
        return city
    }

    toJson(resource: Resource) {
        return resource
    }
}

export const citySerialier = new CountrySerializer()