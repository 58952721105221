import { ResourceProvider } from '../../providers/resource.provider'
import { Chat, ChatMessage, ChatMessageUpdate } from './chatMessages'
import { ChatMessageSerializer, ChatMessageUpdateSerializer, ChatSerializer } from './serializer'


export class ChatProvider extends ResourceProvider<ChatMessage> {
  constructor() {
    super(
      'rest_chat',
      new ChatMessageSerializer())
  }
}
export class ChatListProvider extends ResourceProvider<Chat> {
  constructor() {
    super(
      'rest_chat',
      new ChatSerializer())
  }
}

export class ChatMessageUpdateProvider extends ResourceProvider<ChatMessageUpdate> {
  constructor() {
    super(
      'rest_chat_message',
      new ChatMessageUpdateSerializer())
  }
}

export const messageService = new ChatProvider()
export const chatListService = new ChatListProvider()
export const chatMessageUpdateService = new ChatMessageUpdateProvider()