import { ResourceProvider } from "../../providers/resource.provider";
import { Features } from "./features";
import { FeaturesSerializer } from "./serializer";

export class FeaturesProvider extends ResourceProvider<Features> {
    constructor() {
        super("rest_feature", new FeaturesSerializer());
    };
};

export const featuresService = new FeaturesProvider();