import { Product } from '../Product/Product';
import { Reason } from '../Reason/Reason';
import { Resource } from '../resource';
import { User } from '../user/user';

export class Review extends Resource {
    comment: string | null | undefined;
    created: string | null | undefined;
    id: string | null | undefined;
    order: string | null | undefined;
    owner: User | null | undefined;
    ownerEmail: string | null | undefined;
    product: Product | null | undefined;
    rate: number | null | undefined;
    reasons: Reason[] | null | undefined;
    user: string | null | undefined;
    status: string | null | undefined;
}
