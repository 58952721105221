import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { Manager, SuccessManagerItem } from "./SuccessManagers";


class ManagerSerializer implements Serializer {
    fromJson(json: any) {
        const item = new Manager()
        item.successManagerName = json?.name
        item.successManagerId = json?.id
        return item || undefined
    }
    toJson(resource: Resource) {
        throw new Error("Method not implemented.");
    }

}

export class SuccessManagerSerializer implements Serializer {
    fromJson(json: any) {
        const item = new SuccessManagerItem()
        item.groupName = json.group_name
        item.id = json.id
        item.manager = new ManagerSerializer().fromJson(json.manager)
        return item;
    }
    toJson(resource: Resource) {
        throw new Error("Method not implemented.");
    }

}

export const successManagerSerializer = new SuccessManagerSerializer()