import { Autocomplete, FormControl, ListItem, Paper, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { User } from "../models/user/user";
import { TypographyItem } from "./Typography/TypographyItem";

export const TableHeaderSearchComponent = (props: any) => {
  const { name, onChange, setFilterSearch, getBy, filterItems } = props;
  const [users, setUsers] = useState<User[]>([]);
  const [inputValue, setInputValue] = useState("");
  const [onSearch$] = useState<any>(new Subject().pipe(debounceTime(800)));

  const CustomListItem = (props: any) => {
    const { element, option } = props;

    const [hover, setHover] = useState(false);

    return (
      <ListItem
        key={option?.id}
        sx={{
          display: "flex",
          flexDirection: "column",
          wordBreak: "break-word",
          justifyContent: "center",
        }}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...element}
        style={{ backgroundColor: hover ? "rgba(0,0,0,.3)" : "transparent" }}
      >
        <TypographyItem sx={{ textAlign: "left", width: "100%" }} text={option.name?.length ? option?.name : "No Name"} />
        <TypographyItem sx={{ textAlign: "left", width: "100%" }} text={option?.id} />
      </ListItem>
    );
  };

  useEffect(() => {
    setInputValue(name);
  }, [name]);

  useEffect(() => {
    onSearch$.subscribe((debounced: { [key: string]: string }) => {
      setFilterSearch({ search: debounced, key: getBy === "buyer" ? "user" : getBy });
    });
  }, []);

  const CustomPaper = (props: any) => {
    return (
      <Paper
        elevation={10}
        {...props}
        sx={{
          backgroundColor: "background.default",
          "& .MuiAutocomplete-option": {
            backgroundColor: "background.default",
          },
        }}
      />
    );
  };

  useEffect(() => {
    if (filterItems?.filter((obj: any) => obj.key === (getBy === "buyer" ? "user" : getBy))[0]?.items)
      setUsers(filterItems?.filter((obj: any) => obj.key === (getBy === "buyer" ? "user" : getBy))[0]?.items);
  }, [filterItems]);

  return (
    <FormControl
      key={getBy}
      sx={{
        width: "100%",
        "& .MuiInputLabel-root": {
          color: "white",
          fontSize: 13
        },
        "& .MuiInput-underline:before": {
          borderBottom: "1px solid white",
        },
        "& .MuiInput-root:after": {
          borderBottom: "2px solid white",
        },
        "& .MuiInput-root:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid white",
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
      }}
    >
      <Autocomplete
        value={name ? name : ""}
        onChange={(evt: any, value: any) => {
          if (value) {
            onChange({ key: getBy === "buyer" ? "user" : getBy, value: value?.id });
          }
        }}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        options={users.length ? users : []}
        getOptionLabel={(option) => (option.id || name ? name : "")}
        isOptionEqualToValue={(option, value) => option.name !== value.name || option.name === value.name}
        PaperComponent={CustomPaper}
        inputValue={inputValue ? inputValue : ""}
        renderOption={(props, option) => {
          return <CustomListItem element={props} option={option} key={option.id + getBy} />;
        }}
        renderInput={(params) => (
          <TextField
            sx={{
              "& .MuiInputLabel-root.Mui-focused": {
                color: "white",
                fontWeight: "bold",
              },
              "& .MuiInputLabel-root": {
                color: "white",
                fontWeight: "bold",
              },
              minWidth: "250px",
              input: {
                color: "white",
              },
              color: "white",
            }}
            variant="standard"
            {...params}
            onChange={(evt) =>
              onSearch$.next({
                key: getBy === "buyer" ? "user" : getBy,
                searchValue: evt.target.value,
              })
            }
            name="novaPoshtaWarehouse"
            margin="dense"
            id="novaPoshtaWarehouse"
            label={getBy}
            fullWidth
          />
        )}
      />
    </FormControl>
  );
};
