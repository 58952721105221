import { Box, Grid } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { QueryOptions } from "../helpers/query.options";
import { urlify } from "../helpers/utilities";
import { commentService } from "../models/comments/service";
import { CommentProfile } from "./Profiles/CommentProfile";
import { ReplyComponent } from "./ReplyComponent";
import { CommentsTextField } from "./TextFields/CommentsTextField";

export const CommentsComponent = ({ palette, muiTheme, id, loaded, setloaded }: any) => {
  const [comments, setComments] = useState<any[]>([]);

  const getComments = () => {
    const options = new QueryOptions();
    options.sort = "created";
    options.order = "desc";
    commentService.listWithCounter(options).then((r: any) => {
      setComments(r.current);
    });
  };

  useEffect(() => {
    getComments();
    //eslint-disable-next-line
  }, [loaded]);

  const replyComment = useCallback(
    (Id: any) => {
      return <ReplyComponent palette={palette} muiTheme={muiTheme} id={Id} getComments={getComments} />;
    },
    [id]
  );

  const nestedComment: any = (
    Id: string,
    created: string,
    userName: string,
    text: string,
    comments: any[],
    width: number,
    index: number,
    Width: number,
    Height: number
  ) => {
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];

    return (
      <Box key={index}>
        <Box style={{ width: `${width}px`, float: "right" }}>
          <Box style={{ textAlign: "left", display: "flex", justifyContent: "space-around", width: "100%", marginLeft: "16px" }}>
            <Box style={{ width: "40px", height: "100%" }}>
              <CommentProfile margin="10px 0 0 0" width={Width} height={Height} />
            </Box>
            <Box style={{ display: "flex", flexDirection: "column", width: "100%", marginLeft: "20px" }}>
              <Box>
                <span style={{ color: palette.text.primary, fontWeight: "bold" }}>{userName + " "}</span>
                {days[new Date(created).getDay()] + " " + new Date(created).getDate() + " " + new Date(created).getFullYear()}
              </Box>
              <Box style={{ marginTop: "7px", lineHeight: "20px" }}>{urlify(text, palette.text.primary)}</Box>
              <Box style={{ width: "100%", minHeight: "40px", marginBottom: "10px", marginTop: "10px" }}>{replyComment(Id)}</Box>
            </Box>
          </Box>
        </Box>
        {comments.map((item: any, index: number) =>
          nestedComment(item.id, item.created, item.user, item.text, item.children, width - 50, index + 1 + Math.random())
        )}
      </Box>
    );
  };
  return (
    <Grid container item style={{ width: "1000px", minHeight: "60px", height: "auto", contain: "content", alignContent: "right" }}>
      <Grid item xs={12} style={{ display: "flex", alignItems: "stretch", justifyContent: "space-around" }}>
        <Box style={{ width: "40px", height: "100%" }}>
          <CommentProfile margin="10px 0 0 0" width={40} height={40} />
        </Box>
        <CommentsTextField palette={palette} muiTheme={muiTheme} id={id.split("?")[0]} name="comment" getComments={getComments} stCancel={() => {}} />
      </Grid>
      <Grid xs={12} item style={{ marginTop: "40px" }}>
        {comments?.length ? (
          comments.map(
            (item: any, index: number) =>
              item.task.id === id.split("?")[0] &&
              nestedComment(item.id, item.created, item.user.fullName, item.text, item.children && item.children, 1000, index + 2 + Math.random(), 40, 40)
          )
        ) : (
          <h1>hellos</h1>
        )}
      </Grid>
    </Grid>
  );
};
