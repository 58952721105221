import { Resource } from '../resource';
import { Product } from "../Product/Product";

export class ProductSave extends Resource {
    image: string | null | undefined;
    date: string | null | undefined;
    disabled: boolean | string | null | undefined;
    id: string | null | undefined;
    product: Product | null | undefined;
    productCountry: string | null | undefined;
    productId: string | null | undefined;
    productUser: string | null | undefined;
    user: string | null | undefined;
}
