import ReplyRoundedIcon from "@mui/icons-material/ReplyRounded";
import { Box, IconButton } from "@mui/material";
import { useState } from "react";
import { useLocation } from "react-router-dom";
import { chatStyle, returnAccordingToEmoji, urlify } from "../../../helpers/utilities";
import { chatMessageUpdateService } from "../../../models/chat/service";
import { ConfirmationDialog } from "../../Dialogs/ConfirmationDialog";
import { DottedMenuForSingleMessage } from "../../Menu/DottedMenuForSingleMessage";
import { TypographyItem } from "../../Typography/TypographyItem";

export const AdminSideMessageBox = (props: any) => {
  const {
    index,
    messages,
    item,
    currUser,
    e,
    setEditMode,
    setEditText,
    chatPer,
    setUid,
    currUserName,
    userName,
    setReplyText,
    setReplierName,
    messageDeleteActionHandler,
  } = props;
  const [visible, setVisible] = useState(false);
  const [confirmation, setConfirmation] = useState<any>({
    text: "",
    open: false,
    title: "",
    task: null,
  });
  const location = useLocation();

  const replyButtonClickHandler = (item: any) => {
    setReplierName(item.owner === "admin" ? (item.portal_user?.full_name === currUserName ? "You" : item.portal_user?.full_name) : userName);
    setUid(item.uid);
    setReplyText(item.text);
    setEditMode(false);
    setEditText(null);
  };

  const handleDelete = () => {
    if (item?.uid) {
      if (true) {
        chatMessageUpdateService.delete(item?.uid).then((r: any) => {
          if (r) {
            setEditText(null);
            setEditMode(false);
            messageDeleteActionHandler();
          }
        });
      }
    }
  };
  return (
    <Box
      sx={{
        width: "100%",
        height: "auto",
        display: "flex",
        justifyContent: "flex-end",
        padding: chatStyle(
          index,
          "admin",
          messages?.filter((d: any) => new Date(d.created).toDateString() === e)
        )[2]?.toString(),
        alignItems: "center",
        boxSizing: "border-box",
      }}
      onMouseEnter={() => setVisible(true)}
      onMouseLeave={() => setVisible(false)}
    >
      <ConfirmationDialog
        open={confirmation.open}
        text={confirmation.text}
        title={confirmation.title}
        callback={(c: any) => {
          if (c) {
            handleDelete();
          }
          setConfirmation({
            text: "",
            open: false,
            title: "",
            issue: null,
          });
        }}
      />
      <Box sx={{ display: "flex", fontSize: "0.9em", flexDirection: "column" }}>
        <Box sx={{ display: "flex", flexDirection: "row", height: "100%", justifyContent: "flex-end", alignItems: "center", gap: "10px" }}>
          {visible && (
            <IconButton onClick={() => replyButtonClickHandler(item)}>
              <ReplyRoundedIcon sx={{ color: "text.cardInfo", cursor: "pointer" }} />
            </IconButton>
          )}
          {visible && (
            <DottedMenuForSingleMessage
              setConfirmation={setConfirmation}
              currUserName={currUserName}
              chatPer={chatPer}
              setEditText={setEditText}
              setEditMode={setEditMode}
              text={item.text}
              portalUser={item.portal_user?.full_name}
              item={item}
              setUid={setUid}
            />
          )}
          <Box sx={{ display: "flex", justifyContent: "flex-end" }}>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                height: returnAccordingToEmoji(item.text, "fontSize", "fit-content"),
                contain: "content",
                fontSize: 35,
                textAlign: "left",
                margin: "0",
                padding: returnAccordingToEmoji(item.text, "padding", "10px"),
                maxWidth: "350px",
                backgroundColor: returnAccordingToEmoji(item.text, "background", "#888")?.toLocaleString(),
                color: "white",
                wordBreak: "break-word",
                borderRadius: returnAccordingToEmoji(
                  item.text,
                  "radius",
                  chatStyle(
                    index,
                    "admin",
                    messages?.filter((d: any) => new Date(d.created).toDateString() === e)
                  )[0]?.toString()
                ),
              }}
            >
              {item.original_message && (
                <Box sx={{ paddingLeft: "10px", background: "lightgrey", borderRadius: "15px", boxSizing: "border-box" }}>
                  <Box
                    sx={{
                      paddingLeft: "5px",
                      paddingRight: "5px",
                      display: "flex",
                      flexDirection: "column",
                      alignItem: "flex-start",
                      gap: "15px",
                      minHeight: "70px",
                      backgroundColor: "darkgrey",
                      width: "auto",
                      maxWidth: "330px",
                      wordBreak: "break-word",
                      borderRadius: "0 15px 15px 0",
                    }}
                  >
                    <TypographyItem text={item.reply_user} fontSize={20} fontWeight={600} color={"white"} />
                    <TypographyItem text={item.original_message} fontSize={14} color={"white"} />
                  </Box>
                </Box>
              )}
              {urlify(item.text, "#fff")}
            </Box>
          </Box>
        </Box>
        {chatStyle(
          index,
          "admin",
          messages?.filter((d: any) => new Date(d.created).toDateString() === e)
        )[1] && (
          <Box
            sx={{
              textAlign: "right",
              paddingRight: "5px",
              paddingTop: "5px",
              fontStyle: "italic",
              fontSize: "0.8em",
              color: "grey",
            }}
          >
            {
              <Box component={"span"} style={{ color: "grey" }}>
                {item?.portal_user?.full_name !== currUser && item?.portal_user?.full_name && (
                  <Box component={"span"} style={{ color: "grey" }}>
                    sentBy:{" "}
                  </Box>
                )}
                <Box component={"span"} style={{ fontWeight: "bold", color: "grey" }}>
                  {item?.portal_user?.full_name === currUser ? "" : item?.portal_user?.full_name}
                </Box>
                {item?.portal_user?.full_name !== currUser && item?.portal_user?.full_name && " at "}
                {new Date(item.created).toLocaleTimeString().slice(0, 5)}
              </Box>
            }
          </Box>
        )}
      </Box>
    </Box>
  );
};
