import { Resource } from "../../models/resource";
import { Serializer } from "./../../models/serializer";
import { Reason } from "./Reason";

export class ReasonSerializer implements Serializer {
  fromJson(json: any): Reason {
    const item = new Reason();
    item.reasonId = json.id;
    item.reasonName = json.name;
    item.reasonRates = json.rates;
    return item;
  }
  toJson(resource: Resource) {
    throw new Error("Method not implemented.");
  }
}

export const reasonSerializer = new ReasonSerializer();
