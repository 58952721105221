import { Serializer } from '../serializer'
import { StatusSerializer } from '../status/serializer'
import { Task } from './Task'





export class TaskSerializer implements Serializer {
    fromJson(json: any) {
        const task = new Task()
        task.id = json.id
        task.name = json.name
        task.description = json.description
        task.issue = json.issue
        task.type = json.issue.type
        task.priority = json.priority
        task.component = json.issue.component
        task.category = json.issue.category
        task.project = json.issue.project
        task.user = json.user
        task.assignee = json.assignee
        task.created = json.created
        task.isEnabled = json.is_enabled
        task.version = json.version
        task.minutesEstimated = json.minutes_estimated
        task.minutesSpent = json.minutes_spent
        task.reviewers = json.reviewers
        task.testers = json.testers
        if (json.status) {
            const statusSerializer = new StatusSerializer()
            task.status = statusSerializer.fromJson(json.status)
        }
        task.images = json.issue.images
        if (json.videos) {
            task.videos = json.videos.map((r: any) => {
                return `http://localhost:8060/assets/images/issues/${r.filename}`
            })
        }
        task.deadlineDate = json.deadline_date
        return task
    }

    toJson(item: Task) {
        const obj: any = {
            issue: item.issue.id,
            id: item.id,
            name: item.name,
            description: item.description,
            priority: item.priority.id,
            status: item.status.id,
            version: item.version.id,
            assignee: item.assignee.id,
            // deadline_date: item.deadline_date,
            // reviewers: item.reviewers,
            // testers: item.testers,
            is_enabled: item.isEnabled
            //videos: item.videos,
            //images: item.images,

        }

        if (item.minutesEstimated){
            obj.minutes_estimated = item.minutesEstimated
        }
        if (item.minutesSpent){
            obj.minutes_spent = item.minutesSpent
        }
            return obj
    }
}




