import { ResourceProvider } from "../../providers/resource.provider";
import { TestingPageListItemSerializer, TestListItemSerializer, TestObjectSerializer, TestObjectStatusSerializer } from "./serializer";
import { TestingPageListItem } from "./TestingPageListItem";
import { TestListItem } from "./TestListItem";
import { TestObject, TestStatus } from "./TestObject";

export class TestListItemsProvider extends ResourceProvider<TestListItem> {
    constructor() {
        super("rest_testing_item", new TestListItemSerializer())
    }
}
export class TestObjectProvider extends ResourceProvider<TestObject> {
    constructor() {
        super("rest_testing_object", new TestObjectSerializer())
    }
}

export class TestStatusProvider extends ResourceProvider<TestStatus> {
    constructor() {
        super("rest_testing_status", new TestObjectStatusSerializer())
    }
}

class TestingPageListItemProvider extends ResourceProvider<any> {
    constructor() {
        super("rest_testing_collection", new TestingPageListItemSerializer)
    }
}

export const testListitemService = new TestListItemsProvider()
export const testObjectservice = new TestObjectProvider()
export const testStatusservice = new TestStatusProvider()
export const testingPageListItemService = new TestingPageListItemProvider()
