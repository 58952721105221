import AddCommentOutlinedIcon from "@mui/icons-material/AddCommentOutlined";
import { Box, FormControl, Menu, MenuItem, MenuList, Tooltip } from "@mui/material";
import * as React from "react";
import { useEffect, useState } from "react";
import { Chat } from "../models/chat/chatMessages";
import { chatListService } from "../models/chat/service";
import { SearchComponent } from "./SearchComponent";
import { TypographyItem } from "./Typography/TypographyItem";

export const DottedMenuForNewChatUser = (props: any) => {
  const { openNewUser, setOpenNewUser, searchOnChange, users, allUsers, valueRef, loadUsers, setChatId } = props;
  const [anchorEl, setAnchorEl] = useState<null | SVGSVGElement>(null);
  const [email, setEmail] = useState<string | null>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<SVGSVGElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
    setEmail(null);
  };

  useEffect(() => {
    if (email) {
      const chat = new Chat();
      chat.user = email;
      setOpenNewUser(false);
      chatListService.create(chat).then((e: any) => {
        if (e) {
          handleClose();
          searchOnChange(email);
          setEmail(null);
          setChatId(email);
        }
      });
    }
  }, [email]);
  // const currUser = JSON.parse(localStorage.getItem("currentUser")!)

  return (
    <Box
      sx={{
        position: "absolute",
        bottom: 50,
        right: 50,
        zIndex: 1,
      }}
    >
      <Tooltip title={<h3>{"Start New Chat"}</h3>}>
        <AddCommentOutlinedIcon
          onClick={handleClick}
          sx={{
            color: "text.cardInfo",
            filter: `drop-shadow(0px 0px 3px ${localStorage.getItem("paletteState") === "dark" ? "#ffffff" : "#8a8a8a"})`,
            "&.MuiSvgIcon-root": {
              transform: "scale(-1, 1)",
              fontSize: 45,
            },
          }}
        />
      </Tooltip>
      <FormControl
        sx={{
          width: "100%",
          "& .MuiInputLabel-root.Mui-focused": {
            color: "text.primary",
          },
          "& .MuiInputLabel-root": {
            color: "text.primary",
          },
          "& .MuiInputBase-root": {
            color: "text.primary",
            border: `1px text.primary solid`,
          },
          "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
            borderColor: "text.primary",
            borderWidth: "1px",
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `text.primary`,
            borderWidth: "2px",
          },
        }}
        variant="outlined"
      >
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            sx: {
              backgroundColor: "background.default",
              backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
              "& .MuiListSubheader-root": {
                backgroundColor: "background.default",
              },
              height: "auto",
              width: "35ch",
            },
          }}
        >
          <MenuList sx={{ position: "relative" }}>
            <SearchComponent setOpen={setOpenNewUser} valueRef={valueRef} open={openNewUser} searchOnChange={loadUsers} />
            {(users.length ? users : allUsers).map((item: any, index: number) => {
              return (
                <MenuItem
                  onClick={() => {
                    setEmail(item.email);
                  }}
                  key={index}
                  value={item.email}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    justifyContent: "flex-end",
                    borderWidth: "1px",
                    borderColor: "text.primary",
                    borderStyle: "solid",
                    margin: "12px",
                    borderRadius: "5px",
                    "&:hover": {
                      borderWidth: "3px",
                      margin: `${index === 0 ? "10px 12px -2px 12px" : "-2px 12px"}`,
                      paddingLeft: 1.75,
                    },
                  }}
                >
                  <TypographyItem style={{ width: "100%" }} text={item.name} />
                  <TypographyItem text={item.email} style={{ width: "100%" }} />
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </FormControl>
    </Box>
  );
};
