import { SnackbarOrigin } from '@material-ui/core'

import {
    SET_COUNTER,
    SET_SNACK_BAR,
    SET_USERNAME,
    SET_CATEGORIES,
    SET_STATUSES,
    SET_COMPONENTS,
    SET_PROJECTS,
    SET_PRIORITIES,
    SET_ISSUE_TYPES,
    SET_SNACK,
    SET_ASSIGNEE,
    SET_VERSION,
    SET_FEATURES,
    SET_USER_LIST,
    SET_ISSUES,
    SET_THEME,
    SET_TEST_STATUSES,
    SET_CHAT_BADGE,
    SET_COUNTRY
} from './applicationActions'



export interface State extends SnackbarOrigin {
    open: boolean;
    text: string;
}


const initialState = {
    counter: "001001001",
    categories: [],
    snack: { open: false, text: '' },
    issueTypes: [],
    currentTheme: localStorage.getItem("paletteState") ? localStorage.getItem("paletteState") : 'light',
    testStatuses: [],
    components: [],
    priorities: [],
    projects: [],
    username: 'გიორგი',
    assignee: [],
    users: [],
    version: [],
    features: [],
    issues: [],
    snackBar: { open: false, vertical: 'bottom', horizontal: 'center', text: '', type: '' } as State,
    badgeCount: 0,
    country: localStorage.getItem('country') || "georgia"
}

export const applicationReducer = (state = initialState, action: { type: any; content: any }) => {
    switch (action.type) {
        case SET_THEME: {
            const { currentTheme } = action.content
            return { ...state, currentTheme }
        }
        case SET_COUNTRY: {
            const { country } = action.content
            return { ...state, country }
        }
        case SET_CHAT_BADGE: {
            const { badgeCount } = action.content
            return { ...state, badgeCount }
        }
        case SET_SNACK_BAR: {
            const { snackBar } = action.content
            return { ...state, snackBar }
        }
        case SET_USER_LIST: {
            const { users } = action.content
            return { ...state, users }
        }
        case SET_TEST_STATUSES: {
            const { testStatuses } = action.content
            return { ...state, testStatuses }
        }
        case SET_USERNAME: {
            const { username } = action.content
            return { ...state, username }
        }
        case SET_COUNTER: {
            const { counter } = action.content
            return { ...state, counter }
        }
        case SET_CATEGORIES: {
            const { categories } = action.content
            return { ...state, categories }
        }
        case SET_COMPONENTS: {
            const { components } = action.content
            return { ...state, components }
        }
        case SET_ISSUE_TYPES: {
            const { issueTypes } = action.content
            return { ...state, issueTypes }
        }
        case SET_PROJECTS: {
            const { projects } = action.content
            return { ...state, projects }
        }
        case SET_SNACK: {
            const { snack } = action.content
            return { ...state, snack }
        }
        case SET_STATUSES: {
            const { statuses } = action.content
            return { ...state, statuses }
        }
        case SET_PRIORITIES: {
            const { priorities } = action.content
            return { ...state, priorities }
        }
        case SET_ASSIGNEE: {
            const { assignee } = action.content
            return { ...state, assignee }
        }
        case SET_VERSION: {
            const { version } = action.content
            return { ...state, version }
        }
        case SET_FEATURES: {
            const { features } = action.content
            return { ...state, features }
        }
        case SET_ISSUES: {
            const { issues } = action.content
            return { ...state, issues }
        }

        default:
            return state
    }
}