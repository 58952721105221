export interface QueryBuilder {
  toQueryMap: () => Map<string, string>;
  toQueryString: () => string;
}

export class QueryOptions implements QueryBuilder {
  public id: string | undefined;
  public successGroup: string | undefined;
  public pageSize: number | undefined;
  public pageIndex: number | undefined;
  public order: string | undefined;
  public employee: string | undefined;
  public file: string | undefined;
  public country: string | undefined;
  public sort: string | undefined;
  public tags: string | undefined;
  public search: string | undefined;
  public search_user: string | undefined;
  public type: string | undefined;
  public new: boolean | undefined;
  public list: boolean | undefined;
  public managers: boolean | undefined;
  public birthDate: string | undefined;
  public date: string | undefined;
  public assignee: string | undefined;
  public category: string | undefined;
  public component: string | undefined;
  public issue: string | undefined;
  public priority: string | undefined;
  public project: string | undefined;
  public reviewer: string | undefined;
  public status: string | undefined;
  public tester: string | undefined;
  public user: string | undefined;
  public version: string | undefined;
  public created_from: Date | undefined;
  public created_to: Date | undefined;
  public processingState: string | undefined;
  public method: string | undefined;
  public shippingType: string | undefined;
  public packaging: string | undefined;
  public read: string | undefined;
  public userId: string | undefined;
  public seller: string | undefined;
  public coupon: string | undefined;
  public successManagerId: string | undefined;
  public appVersion: string | undefined;
  public color: string | undefined;
  public subcategory: string | undefined;
  public size: string | undefined;
  public section: string | undefined;
  public condition: string | undefined;
  public brand: string | undefined;
  public rate: string | undefined;
  public reasons: string | undefined;

  toQueryMap() {
    const queryMap = new Map<string, string>();

    if (this.pageSize !== undefined) {
      queryMap.set("pageSize", `${this.pageSize}`);
    }
    if (this.successGroup !== undefined) {
      queryMap.set("success_group", `${this.successGroup}`);
    }
    if (this.assignee !== undefined) {
      queryMap.set("assignee", `${this.assignee}`);
    }
    if (this.category !== undefined) {
      queryMap.set("category", `${this.category}`);
    }
    if (this.processingState !== undefined) {
      queryMap.set("processing_state", `${this.processingState}`);
    }
    if (this.method !== undefined) {
      queryMap.set("method", `${this.method}`);
    }
    if (this.component !== undefined) {
      queryMap.set("component", `${this.component}`);
    }
    if (this.issue !== undefined) {
      queryMap.set("issue", `${this.issue}`);
    }
    if (this.priority !== undefined) {
      queryMap.set("priority", `${this.priority}`);
    }
    if (this.project !== undefined) {
      queryMap.set("project", `${this.project}`);
    }
    if (this.reviewer !== undefined) {
      queryMap.set("reviewer", `${this.reviewer}`);
    }
    if (this.status !== undefined) {
      queryMap.set("status", `${this.status}`);
    }
    if (this.country !== undefined) {
      queryMap.set("country", `${this.country}`);
    }
    if (this.tester !== undefined) {
      queryMap.set("tester", `${this.tester}`);
    }
    if (this.type !== undefined) {
      queryMap.set("type", `${this.type}`);
    }
    if (this.user !== undefined) {
      queryMap.set("user", `${this.user}`);
    }
    if (this.version !== undefined) {
      queryMap.set("version", `${this.version}`);
    }
    if (this.birthDate !== undefined) {
      queryMap.set("birth_date", `${this.birthDate}`);
    }
    if (this.new !== undefined) {
      queryMap.set("new", `${this.new}`);
    }
    if (this.managers !== undefined) {
      queryMap.set("managers", `${this.managers}`);
    }
    if (this.tags !== undefined) {
      queryMap.set("tags", `${this.tags}`);
    }
    if (this.list !== undefined) {
      queryMap.set("list", `${this.list}`);
    }
    if (this.file !== undefined) {
      queryMap.set("file", `${this.file}`);
    }
    if (this.search !== undefined) {
      queryMap.set("search", `${this.search}`);
    }
    if (this.search_user !== undefined) {
      queryMap.set("search_user", `${this.search_user}`);
    }
    if (this.date !== undefined) {
      queryMap.set("date", `${this.date}`);
    }
    if (this.pageIndex !== undefined) {
      queryMap.set("pageIndex", `${this.pageIndex}`);
    }
    if (this.employee !== undefined) {
      queryMap.set("employee", `${this.employee}`);
    }
    if (this.order) {
      queryMap.set("order", this.order);
    }
    if (this.id) {
      queryMap.set("id", `${this.id}`);
    }
    if (this.sort !== undefined) {
      queryMap.set("sort", `${this.sort}`);
    }
    if (this.created_from !== undefined) {
      queryMap.set("created_from", `${this.created_from}`);
    }
    if (this.created_to !== undefined) {
      queryMap.set("created_to", `${this.created_to}`);
    }
    if (this.shippingType !== undefined) {
      queryMap.set("shipping_type", `${this.shippingType}`);
    }
    if (this.packaging !== undefined) {
      queryMap.set("packaging", `${this.packaging}`);
    }
    if (this.read !== undefined) {
      queryMap.set("read", `${this.read}`);
    }
    if (this.userId !== undefined) {
      queryMap.set("user_id", `${this.userId}`);
    }
    if (this.seller !== undefined) {
      queryMap.set("seller", `${this.seller}`);
    }
    if (this.coupon !== undefined) {
      queryMap.set("coupon", `${this.coupon}`);
    }
    if (this.successManagerId !== undefined) {
      queryMap.set("success_manager", `${this.successManagerId}`);
    }
    if (this.appVersion !== undefined) {
      queryMap.set("app_version", `${this.appVersion}`);
    }
    if (this.color !== undefined) {
      queryMap.set("color", `${this.color}`);
    }
    if (this.subcategory !== undefined) {
      queryMap.set("subcategory", `${this.subcategory}`);
    }
    if (this.size !== undefined) {
      queryMap.set("size", `${this.size}`);
    }
    if (this.section !== undefined) {
      queryMap.set("section", `${this.section}`);
    }
    if (this.condition !== undefined) {
      queryMap.set("condition", `${this.condition}`);
    }
    if (this.brand !== undefined) {
      queryMap.set("brand", `${this.brand}`);
    }
    if (this.rate !== undefined) {
      queryMap.set("rate", `${this.rate}`);
    }
    if (this.reasons !== undefined) {
      queryMap.set("reasons", `${this.reasons}`);
    }
    return queryMap;
  }

  toQueryString() {
    let queryString = "";

    this.toQueryMap().forEach((value: string, key: string) => {
      if (value !== "null") {
        queryString = queryString.concat(`${key}=${value}&`);
      }
    });

    return queryString.substring(0, queryString.length - 1);
  }
}
