import { Attribute } from './../Attribute/Attribute';
import { Brand } from "../Brands/Brand";
import { Resource } from "../resource";
import { User } from "../user/user";


export class Product extends Resource {
    name: string | null | undefined;
    brand: string | null | undefined;
    brandId: string | null | undefined;
    brandName: string | null | undefined;
    condition: Attribute | null | undefined;
    country: any | null | undefined;
    id: string | null | undefined;
    image: string | null | undefined;
    images: string[] | null | undefined;
    productUser: User | null | undefined;
    created: string | null | undefined
    description: string | null | undefined
    uid: string | null | undefined
    department: string | null | undefined
    status: string | null | undefined
    discount: string | null | undefined
    price: string | null | undefined
    specialPrice: number | null | undefined
    ownerPrice: string | null | undefined
    ownerPhone: string | null | undefined
    ownerName: string | null | undefined
    ownerEmail: string | null | undefined
    ownerCity: string | null | undefined
    ownerStreet: string | null | undefined
    ownerApartment: string | null | undefined
    ownerManager: string | null | undefined
    isEnabled: boolean | string | null | undefined
    stock: number | null | undefined
    section: Attribute | null | undefined;
    category: Attribute | null | undefined;
    subcategory: Attribute | null | undefined;
    color: Attribute | null | undefined;
    size: Attribute | null | undefined;
}

export class ProductOverride extends Resource {
    brand: string | null | undefined;
    name: string | null | undefined;
    price: number | null | undefined;
    specialPrice: number | null | undefined;
    ownerPrice: number | null | undefined;
    description: string | null | undefined;
    isEnabled: string | null | undefined;
    stock: number | null | undefined;
    country: string | null | undefined;
    section: string | null | undefined;
    condition: string | null | undefined;
    category: string | null | undefined;
    subcategory: string | null | undefined;
    size: string | null | undefined;
    color: string | null | undefined;

}

export interface ProductResponse {
    count: number;
    current: Product[];
    has_next: boolean;
    next: Product[];
}
