import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, Grid, Tab } from "@mui/material";
import { SyntheticEvent, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { environment } from "../environment/environment";
import { QueryOptions } from "../helpers/query.options";
import { EmployeeGroup } from "../models/eployeeGroup/employeeGroup";
import { employeeGroupService } from "../models/eployeeGroup/service";
import { User } from "../models/user/user";
import { versionService } from "../models/Version/service";
import { Version } from "../models/Version/Version";
import TestingTableList from "./Tables/TestingTableList";
import TabTask from "./Tabs/TabTask";

const VersionTabs = (props: any) => {
  const { id } = props;

  const [testers, setTesters] = useState<User[]>([new User()]);
  const [version, setVersion] = useState<any>(new Version());
  const [value, setValue] = useState("taskTable");
  const location = useLocation();
  const history = useHistory();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const Ref = useRef(false);

  const loadVersion = (id: string) => {
    versionService.read(id).then((tsk: Version) => {
      setVersion(tsk);
    });
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    loadVersion(params.get("version")!);
  }, [location]);

  useEffect(() => {
    if (Ref.current) {
      return;
    }
    interface Res {
      count: number;
      current: EmployeeGroup[];
      hasNext: boolean;
      next: EmployeeGroup[];
    }
    employeeGroupService.listWithCounter(new QueryOptions()).then((r: Res) => {
      const [Testers] = r?.current;
      setTesters(Testers.users!);
    });
    Ref.current = true;
  }, [location.pathname]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const value: string = query.get("tab")!;
    setValue(value);
  }, []);

  useEffect(() => {
    const listener = history.listen((location) => {
      const query = new URLSearchParams(location.search);
      const value: string = query.get("tab")!;
      setValue(value);
    });

    return () => listener();
  }, []);

  return (
    <Grid item lg={12} xs={12} overflow={"auto"}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList
            onChange={handleChange}
            aria-label="lab API tab example"
            sx={{
              ".MuiTabs-indicator": {
                backgroundColor: "text.primary",
              },
            }}
          >
            <Tab
              label="Tasks"
              value="taskTable"
              onClick={() => {
                history.push({ pathname: `/versionDetails`, search: environment.baseQueryParams + "&tab=taskTable" + `&version=${id}` });
              }}
            />
            <Tab
              label="Testing"
              value="testingTable"
              onClick={() => {
                history.push({ pathname: `/versionDetails`, search: environment.baseQueryParams + "&tab=testingTable" + `&version=${id}` });
              }}
            />
          </TabList>
        </Box>
        <TabPanel sx={{ overflow: "auto", height: "calc(100vh - 212px)" }} value="taskTable">
          <TabTask pageName={"versionDetails"} />
        </TabPanel>
        <TabPanel value="testingTable">
          <Box display={"flex"} justifyContent="center" overflow={"auto"}>
            <Grid key={id} item container direction="column" justifyContent="center" spacing={0} xs={12} lg={12}>
              <TestingTableList testers={testers} version={[version]} />
            </Grid>
          </Box>
        </TabPanel>
      </TabContext>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
    theme: state.theme.theme,
  };
};

export default connect(mapStateToProps)(VersionTabs);
