import { Close } from "@mui/icons-material";
import { Box, createTheme, Dialog, DialogContent, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Dispatch, SetStateAction } from "react";
import { taskService } from "../../models/Task/service";
import { Task } from "../../models/Task/Task";
import TasksForm from "../Forms/TasksForm";

interface TasksFormDialogProps {
  open: boolean;
  item: Task;
  handleDialog: (val: boolean) => void;
  setSubmitted: Dispatch<SetStateAction<boolean>>;
  onAlert: (text: string) => void;
  app: any;
}

export default function TaskFormDialog(props: TasksFormDialogProps) {
  /**
   * Props go here
   */
  const { open, handleDialog, setSubmitted, item, app, onAlert } = props;

  // const handleSearchRefresh = (key?: string | any) => {
  //     setIssues([])
  // }

  const getValues = (values: any) => {
    const task = new Task();
    task.issue = values.issue;
    task.name = values.name;
    task.description = values.description;
    task.priority = values.priority;
    task.status = values.status;
    task.version = values.version;
    task.assignee = values.assignee;
    task.minutesEstimated = values.minutesEstimated;
    task.minutesSpent = values.minutesSpent;

    if (item) {
      task.id = item.id;
      taskService.update({ ...task, id: task.id }).then((r: any) => {
        if (r?.name === "AjaxError") {
          onAlert(`საკითხი ${task.name} ვერ განახლდა::error`);
          setSubmitted((prev) => !prev);
          handleDialog(false);
        } else {
          onAlert(`საკითხი ${task.name} განახლდა წარმატებით::success`);
          setSubmitted((prev) => !prev);
          handleDialog(false);
        }
      });
    } else {
      taskService.create(task).then((r: any) => {
        if (r?.name === "AjaxError") {
          onAlert(`საკითხი ${task.name} ვერ დაემატა::error`);
          setSubmitted((prev) => !prev);
          handleDialog(false);
        } else {
          onAlert(`საკითხი ${task.name} დაემატა წარმატებით::success`);
          setSubmitted((prev) => !prev);
          handleDialog(false);
        }
      });
    }
  };

  const localTheme = createTheme({
    palette: {
      mode: app.currentTheme,
      primary: {
        main: app.currentTheme === "dark" ? grey[100] : grey[900],
      },
    },
    typography: {
      fontSize: 13,
    },
  });
  return (
    <ThemeProvider theme={localTheme}>
      <Dialog open={open} fullWidth={true} maxWidth={"md"}>
        <DialogContent style={{ overflow: "auto" }}>
          <Box onClick={() => handleDialog(false)} style={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }}>
            <Close />
          </Box>
          <TasksForm item={item} getValues={getValues} issues={app?.issues} app={app} handleDialog={handleDialog} />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}
