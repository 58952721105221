import { makeStyles } from "@material-ui/styles";
import { TypographyItem } from "../Components/Typography/TypographyItem";
import { filterLists } from "../environment/environment";
import { orderFilterService } from "../models/filter/service";
import { COLORS } from "./colors";
import { QueryOptions } from "./query.options";
declare global {
  interface String {
    capitalize(): string;
  }
}

String.prototype.capitalize = function () {
  return this.charAt(0).toUpperCase() + this.slice(1);
};

export const recFlatListFromListDict = <T extends unknown>(dict: T | unknown[]) => {
  let list: unknown[] = [];
  const recFunc = (lst: unknown[], variable: unknown) => {
    if (typeof variable === "object") {
      if (variable) {
        Object.entries(variable).forEach(([key, value]) => {
          if (typeof value === "string" || typeof value === "number") {
            lst.push({ [key]: value });
          } else if (typeof value === "object") {
            recFunc(lst, value);
          }
        });
      }
    } else if (Array.isArray(variable)) {
      if (variable.length) {
        variable.forEach((e) => {
          recFunc(lst, e);
        });
      }
    }
  };
  recFunc(list, dict);
  return list;
};

export const useQuery = () => {};
const otherParams = [
  { value: "pageSize", type: "number", default: 50 },
  { value: "pageIndex", type: "number", default: 1 },
  { value: "sort", type: "string", default: "created" },
  { value: "order", type: "string", default: "desc" },
  { value: "search", type: "string", default: "" },
  { value: "created_from", type: "string", default: null },
  { value: "created_to", type: "string", default: null },
  { value: "tabs", type: "string", default: "logs" },
  { value: "birth_date", type: "string", default: null },
];

export const generateUrl = (
  selectedFilters: any[],
  page: string,
  pageString: string,
  pageIndex = 1,
  pageSize = 50,
  sort = "",
  order = "",
  search = "",
  created_from: null | string = null,
  created_to: null | string = null,
  tabs: null | string = null,
  birthDay: null | string = null
) => {
  const queryList = [
    { query: "pageIndex", value: pageIndex === 0 ? 1 : pageIndex },
    { query: "pageSize", value: pageSize },
    { query: "sort", value: sort },
    { query: "order", value: order },
    { query: "search", value: search.length && search },
    { query: "created_from", value: created_from?.length && created_from },
    { query: "created_to", value: created_to?.length && created_to },
    { query: "tabs", value: tabs?.length && tabs },
    { query: "birth_date", value: birthDay?.length && birthDay },
  ];

  const result = queryList
    .filter((item) => item.value)
    .map((item) => (item.value ? `${item.query}=${item.value}` : ""))
    .join("&");

  if (selectedFilters.length) {
    let url = "";
    filterLists[page].forEach((param: string) => {
      const items = selectedFilters.filter((e) => e.key === param).map((item) => item.id);
      if (items.length) {
        const prefix = url.length > 3 ? `${url}&` : `${url}`;
        url = `${prefix}${param}=${items.toString()}`;
      }
    });

    url = `${url}&${result}`;

    return `/${pageString}?${url}`;
  } else {
    let url = "";
    const prefix = url.length > 3 ? `${url}&` : `${url}`;
    url = `${prefix}${url}${result}`;

    return `/${pageString}?${url}`;
  }
};

export const urlChanger = (history: any, pageName: string, changeBy: string, value: unknown, index?: string | number) => {
  const newSearchValue: string = (() => {
    let loc: any = window.location.search;
    if (typeof value === "string" || typeof value === "number") {
      if (index) {
        const local = loc.split("&").filter((item: any) => item.indexOf("pageIndex") === -1);
        loc = [...["pageIndex=1"], ...local].join("&");
      }
      if (typeof value === "string" && value.length) {
        if (loc.indexOf(changeBy) > -1) {
          loc = loc.replace(
            loc
              .split("&")
              .filter((item: any) => item.indexOf(changeBy) > -1)
              .join("&"),
            `${changeBy}=${value}`
          );
        } else {
          loc = loc + `&${changeBy}=${value}`;
        }
      } else {
        const nexLoc =
          "&" +
          loc
            .split("&")
            .filter((item: any) => item.indexOf(changeBy) > -1)
            .join("&");
        loc = loc.replace(nexLoc, "");
      }
    }
    return loc;
  })();
  history.push({ pathname: `/${pageName}`, search: newSearchValue });
};

export const generateParamsFromUrl = (location: any, page: string) => {
  const query = new URLSearchParams(location.search);
  const options: any = new QueryOptions();
  filterLists[page].forEach((param: string) => {
    options[param] = query.get(param);
  });

  otherParams.forEach((param: any) => {
    const paramValue = query.get(param.value);
    if (paramValue) {
      options[param.value] = param.type === "string" ? paramValue : Number(paramValue);
    }
  });
  return options;
};

export const getStatusColor = (item: any) => {
  if (item && item.status) {
    switch (item.status.uid) {
      case "to_do":
        return COLORS.status.to_do;
      case "in_progress":
        return COLORS.status.in_progress;
      case "completed":
        return COLORS.status.completed;
      case "to_discuss":
        return COLORS.status.to_discuss;
      case "testing":
        return COLORS.status.testing;
      case "done":
        return COLORS.status.done;
      default:
        return COLORS.status.default;
    }
  }
};

export const getPriorityColor = (item: any) => {
  if (item && item.priority) {
    switch (item.priority.uid) {
      case "very_low":
        return COLORS.priority.very_low;
      case "low":
        return COLORS.priority.low;
      case "medium":
        return COLORS.priority.medium;
      case "high":
        return COLORS.priority.high;
      case "very_high":
        return COLORS.priority.very_high;
      default:
        return COLORS.status.default;
    }
  }
};

const detectIfOnlyEmoji = (str: string) => {
  const emojiRegex = /(\u00a9|\u00ae|[\u2000-\u3300]|\ud83c[\ud000-\udfff]|\ud83d[\ud000-\udfff]|\ud83e[\ud000-\udfff]|[❤️])/g;
  return !str.replace(new RegExp(emojiRegex, "g"), "").length;
};

export const returnAccordingToEmoji = (string: string, cases: string, defaultValue: string | number) => {
  switch (cases) {
    case "fontSize":
      return detectIfOnlyEmoji(string.replaceAll(" ", "")) ? 40 : defaultValue;

    case "background":
      return detectIfOnlyEmoji(string.replaceAll(" ", "")) ? "transparent" : defaultValue;

    case "padding":
      return detectIfOnlyEmoji(string.replaceAll(" ", "")) ? 1 : defaultValue;
    case "radius":
      return detectIfOnlyEmoji(string.replaceAll(" ", "")) ? 0 : defaultValue;
    default:
      break;
  }
};

export const urlifyForTable = (text: string) => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part: string, index: number) => {
    if (part.match(urlRegex)) {
      return (
        <a key={index} target="_blank" href={part}>
          <TypographyItem uppercase={true} fontWeight={"400"} color={"blue"} fontSize={14} text={part} padding={10} />
        </a>
      );
    }
    return part;
  });
};

export const urlify = (text: any, color = "black") => {
  const urlRegex = /(https?:\/\/[^\s]+)/g;
  return text.split(urlRegex).map((part: any) => {
    if (part.match(urlRegex)) {
      return (
        <a key={part} target="_blank" href={part}>
          <TypographyItem uppercase={true} fontWeight={"400"} color={"blue"} fontSize={14} text={part} padding={10} />
        </a>
      );
    }
    return detectIfOnlyEmoji(text.replaceAll(" ", "")) ? (
      text
    ) : (
      <TypographyItem key={part} uppercase={false} fontWeight={"400"} color={color} fontSize={14} text={part} padding={10} />
    );
  });
};

export const tableBodyStyles = makeStyles(() => ({
  class: {
    whiteSpace: "normal",
    marginTop: "35px",
    display: "-webkit-box",
    WebkitBoxOrient: "vertical",
    WebkitLineClamp: 2,
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

export const chatStyle = (index: number, Case: string, messages: any) => {
  const getMin = (r: any) => new Date(r?.created).getTime();
  const prev = messages[index - 1];
  const curr = messages[index];
  const next = messages[index + 1];
  if (index !== 0) {
    if (prev?.owner === Case && curr?.owner === Case && next?.owner === Case) {
      if (getMin(next) - getMin(curr) < 100000 && getMin(curr) - getMin(prev) < 100000) {
        return Case === "user" ? ["5px 20px 20px 5px", false, "1px 10px 1px 10px"] : ["20px 5px 5px 20px", false, "1px 30px 1px 30px"];
      } else if (getMin(curr) - getMin(prev) < 100000 && getMin(next) - getMin(curr) > 100000)
        return Case === "user" ? ["5px 20px 20px 20px", true, "1px 10px 20px 10px"] : ["20px 5px 20px 20px", true, "1px 30px 20px 30px"];
      else if (getMin(curr) - getMin(prev) > 100000 && getMin(next) - getMin(curr) < 100000)
        return Case === "user" ? ["20px 20px 20px 5px", false, "1px 10px 1px 10px"] : ["20px 20px 5px 20px", false, "1px 30px 1px 30px"];
      else return Case === "user" ? ["20px 20px 20px 20px", true, "7px 10px 20px 10px"] : ["20px 20px 20px 20px", true, "7px 30px 20px 30px"];
    } else if (next?.owner === Case && getMin(next) - getMin(curr) < 100000)
      return Case === "user" ? ["20px 20px 20px 5px", false, "1px 10px 1px 10px"] : ["20px 20px 5px 20px", false, "1px 30px 1px 30px"];
    else if (prev?.owner === Case && getMin(curr) - getMin(prev) < 100000)
      return Case === "user" ? ["5px 20px 20px 20px", true, "1px 10px 20px 10px"] : ["20px 5px 20px 20px", true, "1px 30px 20px 30px"];
    else return Case === "user" ? ["20px 20px 20px 20px", true, "1px 10px 20px 10px"] : ["20px 20px 20px 20px", true, "1px 30px 30px 10px"];
  } else if (next?.owner === Case && getMin(next) - getMin(curr) < 100000)
    return Case === "user" ? ["20px 20px 20px 5px", false, "7px 10px 1px 10px"] : ["20px 20px 5px 20px", false, "1px 30px 1px 30px"];
  else return Case === "user" ? ["20px 20px 20px 20px", true, "7px 10px 20px 10px"] : ["20px 20px 20px 20px", true, "7px 30px 20px 30px"];
};

export const FormattedTime = (time: number) => {
  const dateTime = new Date(time);
  const seconds = Date.now() - dateTime.getTime() >= 1000 ? Math.trunc(Math.round(Date.now() - dateTime.getTime()) / 1000) : null;
  const minutes = seconds && seconds >= 60 ? Math.trunc(seconds / 60) : null;
  const hours = minutes && minutes >= 60 ? Math.trunc(minutes / 60) : null;
  const days = hours && hours >= 24 ? Math.round(hours / 24) : null;
  const months = days && days >= 30 ? Math.round(days / 30) : null;
  const years = months && months >= 12 ? Math.round(months / 12) : null;
  const redactedDate = minutes
    ? `${years ? `${years} yrs` : months ? `${months} month` : days ? `${days} day` : hours ? `${hours} hour` : minutes ? `${minutes} min` : null} ago`
    : null;

  return redactedDate;
};

export const services = {
  orderFilterService: orderFilterService,
};
