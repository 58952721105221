import { offerSerializer } from "../Offer/serializer";
import { addressSerializer } from "../Address/serializer";
import { productSerializer } from "../Product/serializer";
import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { userSerializer } from "../user/serializer";
import { Order, OrderOverride } from "./Order";

class OrderSerializer implements Serializer {
    fromJson(json: any) {
        const orderItem = new Order();

        orderItem.brandName = json.brand_name
        if (json.address)
            orderItem.address = addressSerializer.fromJson(json.address)
        orderItem.campaign = json.campaign
        orderItem.completedLogged = json.completed_logged
        orderItem.country = json.country
        orderItem.coupon = json.coupon
        orderItem.created = json.created
        orderItem.currency = json.currency
        orderItem.discount = json.discount
        orderItem.exchangeRate = json.exchange_rate
        orderItem.id = json.id
        orderItem.initiatedLogged = json.initiated_logged
        orderItem.method = json.method
        if (json.offer)
            orderItem.offer = offerSerializer.fromJson(json.offer)
        orderItem.ownerPrice = json.owner_price
        orderItem.packaging = json.packaging
        orderItem.paidWithSolo = json.paid_with_solo
        orderItem.parentCartId = json.parent_cart_id
        orderItem.paymentCurrency = json.payment_currency
        orderItem.paymentProcessing = json.payment_processing
        orderItem.paymentState = json.payment_state
        orderItem.pickupAddress = json.pickup_address
        orderItem.processingState = json.processing_state
        if (json.product)
            orderItem.product = productSerializer.fromJson(json.product)
        if (json.seller_address)
            orderItem.sellerAddress = addressSerializer.fromJson(json.seller_address)
        orderItem.shippingMethod = json.shipping_method
        orderItem.shippingType = json.shipping_type
        orderItem.size = json.size
        orderItem.soldNotified = json.sold_notified
        orderItem.status = json.status
        orderItem.total = json.total
        orderItem.totalOriginal = json.total_original
        orderItem.totalToPay = json.total_to_pay
        orderItem.uid = json.uid
        if (json.user)
            orderItem.user = userSerializer.fromJson(json.user)

        return orderItem
    }
    toJson(resource: Resource) {
        return resource
    }

}

export const orderSerializer = new OrderSerializer()

class OrderOverrideSerializer implements Serializer {
    fromJson(json: any) {
        return json
    }

    toJson(resource: OrderOverride) {
        const obj: any = {}
        obj.order = resource.order
        obj.param_name = resource.paramName
        obj.param_new_value = resource.paramNewValue
        return obj
    }
}

export const orderOverrideSerializer = new OrderOverrideSerializer()