import { Box } from "@mui/material";
import { getAnalytics } from "firebase/analytics";
import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { useEffect, useLayoutEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { environment } from "../environment/environment";
import { userService } from "../models/user/service";
import { User } from "../models/user/user";

export const PushService = (props: any) => {
  const { onMessageReceived, id } = props;
  const [isTokenFound, setTokenFound] = useState(false);
  const [currToken, setCurrToken] = useState("");
  const [init, setInit] = useState(false);
  const history = useHistory();

  useLayoutEffect(() => {
    setInit(true);
  }, []);
  const firebaseConfig = {
    apiKey: "AIzaSyAPkfHNa1dY8thR8weNRGS85t6bhM_x5cI",
    authDomain: "phubber.firebaseapp.com",
    databaseURL: "https://phubber.firebaseio.com",
    projectId: "phubber",
    storageBucket: "phubber.appspot.com",
    messagingSenderId: "728584966601",
    appId: "1:728584966601:web:fbd03c2c270fc1ed861818",
    measurementId: "G-4CD8624DNV",
  };

  // Initialize Firebase
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
  const messaging = getMessaging(app);

  const onMessageListener = () =>
    new Promise((resolve) => {
      onMessage(messaging, (payload) => {
        resolve(payload);
      });
    });

  useEffect(() => {
    if (currToken.length) {
      const user = new User();
      user.pushToken = currToken;
      user.id = id;
      userService.update(user);
    }
  }, [currToken, id]);

  const getPushToken = () => {
    return getToken(messaging, { vapidKey: environment.messagingKey })
      .then((currentToken) => {
        if (currentToken) {
          console.log("current token for client: ", currentToken);
          setCurrToken(currentToken);
          setTokenFound(true);
          // Track the token -> client mapping, by sending to backend server
          // show on the UI that permission is secured
        } else {
          console.log("No registration token available. Request permission to generate one.");
          setTokenFound(false);
          // shows on the UI that permission is required
        }
      })
      .catch((err) => {
        console.log(err);
        // catch error while creating client token
      });
  };

  const subscribe = async () => {
    onMessageListener()
      .then((payload: any) => {
        let path = require("../assets/msgTone.mp3").default;
        const audio = new Audio(path);
        audio.play();
        const promise = audio.play();
        promise.then(() => {});
        onMessageReceived(payload.notification.body);
      })
      .catch((err) => console.log("failed: ", err))
      .finally(() => {
        subscribe();
      });
  };

  useEffect(() => {
    if (init) {
      getPushToken();
      subscribe();
      navigator.permissions.query({ name: "notifications" });
      return () => {
        getPushToken();
        subscribe();
      };
    }
  }, [init]);

  return <Box></Box>;
};
