import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { userSerializer } from "../user/serializer";
import { EmployeeGroup } from "./employeeGroup";

export class EmployeeGroupSerializer implements Serializer {
    fromJson(json: any) {
        const item = new EmployeeGroup()
        item.created = json.created
        item.id = json.id
        item.name = json.name
        item.user = userSerializer.fromJson(json.user)
        item.users = json.users.map((item: any) => userSerializer.fromJson(item))
        return item
    }
    toJson(resource: Resource) {
        return resource
    }
}