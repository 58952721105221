import { Box, createTheme, Grid, PaletteMode, ThemeProvider, useMediaQuery } from "@mui/material";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import "../../App.css";
import { UserProfileAnimator } from "../../Components/Animation/UserProfileAnimator";
import ChatComponent from "../../Components/ChatComponents/MessageComponents/ChatComponent";
import UserListComponent from "../../Components/ChatComponents/UserListComponent";
import { ChatDialog } from "../../Components/Dialogs/ChatDialog";
import { UserProfileDialog } from "../../Components/Dialogs/UserProfileDialog";
import { DottedMenuForChatFilter } from "../../Components/DottedMenuForChatFilters";
import { TypographyItem } from "../../Components/Typography/TypographyItem";
import { App, Auth, ReduxState } from "../../environment/environment";
import { permissionList } from "../../helpers/permCheckers";
import { QueryOptions } from "../../helpers/query.options";
import { setCountryName } from "../../models/application/applicationActions";
import { Chat } from "../../models/chat/chatMessages";
import { chatListService } from "../../models/chat/service";
import { successManagerService } from "../../models/successManagers/service";
import { SuccessManagerItem, SuccessManagerResponse } from "../../models/successManagers/SuccessManagers";
import { userProfileService } from "../../models/user/service";
import { User } from "../../models/user/user";
import { Palette } from "../../providers/ThemeProvider";

interface ChatPageProps {
  auth: Auth;
  palette: Palette;
  app: App;
  setCountryName: any;
  mode?: PaletteMode;
}

const ChatPage = (props: ChatPageProps) => {
  const { auth, setCountryName, palette, mode } = props;
  const [successManagerId, setSuccessManagerId] = useState<string | null>(null);
  const [successManagersList, setSuccessManagersList] = useState<SuccessManagerItem[] | null>(null);
  const [search, setSearch] = useState<string | null>(null);
  const [tempSearchHolder, setTempSearchHolder] = useState<string | null>();
  const [chats, setChats] = useState<Chat[]>([]);
  const [chat, setChat] = useState<Chat | null>(null);
  const [userProfile, setUserProfile] = useState<User | null>();
  const [pageIndex, setPageIndex] = useState<any | null>({ index: 1 });
  const [country, setCountry] = useState<string | null>("georgia");
  const [init, setInit] = useState<boolean>(false);
  const [chatId, setChatId] = useState<string | null>(null);
  const [chatEmail, setChatEmail] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [chatPer] = useState(permissionList(auth, "Chat"));
  const [read, setRead] = useState<string | null>(null);
  const [chatSelectDirection, setChatSelectDirection] = useState("right");
  const [chatDialogOpen, setChatDialogOpen] = useState(false);
  const [userProfileDialogOpen, setUserProfileDialogOpen] = useState(false);
  const [constantWidth, setConstantWidth] = useState(0);

  const history = useHistory();
  const location = useLocation();

  const countryFilterActionHandler = (evt: any) => {
    setTempSearchHolder(null);
    setChat(null);
    setSearch(null);
    setChats([]);
    setCountry(evt.target.value);
    setChatId(null);
    setCountryName(evt.target.value);
    setPageIndex({ index: 1 });
  };
  const successManagersFilterActionHandler = (evt: any) => {
    setTempSearchHolder(null);
    setChat(null);
    setSearch(null);
    setChats([]);
    setChatId(null);
    setPageIndex({ index: 1 });
    if (evt.target.value === "every") setSuccessManagerId(null);
    else setSuccessManagerId(evt.target.value);
  };

  const readFilterActionHandler = () => {
    setChats([]);
    setChat(null);
    setTempSearchHolder(null);
    setSearch(null);
    setChatId(null);
    setPageIndex({ index: 1 });
    setRead(read ? null : "1");
  };

  const messageDeleteActionHandler = () => {
    getChats(search, { index: 1 }, country, successManagerId, false, read);
    getChat(chatEmail);
  };

  const messageSenderUpdaterHandler = () => {
    getChats(search, { index: 1 }, country, successManagerId, false, read);
    getChat(chatEmail);
  };

  const chatUpdateOnUserProfileChangeHandler = () => {
    getChats(search, { index: 1 }, country, successManagerId, false, read);
    loadUserProfile(chatId);
  };

  useEffect(() => {
    successManagerService.listWithCounter(new QueryOptions()).then((e: SuccessManagerResponse) => {
      if (e?.current?.length) setSuccessManagersList(e.current);
    });
  }, []);

  useEffect(() => {
    if (!init) {
      const urlQuery = new URLSearchParams(location.search);
      const search = urlQuery.get("searchUsr");
      const country = urlQuery.get("country");
      const successManagerId = urlQuery.get("successManagerId");
      const tempSearchHolder = urlQuery.get("tempSearchHolder");
      const read = urlQuery.get("read");
      setSearch(tempSearchHolder ? tempSearchHolder : search);
      setCountry(country);
      setSuccessManagerId(successManagerId);
      setRead(read);
      getChats(tempSearchHolder ? tempSearchHolder : search, pageIndex, country, successManagerId, true, read, tempSearchHolder);
    }
    //eslint-disable-next-line
  }, [init]);

  useEffect(() => {
    const historyListener = history.listen((location) => {
      if (init) {
        const urlQuery = new URLSearchParams(location.search);
        const srch = search ? search : urlQuery.get("searchUsr");
        const country = urlQuery.get("country");
        const successManagerId = urlQuery.get("successManagerId");
        const read = urlQuery.get("read");
        const pageIndex = { index: urlQuery.get("pageIndex") };
        const tempSearchHolder = urlQuery.get("tempSearchHolder");
        getChats(srch, pageIndex, country, successManagerId, true, read, tempSearchHolder);
      }
    });

    return () => {
      historyListener();
    };
  }, [init, search]);

  useEffect(() => {}, [chats]);

  useLayoutEffect(() => {
    setConstantWidth(window.screen.width);

    return () => setConstantWidth(0);
  }, []);

  const getChats = (
    search: string | undefined | null = undefined,
    index: any | undefined | null = undefined,
    country: string | undefined | null = undefined,
    successManagerId: string | undefined | null = undefined,
    loading: boolean = false,
    read: string | undefined | null = undefined,
    chatId: string | undefined | null = null
  ) => {
    const primaryChatsQuery = new QueryOptions();
    if (search) primaryChatsQuery.search_user = search;
    if (index) primaryChatsQuery.pageIndex = index.index;
    if (country) primaryChatsQuery.country = country;
    if (successManagerId) primaryChatsQuery.successGroup = successManagerId;
    if (read) primaryChatsQuery.read = read;
    index.index < 2 && !chatId && setLoading(loading);
    primaryChatsQuery.sort = "messages.created";
    primaryChatsQuery.order = "desc";
    chatListService.listWithCounter(primaryChatsQuery).then((res: any) => {
      if (res?.current.length !== 0 && res?.current.length == 24) {
        setLoading(false);
        setInit(true);
        setChats((prev) =>
          [...res.current, ...prev]
            .filter((v, i, a) => a.findIndex((t) => t.id === v.id) === i)
            .sort((a: any, b: any) =>
              new Date(a?.messages[a?.messages?.length - 1]?.created) < new Date(b?.messages[b?.messages?.length - 1]?.created) ? 11 : -1
            )
        );
      } else {
        setChats(res?.current);
        setLoading(false);
      }
    });
  };

  const getChat = (search: string | null | undefined) => {
    const primaryChatsQuery = new QueryOptions();
    if (search) primaryChatsQuery.search_user = search;
    primaryChatsQuery.sort = "messages.created";
    primaryChatsQuery.order = "desc";
    chatListService.listWithCounter(primaryChatsQuery).then((res: any) => {
      const [uniqueChat] = res?.current;
      setChat(uniqueChat);
      setLoading(false);
    });
  };

  const loadUserProfile = (id: string | null) => {
    userProfileService.read(id as string).then((profile) => {
      if (profile.name === "AjaxError") {
        profile.name = "User Profile Didn't Load";
        profile.isActive = false;
      } else {
        profile.isActive = true;
      }

      setUserProfile(profile);
    });
  };

  useEffect(() => {
    if (init)
      history.push(
        `/chat?country=${country ? country : "georgia"}${pageIndex ? `&pageIndex=${pageIndex.index}` : ""}${search ? `&searchUsr=${search}` : ""}${
          successManagerId ? `&successManagerId=${successManagerId}` : ""
        }${tempSearchHolder ? `&tempSearchHolder=${tempSearchHolder}` : ""}${read ? `&read=${read}` : ""}`
      );
  }, [search, country, successManagerId, pageIndex, tempSearchHolder, read]);

  useEffect(() => {
    if (chatId) {
      getChat(chatEmail);
      loadUserProfile(chatId);
    }
  }, [chatId]);

  useEffect(() => {
    const interval = setInterval(() => {
      getChats(search, { index: 1 }, country, successManagerId, false, read);
      if (chatId) getChat(chatEmail);
    }, 10000);
    return () => {
      clearInterval(interval);
    };
  }, [search, country, successManagerId, chatId, read]);

  const animated = useCallback(() => {
    return (
      <UserProfileAnimator
        userProfileUpdateHandler={chatUpdateOnUserProfileChangeHandler}
        direction={chatSelectDirection}
        userProfile={userProfile}
        chat={chat}
        {...props}
      />
    );
  }, [userProfile]);

  const localTheme = createTheme({
    palette: {
      mode: mode,
    },
  });

  const isMedium = !useMediaQuery("(max-width: 1000px)");

  return (
    <ThemeProvider theme={localTheme}>
      <Grid container>
        <ChatDialog
          onOpen={() => setUserProfileDialogOpen(!isMedium)}
          open={chatDialogOpen && !isMedium}
          setOpen={setChatDialogOpen}
          chat={chat}
          chatPer={chatPer}
          auth={auth}
          messageDeleteActionHandler={messageDeleteActionHandler}
          messageSenderUpdaterHandler={messageSenderUpdaterHandler}
        />
        <UserProfileDialog
          open={userProfileDialogOpen && !isMedium}
          setOpen={setUserProfileDialogOpen}
          userProfile={userProfile}
          chat={chat}
          userProfileUpdateHandler={chatUpdateOnUserProfileChangeHandler}
        />

        <Grid
          item
          sx={{
            flexDirection: "column",
            display: "flex",
            flex: 1,
            padding: 0,
            backgroundColor: "background.default",
            paddingBottom: 0,
            maxWidth: isMedium ? `${(constantWidth / 12) * 3}px` : "100vw",
            width: isMedium ? `${(constantWidth / 12) * 3}px` : "100vw",
          }}
        >
          <Box
            sx={{
              backgroundColor: "background.test",
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
              justifyContent: "space-between",
              paddingLeft: "15px",
            }}
          >
            <TypographyItem style={{ cursor: "pointer" }} onClick={() => {}} text={`${"Chats"}`} fontSize={23} fontWeight="600" />
            <Box sx={{ minWidth: 120, margin: "5px 17px", width: "50%" }}>
              <DottedMenuForChatFilter
                readFilterActionHandler={readFilterActionHandler}
                read={read}
                country={country}
                countryFilterActionHandler={countryFilterActionHandler}
                successManagersList={successManagersList}
                successManagerId={successManagerId}
                successManagersFilterActionHandler={successManagersFilterActionHandler}
              />
            </Box>
          </Box>
          <Grid
            sx={{
              display: "flex",
              flexDirection: "row",
              width: "100%",
              height: "100%",
            }}
          >
            <UserListComponent
              palette={palette}
              searchValue={search}
              chats={chats?.length ? chats : []}
              setChatId={(val: string, val2: string) => {
                setChatEmail(val2);
                setChatId(val);
                setTempSearchHolder(val2);
                setChatDialogOpen(window.innerWidth < 1200 ? true : false);
              }}
              chat={chat}
              usrMessage={chat}
              searchOnChange={(val: string) => {
                setSearch(val);
                val.length && setTempSearchHolder(null);
                setChats([]);
                setPageIndex({ index: 1 });
                setLoading(true);
              }}
              loading={loading}
              setPageIndex={setPageIndex}
              setChatSelectDirection={setChatSelectDirection}
              pageIndex={pageIndex}
              int={init}
              auth={auth}
            />
          </Grid>
        </Grid>

        {isMedium && (
          <Grid
            container
            item
            sx={{
              borderRight: "solid 1px lightgrey",
              width: isMedium ? `calc(100vw - ${(constantWidth / 12) * 6}px)` : "100vw",
              maxWidth: isMedium ? `calc(100vw - ${(constantWidth / 12) * 6}px)` : "100vw",
            }}
          >
            <ChatComponent
              chat={chat}
              chatPer={chatPer}
              messageDeleteActionHandler={messageDeleteActionHandler}
              currUserName={auth?.user?.fullName}
              messageSenderUpdaterHandler={messageSenderUpdaterHandler}
              onOpen={() => {}}
            />
          </Grid>
        )}
        {isMedium && (
          <Grid
            container
            item
            sx={{
              flexDirection: "column",
              backgroundColor: "background.default",
              borderLeft: "solid 1px lightgrey",
              overflow: "hidden",
              width: isMedium ? `${(constantWidth / 12) * 3}px` : "100vw",
              maxWidth: isMedium ? `${(constantWidth / 12) * 3}px` : "100vw",
            }}
          >
            {animated()}
          </Grid>
        )}
      </Grid>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    mode: state.app.currentTheme,
    auth: state.auth,
    palette: state.theme.palette,
    app: state.app,
  };
};

export default connect(mapStateToProps, {
  setCountryName,
})(ChatPage);
