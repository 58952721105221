import { ajax } from "rxjs/ajax"
import { map, catchError } from "rxjs/operators"
import { environment } from "../../environment/environment"
import { of } from "rxjs"
import { UserProfileInfoCardEditSerializer, UserSerializer } from "./serializer"
import { User, UserProfileInfoCardEdit } from "./user"
import { ResourceProvider } from "../../providers/resource.provider"

const API_BASE_URL = environment.basePath

export const login = (username: string, password: string, token: string) => {
    const url = `${API_BASE_URL}/login_user`
    const userSerializer = new UserSerializer()
    const headers = {
        "Content-Type": "application/json"
    }
    const body = { username, password, token }
    return ajax({ url, method: "POST", headers, body }).pipe(
        map((resp: any) => userSerializer.fromJson(resp.response) as User),
        catchError((error) => {
            return of(error);
        })
    )
};

const getToken = async () => {
    const currentUser: string | any = localStorage.getItem("currentUser") ? localStorage.getItem("currentUser") : ''
    const user: User | null = await JSON.parse(currentUser);
    if (!user?.accessToken) {
        return 'app'
    }
    return user.accessToken;
}

export const logout = async () => {
    const token = await getToken()
    const headers: any = {}
    headers["Accept"] = "*/*"
    headers["Content-Type"] = "application/json"
    if (token && token.length) {
        headers["Authorization"] = `Bearer ${token}`
    }
    const url = `${API_BASE_URL}/logout_user`
    return ajax({ url, method: "DELETE", headers }).pipe(map((resp: any) => resp),
        catchError((error) => {
            return of(error)
        })
    ).toPromise();
}

export class UserListProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_employee_list", new UserSerializer())
    }
}
export class UserProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_employee", new UserSerializer())
    }
}
export class UserUpdateProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_update_employee", new UserSerializer())
    }
}
export class UserRegistrationProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_user", new UserSerializer())
    }
}
export class ChartProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_chart", new UserSerializer())
    }
}
export class UserProfileProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_user_profile", new UserSerializer())
    }
}
export class UserProfileConciseProvider extends ResourceProvider<User> {
    constructor() {
        super("rest_user_concise", new UserSerializer())
    }
}
export class UserProfileInfoCarEditProvider extends ResourceProvider<UserProfileInfoCardEdit> {
    constructor() {
        super("rest_app_user_data_override", new UserProfileInfoCardEditSerializer())
    }
}

export const userListService = new UserListProvider()
export const userService = new UserProvider()
export const userProfileConciseService = new UserProfileConciseProvider()
export const userUpdateService = new UserUpdateProvider()
export const userRegistrationService = new UserRegistrationProvider()
export const chartService = new ChartProvider()
export const userProfileService = new UserProfileProvider()
export const userProfileEditService = new UserProfileInfoCarEditProvider()

