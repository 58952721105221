import { ResourceProvider } from "../../providers/resource.provider";
import { Badge } from "./Badge";
import { BadgeSerializer } from "./serializer";

class BadgeProvider extends ResourceProvider<Badge> {
    constructor() {
        super(
            "rest_chat_counter",
            new BadgeSerializer()
        )
    }
}

export const badgeService = new BadgeProvider()