import { Box, Grid, Table, TableContainer } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { setSnack } from "../../models/application/applicationActions";
import { versionService } from "../../models/Version/service";
import { Version } from "../../models/Version/Version";
import VersionsFormDialog from "../Dialogs/VersionsFormDialog";
import MainTableHeaderBodyContainer from "../MainTableHeaderBodyContainer";

const VersionsTable = (props: any) => {
  const {
    setSnack,
    app,
    muiTheme,
    palette,
    tableName,
    handleSubmit,
    tableList,
    handleOrder,
    handleOnToggle,
    handleOnChange,
    selectedFilters,
    tableStructureList,
    filterItems,
  } = props;

  const localList = JSON.parse(localStorage.getItem(tableName) || "{}");
  const [cachedList, setCachedList] = useState<{ key: string; list: any[] }>({ key: "", list: [] });
  const [filteredList, setFilteredList] = useState<any[]>(Object.keys(localList).length ? localList : tableStructureList);
  const [version, setVersion] = useState<Version | null>(null);
  const [open, setOpen] = useState(false);

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const getOverallTrueFalseFromFilteredList = () => {
    return filteredList.filter((e) => e.enabled === true).length ? true : false;
  };

  const handleCachedList = (item: { key: string; list: any[] }) => {
    setCachedList(item);
  };

  const handleEdit = (state: boolean, item: Version) => {
    setOpen(state);
    setVersion(item);
  };

  const handleFilteredList = (state: any[]) => {
    setFilteredList(state);
  };

  return (
    <Grid item lg={12} overflow={"overlay"}>
      <TableContainer>
        <Table sx={{ width: getOverallTrueFalseFromFilteredList() ? "100%" : "fit-content" }}>
          <MainTableHeaderBodyContainer
            padding="checkbox"
            size="small"
            align="center"
            handleEdit={handleEdit}
            filterItems={filterItems}
            headerList={filteredList}
            handleOrder={handleOrder}
            handleOnToggle={handleOnToggle}
            handleCachedList={handleCachedList}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            cachedList={cachedList}
            handleFilteredList={handleFilteredList}
            handleSubmit={handleSubmit}
            updater={new Version()}
            service={versionService}
            copySuccessMessage={"Id copied successfully"}
            tableList={tableList}
            tableName={tableName}
            pathname="versionDetails"
          />
        </Table>
      </TableContainer>
      <VersionsFormDialog setSubmitted={handleSubmit} onAlert={onAlert} item={version!} open={open} handleDialog={(bool: any) => setOpen(bool)} />
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { setSnack })(VersionsTable);
