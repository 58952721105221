import { Serializer } from '../serializer'
import { TaskComponent } from './taskcomponent'




export class TaskComponentSerializer implements Serializer {
    fromJson(json: any) {
        const component = new TaskComponent()
        component.name = json.name
        component.id = json.id
        component.description = json.description
        return component
    }

    toJson(item: TaskComponent) {
        const obj: any = {}
        
        return obj
    }
}
