import { PaletteMode } from "@mui/material";
import { grey } from "@mui/material/colors";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import React, { useEffect } from "react";
import { connect } from "react-redux";
import { setTheme } from "../models/application/applicationActions";

const getDesignTokens = (mode: PaletteMode) => ({
  palette: {
    ...(mode === "dark" && {
      background: {
        reverse: grey[300],
        closeButton: "gray",
        paper: grey[600],
        test: grey[900],
        default: grey[900],
      },
    }),
    ...(mode === "light" && {
      background: {
        reverse: grey[900],
        closeButton: "black",
        paper: grey[400],
        test: "white",
        default: grey[300],
      },
    }),
    text: {
      ...(mode === "light"
        ? {
            cardInfo: "#8a8a8a",
            primary: grey[900],
            secondary: grey[800],
            contrast: "black",
          }
        : {
            cardInfo: "#fff",
            primary: grey[100],
            secondary: grey[500],
            contrast: "white",
          }),
    },
  },
});

const MuiThemeProvider = (props: any) => {
  const { app } = props;
  const [mode, setMode] = React.useState<"light" | "dark">(app?.currentTheme ? app.currentTheme : "dark");

  useEffect(() => {
    if (app.fixedFilter?.shown) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "";
    }
  }, [app.fixedFilter]);

  useEffect(() => {
    setMode(app?.currentTheme ? app.currentTheme : localStorage.getItem("paletteState"));
  }, [app?.currentTheme]);

  const theme = React.useMemo(
    () =>
      createTheme({
        ...getDesignTokens(mode),
      }),
    [mode]
  );
  return <ThemeProvider theme={theme}>{props.children}</ThemeProvider>;
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
  };
};

export default connect(mapStateToProps, { setTheme })(MuiThemeProvider);
