import { createStyles, makeStyles, Theme } from "@material-ui/core";
import { connect } from 'react-redux';
import { register } from "../models/user/authActions";



const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    leftcolumn: {
      padding: 10
    }
  })
);

const MainPage = (props: any) => {
  const { palette } = props
  const classes = useStyles();
  return (
    <div
      className={classes.root}
      style={{
        background: palette.background.default,
        height: "calc(100vh - 120px)",
        display: "flex",
        alignItems: "center",
        flexDirection: "column"
      }}>
      <h1 style={{ color: palette.text.primary }}>Main Page</h1>
    </div >
  )
}


const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    palette: state.theme.palette
  }
}

export default connect(mapStateToProps, { register })(MainPage)