// Generated by https://quicktype.io

import { Resource } from "../resource";

export interface LogsResponse {
    count: number;
    current: PageLogs[];
    hasNext: boolean;
    next: any[];
}

export class PageLogs extends Resource {
    batchId: string | null | undefined;
    created: string | null | undefined;
    date: string | null | undefined;
    filterSetId: string | null | undefined;
    product: string | null | undefined;
    productId: string | null | undefined;
    promotionId: string | null | undefined;
    screen: string | null | undefined;
    type: string | null | undefined;
    user: string | null | undefined;
    userId: string | null | undefined;
    image: string | null | undefined;
    productName: string | null | undefined;
    seller: string | null | undefined;
}

export class EngagedProductsLogs extends Resource {
    image: string | null | undefined;
    total: number | null | undefined = 0;
    save: number | null | undefined = 0;
    like: number | null | undefined = 0;
    open: number | null | undefined = 0;
    initialOfferStart: number | null | undefined = 0;
    initialOfferFinish: number | null | undefined = 0;
    productExists: boolean | null | undefined;
    stock: number | null | undefined = 0;
}
