import CloseRoundedIcon from "@mui/icons-material/CloseRounded";
import { Box, Grid, IconButton } from "@mui/material";
import MessageSenderForm from "../../Forms/MessageSenderForm";
import { TypographyItem } from "../../Typography/TypographyItem";

const MessageInputComponent = (props: any) => {
  const { setEditMode, editMode, setEditText, editText, uid, chat, replyText, replierName, setReplierName, setReplyText, messageSenderUpdaterHandler } = props;

  const replierCloseHandler = () => {
    setReplierName(null);
    setReplyText(null);
  };

  return (
    <Grid item container>
      {replyText && (
        <Box
          sx={{
            widht: "100%",
            minHeight: "70px",
            borderTop: "solid 1px lightgrey",
            paddingLeft: "15px",
            display: "flex",
            flexDirection: "column",
            alignItems: "flex-start",
            justifyContent: "space-evenly",
            position: "relative",
            backgroundColor: "background.default",
          }}
        >
          <IconButton onClick={replierCloseHandler} sx={{ position: "absolute", top: "21%", bottom: "21%", right: 30, margin: "auto" }}>
            <CloseRoundedIcon />
          </IconButton>
          <TypographyItem text={replierName} fontWeight={600} color={"text.primary"} size={20} />
          <Box
            sx={{
              color: "text.primary",
              whiteSpace: "normal",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 1,
              overflow: "hidden",
              textOverflow: "ellipsis",
              textAlign: "left",
              width: "450px",
              fontSize: ".9em",
            }}
          >
            {replyText}
          </Box>
        </Box>
      )}
      <Box
        sx={{
          minHeight: "60px",
          width: "100%",
          display: "flex",
          alignItems: "end",
          boxSizing: "border-box",
          borderTop: "solid 1px lightgrey",
          backgroundColor: "background.default",
        }}
      >
        <Box sx={{}} style={{ display: "flex", height: "100%", padding: "0px 0px 15px 10px", width: "100%" }}>
          <MessageSenderForm
            setReplyText={setReplyText}
            replyText={replyText}
            setEditText={setEditText}
            editText={editText}
            setEditMode={setEditMode}
            editMode={editMode}
            id={chat?.id}
            uid={uid}
            messageSenderUpdaterHandler={messageSenderUpdaterHandler}
          />
        </Box>
      </Box>
    </Grid>
  );
};

export default MessageInputComponent;
