import { Resource } from "./../resource";

export class CartOrder extends Resource {
  address: string | null | undefined;
  bundleDiscount: number | null | undefined;
  country: string | null | undefined;
  coupon: string | null | undefined;
  couponDiscount: number | null | undefined;
  created: string | null | undefined;
  currency: string | null | undefined;
  giftPackaging: number | null | undefined;
  grandTotal: number | null | undefined;
  installmentDiscount: number | null | undefined;
  method: string | null | undefined;
  paidWithSolo: boolean | null | undefined;
  paymentMethod: string | null | undefined;
  phubbalanceDiscount: number | null | undefined;
  phubbalanceUsed: number | null | undefined;
  pickupAddress: string | null | undefined;
  pickupDiscount: number | null | undefined;
  sellerDiscount: number | null | undefined;
  shippingMethod: string | null | undefined;
  shippingType: string | null | undefined;
  productCount: string | null | undefined;
  status: string | null | undefined;
  subtotal: number | null | undefined;
  total: number | null | undefined;
  type: string | null | undefined;
  user: string | null | undefined;
}
