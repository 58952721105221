import { ResourceProvider } from "../../providers/resource.provider";
import { TaskSerializer } from "./serializer";
import { Task } from "./Task";




export class TaskProvider extends ResourceProvider<Task> {
    constructor() {
        super("rest_task", new TaskSerializer())
    }
}


export const taskService = new TaskProvider()
