import { IndeterminateCheckBox } from "@mui/icons-material";
import { Box, Checkbox, ClassNameMap, PaletteMode, TableCell, Tooltip, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import DottedMenu from "../../Components/DottedMenu";
import { QueryOptions } from "../../helpers/query.options";
import { testingPageListItemService } from "../../models/testing/service";
import { TestingPageListItem } from "../../models/testing/TestingPageListItem";
import { User } from "../../models/user/user";
import { Palette } from "../../providers/ThemeProvider";

const useStyles = makeStyles(() => ({
  checkbox: (props: any) => ({
    "& .PrivateSwitchBase-input-20": {
      color: "white",
    },
    "& .MuiIconButton-label": {
      color: "darkgreen",
    },
  }),
  tableCell: {
    padding: " 10px 0 10px 0",
  },
}));

class DT {
  "To Do": number;
  "Done": number;
  "Fail": number;
}

interface TestingStatusesProps {
  id: string;
  testers: User[];
  palette: Palette;
  name: string;
  theme: PaletteMode;
}

interface CheckboxProps {
  testing: TestingPageListItem | any;
  classes: ClassNameMap;
  isMain: Boolean;
  item: User;
}

const CheckBoxWIthStatus = (props: CheckboxProps) => {
  const { classes, testing, isMain, item } = props;

  const [testCount, setTestCount] = useState(testing?.count);

  useEffect(() => {
    setTestCount(testing?.count);
  }, [testing?.count]);

  const TestingStatuses = (item: User) => {
    if (testing?.testers?.filter((e: any) => e.tester.id === item?.id) !== undefined) {
      const [Item] = testing?.testers?.filter((e: any) => e.tester.id === item?.id);
      return Item;
    } else return undefined;
  };

  const TitleComponent = (count: DT, Title: string) => (
    <Box>
      <Typography variant="h5" style={{ width: "100%", height: "30px", fontWeight: "bolder" }}>
        {Title}
      </Typography>
      <Typography variant="h6" style={{ color: "#fabc06" }}>
        To Do: {count["To Do"]}
      </Typography>
      <Typography variant="h6" style={{ color: "#09e841" }}>
        Done: {count.Done}
      </Typography>
      <Typography variant="h6" style={{ color: "#f28e8e" }}>
        Fail: {count.Fail}
      </Typography>
    </Box>
  );

  const GetCount = (Count: any) => {
    const Dict = new DT();
    Dict["To Do"] = testCount - CountStatuses(Count, "Done") - CountStatuses(Count, "Fail");
    Dict["Done"] = CountStatuses(Count, "Done");
    Dict["Fail"] = CountStatuses(Count, "Fail");
    return Dict;
  };

  const CountStatuses = (Count: any, name: string) => {
    return Count?.statuses?.filter((e: any) => e.status.name === name).length ? Count?.statuses?.filter((e: any) => e.status.name === name)[0].count : 0;
  };

  const Title = (Func: typeof TestingStatuses, item: any) => {
    if (Func(item) && Func(item)["true/false"]) {
      const Count = Func(item);
      let Elem = TitleComponent(GetCount(Count), "Testing Done"),
        name = "Testing Done";
      return {
        Elem,
        name,
      };
    } else if (Func(item) && GetCount(Func(item)).Fail === 0) {
      const Count = Func(item);
      let Elem = TitleComponent(GetCount(Count), "Testing Started"),
        name = "Testing Started";
      return {
        Elem,
        name,
      };
    } else if (Func(item) && GetCount(Func(item)).Fail !== 0) {
      const Count = Func(item);
      let Elem = TitleComponent(GetCount(Count), "Has Failed Item"),
        name = "Has Failed Item";
      return {
        Elem,
        name,
      };
    } else {
      const Count = Func(item);
      let Elem = TitleComponent(GetCount(Count), "Testing Not Started"),
        name = "Testing Not Started";
      return {
        Elem,
        name,
      };
    }
  };

  const StatusColor = (Func: typeof TestingStatuses, item: any, title: typeof Title) => {
    const { Elem, name }: any = title(Func, item);
    switch (name) {
      case "Testing Done":
        return "#09e841";
      case "Testing Started":
        return "#fabc06";
      case "Testing Not Started":
        return "lightgrey";
      default:
        return "#f44336";
    }
  };

  const ToolTipCheckBox = (Func: typeof TestingStatuses, title: typeof Title, item: User, Color: typeof StatusColor) => {
    const { Elem, name }: any = title(Func, item);
    return (
      <Box style={{ position: "relative", display: "flex", justifyContent: "center", height: "fit-content", width: "fit-content" }}>
        <Box
          style={{
            width: "15px",
            height: "15px",
            position: "absolute",
            background: "white",
            left: "0",
            right: "0",
            top: 0,
            bottom: 0,
            margin: "auto",
          }}
        />
        {isMain ? (
          <Checkbox
            className={classes.checkbox}
            color="secondary"
            checked={testing?.status || false}
            onClick={(e) => e.preventDefault()}
            icon={<IndeterminateCheckBox color="error" />}
          />
        ) : (
          <Tooltip title={Elem}>
            <Checkbox
              className={classes.checkbox}
              color="secondary"
              checked={Func(item) ? Func(item)["true/false"] : false}
              icon={<IndeterminateCheckBox style={{ color: Color(Func, item, title) }} />}
            />
          </Tooltip>
        )}
      </Box>
    );
  };

  return ToolTipCheckBox(TestingStatuses, Title, item, StatusColor);
};

export const TestingStatuses = (props: TestingStatusesProps) => {
  const { id, testers, name, theme } = props;

  const [testing, setTesting] = useState<TestingPageListItem | any>();

  const getTestingPageList = (id: string) => {
    const options = new QueryOptions();
    if (id) {
      options.version = id;
      testingPageListItemService.listDict(options).then((r) => {
        setTesting(r);
      });
    }
  };

  useEffect(() => {
    getTestingPageList(id);
  }, [id]);

  const classes = useStyles({});

  return (
    <>
      <TableCell align="center" className={classes.tableCell}>
        <Box
          style={{
            minWidth: "100%",
            minHeight: "80px",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <CheckBoxWIthStatus testing={testing} classes={classes} isMain={true} item={new User()} />
        </Box>
      </TableCell>
      {testers.map((item: User, index: number) => {
        return (
          <TableCell key={index} align="center" className={classes.tableCell}>
            <Box
              style={{
                minWidth: "120px",
                minHeight: "80px",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "space-between",
                padding: "0 10px 0 10px",
              }}
            >
              <CheckBoxWIthStatus testing={testing} classes={classes} isMain={false} item={item} />
              <DottedMenu testerID={item.id as string} versionID={id} versionName={name} />
            </Box>
          </TableCell>
        );
      })}
    </>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    roles: state.auth.user.roles,
    app: state.app,
    palette: state.theme.palette,
    theme: state.theme.theme,
  };
};

export default connect(mapStateToProps)(TestingStatuses);
