import { saveSerializer } from './serializer';
import { ProductSave } from './ProductSave';
import { ResourceProvider } from '../../providers/resource.provider';

export class SaveProvider extends ResourceProvider<ProductSave> {
    constructor() {
        super(
            "rest_product_save",
            saveSerializer
        )
    }
}

export const saveService = new SaveProvider()