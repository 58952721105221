import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import TestingTableList from "../../Components/Tables/TestingTableList";
import { App, Auth } from "../../environment/environment";
import { EmployeeGroup } from "../../models/eployeeGroup/employeeGroup";
import { employeeGroupService } from "../../models/eployeeGroup/service";
import { register } from "../../models/user/authActions";
import { User } from "../../models/user/user";
import { Version } from "../../models/Version/Version";
import { Palette } from "../../providers/ThemeProvider";

interface TestingPageProps {
  palette: Palette;
  app: App;
  auth: Auth;
}

const TestingPage = (props: TestingPageProps) => {
  const { palette, app } = props;
  const [version, setVersion] = useState<Version[] | any>(app?.version.sort((a: any, b: any) => a.name.localeCompare(b.name)));
  const [testers, setTesters] = useState<User[]>([new User()]);

  useEffect(() => {
    if (app.version) {
      setVersion(app?.version.sort((a: any, b: any) => a.name.localeCompare(b.name)));
    }

    //temp to be changed to variable 62de58cfe8a6753d504f0cac testers group
    employeeGroupService.read("62de58cfe8a6753d504f0cac").then((r: EmployeeGroup) => {
      setTesters(r?.users?.length ? r.users : []);
    });

    return () => {
      setVersion([]);
      setTesters([]);
    };
  }, [app?.version]);

  return (
    <Grid container item justifyContent={"center"} overflow="auto">
      <Grid item lg={6} md={12} xs={12}>
        <TestingTableList testers={testers} version={version} />
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
    app: state.app,
  };
};

export default connect(mapStateToProps, { register })(TestingPage);
