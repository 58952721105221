import { Grid } from '@mui/material'
import React from 'react'
import { User } from '../../models/user/user'
import { TypographyItem } from '../Typography/TypographyItem'

interface SocialStatsProps {
    userProfile: User
}

export const UserProfileSocialStats = (props: SocialStatsProps) => {
    const {userProfile} = props
    interface StatsProps {
        title: string
        nValue: number | string
    }

    const StatField = (props: StatsProps) => {
        const { title, nValue } = props
        return <Grid
            item
            container
            flex={1}
            sx={{ maxHeight: '100%', boxSizing: 'border-box' }
            }
            display='grid'
            gridTemplateColumns={'auto'}
            justifyContent='space-evenly'
        >
            <TypographyItem
                style={{ padding: 0, margin: 0 }}
                fontSize={20}
                text={nValue}
                fontWeight='600'
            />
            <TypographyItem
                fontSize={20}
                text={title}
                fontWeight='500'
            />
        </Grid >
    }

    return (
        <Grid spacing={0} container item sx={{ width: '90%', maxHeight: '30%', padding: '5px 0 17px 0', boxSizing: 'border-box' }} flex={3} display='flex' justifyContent='space-between'>
            <StatField title='Listing' nValue={userProfile?.listingsCount} />
            <StatField title='Followers' nValue={userProfile?.followersCount} />
            <StatField title='Following' nValue={userProfile?.followingCount} />
        </Grid>
    )
}
