import { ResourceProvider } from "../../providers/resource.provider";
import { EmployeeGroup } from "./employeeGroup";
import { EmployeeGroupSerializer } from "./serializer";

export class EmployeeGroupProvider extends ResourceProvider<EmployeeGroup> {
    constructor() {
        super(
            "rest_employee_group",
            new EmployeeGroupSerializer()
        )
    }
}

export const employeeGroupService = new EmployeeGroupProvider()