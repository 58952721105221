import { PaletteMode } from "@mui/material";
import { State } from "../models/application/applicationActions";
import { Category } from "../models/category/category";
import { Component } from "../models/component/component";
import { Features } from "../models/features/features";
import { Issue, IssueType } from "../models/issue/Issue";
import { Priority } from "../models/priority/priority";
import { Project } from "../models/project/project";
import { TestStatus } from "../models/testing/TestObject";
import { User } from "../models/user/user";
import { Status, Version } from "../models/Version/Version";
import { Palette } from "../providers/ThemeProvider";

const production = true;

export const environment = {
  production: production,
  tempServer: "http://localhost:5000",
  basePath: production ? "https://portal.phubber.ge" : "http://192.168.100.20:8060",
  baseQueryParams: "?pageIndex=1&pageSize=30&sort=created&order=desc",
  phubberProductionPath: "https://phubber.ge",
  apiKey: "app_key",
  apiSecret: "app_secret",
  tokenTime: 7 * 24 * 60 * 60 * 1000,
  imagesPath: "static/images",
  IssueImages: "https://portal.phubber.ge/assets/portal_images/",
  usertImages: "https://portal.phubber.ge/assets/imgs/users/",
  productImages: "https://phubber.ge/assets/imgs/products/",

  defaultUserName: "არმაზი სიხარულიძე",
  allowedDocumentFormats: [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "image/png",
    "image/jpeg",
  ],
  messagingKey: "BKVCbww2LKbDpdZBwQFr37iENh-rbGngEdIAN9vMWqb2na8WdnaoDNVTA1l6GOGZPdDcXaSr3wMBaD0fhYsmdqo",
  tables: { orders: { pageSize: 30 } },
};

export const filterLists: { [key: string]: string[] } = {
  tasks: [
    "assignee",
    "category",
    "component",
    "issue",
    "priority",
    "project",
    "reviewer",
    "status",
    "tester",
    "type",
    "user",
    "version",
    "search",
    "created_from",
    "created_to",
  ],

  issues: ["category", "component", "project", "status", "type", "user", "search", "created_from", "created_to"],
  versions: ["name", "uid", "user", "created_from", "created_to", "search"],
  orders: [
    "search",
    "status",
    "processingState",
    "country",
    "method",
    "shippingType",
    "packaging",
    "seller",
    "user",
    "brand",
    "coupon",
    "created_from",
    "created_to",
  ],
  users: ["successManagerId", "appVersion", "created_from", "created_to", "birth_date", "search"],
  userDetails: ["search", "created_from", "created_to", "reasons", "rate"],
  logs: [""],
  products: ["search", "created_from", "created_to"],
  inVersionTasksTable: ["version"],
  usersPageLogs: [""],
  engagedProductsLogs: [""],
  userComments: [""],
  productSaves: [""],
  productPromotion: [""],
  cartOrder: [""],
  serviceReview: [""],
  productReview: [""],
};

export const versionsStructureList = [
  { name: "სახელი", getBy: "name", appFilterer: null, type: "nonDynamic", enabled: true, width: "160px", tooltip: true },
  { name: "UID", getBy: "uid", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "შექმნის თარიღი", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "საწყისი თარიღი", getBy: "startDate", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "გაშვების თარიღი", getBy: "releaseDate", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "ანდროიდის ვერსია", getBy: "version_android", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "აიოესის ვერსია", getBy: "version_ios", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];
export const ordersStructureList = [
  { name: "uid", getBy: "uid", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "buyer color", getBy: "buyerColor", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "seller color", getBy: "sellerColor", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "product id", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "image", getBy: "image", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "product", getBy: "product", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "brand", getBy: "brand", appFilterer: null, type: "alternateDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "processing state", getBy: "processingState", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "payment method", getBy: "method", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "shipping type", getBy: "shippingType", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "discount", getBy: "discount", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "offer", getBy: "offer", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "status", getBy: "status", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "created", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: false },
  { name: "order country", getBy: "country", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "seller country", getBy: "productUser.country", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "buyer country", getBy: "user.country", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "buyer", getBy: "buyer", appFilterer: null, type: "alternateDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "seller", getBy: "seller", appFilterer: null, type: "alternateDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "total", getBy: "total", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "coupon", getBy: "coupon", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: false },
  { name: "packaging", getBy: "packaging", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: false },
];

export const issuesStructureList = [
  { name: "სახელი", getBy: "name", appFilterer: null, type: "nonDynamic", enabled: true, width: "160px", tooltip: true },
  { name: "აღწერა", getBy: "description", appFilterer: null, type: "nonDynamic", enabled: true, width: "150px", tooltip: true },
  { name: "ავტორი", getBy: "user", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "სახეობა", getBy: "type", appFilterer: "issueTypes", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "პროექტი", getBy: "project", appFilterer: "projects", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "კატეგორია", getBy: "category", appFilterer: "categories", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "კომპონენტი", getBy: "component", appFilterer: "components", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "შექმნის თარიღი", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
];

export const tasksStructureList = [
  { name: "სახელი", getBy: "name", appFilterer: null, type: "nonDynamic", enabled: true, width: "160px", tooltip: true },
  { name: "საკითხი", getBy: "issue", appFilterer: "issues", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "მომხმარებელი", getBy: "user", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "კატეგორია", getBy: "category", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "პროექტი", getBy: "project", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "ტიპი", getBy: "type", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "კომპონენტი", getBy: "component", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "პასუხისმგებელი", getBy: "assignee", appFilterer: "assignee", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "ვერსია", getBy: "version", appFilterer: "version", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "შექმნის თარიღი", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "min esti.", getBy: "minutesEstimated", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "min actl.", getBy: "minutesSpent", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "აღწერა", getBy: "description", appFilterer: null, type: "nonDynamic", enabled: true, width: "150px", tooltip: true },
  { name: "პრი.", getBy: "priority", appFilterer: "priorities", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "სტატუსი", getBy: "status", appFilterer: "statuses", type: "dynamic", enabled: true, width: "max-content", tooltip: true },
];
export const appUsersStructureList = [
  { name: "image", getBy: "image", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  {
    name: "success manager",
    getBy: "successManagerGroup.manager.successManagerId",
    appFilterer: null,
    type: "dynamic",
    enabled: "auto",
    width: "max-content",
    tooltip: true,
  },
  { name: "app version", getBy: "appVersion", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "user name", getBy: "name", appFilterer: null, type: "nonDynamic", enabled: true, width: "160px", tooltip: true },
  { name: "user email", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "username", getBy: "username", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "user ID", getBy: "userId", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "phone", getBy: "phone", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "department", getBy: "department", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "birth date", getBy: "birthDate", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "bank account number", getBy: "bankAccountNumber", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "enabled/disabled", getBy: "isActive", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "balance", getBy: "promotionBalance", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];
export const pageLogsStructureList = [
  { name: "სურათი", getBy: "image", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "პროდუქტის ID", getBy: "productId", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "პროდუქტის სახელი", getBy: "productName", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "გამყიდველი", getBy: "seller", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "ეკრანი", getBy: "screen", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "ტიპი", getBy: "type", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "შექმნა", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
];

export const userCommentsStructureList = [
  { name: "product Id", getBy: "productId", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "comment Id", getBy: "commentId", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "comment", getBy: "comment", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product name", getBy: "productName", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product image", getBy: "productImage", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product enabled", getBy: "created", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product in stock", getBy: "productInStock", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product owner", getBy: "productOwner", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product owner manager", getBy: "productOwnerManager", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "counted", getBy: "counted", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "created", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "is enabled", getBy: "isEnabled", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "notified", getBy: "notified", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];

export const inVersionTasksStructureList = [
  { name: "საკითხი", getBy: "issue", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "მომხმარებელი", getBy: "user", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "კატეგორია", getBy: "category", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "პროექტი", getBy: "project", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "ტიპი", getBy: "type", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "კომპონენტი", getBy: "component", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "პასუხისმგებელი", getBy: "assignee", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "ვერსია", getBy: "version", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "შექმნის თარიღი", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "min esti.", getBy: "minutesEstimated", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "min actl.", getBy: "minutesSpent", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "აღწერა", getBy: "description", appFilterer: null, type: "nonDynamic", enabled: true, width: "150px", tooltip: true },
  { name: "პრი.", getBy: "priority", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "სტატუსი", getBy: "status", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];
export const productSummaryStructureList = [
  { name: "id", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "image", getBy: "image", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "name", getBy: "name", appFilterer: null, type: "nonDynamic", enabled: true, width: "160px", tooltip: true },
  { name: "created", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "brand", getBy: "brand", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "condition", getBy: "condition", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "country", getBy: "country", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "size", getBy: "size", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "enabled/disabled", getBy: "isEnabled", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];
export const productsStructureList = [
  { name: "id", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "image", getBy: "image", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "name", getBy: "name", appFilterer: null, type: "nonDynamic", enabled: true, width: "160px", tooltip: true },
  { name: "description", getBy: "description", appFilterer: null, type: "nonDynamic", enabled: true, width: "150px", tooltip: true },
  { name: "owner name", getBy: "ownerName", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "owner email", getBy: "ownerEmail", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "owner phone", getBy: "ownerPhone", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "owner price", getBy: "ownerPrice", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "price", getBy: "price", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "special price", getBy: "specialPrice", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "created at", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "brand name", getBy: "brand", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "category", getBy: "category.name", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "subcategory", getBy: "subcategory.name", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "section", getBy: "section.name", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "size", getBy: "size.name", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "condition", getBy: "condition.name", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "country", getBy: "country", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "enabled/disabled", getBy: "isEnabled", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];
export const engagedProductsLogsStructureList = [
  { name: "ID", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Image", getBy: "image", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Save", getBy: "save", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Like", getBy: "like", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Open", getBy: "open", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Initial Offer Start", getBy: "initialOfferStart", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Initial Offer Finish", getBy: "initialOfferFinish", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Total", getBy: "total", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Product Exists", getBy: "productExists", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "Stock", getBy: "stock", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];
export const productSavesStructureList = [
  { name: "image", getBy: "image", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "date", getBy: "date", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "enabled/disabled", getBy: "disabled", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "id", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "country", getBy: "productCountry", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product id", getBy: "productId", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product user", getBy: "productOwner", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];
export const productPromotionsStructureList = [
  { name: "id", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "status", getBy: "status", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "created", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "expiry date", getBy: "expired", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "seller balance", getBy: "promotion balance", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "image", getBy: "image", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product name", getBy: "productName", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "brand name", getBy: "brandName", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product ID", getBy: "productId", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "special price", getBy: "specialPrice", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "gender", getBy: "gender", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "age from", getBy: "ageFrom", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "age to", getBy: "ageTo", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "daily budget", getBy: "dailyBudget", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "duration in days", getBy: "durationDays", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "total budget", getBy: "totalBudget", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "feed views", getBy: "numberOfFeedsViews", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "search views", getBy: "numberOfFilterViews", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "clicks", getBy: "numberOfProductHits", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "likes", getBy: "numberOfLikes", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "saves", getBy: "numberOfSaves", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "comments", getBy: "numberOfComments", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "follow", getBy: "numberOfFollows", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "profile clicks", getBy: "numberOfProfileHits", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "total spent", getBy: "totalSpent", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "unused balance", getBy: "totalRest", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "used ratio", getBy: "ratio", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];
export const cartOrdersStructureList = [
  { name: "id", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "user email", getBy: "user", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "address", getBy: "address", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "bundle discount", getBy: "bundleDiscount", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "country", getBy: "country", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "coupon", getBy: "coupon", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "coupon discount", getBy: "couponDiscount", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "created", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "currency", getBy: "currency", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "gift packaging", getBy: "giftPackaging", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "grand total", getBy: "grandTotal", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "installment discount", getBy: "installmentDiscount", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "method", getBy: "method", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "paid with solo", getBy: "paidWithSolo", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "payment method", getBy: "paymentMethod", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "phubbalance discount", getBy: "phubbalanceDiscount", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "phubbalance used", getBy: "phubbalanceUsed", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "pickup address", getBy: "pickupAddress", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "pickup discount", getBy: "pickupDiscount", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "seller discount", getBy: "sellerDiscount", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "shipping method", getBy: "shippingMethod", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "shipping type", getBy: "shippingType", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "status", getBy: "status", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "subtotal", getBy: "subtotal", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "total", getBy: "total", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "product count", getBy: "productCount", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "type", getBy: "type", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];

export const reviewsStructureList = [
  { name: "id", getBy: "id", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "rate", getBy: "rate", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "created", getBy: "created", appFilterer: null, type: "date", enabled: true, width: "max-content", tooltip: true },
  { name: "order", getBy: "order", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "owner email", getBy: "ownerEmail", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "reasons", getBy: "reasons", appFilterer: null, type: "dynamic", enabled: true, width: "max-content", tooltip: true },
  { name: "status", getBy: "status", appFilterer: null, type: "nonDynamic", enabled: true, width: "max-content", tooltip: true },
];

export interface App {
  counter: string;
  currentTheme: PaletteMode;
  categories: Category[];
  snack: State;
  issueTypes: IssueType[];
  testStatuses: TestStatus[];
  components: Component[];
  priorities: Priority[];
  projects: Project[];
  username: string;
  assignee: User[];
  users: any[];
  version: Version[];
  features: Features[];
  issues: Issue[];
  snackBar: SnackBar;
  statuses: Status[];
}

export interface Auth {
  user: User;
  loggedIn: boolean;
  errorStatus: any;
}

export interface Theme {
  theme: PaletteMode;
  palette: Palette;
}

export interface ReduxState {
  auth: Auth;
  app: App;
  theme: Theme;
}

interface SnackBar {
  open: Boolean;
  vertical: string;
  horizontal: string;
  text: string;
}
