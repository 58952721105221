
import { Resource } from "../resource";

export interface SuccessManagerResponse {
    count:    number;
    current:  SuccessManagerItem[];
    has_next: boolean;
    next:     any[];
}

export class SuccessManagerItem extends Resource {
    groupName: string | undefined;
    id:         string | undefined;
    manager:    Manager | undefined;
}

export class Manager extends Resource {
    successManagerId:   string | undefined;
    successManagerName: string | undefined;
}
