import { UserComment, UserCommentOverride } from './UserComment';
import { ResourceProvider } from './../../providers/resource.provider';
import { userCommentSerializer, userCommentOverrideSerialzier } from './serializer';

class UserCommentServiceProvider extends ResourceProvider<UserComment> {
    constructor() {
        super(
            "rest_product_comment",
            userCommentSerializer
        )
    }
}

export const userCommentService = new UserCommentServiceProvider()

class UserCommentOverrideService extends ResourceProvider<UserCommentOverride> {
    constructor() {
        super(
            "rest_product_comment_override",
            userCommentOverrideSerialzier
        )
    }
}

export const userCommentOverrideService = new UserCommentOverrideService()