import { Project } from "../project/project"
import { Resource } from "../resource"
import { TaskComponent } from "../taskcomponent/taskcomponent"
import { Category } from "../category/category"
import { User } from "../user/user"
import { Version } from "../Version/Version"
import { Status } from "../status/status"
import { Task } from "../Task/Task"

export class IssueType extends Resource {
    name: string | any
}

export class Issue extends Resource {
    name: string | any
    images: File[] | any
    videos: string[] | any
    project: Project | any
    task: Task | any
    description: string | any
    type: IssueType | any
    category: Category | any
    component: TaskComponent | any
    version: Version | any
    is_enabled: Boolean | any
    created: Date | any
    status: Status | any
    user: User | any
    resource: any
}