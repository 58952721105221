import CloseIcon from "@mui/icons-material/Add";
import { Box, createTheme, Dialog, DialogContent, PaletteMode, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { connect } from "react-redux";
import { ReduxState } from "../../environment/environment";
import { permValid } from "../../helpers/permCheckers";
import { QueryOptions } from "../../helpers/query.options";
import { Component } from "../../models/component/component";
import { componentService } from "../../models/component/service";
import { Issue } from "../../models/issue/Issue";
import { issueService } from "../../models/issue/service";
import IssueForm from "../Forms/IssuesForm";

interface IssuesFormDialogProps {
  open: boolean;
  item: Issue;
  handleDialog: Function;
  setComponents: any;
  onAlert: Function;
  componentPer: string[];
  setSubmitted: Dispatch<SetStateAction<boolean>>;
  mode: PaletteMode;
}

function IssuesFormDialog(props: IssuesFormDialogProps) {
  const { open, handleDialog, item, setComponents, onAlert, componentPer, setSubmitted, mode } = props;

  const [respState, setRespState] = useState(false);
  const [componentOpenState, setComponentOpenState] = useState(false);

  /**
   * Fucntions go here
   */

  const getValues = (values: any) => {
    const iss = new Issue();
    iss.name = values.name;
    iss.description = values.description;
    iss.project = values.project;
    iss.status = values.status;
    iss.category = values.category;
    iss.component = values.component;
    iss.type = values.type;
    // item.image = values.image
    // item.videos = values.videos
    // item.is_enabled = values.is_enabled
    if (item) {
      iss.id = item.id;
      issueService.update({ ...iss, id: item.id }).then((r: any) => {
        if (r?.name === "AjaxError") {
          onAlert(`საკითხი ${iss.name} ვერ განახლდა::error`);
          setSubmitted((prev) => !prev);
          handleDialog(false);
        } else {
          onAlert(`საკითხი ${iss.name} განახლდა წარმატებით::success`);
          setSubmitted((prev) => !prev);
          handleDialog(false);
        }
      });
    } else {
      issueService.create(iss).then((r: any) => {
        if (r?.name === "AjaxError") {
          onAlert(`საკითხი ${iss.name} ვერ დაემატა::error`);
          setSubmitted((prev) => !prev);
          handleDialog(false);
        } else {
          onAlert(`საკითხი ${iss.name} დაემატა წარმატებით::success`);
          setSubmitted((prev) => !prev);
          handleDialog(false);
        }
      });
    }
  };

  const loadComponents = () => {
    const options = new QueryOptions();
    options.pageSize = 100;
    componentService.listWithCounter(options).then((r) => {
      setComponents(r.current);
    });
  };

  useEffect(() => {
    loadComponents();
    //eslint-disable-next-line
  }, [respState]);

  const setRespStateHandler = () => {
    permValid(componentPer, "write") && setComponentOpenState(true);
  };

  const createComponent = (value: any) => {
    const component = new Component();
    component.name = value.name;
    componentService.create(component).then((r: Component) => {
      setComponentOpenState(false);
      setRespState(!respState);
    });
  };

  const handleOpenState = (val: boolean) => {
    return () => setComponentOpenState(val);
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
    typography: {
      fontSize: 13,
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 13,
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={localTheme}>
      <Dialog open={open} fullWidth={true} maxWidth={"md"}>
        <DialogContent
          sx={{
            overflow: "hidden",
            backgroundColor: "background.default",
            backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
          }}
        >
          <Box onClick={() => handleDialog(false)} sx={{ position: "absolute", right: 10, top: 10, cursor: "pointer" }}>
            <CloseIcon style={{ transform: "rotate(45deg)" }} />
          </Box>
          <IssueForm
            item={item}
            createComponent={createComponent}
            setRespStateHandler={setRespStateHandler}
            getValues={getValues}
            handleOpenState={handleOpenState}
            componentOpenState={componentOpenState}
            handleDialog={handleDialog}
            componentPer={componentPer}
          />
        </DialogContent>
      </Dialog>
    </ThemeProvider>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { app } = state;
  return {
    mode: app.currentTheme,
  };
};

export default connect(mapStateToProps)(IssuesFormDialog);
