import { Box } from "@mui/material";
import { FilterChip } from "./FilterChip";

export const FilterHeader = (props: any) => {
  const { items, handleDelete } = props;

  return (
    <Box sx={{ display: "flex", width: "100%", textAlign: "left", padding: "5px 0px 0px 0px", flexWrap: "wrap", alignItems: "center" }}>
      {items?.map((item: any, index: number) => {
        return <FilterChip key={index} item={item} handleDelete={handleDelete} />;
      })}
    </Box>
  );
};
