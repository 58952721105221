import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FilterHeader } from "../../Components/Filter/FilterHeader";
import Pagination from "../../Components/Pagination";
import SearchDateFilter from "../../Components/SearchDateFilter";
import CartOrderTable from "../../Components/Tables/CartOrderTable";
import { cartOrdersStructureList, filterLists } from "../../environment/environment";
import { useDidMountEffect } from "../../helpers/customHooks";
import { QueryOptions } from "../../helpers/query.options";
import { generateParamsFromUrl, urlChanger } from "../../helpers/utilities";
import { setSnack } from "../../models/application/applicationActions";
import { CartOrder } from "../../models/CartOrder/CartOrder";
import { cartOrderService } from "../../models/CartOrder/service";
import { register } from "../../models/user/authActions";

const CartOrderPage = (props: any) => {
  const { palette } = props;

  const history = useHistory();
  const location = useLocation();
  const pageName = "userDetails";
  const pagePathnmae = `${pageName}/${location.pathname.split("/")[2]}`;
  const [options, setOptions] = useState<any>(new QueryOptions());
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [cartOrderList, setCartOrderList] = useState<CartOrder[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [count, setCount] = useState(1);
  const [urlValues, setUrlValues] = useState({
    pageIndex: 1,
    pageSize: 30,
    sort: "created",
    search: "",
    order: "desc",
    created_from: null,
    created_to: null,
  });

  const handleOrder = (state: string) => {
    urlChanger(history, pagePathnmae, "order", state);
  };

  useDidMountEffect(() => {
    if (options) {
      getCartOrders(options);
    }
  }, [JSON.stringify(options), submitted]);

  const handleUrlValues = (setBy: string, value: unknown) => {
    if (typeof value === "string" || typeof value === "number") {
      setUrlValues((prev) => {
        return { ...prev, [setBy]: value };
      });
    }
  };

  const handleOnChange = (item: any) => {
    urlChanger(history, pagePathnmae, item.key, item.value.length ? item.value : "", 1);
  };
  const handleFilterDelete = (item: any) => {
    let loc: any = window.location.search;
    loc = loc
      .split("&")
      .filter((r: any) => r.split("=")[1].indexOf(item.id) > -1 && r.split("=")[0].indexOf(item.key) > -1)
      .toString()
      .split("=")[1]
      .split(",")
      .filter((itm: any) => itm !== item.id)
      .toString();
    urlChanger(history, pagePathnmae, item.key, loc, 1);
  };

  const handleOnToggle = (items: any[], name: string) => {
    let key = "";
    let params = "";
    if (items.length) {
      key = items[0].key;
      params = items.map((item) => item.id).toString();
      urlChanger(history, pagePathnmae, key, params, 1);
    } else {
      urlChanger(history, pagePathnmae, items[0].key, items[0].id, 1);
    }
  };

  const clearFilterRedirect = () => {
    let loc = location.search;
    let arrParams = loc
      .split("&")
      .filter((item) => filterLists[pageName].toString().indexOf(item.split("=")[0]) === -1)
      .join("&");
    history.push({ pathname: `/${pagePathnmae}`, search: arrParams });
  };

  useEffect(() => {
    let arr: any = [];
    const params = generateParamsFromUrl(location, pageName);

    params.userId = location.pathname.split("/")[2];
    Object.entries(params).forEach(([key, value]) => {
      if (filterLists[pageName].filter((e: string) => e === key).length) {
        if (typeof value === "string") {
          value.split(",").forEach((item) => {
            arr.push({ id: item, name: item, key: key });
          });
        }
        if (key === "search" || key === "created_from" || key === "created_to" || key === "birth_date" || key === "pageIndex") {
          handleUrlValues(key, value ? value : "");
        }
      }
      if (key === "pageIndex") {
        handleUrlValues(key, value ? value : "");
      }
    });

    setSelectedFilters(arr);
    setOptions(params);
  }, [location]);

  const getCartOrders = (options: QueryOptions) => {
    cartOrderService.listWithCounter(options).then((r) => {
      setCartOrderList(r.current);
      setCount(r.count);
    });
  };

  return (
    <Box
      sx={{
        backgroundColor: "background.paper",
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        overflowY: "auto",
      }}
    >
      <Grid
        sx={{
          width: "100%",
          padding: "20px",
          paddingBottom: "0",
          height: "calc(100% - 10px)",
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
        }}
      >
        <Grid>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
            }}
          >
            <SearchDateFilter
              setSearch={(item: any) => {
                if (history.location.search.indexOf("search") > -1) {
                  urlChanger(history, pagePathnmae, "search", item.searchValue, 1);
                } else if (history.location.search.indexOf("search") < 0 && item.searchValue.length) {
                  urlChanger(history, pagePathnmae, "search", item.searchValue, 1);
                }
              }}
              setFrom={(item: any) => urlChanger(history, pagePathnmae, "created_from", item)}
              setTo={(item: any) => urlChanger(history, pagePathnmae, "created_to", item)}
              search={urlValues.search}
              from={urlValues.created_from}
              to={urlValues.created_to}
            />

            {selectedFilters?.length > 0 && (
              <Button
                sx={{ margin: "0px 20px" }}
                onClick={() => {
                  setSelectedFilters([]);
                  clearFilterRedirect();
                }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
              >
                ფილტრის მოხსნა
              </Button>
            )}
          </Box>
        </Grid>
        <Box sx={{ minHeight: "42px", padding: "0px 20px" }}>
          {selectedFilters?.length > 0 && (
            <FilterHeader removeAll={() => setSelectedFilters([])} items={[...selectedFilters]} handleDelete={handleFilterDelete} />
          )}
        </Box>

        <Grid item xs={12} lg={12} sx={{ padding: "0px 20px", width: "100%" }}>
          <CartOrderTable
            tableName={"cartOrderTable"}
            handleSubmit={setSubmitted}
            tableList={cartOrderList}
            handleOrder={handleOrder}
            handleOnToggle={handleOnToggle}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            tableStructureList={cartOrdersStructureList}
          />
          {count && (
            <Pagination
              count={count}
              pageSize={urlValues.pageSize}
              setPageIndex={(item: any) => {
                handleUrlValues("pageIndex", item);
                urlChanger(history, pagePathnmae, "pageIndex", `${item}`);
              }}
              pageIndex={urlValues.pageIndex}
              palette={palette}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state?.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { register, setSnack })(CartOrderPage);
