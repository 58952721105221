export const processingStatesToGeo: any = {
    in_progress: "პროგრესში",
    approved: "დამტკიცებული",
    canceled: "გაუქმებული",
    delivered: "მიწოდებული",
    returned: "დაბრუნებული",
}

export const shippingToGeo: any = {
    Delivery: "მიწოდება ^",
    delivery: "მიწოდება",
    pickup: "გატანა",
}

export const countryToGeo: any = {
    ukraine: "უკრაინა",
    georgia: "საქართველო",
}

export const statusToGeo: any = {
    bog_canceled: "ბოგის გაუმქებული",
    paid: "გადახდილი",
    paid_out_of_stock: "paid_out_of_stock",
    pending: "განხილვაზე",
    refused: "უარყოფილი",
    space_canceled: "სფეისის გაუქმებული",
    to_be_confirmed: "დასადასტურებელი",
}

export const paymentToGeo: any = {
    balance: "ბალანსი",
    bog_installment: "BOG განვადება",
    tbc_installment: "TBC განვადება",
    card: "ბარათი",
    installment: "განვადება",
    space: "სფეისი",
    space_installment: "სფეის განვადება",
}