import { Department } from "../department/department";
import { Resource } from "../resource";
export class UserComment extends Resource {
  counted: boolean | null | undefined;
  created: string | null | undefined;
  isEnabled: boolean | null | undefined;
  notified: boolean | null | undefined;
  productId: string | null | undefined;
  commentId: string | null | undefined;
  productEnabled: boolean | string | null | undefined;
  productImage: string | null | undefined;
  productName: string | null | undefined;
  productInStock: number | null | undefined;
  productOwner: string | null | undefined;
  productOwnerManager: string | null | undefined;
  comment: string | null | undefined;
  userDepartment: Department | null | undefined;
  userId: string | null | undefined;
  userSuccessManager: string | null | undefined;
}

export class UserCommentOverride extends Resource {
  productComment: string | null | undefined;
  paramName: string | null | undefined;
  paramNewValue: string | null | undefined;
}
