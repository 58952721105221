import { ComponentSerializer } from '../component/serializer'
import { ProjectSerializer } from '../project/serializer'
import { Resource } from '../resource'
import { Serializer } from '../serializer'
import { UserSerializer } from '../user/serializer'
import { VersionSerializer } from '../Version/serializet'
import { Testers, TestingPageListItem, VersionLoc } from './TestingPageListItem'
import { TestListItem } from './TestListItem'
import { TestObject, TestStatus } from './TestObject'

export class TestListItemSerializer implements Serializer {
    fromJson(json: any) {
        const item = new TestListItem()
        const userSerializer = new UserSerializer()
        const componentSerializer = new ComponentSerializer()
        const projectSerializer = new ProjectSerializer()
        item.id = json.id
        item.component = componentSerializer.fromJson(json.component)
        item.project = projectSerializer.fromJson(json.project)
        item.user = userSerializer.fromJson(json.user)
        item.description = json.description
        item.name = json.name
        return item
    }

    toJson(item: any) {
        const obj: any = {
        }
        return obj
    }
}

export class TestObjectSerializer implements Serializer {
    fromJson(json: any) {
        const item = new TestObject()
        const userSerializer = new UserSerializer()
        const testListItemSerializer = new TestListItemSerializer()
        const statusSerializer = new TestObjectStatusSerializer()
        const versionSerializer = new VersionSerializer()
        item.id = json.id
        item.status = statusSerializer.fromJson(json.status)
        item.testListItem = testListItemSerializer.fromJson(json.testing_item)
        item.tester = userSerializer.fromJson(json.tester)
        item.created = json.created
        item.version = versionSerializer.fromJson(json.version)
        item.updated = json.updated
        return item
    }

    toJson(item: TestObject) {
        const obj: any = {
            status: item.status.id,
            testing_item: item.testListItem.id,
            tester: item.tester.id,
            version: item.version.id

        }
        if (item.id) {
            obj.id = item.id
        }
        return obj
    }
}




export class TestObjectStatusSerializer implements Serializer {
    fromJson(json: any) {
        const item = new TestStatus()
        item.id = json.id
        item.name = json.name
        return item
    }

    toJson(item: any) {
        const obj: any = {
        }
        return obj
    }
}

export class TestingPageListItemSerializer implements Serializer {
    fromJson(json: any) {
        const item = new TestingPageListItem()

        item.status = json.status
        item.testers = json.testers
        item.version = json.version
        item.count = json.testing_item_count
        return item
    }

    toJson(item: any) {
        const obj: any = {
        }
        return obj
    }
}




