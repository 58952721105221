import { Box, ListItem, ListItemButton, Menu, PaletteMode, Rating, Stack } from "@mui/material";
import { MouseEvent, useState } from "react";
import { connect } from "react-redux";
import { Reason } from "../models/Reason/Reason";
import { TypographyItem } from "./Typography/TypographyItem";

interface ReviewReasonsComponentProps {
  reasons?: Reason[];
  mode?: PaletteMode;
}

const ReviewReasonsComponent = (props: ReviewReasonsComponentProps) => {
  const { reasons } = props;

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuOpen = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <Box>
      <ListItemButton disabled={!reasons?.length} onClick={handleMenuOpen}>
        <Box padding={"0px 0px"} sx={{ display: "flex", width: "100%", gap: "15px", justifyContent: "space-between" }}>
          <TypographyItem sx={{ whiteSpace: "noWrap" }} text={`Reasons Count:`} fontSize={12} />
          <TypographyItem sx={{ whiteSpace: "noWrap" }} text={reasons?.length} fontSize={12} />
        </Box>
      </ListItemButton>
      <Menu
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
        transformOrigin={{ vertical: "top", horizontal: "center" }}
        anchorEl={anchorEl}
        onClose={handleMenuClose}
        open={open}
        PaperProps={{
          sx: {
            minWidth: "310px",
            overflow: "auto",
            maxHeight: `calc(100vh / 1.5)`,
          },
        }}
      >
        {reasons?.map((item: Reason) => (
          <ListItem sx={{ display: "flex", flexDirection: "column" }} divider>
            <Box padding={"5px 0px"} sx={{ display: "flex", width: "100%", gap: "20px", justifyContent: "space-between" }}>
              <TypographyItem text={"Reason Id:"} fontWeight={600} sx={{ whiteSpace: "noWrap" }} />
              <TypographyItem text={item.reasonId} sx={{ whiteSpace: "noWrap" }} />
            </Box>
            <Box padding={"5px 0px"} sx={{ display: "flex", width: "100%", gap: "20px", justifyContent: "space-between" }}>
              <TypographyItem text={"Reason Name:"} fontWeight={600} sx={{ whiteSpace: "noWrap" }} />
              <TypographyItem text={item.reasonName} sx={{ whiteSpace: "noWrap" }} />
            </Box>
            <Box padding={"5px 0px"} sx={{ display: "flex", width: "100%", gap: "20px", justifyContent: "space-between", alignItems: "center" }}>
              <TypographyItem text={"Rates:"} fontWeight={600} sx={{ whiteSpace: "noWrap" }} />
              <Stack direction={"column"}>
                {item.reasonRates?.map((num) => (
                  <Rating value={num} readOnly />
                ))}
              </Stack>
            </Box>
          </ListItem>
        ))}
      </Menu>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    mode: state?.app.currentTheme,
  };
};

export default connect(mapStateToProps)(ReviewReasonsComponent);
