import { Box, Grid, Theme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { QueryOptions } from "../../helpers/query.options";
import { TestingAccordion } from "../../models/component/Accordions/TestingAccordion";
import { Component } from "../../models/component/component";
import { testListitemService, testObjectservice } from "../../models/testing/service";
import { TestListItem } from "../../models/testing/TestListItem";
import { register } from "../../models/user/authActions";
import { Version } from "../../models/Version/Version";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(1),
      textAlign: "center",
      color: theme.palette.text.secondary,
    },
    leftcolumn: {
      padding: 10,
    },
  })
);

const TestingEdit = (props: any) => {
  const { palette, muiTheme, app, auth } = props;
  const classes = useStyles();
  const [init, setInit] = useState(false);
  const [testListItems, setTestListItems] = useState<TestListItem[]>([]);
  const [components, setComponents] = useState<{ id: string; items: TestListItem[]; component: Component }[]>([]);
  const [version, setVersion] = useState<Version | any>();
  const [options, setOptions] = useState<QueryOptions>();
  const [name, setName] = useState("");
  const [evt, setEvt] = useState("");
  const location: any = useLocation();

  const loadTestList = (Options: QueryOptions) => {
    const opts = new QueryOptions();
    opts.pageSize = 200;
    testListitemService.listWithCounter(opts).then((r: any) => {
      if (r) {
        testObjectservice.listWithCounter(Options).then((resp: any) => {
          const foundArr: any[] = [];
          resp?.current?.forEach((item: any) => {
            const [foundListItem] = r.current.filter((i: TestListItem) => i.id === item.testListItem.id);
            if (foundListItem) {
              foundListItem.testObject = item;
              foundArr.push(foundListItem);
            }
          });
          setInit(false);
          setTestListItems([...foundArr, ...r.current.filter((i: any) => foundArr.map((e) => e.id).indexOf(i.id) < 0)]);
        });
      }
    });
  };

  useEffect(() => {
    if (options?.tester) loadTestList(options);
  }, [options]);

  useEffect(() => {
    const query = new URLSearchParams(location.search || location.pathname);
    const options = new QueryOptions();
    options.pageSize = 200;
    options.version = query.get("version")!;
    options.tester = query.get("tester")!;
    setName(query.get("name")!);
    setEvt(query.get("evt")!);
    !!!options.tester ? (window.location.href = "/") : (options.assignee = "");
    setOptions(options);
  }, []);

  useEffect(() => {
    if (testListItems.length) {
      const ids = testListItems.map((a: TestListItem) => a.component.id).filter((v: Component, i: number, a: any) => a.indexOf(v) === i);
      const arr: any = [];
      for (var i = 0; i < ids.length; i++) {
        const items = testListItems.filter((item) => item.component.id === ids[i]);
        if (items.length) {
          arr.push({ id: ids[i], component: items[0].component, items });
        }
      }
      if (!init) {
        setComponents(arr.sort((a: any, b: any) => a.component.name?.localeCompare(b.component.name)));
        setInit(true);
      }
    }
  }, [testListItems, version]);

  useEffect(() => {
    if (app.version) {
      setVersion(app.version[0]);
    }
  }, [app.version]);

  return (
    <Box
      style={{
        background: palette.background.default,
        height: "100vh",
        marginBottom: "120px",
        width: "100%",
        overflowY: "auto",
      }}
      className={classes.root}
    >
      <Box>
        <Grid item xs={12} lg={6} style={{ padding: 20, height: 2200 }}>
          <Box>
            <h1 style={{ color: palette.text.primary }}>{name}</h1>
            {
              <Box style={{ paddingBottom: 70 }}>
                <TestingAccordion
                  evt={evt}
                  onReload={loadTestList}
                  user={auth.user}
                  items={components}
                  setComponents={setComponents}
                  statuses={app?.testStatuses}
                  muiTheme={muiTheme}
                  options={options}
                  id={options?.version}
                />
              </Box>
            }
          </Box>
        </Grid>
      </Box>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
    app: state.app,
  };
};

export default connect(mapStateToProps, { register })(TestingEdit);
