import { ResourceProvider } from "../../providers/resource.provider";
import { Project } from "./project";
import { ProjectSerializer } from "./serializer";



export class ProjectProvider extends ResourceProvider<Project> {
    constructor() {
        super("rest_project", new ProjectSerializer())
    }
}


export const projectService = new ProjectProvider()
