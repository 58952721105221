import { Edit } from "@mui/icons-material";
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard";
import CreditCardOutlinedIcon from "@mui/icons-material/CreditCardOutlined";
import DoneOutlineRoundedIcon from "@mui/icons-material/DoneOutlineRounded";
import EventNoteIcon from "@mui/icons-material/EventNote";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import MailOutlineRoundedIcon from "@mui/icons-material/MailOutlineRounded";
import PhoneOutlinedIcon from "@mui/icons-material/PhoneOutlined";
import SupportAgentRoundedIcon from "@mui/icons-material/SupportAgentRounded";
import { Box, Grid, InputAdornment, TextField, Tooltip } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DesktopDatePicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { Address } from "../../models/Address/Address";
import { setSnack } from "../../models/application/applicationActions";
import { userProfileEditService } from "../../models/user/service";
import { UserProfileInfoCardEdit } from "../../models/user/user";
import AddressFormDialog from "../Dialogs/AddressFormDialog";
import { ConfirmationDialog } from "../Dialogs/ConfirmationDialog";
import { TypographyItem } from "../Typography/TypographyItem";

const InfoCardItem = (props: any) => {
  const { icon, text, type = null, address = null, userId, userProfileUpdateHandler, onAlert } = props;

  const [hover, setHover] = useState(false);
  const [open, setOpen] = useState(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [editableValue, setEditableValue] = useState<string | null>(null);
  const [confirmation, setConfirmation] = useState<any>({
    text: "",
    open: false,
    title: "",
    task: null,
  });

  const setDatePickerOpen = () => {
    setAnchorEl(document.getElementById("anchorTarget"));
  };

  const closeDatePicker = () => {
    setAnchorEl(null);
  };

  const datePickerOpen: boolean = Boolean(anchorEl);

  const editModeHandler = (e: any) => {
    setEditableValue(e.target.value);
  };

  const onKeyDownHandler = (e: any) => {
    if (e.keyCode === 13) {
      if (userId) {
        onUpdateButtonPress();
      }
    }
  };

  const onUpdateButtonPress = () => {
    if (userId) {
      setConfirmation({
        text: "ნამდვილად გსურთ შეცვლა?",
        open: true,
        title: "ველის შეცვლა",
        task: null,
      });
    }
  };

  const animatedComponent = (isVisible: boolean, component: JSX.Element) => (
    <Animated
      style={{
        width: "100%",
        justifyContent: "space-evenly",
        alignItems: "center",
        display: isVisible ? "flex" : "none",
      }}
      animationIn={"zoomIn"}
      animationOut={"zoomOut"}
      animationInDuration={200}
      isVisible={isVisible}
    >
      {component}
    </Animated>
  );

  useEffect(() => {
    setHover(false);
    setOpen(false);
  }, [userId]);

  useEffect(() => {
    setEditableValue(text === "Not Registered" ? null : text);
  }, [text]);

  const handleFromDateChange = (date: Date | null) => {
    setEditableValue(date !== null ? date.toDateString() : null);
    if (date) {
      const usr = new UserProfileInfoCardEdit();
      usr.app_user = userId;
      usr.param_name = type;
      usr.param_new_value = editableValue! && date.toDateString()!;
      userProfileEditService.create(usr).then((r: any) => {
        if (r) {
          userProfileUpdateHandler();
        }
      });
    }
  };

  return (
    <Box>
      {(type !== "address" ? !open : true) ? (
        type !== "birth_date" ? (
          <TypographyItem
            text={
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  textAlign: "left",
                  position: "relative",
                  justifyContent: "space-between",
                }}
                onMouseEnter={() => setHover(true)}
                onMouseLeave={() => setHover(false)}
              >
                {icon}
                <Box sx={{ paddingLeft: "40px", wordBreak: "break-word" }}>{text}</Box>
                {hover && type !== "id" && (
                  <Edit
                    sx={{
                      position: "absolute",
                      right: 0,
                      boxShadow: "0.5px 2px 10px 15px text.cardInfo",
                      borderRadius: "15px",
                      cursor: "pointer",
                    }}
                    onClick={() => setOpen(true)}
                  />
                )}
              </Box>
            }
            fontSize={19}
            fontWeight="500"
          />
        ) : (
          <LocalizationProvider dateAdapter={AdapterDateFns}>
            <DesktopDatePicker
              onClose={closeDatePicker}
              PopperProps={{
                anchorEl: anchorEl,
              }}
              open={datePickerOpen}
              label={"From..."}
              inputFormat="dd/MM/yyyy"
              renderInput={() => (
                <TypographyItem
                  id="anchorTarget"
                  text={
                    <Box
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        textAlign: "left",
                        position: "relative",
                        justifyContent: "space-between",
                      }}
                      onMouseEnter={() => setHover(true)}
                      onMouseLeave={() => setHover(false)}
                    >
                      {icon}
                      <Box sx={{ paddingLeft: "40px", wordBreak: "break-word" }}>{text}</Box>
                      {hover && type !== "id" && (
                        <Edit
                          sx={{
                            position: "absolute",
                            right: 0,
                            boxShadow: "0.5px 2px 10px 15px text.cardInfo",
                            borderRadius: "15px",
                            cursor: "pointer",
                          }}
                          onClick={setDatePickerOpen}
                        />
                      )}
                    </Box>
                  }
                  fontSize={19}
                  fontWeight="500"
                />
              )}
              onChange={handleFromDateChange}
              value={editableValue ? new Date(editableValue) : null}
            />
          </LocalizationProvider>
        )
      ) : (
        type !== "id" &&
        type !== "birth_date" && (
          <TextField
            sx={{
              margin: "5px",
              color: "text.primary",
              "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                borderColor: `text.cardInfo`,
              },
              "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                borderColor: `text.cardInfo`,
                borderWidth: "2px",
              },
            }}
            multiline
            onKeyDown={onKeyDownHandler}
            value={editableValue}
            onChange={editModeHandler}
            InputProps={{
              sx: { ...{ color: "text.cardInfo" } },
              endAdornment: (
                <InputAdornment position="end" sx={{ height: "50px", width: "100px", display: "flex" }}>
                  {animatedComponent(
                    true,
                    <Tooltip title="Close Edit">
                      <HighlightOffIcon sx={{ cursor: "pointer" }} onClick={() => setOpen(false)} />
                    </Tooltip>
                  )}
                  {animatedComponent(
                    editableValue!?.length > 0,
                    <Tooltip title="Update Value">
                      <DoneOutlineRoundedIcon onClick={onUpdateButtonPress} sx={{ cursor: "pointer" }} />
                    </Tooltip>
                  )}
                </InputAdornment>
              ),
            }}
          />
        )
      )}
      {type === "address" && (
        <AddressFormDialog
          open={open}
          handleDialog={setOpen}
          address={address}
          userId={userId}
          onAlert={onAlert}
          setSubmitted={(state: any) => userProfileUpdateHandler()}
          title={"Address Form"}
        />
      )}
      <Box display={"none"}>
        <ConfirmationDialog
          open={confirmation.open}
          text={confirmation.text}
          title={confirmation.title}
          callback={(c: any) => {
            if (c) {
              setOpen(false);
              const usr = new UserProfileInfoCardEdit();
              usr.app_user = userId;
              usr.param_name = type;
              usr.param_new_value = editableValue! && editableValue!;
              userProfileEditService.create(usr).then((r: any) => {
                if (r) {
                  userProfileUpdateHandler();
                }
              });
            }
            setConfirmation({
              text: "",
              open: false,
              title: "",
              issue: null,
            });
          }}
        />
      </Box>
    </Box>
  );
};

const UserProfileInfoCard = (props: any) => {
  const { userProfile, userProfileUpdateHandler, setSnack, app, mode } = props;

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const infoCardList = [
    {
      icon: <MailOutlineRoundedIcon sx={{ fontSize: 25, fontWeight: "inherit", position: "absolute", top: 4.5, left: 0 }} />,
      text: userProfile?.email,
      enabled: false,
      type: "id",
      defaultText: "Not Provided",
    },
    {
      icon: <SupportAgentRoundedIcon sx={{ fontSize: 25, fontWeight: "inherit", position: "absolute", top: 4.5, left: 0 }} />,
      text: userProfile?.successManagerGroup?.manager?.successManagerId,
      enabled: false,
      type: "success_manager_group",
      defaultText: "Not Provided",
    },
    {
      icon: <PhoneOutlinedIcon sx={{ fontSize: 25, fontWeight: "inherit", position: "absolute", top: 4.5, left: 0 }} />,
      text: userProfile?.phone,
      enabled: false,
      type: "phone",
      defaultText: "Not Provided",
    },
    {
      icon: <CardGiftcardIcon sx={{ fontSize: 25, fontWeight: "inherit", position: "absolute", top: 4.5, left: 0 }} />,
      text: userProfile?.promotionBalance,
      enabled: false,
      type: "promotion_balance",
      defaultText: "Not Provided",
    },
    {
      icon: <CreditCardOutlinedIcon sx={{ fontSize: 25, fontWeight: "inherit", position: "absolute", top: 4.5, left: 0 }} />,
      text: userProfile?.bankAccountNumber,
      enabled: false,
      type: "bank_account_number",
      defaultText: "Not Provided",
    },
    {
      icon: <EventNoteIcon sx={{ fontSize: 25, fontWeight: "inherit", position: "absolute", top: 4.5, left: 0 }} />,
      text: new Date(userProfile?.birthDate).toDateString(),
      enabled: false,
      type: "birth_date",
      defaultText: "Not Provided",
    },
    {
      icon: <LocationOnOutlinedIcon sx={{ fontSize: 25, fontWeight: "inherit", position: "absolute", top: 4.5, left: 0 }} />,
      text: userProfile?.addresses,
      enabled: false,
      type: "address",
      defaultText: "Not Provided",
    },
  ];
  return (
    <Grid
      sx={{ backgroundColor: mode === "dark" ? grey[600] : grey[200], gap: "10px" }}
      container
      display="flex"
      flexDirection="column"
      justifyContent="space-evenly"
      alignItems="left"
      padding="20px 30px 20px 30px"
    >
      {infoCardList.map((item, index) =>
        item.type !== "address" ? (
          <InfoCardItem
            key={index}
            icon={item.icon}
            text={item.text ? item.text : item.defaultText}
            userId={userProfile?.email}
            type={item.type}
            enabled={item.enabled}
            userProfileUpdateHandler={userProfileUpdateHandler}
          />
        ) : item.text?.length > 0 ? (
          item.text?.map((address: Address, index: number) => (
            <InfoCardItem
              key={index}
              icon={item.icon}
              text={
                address?.street?.length || address?.city?.name?.length || address?.country?.name?.length
                  ? `${address?.street}, ${address?.city?.name},  ${address?.country?.name}`
                  : item.defaultText
              }
              userId={userProfile?.email}
              type={item.type}
              enabled={item.enabled}
              userProfileUpdateHandler={userProfileUpdateHandler}
              address={address}
              onAlert={onAlert}
            />
          ))
        ) : (
          <InfoCardItem
            key={index}
            icon={item.icon}
            text={item.defaultText}
            userId={userProfile?.email}
            type={item.type}
            enabled={item.enabled}
            userProfileUpdateHandler={userProfileUpdateHandler}
            address={null}
            onAlert={onAlert}
          />
        )
      )}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps, { setSnack })(UserProfileInfoCard);
