import { ResourceProvider } from "../../providers/resource.provider";
import { Category } from "./category";
import { CategorySerializer } from "./serializer";




export class CategoryProvider extends ResourceProvider<Category> {
    constructor() {
        super("rest_category", new CategorySerializer())
    }
}


export const categoryService = new CategoryProvider()
