import { AccountCircle } from "@mui/icons-material";
import { Box, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { environment } from "../../environment/environment";
import { FormattedTime } from "../../helpers/utilities";
import { TypographyItem } from "../Typography/TypographyItem";

export const UserListItem = (props: any) => {
  const { list, item, user, index, onSelect, chat } = props;
  const [image, setImage] = useState<string | any>(null);
  const [date, setDate] = useState(item?.messages[item?.messages.length - 1 || 0]?.created);

  useEffect(() => {
    if (item) {
      setImage(environment.usertImages + item?.user?.image + `.${"jpg" || "png"}`);
    }
  }, [item?.user?.image]);

  const scrollToBottom = () => {
    setTimeout(
      () =>
        document.getElementById("scrollView")?.scrollTo({
          top: document.getElementById("scrollView")!.scrollHeight + 200,
          behavior: "smooth",
        }),
      100
    );
  };

  useEffect(() => {
    setDate(item.messages[item.messages.length - 1 || 0]?.created);
  }, [item]);

  const checkUser = () => item?.messages?.length && item?.messages[item?.messages?.length - 1 || 0]?.portal_user?.full_name === user?.fullName;

  const checkAdminOrNot = () => item?.messages[item?.messages?.length - 1 || 0]?.owner;

  return (
    <Box
      id="users"
      key={index}
      onClick={() => {
        onSelect(item);
        scrollToBottom();
      }}
      sx={{
        display: "flex",
        padding: "10px",
        cursor: "pointer",
        alignItems: "center",
        backgroundColor: chat?.id === item?.id ? "#cacaca" : "background.default",
        position: "relative",
      }}
    >
      <Box
        sx={{
          width: "50px",
          height: "50px",
          borderRadius: "25px",
          backgroundColor: "grey",
          contain: "content",
        }}
      >
        {image ? (
          <Box
            component={"img"}
            sx={{ width: "100%", height: "100%", objectFit: "cover" }}
            onError={() => {
              setImage(null);
            }}
            src={image}
            alt=""
          />
        ) : (
          <AccountCircle
            sx={{
              "&.MuiSvgIcon-root": {
                fontSize: "50px",
                color: "pink",
              },
            }}
          />
        )}
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "space-between",
          textAlign: "left",
          paddingLeft: 10,
        }}
      >
        <TypographyItem text={`${item?.user?.name}`} fontSize={15} fontWeight="600" />
        <TypographyItem
          sx={{
            margin: "5px 0 5px 0",
            fontSize: "0.9em",
            fontStyle: "italic",
            color: "text.primary",
          }}
          text={`${item?.user?.id}`}
          fontSize={15}
          fontWeight="600"
        />

        <Box sx={{ margin: 0, display: "flex", fontSize: "0.8em", alignItems: "center" }}>
          {checkUser() && <TypographyItem sx={{ marginRight: "5px", color: "text.cardInfo" }} text={"You:"} fontSize={13} fontWeight="300" />}
          <Tooltip
            title={
              <Typography variant="h6" sx={{ lineHeight: "25px" }}>
                {item.messages[item.messages.length - 1 || 0]?.text}
              </Typography>
            }
          >
            <Box
              sx={{
                whiteSpace: "normal",
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                WebkitLineClamp: 1,
                overflow: "hidden",
                textOverflow: "ellipsis",
                textAlign: "left",
                width: "150px",
                color: checkAdminOrNot() === "user" ? "red" : "green",
              }}
            >
              {item.messages[item.messages.length - 1 || 0]?.text}
            </Box>
          </Tooltip>

          <Box
            sx={{
              justifyContent: "center",
              alignItems: "center",
              width: "fit-content",
              position: "absolute",
              right: 5,
              bottom: 10,
              color: "text.primary",
            }}
          >
            {FormattedTime(date)}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};
