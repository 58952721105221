import { Box, Button, ButtonBase, Grid, Tabs } from "@mui/material";
import { StyledBadge, ToolbarTab, ToolbarTabChat } from "../Header/Toolbar";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import { environment } from "../../environment/environment";
import { grey } from "@mui/material/colors";

export const MobileMenu = (props: any) => {
  const { tabList, mode, value, handleChange, returnBadgeValue } = props;
  return (
    <Grid container sx={{ backgroundColor: mode === "dark" ? grey[800] : grey[600] }} height={"100%"}>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="lab API tab example"
        orientation="vertical"
        sx={{
          ".MuiTabs-indicator": {
            backgroundColor: "text.primary",
          },
        }}
      >
        {tabList.map((item: any, index: any) =>
          item.id === "chat" ? (
            <ToolbarTabChat
              disableRipple
              key={index}
              label={
                <Box sx={{ width: "100px" }}>
                  <StyledBadge
                    anchorOrigin={{
                      horizontal: "right",
                      vertical: "bottom",
                    }}
                    badgeContent={returnBadgeValue}
                    color="error"
                    overlap="circular"
                  >
                    {item.name}
                  </StyledBadge>
                </Box>
              }
              value={item.id}
              component={Link}
              to={{
                pathname: `/${item.id}`,
                search: "?country=georgia&pageIndex=1",
              }}
            />
          ) : (
            <ToolbarTab
              disableRipple
              key={index}
              sx={{
                display: !item.validation ? "none" : "inline-flex",
              }}
              label={<Box sx={{ width: "100px", textAlign: "center", color: "" }}>{item.name}</Box>}
              value={item.id}
              component={Link}
              to={{ pathname: `/${item.id}`, search: environment.baseQueryParams.replace("sort=created", `sort=${item.sort}`) }}
            />
          )
        )}
      </Tabs>
    </Grid>
  );
};
