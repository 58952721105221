import { countryToGeo, paymentToGeo, shippingToGeo } from "../../helpers/localTranslationDict";
import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { IdName, IssueFilter, OrderFilter, ReviewFilter, TaskFilter, UserFilter, VersionFilter } from "./Filter";

export class TaskFilterSerializer implements Serializer {
  fromJson(json: any) {
    const item = new TaskFilter();
    const nameSerializer = new IDNAMESerializer();
    if (json.versions) {
      item.assignee = json.assignees.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.categories) {
      item.category = json.categories.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.components) {
      item.component = json.components.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.issues) {
      item.issue = json.issues.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.priorities) {
      item.priority = json.priorities.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.projects) {
      item.project = json.projects.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.reviewers) {
      item.reviewer = json.reviewers.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.statuses) {
      item.status = json.statuses.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.testers) {
      item.tester = json.testers.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.types) {
      item.type = json.types.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.users) {
      item.user = json.users.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.versions) {
      item.version = json.versions.map((item: any) => nameSerializer.fromJson(item));
    }

    return item;
  }

  toJson(item: TaskFilter) {
    const obj: any = {};
    return obj;
  }
}

export class IssueFilterSerializer implements Serializer {
  fromJson(json: any) {
    const item = new IssueFilter();
    const nameSerializer = new IDNAMESerializer();

    if (json.categories) {
      item.category = json.categories.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.components) {
      item.component = json.components.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.projects) {
      item.project = json.projects.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.statuses) {
      item.status = json.statuses.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.types) {
      item.type = json.types.map((item: any) => nameSerializer.fromJson(item));
    }
    if (json.users) {
      item.user = json.users.map((item: any) => nameSerializer.fromJson(item));
    }
    return item;
  }

  toJson(item: IssueFilter) {
    const obj: any = {};
    return obj;
  }
}

export class VersionFilterSerializer implements Serializer {
  fromJson(json: any) {
    const item = new VersionFilter();
    const nameSerializer = new IDNAMESerializer();
    if (json.names) item.name = json.names.map((item: any) => nameSerializer.fromJson(item));
    if (json.uids) item.uid = json.uids.map((item: any) => nameSerializer.fromJson(item));
    if (json.users) item.user = json.users.map((item: any) => nameSerializer.fromJson(item));
    return item;
  }
  toJson(resource: Resource) {
    return resource;
  }
}

export class UsersFilterSerializer implements Serializer {
  fromJson(json: any) {
    const userFilterItem = new UserFilter();
    const nameListSerializer = new IDNAMESerializer();

    if (json.success_managers) userFilterItem.successManagerId = json.success_managers.map((item: any) => Object.assign({}, { id: item, name: item }));
    if (json.app_versions) userFilterItem.appVersion = json.app_versions.map((item: any) => Object.assign({}, { id: item, name: item }));
    return userFilterItem;
  }
  toJson(resource: Resource) {
    return resource;
  }
}

export const usersFilterSerializer = new UsersFilterSerializer();

export class OrderFilterSerializer implements Serializer {
  fromJson(json: any) {
    const orderFilterItem = new OrderFilter();
    const nameListSerializer = new IDNAMESerializer();

    if (json.statuses) orderFilterItem.status = json.statuses.map((item: any) => Object.assign({}, { id: item, name: item }));
    if (json.processing_states) orderFilterItem.processingState = json.processing_states.map((item: any) => Object.assign({}, { id: item, name: item }));
    if (json.countries) orderFilterItem.country = json.countries.map((item: any) => Object.assign({}, { id: item, name: countryToGeo[item] }));
    if (json.methods) orderFilterItem.method = json.methods.map((item: any) => Object.assign({}, { id: item, name: paymentToGeo[item] }));
    if (json.shipping_types) orderFilterItem.shippingType = json.shipping_types.map((item: any) => Object.assign({}, { id: item, name: shippingToGeo[item] }));
    if (json.coupons) orderFilterItem.coupon = json.coupons.map((item: any) => Object.assign({}, { id: item, name: item }));
    if (json.packagings) orderFilterItem.packaging = json.packagings.map((item: any) => nameListSerializer.fromJson(item));
    return orderFilterItem;
  }

  toJson(resource: Resource) {
    return resource;
  }
}

export const orderFilterSerializer = new OrderFilterSerializer();
export class ReviewFilterSerializer implements Serializer {
  fromJson(json: any) {
    const ReviewFilterItem = new ReviewFilter();
    const nameListSerializer = new IDNAMESerializer();
    if (json.rates) ReviewFilterItem.rate = json.rates.map((item: number) => Object.assign({}, { id: String(item), name: String(item) }));
    if (json.reasons) ReviewFilterItem.reasons = json.reasons.map((item: IdName) => nameListSerializer.fromJson(item));
    return ReviewFilterItem;
  }

  toJson(resource: Resource) {
    return resource;
  }
}

export const reviewFilterSerializer = new ReviewFilterSerializer();

export class IDNAMESerializer implements Serializer {
  fromJson(json: any) {
    const item = new IdName();
    item.id = json.id;
    item.name = json.name;
    return item;
  }

  toJson(item: IdName) {
    const obj: any = {};
    return obj;
  }
}
