import { ResourceProvider } from './../../providers/resource.provider';
import { NovaPoshta } from './NovaPoshta';
import { novaPoshtaSerialier } from './serialzer';

export class CountryProvider extends ResourceProvider<NovaPoshta> {
    constructor() {
        super(
            "rest_novaposhta",
            novaPoshtaSerialier
        )
    }
}

export const novaPoshtaService = new CountryProvider()