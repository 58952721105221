import { cartOrderSrializer } from './serializer';
import { CartOrder } from './CartOrder';
import { ResourceProvider } from './../../providers/resource.provider';

export class CartOrderProvider extends ResourceProvider<CartOrder> {
    constructor() {
        super(
            "rest_cart_order",
            cartOrderSrializer
        )
    }
}

export const cartOrderService = new CartOrderProvider()