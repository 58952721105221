import { NovaPoshta } from './../NovaPoshta/NovaPoshta';
import { NameId, Resource } from "../resource";

export class Address extends Resource {
	addressName: string | undefined | null //addressName
	firstName: string | undefined | null //firstName
	apartment: string | undefined | null //apartment
	buildingId: string | undefined | null //buildingId
	city: NameId | null | undefined //cityå
	comment: string | undefined | null //comment
	country: NameId | undefined | null //country
	entrance: string | undefined | null //enterance
	floor: string | undefined | null //enterance
	googlePlaceId: string | undefined | null //googlePlaceId
	isMain: boolean | undefined | null //IsMain
	lastName: string | undefined | null //LastName
	location: Location | undefined | null
	novaPoshtaWarehouse: NovaPoshta | null | undefined //nova...
	personalId: string | undefined | null //personalId
	phone: string | undefined | null //phone
	postalCode: string | undefined | null //postalCode
	recipient: string | undefined | null //recipient
	street: string | undefined | null //street
	type: string | undefined | null //type
	uid: string | undefined | null
	appUser: string | null | undefined;
}



export class Location extends Resource {
	address: string | undefined | null
	lat: number | undefined | null
	lng: number | undefined | null
}

