import { createStyles, Grid, makeStyles, Theme } from "@material-ui/core";
import { useState } from "react";
import cookie from 'react-cookies';
import { connect } from 'react-redux';
import LoginForm from "../../Components/Forms/LoginForm";
import { environment } from "../../environment/environment";
import { setSnackBar } from '../../models/application/applicationActions';
import { authenticate, login, register } from "../../models/user/authActions";
import {
    login as loginUser
} from "../../models/user/service";
import { User } from "../../models/user/user";

interface AuthTypes {
    user: object
    loggedIn: boolean
    errorStatus: unknown
}

interface LoginPropTypes {
    auth: AuthTypes
    setSnackBar: Function,
    authenticate: Function,
    login: Function

}

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            flexGrow: 1,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",

        },
        paper: {
            padding: theme.spacing(1),
            textAlign: "center",
            color: theme.palette.text.secondary,
        },
        leftcolumn: {
            padding: 10
        }
    })
);

const LoginPage = (props: LoginPropTypes) => {

    /**
     * Props go here
     */
    const {
        setSnackBar,
        login
    } = props

    /**
     * States go here
     */
    const [tokenMode, setTokenMode] = useState(false)
    const [wrongCredentials, setWrongCredentials] = useState(false)

    /**
    * Functions go here
    */
    const getValues = (values: any) => {
        loginUser(values.personalId, values.password, values.token).subscribe((resp: any) => {
            if (resp.accessToken) {
                let user = new User()
                user = user.updateUser(resp)
                login(user)
                let now = new Date()
                const expTime = new Date(now.getTime() + environment.tokenTime)
                localStorage.setItem('login-event', 'login' + Math.random());
                cookie.save('user', user?.email as string, { expires: expTime })
            }
            if (resp.status === 401) {
                setWrongCredentials(true)
            }
            if (resp.status === 402) {
                setTokenMode(true)
                setSnackBar({ vertical: 'bottom', horizontal: 'center', text: resp.response.message, open: true })
                setTimeout(() => {
                    setSnackBar({ vertical: 'bottom', horizontal: 'center', text: resp.response.message, open: false })
                }, 3000)
            }
        })
    }

    const classes = useStyles();
    return <div className={classes.root}>
        <Grid container spacing={0} style={{ display: "flex", justifyContent: 'center', alignItems: 'center' }}>
            <Grid container item xs={12} lg={3} spacing={6} style={{ padding: 20 }}>
                {/* <LoginForm onOpen={() => { }} onClose={() => { }} setSnackBar={() => { }} dialogMode={() => { }} /> */}
                <LoginForm
                    tokenMode={tokenMode}
                    wrongCredentials={wrongCredentials}
                    setWrongCredentials={setWrongCredentials}
                    dialogMode={true}
                    getValues={getValues}
                />
            </Grid>
        </Grid>

    </div >
}


const mapStateToProps = (state: any) => {
    return {
        auth: state.auth
    }
}

export default connect(
    mapStateToProps,
    {
        authenticate,
        login,
        setSnackBar,
        register
    }
)(LoginPage)