import { Redirect, Switch } from "react-router-dom";

import { connect } from "react-redux";
import GuardedRoute from "./GuardedRoute";
import LoginPage from "./pages/Auth/LoginPage";
import ChatPage from "./pages/Chat/ChatPage";
import EmployeeProfilePage from "./pages/EmployeeProfile/EmployeeProfilePage";
import IssueDetailsPage from "./pages/Issues/IssueDetailsPage";
import IssuesPage from "./pages/Issues/IssuesPage";
import MainPage from "./pages/MainPage";
import OrdersPage from "./pages/Orders/OrdersPage";
import ProductsPage from "./pages/Products/ProductsPage";
import TaskDetailsPage from "./pages/tasks/TaskDetailsPage";
import TasksPage from "./pages/tasks/TasksPage";
import TestingEditPage from "./pages/Testing/TestingEditPage";
import TestingPage from "./pages/Testing/TestingPage";
import UsersDetailsPage from "./pages/Users/UsersDetailsPage";
import UsersPage from "./pages/Users/UsersPage";
import VersionDetailsPage from "./pages/Version/VersionDetailsPage";
import Versions from "./pages/Version/VersionsPage";

const redirect = () => {
  return <Redirect to="/" />;
};

const Routing = (props: any) => {
  const { loggedIn } = props;

  const localStorageCheckerForUser: boolean = (() => (JSON.parse(localStorage.getItem("currentUser")!)?.accessToken ? true : false))();

  if (localStorageCheckerForUser && !loggedIn) {
    return <h1>...Loading</h1>;
  } else
    return (
      <Switch>
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/" component={MainPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/login" component={LoginPage} replace />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/testing" component={TestingPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/test/:id" component={TestingEditPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/versions" component={Versions} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/versionDetails" component={VersionDetailsPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/issues" component={IssuesPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/issueDetails/:id" component={IssueDetailsPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/tasks" component={TasksPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/taskDetails/:id" component={TaskDetailsPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/chat" component={ChatPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/orders" component={OrdersPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/chat" component={ChatPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/users" component={UsersPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/products" component={ProductsPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/userDetails/:id" component={UsersDetailsPage} exact />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="/employeeProfile" component={EmployeeProfilePage} />
        <GuardedRoute auth={loggedIn} access={loggedIn} path="*" component={redirect} exact />
      </Switch>
    );
};

const mapStateToProps = (state: any) => {
  return {
    user: state?.auth?.user,
    loggedIn: state.auth.loggedIn,
  };
};

export default connect(mapStateToProps)(Routing);
