import { Resource } from "../resource";
import { User } from "../user/user";
import { Version } from "../Version/Version";
import { TestListItem } from "./TestListItem";

export class TestObject extends Resource {
    created:      string | any
    status:       TestStatus| any
    tester:       User | any
    testListItem: TestListItem | any
    updated:      string | any
    user:         User | any
    version:      Version | any
}

export class TestStatus extends Resource{
    name: string | any
}







