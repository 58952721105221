import {
    userRegistrationService,
    login as loginService
} from "./service"
import { User } from "./user"
import { Dispatch } from 'redux';

export const LOGOUT = "LOGOUT"
export const LOGIN = "LOGIN"
export const LOGIN_ERROR = "LOGIN_ERROR"
export const EXISTING_ACCOUNT_START = "EXISTING_ACCOUNT_START"
export const EXISTING_ACCOUNT_SUCCESS = "EXISTING_ACCOUNT_SUCCESS"
export const EXISTING_ACCOUNT_CLEAR = "EXISTING_ACCOUNT_CLEAR"
export const CHANGE_USER_IMAGE = "CHANGE_USER_IMAGE"
export const CHANGE_USERNAME = "CHANGE_USERNAME"

export const login = (user: User) => {
    return { type: LOGIN, content: { user, loggedIn: true } }
}

export const loginError = (resp: any) => {
    return { type: LOGIN_ERROR, content: { errorStatus: resp } }
}

export const changeUserImage = (imageURI: string) => {
    return { type: CHANGE_USER_IMAGE, content: { imageURI: imageURI } }
}

export const changeUsername = (username: string) => {
    return { type: CHANGE_USERNAME, content: { username: username } }
}


export const logout = () => {
    return { type: LOGOUT }
}
export const authenticate = (
    email: string,
    password: string, 
    accessToken: string
) => {
    return (dispatch: Dispatch) => {
        loginService(email, password, accessToken).subscribe((resp: any) => {
            if (resp.accessToken) {
                let user = new User()
                user = user.updateUser(resp)
                dispatch(login(user))
            }
            if (!resp.accessToken) {
                dispatch(loginError(resp))
            }
        })
    }
}

export const register = (user: User) => {
    return (dispatch: any) => {
        userRegistrationService.createWithFile(user).then((resp: any) => {
            if (resp?.email) {
                dispatch(authenticate(user.email as string, user.password as string, user.accessToken as string));
            } else {
                if (resp.message === 'username_already_exists') {

                }
                if (resp.message === 'user_already_exists') {

                }
            }
        });
    };
};

