import { AccountCircle } from "@mui/icons-material";
import { Box, Dialog, SxProps, Theme } from "@mui/material";
import { ReactEventHandler, useCallback, useEffect, useState } from "react";

import ArrowBackIosNewRoundedIcon from "@mui/icons-material/ArrowBackIosNewRounded";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import ClearRoundedIcon from "@mui/icons-material/ClearRounded";
import CircularProgress from "@mui/material/CircularProgress";

export type DistributiveOmit<T, K extends keyof any> = T extends any ? Omit<T, K> : never;

export type Overwrite<T, U> = DistributiveOmit<T, keyof U> & U;

type GenerateStringUnion<T> = Extract<
  {
    [Key in keyof T]: true extends T[Key] ? Key : never;
  }[keyof T],
  string
>;

export type OverridableStringUnion<T extends string | number, U = {}> = GenerateStringUnion<Overwrite<Record<T, true>, U>>;

interface ImageViewerMainProps {
  sx?: SxProps<Theme> | undefined;
  onError?: ReactEventHandler<HTMLImageElement> | undefined;
  /**
   * ### Main Image Display Width
   *
   * Gets automatically converted to `pixels`
   * @default 'auto'
   */
  displayWidth?: number;
  /**
   * ### Main Image Display Height
   *
   * Gets automatically converted to `pixels`
   * @default 70
   */
  displayHeight?: number;
  /**
   * ### Main Image Display String
   *
   * Gets set on main display
   */
  displayImageSrc?: string;
  /**
   * ### Images' String List
   *
   * Gets set in album for swipping left or right
   */
  additionalImages?: string[];
}

/**
 * ## Description
 *
 * It's a custom component that takes in main display image string and
 * list of the other images strings
 *
 * @[Requires MUI to work!](https://mui.com/)
 */
export const ImageViewerMain = (props: ImageViewerMainProps) => {
  const { displayWidth = "auto", displayHeight = 70, displayImageSrc, onError, sx, additionalImages } = props;

  const [image, setImage] = useState<string | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [imagesList, setImagesList] = useState<string[]>([]);
  const [imgIndex, setImgIndex] = useState(0);
  const [clicked, setClicked] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (displayImageSrc) {
      setImage(() => {
        if (displayImageSrc === null) return null;
        else return displayImageSrc;
      });
      setImagesList((prev) => {
        return [...prev, displayImageSrc];
      });
    }
  }, [displayImageSrc]);

  useEffect(() => {
    if (additionalImages) {
      setImagesList((prev) => {
        return [
          ...prev,
          ...additionalImages.map((item) => {
            return `https://phubber.ge/assets/imgs/products/${item}`;
          }),
        ];
      });
    }

    return () => {
      setImagesList([]);
    };
  }, [additionalImages]);

  const imageZoommer = useCallback(() => {
    return (
      <Dialog
        onKeyDown={(evt: any) => {
          if (evt.key === "Escape") {
            setOpen(false);
            setClicked(false);
            setImgIndex(0);
          }
        }}
        fullWidth={true}
        open={open}
      >
        <Box
          className="viewer"
          sx={{
            top: 0,
            bottom: 0,
            left: 0,
            rigth: 0,
            zIndex: 1400,
            position: "fixed",
            width: "100vw",
            height: "100vh",
            backgroundColor: "rgba(0, 0, 0, .6)",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              position: "relative",
              width: "100%",
              height: "100%",
              overflowY: "auto",
            }}
          >
            {imagesList?.length > 1 && (
              <ArrowBackIosNewRoundedIcon
                onClick={() => {
                  setLoading(true);
                  setImgIndex((prev) => {
                    if (prev <= imagesList.length - 1 && prev !== 0) return prev - 1;
                    else if (prev === 0) return imagesList.length - 1;
                    else return prev;
                  });
                }}
                sx={{
                  cursor: "pointer",
                  fontSize: "80px",
                  color: "white",
                  position: "fixed",
                  top: "50%",
                  left: 15,
                  marginTop: "-40px",
                }}
              />
            )}
            {imagesList?.length > 1 && (
              <ArrowForwardIosRoundedIcon
                onClick={() => {
                  setLoading(true);
                  setImgIndex((prev) => {
                    if (prev >= 0 && prev !== imagesList.length - 1) return prev + 1;
                    else if (prev === imagesList.length - 1) return 0;
                    else return prev;
                  });
                }}
                sx={{
                  cursor: "pointer",
                  fontSize: "80px",
                  color: "white",
                  position: "fixed",
                  top: "50%",
                  right: 15,
                  marginTop: "-40px",
                }}
              />
            )}
            <ClearRoundedIcon
              onClick={() => {
                setOpen(false);
                setClicked(false);
                setImgIndex(0);
              }}
              sx={{
                cursor: "pointer",
                fontSize: "65px",
                color: "white",
                position: "fixed",
                top: 30,
                right: 30,
              }}
            />
            <img
              id="sliderImage"
              onClick={() => {
                setClicked((prev) => !prev);
              }}
              style={{
                display: !loading ? "block" : "none",
                paddingTop: clicked ? window.innerHeight * 1.333 - window.innerHeight : "0px",
                cursor: clicked ? "zoom-out" : "zoom-in",
                height: window.innerHeight,
                transform: clicked ? "scale(1.5)" : "scale(1)",
                objectFit: "contain",
              }}
              src={imagesList[imgIndex]}
              onLoad={() => setLoading(false)}
            />
            <CircularProgress sx={{ display: loading ? "block" : "none" }} />
          </Box>
        </Box>
      </Dialog>
    );
  }, [open, clicked, imgIndex, imagesList, loading]);

  return (
    <Box
      id="ImageViewerMain"
      className="ImageViewer-main-container"
      sx={{
        ...sx,
        height: `${displayHeight}px`,
        width: "fit-content",
        cursor: "zoom-in",
      }}
    >
      {image ? (
        <img
          onClick={() => {
            setOpen(true);
          }}
          onError={() => setImage(null)}
          src={image}
          style={{
            height: `${displayHeight}px`,
            width: displayWidth === "auto" ? "70px" : `${displayWidth}px`,
            objectFit: "cover",
          }}
        />
      ) : (
        <AccountCircle
          className="ImageViewer-main-empty-icon"
          sx={{
            fontSize: `${displayHeight}px` || `${displayWidth}px`,
            color: "text.cardInfo" || "grey",
            "&.MuiSvgIcon-root": {
              fontSize: `${displayHeight}px` || `${displayWidth}px`,
            },
          }}
        />
      )}
      {imageZoommer()}
    </Box>
  );
};
