import { Box, Checkbox, FormControl, ListItemText, MenuItem, Select } from "@mui/material";
import { useCallback, useEffect, useState } from "react";

export const FilterSelectBox = (props: any) => {
  const { name, onChange, selected = true, onToggle, items } = props;
  const [open, setOpen] = useState(false);
  const [_, setLocalValueString] = useState({ key: "", value: "" });

  useEffect(() => {
    setLocalValueString(() => {
      if (selected?.length) {
        return { key: selected[0].key, value: selected.map((item: any) => item.id).toString() };
      } else {
        return { key: "", value: "" };
      }
    });
  }, [selected]);

  const RenderList = useCallback(() => {
    return items
      ?.filter((item: any) => item.id)
      ?.map((item: any, index: number) => {
        return (
          <MenuItem
            key={index}
            value={item.id}
            onClick={() => {
              setLocalValueString((prev) => {
                if (prev.value.indexOf(item.id) > -1) {
                  onChange({
                    key: item.key,
                    value: prev.value
                      .split(",")
                      .filter((itm) => itm !== item.id)
                      .join(","),
                  });
                  return {
                    key: item.key,
                    value: prev.value
                      .split(",")
                      .filter((itm) => itm !== item.id)
                      .join(","),
                  };
                } else {
                  if (prev.value.length) {
                    onChange({
                      key: item.key,
                      value: prev.value + `,${item.id}`,
                    });
                    return {
                      key: item.key,
                      value: prev.value + `,${item.id}`,
                    };
                  } else {
                    onChange({ ...prev, key: item.key, value: item.id });
                    return { ...prev, key: item.key, value: item.id };
                  }
                }
              });
            }}
          >
            <Checkbox color="success" checked={selected?.map((p: any) => p.id).indexOf(item.id) > -1} />
            <ListItemText primary={item.name} />
          </MenuItem>
        );
      });
    //eslint-disable-next-line
  }, [selected]);

  return items?.length ? (
    <FormControl
      sx={{
        width: "100%",
        "& .MuiInputLabel-root": {
          color: "text.primary",
        },
        "& .MuiInput-underline:before": {
          borderBottom: "1px solid white",
        },
        "& .MuiInput-root:after": {
          borderBottom: "2px solid white",
        },
        "& .MuiInput-root:hover:not(.Mui-disabled):before": {
          borderBottom: "2px solid white",
        },
        "& .MuiSvgIcon-root": {
          color: "white",
        },
      }}
    >
      <Select
        onBlur={() => {
          setOpen(false);
        }}
        onClick={() => {
          setOpen((prev) => !prev);
        }}
        open={open}
        sx={{ color: "white", fontWeight: "bold" }}
        multiple
        value={[name]}
        variant="standard"
        renderValue={(selected) => (selected as string[]).join(", ")}
        MenuProps={{
          sx: {
            "& .MuiPaper-root": {
              overflow: "overlay",
            },
          },
        }}
      >
        <MenuItem key={"remove"} value={""} onClick={() => (selected.length ? onToggle(false) : onToggle(true))}>
          <Checkbox color="success" checked={selected?.length ? true : false} />
          <ListItemText primary={"Select All"} />
        </MenuItem>
        <RenderList />
      </Select>
    </FormControl>
  ) : (
    <Box>{name}</Box>
  );
};
