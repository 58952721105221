import { makeStyles, Tooltip } from "@material-ui/core";
import { FileCopyOutlined } from "@material-ui/icons";
import { TableHead } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import { Box } from "@mui/system";
import { useState } from "react";
import { connect } from "react-redux";
import SimpleBar from "simplebar-react";
import { useDidMountEffect } from "../../helpers/customHooks";
import { QueryOptions } from "../../helpers/query.options";
import { setSnack } from "../../models/application/applicationActions";
import { orderFilterService } from "../../models/filter/service";
import { Order } from "../../models/Order/Order";
import { OrdersTableColumn } from "../OrdersTableColumn";
import TableHeader from "../TableHeader";

export const useStyles = makeStyles((theme) => ({
  header: {
    backgroundColor: "#3F51B5",
    color: "#fff",
    fontWeight: "bold",
    paddingLeft: "10px",
    paddingRight: "10px",
    fontSize: "12px",
  },

  body: {
    padding: "30px 10px 30px 10px",
    width: "250px",
    fontSize: "12px",
    textAlign: "center",
  },
}));

const OrdersTable = (props: any) => {
  const {
    setSnack,
    app,
    muiTheme,
    palette,
    tableName,
    handleSubmit,
    tableList,
    handleOrder,
    handleOnToggle,
    handleOnChange,
    selectedFilters,
    tableStructureList,
    options,
    filterSearch,
    setFilterSearch,
  } = props;
  const classes = useStyles();
  const [cachedList, setCachedList] = useState<{ key: string; list: any[] }>({ key: "", list: [] });
  const [processingStateList, setProcessingStateList] = useState([]);
  const [statusList, setStatusList] = useState([]);
  const localList = JSON.parse(localStorage.getItem(tableName) || "{}");
  const [filterItems, setFilterItems] = useState<any[]>([]);
  const [filteredList, setFilteredList] = useState<any[]>(Object.keys(localList).length ? localList : tableStructureList);

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  useDidMountEffect(() => {
    loadFilter(options);
    loadEditableListItems(new QueryOptions());
  }, [JSON.stringify(options)]);

  const loadEditableListItems = (options: QueryOptions) => {
    orderFilterService.listDict(options).then((resp: any) => {
      setProcessingStateList(resp?.processingState?.map((item: any) => item.name));
      setStatusList(resp?.status?.map((item: any) => item.name));
    });
  };

  const loadFilter = (options: QueryOptions) => {
    orderFilterService.listDict(options).then((resp: any) => {
      const array = Object.keys(resp)
        .map((k) => {
          if (resp[k]) {
            return {
              key: k,
              items: resp[k].map((item: any) => {
                return { ...item, key: k };
              }),
            };
          } else return undefined;
        })
        .filter((item) => item?.key);
      setFilterItems(array);
    });
  };

  const getOverallTrueFalseFromFilteredList = () => {
    return filteredList.filter((e) => e.enabled === true).length ? true : false;
  };

  const loadAlternateFilter = (item: any, setFilter: any) => {
    const alternativeFilterQuery = new QueryOptions();
    alternativeFilterQuery.search = item.searchValue;
    item.service?.listWithCounter(alternativeFilterQuery).then((r: any) => {
      setFilter((prev: any) => {
        return [
          ...prev.filter((i: any) => i.key !== item.id),
          {
            key: item.id,
            items: r.current.map((i: any) => {
              return { id: i.id, name: `${i.name}`, key: item.id };
            }),
          },
        ];
      });
    });
  };

  const loadServiceByKey = (item: any) => {
    const [fs] = filterSearch.filter((f: any) => f.id === item.key);
    if (fs) {
      loadAlternateFilter({ ...fs, searchValue: item.search.searchValue }, setFilterItems);
      setFilterSearch((prev: any) => {
        return [
          ...prev.filter((itm: any) => itm.id !== item.key),
          {
            id: item.key,
            searchValue: item.search.searchValue,
            service: prev.filter((itm: any) => itm.id === item.key)[0].service,
          },
        ];
      });
    }
  };

  return (
    <Box sx={{ position: "relative" }}>
      <SimpleBar>
        <Table sx={{ width: getOverallTrueFalseFromFilteredList() ? "100%" : "fit-content" }} stickyHeader={true}>
          <TableHead>
            <TableRow sx={{ height: "35px", backgroundColor: palette.background.smth }}>
              <TableHeader
                setOrder={handleOrder}
                headerList={filteredList}
                filterItems={filterItems}
                onToggle={handleOnToggle}
                setCachedList={setCachedList}
                handleOnChange={handleOnChange}
                selectedFilters={selectedFilters}
                cachedList={cachedList}
                Background={palette.background.paper}
                setFilterSearch={(item: any) => {
                  loadServiceByKey(item);
                }}
                filterSearch={filterSearch}
                handleFilteredList={(state: any) => setFilteredList(state)}
                tableName={tableName}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {tableList?.map((orderItem: Order, index: number) => (
              <TableRow key={index} sx={{ background: palette.background.smth }}>
                <TableCell sx={{ width: "10px" }} padding="none" size="small" align="center">
                  <Tooltip title={`ID : ${orderItem.id}`}>
                    <FileCopyOutlined
                      onClick={() => {
                        navigator.clipboard.writeText(orderItem?.id as string);
                        onAlert(`თასქის ID დაკოპირდა : ${orderItem?.id}::success`);
                      }}
                      style={{ cursor: "pointer", color: palette.text.primary }}
                    />
                  </Tooltip>
                </TableCell>
                <OrdersTableColumn
                  statusList={statusList}
                  processingStateList={processingStateList}
                  idx={index}
                  table={filteredList}
                  bodyClass={classes.body}
                  item={orderItem}
                  palette={palette}
                  onAlert={onAlert}
                  setSubmitted={handleSubmit}
                />
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </SimpleBar>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { setSnack })(OrdersTable);
