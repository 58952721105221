import { ExpandMore } from "@mui/icons-material";
import { Accordion, AccordionDetails, AccordionSummary, Box, Checkbox, Theme, Typography, useTheme } from "@mui/material";
import { createStyles, makeStyles } from "@mui/styles";
import React, { useEffect } from "react";
import "../../../App.css";
import { TestListItem } from "../../testing/TestListItem";
import { AccordionList } from "./AccordionList";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    heading: {
      textAlign: "left",
      paddingLeft: 15,
      fontWeight: "bold",
      fontSize: theme.typography.pxToRem(15),
      flexBasis: "33.33%",
      flexShrink: 0,
    },
    secondaryHeading: {
      fontSize: theme.typography.pxToRem(15),
      color: theme.palette.text.secondary,
    },
  })
);

const AccordionItem = (props: any) => {
  const { item, classes, statuses, user, onReload, options, id, evt } = props;
  const [expanded, setExpanded] = React.useState<string | false>(false);
  const [checked, setChecked] = React.useState<TestListItem[]>([]);

  useEffect(() => {
    if (item?.items?.length) {
      setChecked(item.items.filter((i: any) => i.testObject?.status.id === "62bc3452da4fdc1868e08917"));
    }
  }, [item, options]);

  const onUpdate = (item: TestListItem) => {
    onReload(options);
  };

  const handleChange = (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
    setExpanded(isExpanded ? panel : false);
  };

  const theme = useTheme();
  return (
    <Accordion expanded={expanded === item.id} onChange={handleChange(item.id)}>
      <AccordionSummary
        sx={{
          "& .MuiAccordionSummary-content": {
            [theme.breakpoints.down("sm")]: {
              justifyContent: "center",
            },
          },
        }}
        expandIcon={<ExpandMore />}
      >
        <Typography className={classes.heading}>{item.component.name}</Typography>
        <Box style={{ top: expanded ? 3 : 3, position: "absolute", right: 50 }}>
          <Checkbox edge="end" checked={checked?.length === item.items.length} />
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionList
          evt={evt}
          id={id}
          user={user}
          onReload={onReload}
          onUpdate={onUpdate}
          items={item.items}
          statuses={statuses}
          checked={checked}
          options={options}
        />
      </AccordionDetails>
    </Accordion>
  );
};

export const TestingAccordion = (props: any) => {
  const { items, muiTheme, statuses, user, onReload, options, id, evt } = props;
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      {items.map((item: any, index: number) => {
        return (
          <AccordionItem evt={evt} id={id} onReload={onReload} classes={classes} item={item} key={index} statuses={statuses} user={user} options={options} />
        );
      })}
    </Box>
  );
};
