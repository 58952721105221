import { isEmpty } from "lodash";
import * as Yup from "yup";
const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@(([[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
const phoneRegex = /^\d{9}$/;

export const required = ({ value, message }: any) => {
  if (typeof value === "number") {
    return value === 0 || value ? undefined : message ? message : "Required field";
  }
  if (typeof value) return !isEmpty(value) ? undefined : message ? message : "Required field";
};

export const email = ({ value, message }: any) => {
  return emailRegex.test(value?.toLowerCase()) ? undefined : message ? message : "Required field";
};

export const phone = ({ value, message }: any) => {
  return phoneRegex.test(value) ? undefined : message ? message : "Invalid format";
};

export const maxLength = ({ value, message, length }: any) => {
  if (value && value.length > length) {
    return message ? message : "Required field";
  }
};
export const minLength = ({ value, message, length }: any) => {
  if (value && value.length < length) {
    return message ? message : "Required field";
  }
};

export const emailAlreadyExists = ({ value, message, args, showAfter }: any) => {
  if (args) {
    return message ? message : "Required field";
  }
};

export const usernameAlreadyExists = ({ value, message, args, showAfter }: any) => {
  if (args === true) {
    return message ? message : "Required field";
  }
};

export const Schema = Yup.object().shape({
  email: Yup.string().required("სალადებულო ველია").email("ელ.ფოსტა არასწორი ფორმატია."),
  phone: Yup.string().required("სალადებულო ველია").min(9, "ტელეფონი უნდა იყოს 9 ნიშნა").max(9, "ტელეფონი უნდა იყოს 9 ნიშნა"),
});
/* password: Yup.string().required("field_is_required"),
confirmpassword: Yup.string().when("password", {
  is: (val: any) => (val && val.length > 0 ? true : false),
  then: Yup.string().oneOf(
    [Yup.ref("password")],
    "confirmpassword_incorrect"
  )
}) */

export const validationSchemas = (val: any[], val2: any[]) => {
  return {
    uploadDocument: Yup.object().shape({
      name: Yup.string().required("დასახელება სალალდებულო ველია.").min(3, "მისაღებია უმოკლესი 3 ნიშნა სახელი"),
    }),
    documentRequest: Yup.object().shape({
      text: Yup.string().required("ტექსტი სალალდებულო ველია.").min(3, "მისაღებია უმოკლესი 3 ნიშნა სახელი"),
    }),
    shareDocument: Yup.object().shape({
      employees: Yup.string().required("თანამშრომლები სალალდებულო ველია."),
      file: Yup.string().required("დოკუმენტი სალალდებულო ველია."),
    }),
    versionSchema: Yup.object().shape({
      name: Yup.string().required("დასახელება სალალდებულო ველია."),
      uid: Yup.string().required("UID სალალდებულო ველია.").notOneOf(val, "UID უკვე არსებობს").nullable(),
      startDate: Yup.string().required("დაწყების თარიღი სავალდებულო ველია"),
      releaseDate: Yup.string().required("გაშვების თარიღი სავალდებულო ველია"),
    }),
    issueSchema: Yup.object().shape({
      name: Yup.string().required("დასახელება სალალდებულო ველია."),
      description: Yup.string().required("აღწერა სალალდებულო ველია."),
      status: Yup.object().required("სტატუსი სალალდებულო ველია.").nullable(),
      project: Yup.object().required("პროექტი სალალდებულო ველია.").nullable(),
      category: Yup.object().required("კატეგორია სალალდებულო ველია.").nullable(),
      component: Yup.object().required("კომპონენტი სალალდებულო ველია.").nullable(),
      type: Yup.object().required("სახეობა სალალდებულო ველია.").nullable(),
    }),
    taskSchema: Yup.object().shape({
      issue: Yup.object().required("საკითხი სავალდებულო ველია.").nullable(),
      name: Yup.string().required("დასახელება სავალდებულო ველია."),
      description: Yup.string().required("განმარტება სავალდებულო ველია."),
      status: Yup.object().required("სტატუსი სავალდებულო ველია."),
      priority: Yup.object().required("პრიორიტეტი სავალდებულო ველია."),
      version: Yup.object().required("ვერსია სავალდებულო ველია."),
      assignee: Yup.object().required("პასუხისმგებელი სავალდებულო ველია."),
    }),
    productSchema: Yup.object().shape({
      name: Yup.string().required("დასახელება სავალდებულო ველია."),
      brand: Yup.object().required("საკითხი სავალდებულო ველია.").nullable(),
      price: Yup.number(),
      specialPrice: Yup.number(),
    }),
    registration: Yup.object().shape({
      email: Yup.string().required("ელ.ფოსტა სალალდებულო ველია.").email("ელ.ფოსტა არასწორი ფორმატია."),
      firstName: Yup.string().required("სახელი სალალდებულო ველია.").min(2, "მისაღებია უმოკლესი 2 ნიშნა სახელი"),
      lastName: Yup.string().required("გვარი სალალდებულო ველია.").min(2, "მისაღებია უმოკლესი 2 ნიშნა გვარი"),
      personalId: Yup.string()
        .required("პორადი ნომერი სალალდებულო ველია.")
        .min(11, "პირადი ნომერი უნდა იყოს 11 ნიშნა")
        .max(11, "პირადო ნომერი უნდა იყოს 11 ნიშნა"),
      phone: Yup.string().required("ტელეფონი სალადებულო ველია.").min(9, "ტელეფონი უნდა იყოს 9 ნიშნა.").max(9, "ტელეფონი უნდა იყოს 9 ნიშნა."),
      password: Yup.string().required("პაროლი სავალდებულო ველია."),
      confirmpassword: Yup.string()
        .required("პაროლის დადასტურება სავალდებულო ველია.")
        .when("password", {
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref("password")], "პაროლები არ ემთხვევა."),
        }),
    }),

    address: Yup.object().shape({
      street: Yup.string().required("ქუჩის მითითება აუცილებელია"),
      city: Yup.string().required("ქალაქის მითითება აუცილებელია"),
      country: Yup.string().required("ქვეყნის მითითება აუცილებელია"),
    }),
    exportReport: Yup.object().shape({
      month: Yup.string().required("თვე სალალდებულო ველია."),
    }),
    restorePassword: Yup.object().shape({
      // personalId: Yup.string().required("პირადი ნომერი სავალდებულო ველია"),
      // token: Yup.string().required("კოდი სალალდებულო ველია.").min(2, 'მისაღებია უმოკლესი 2 ნიშნა სახელი'),
      password: Yup.string().required("password is a required field."),
      confirmPassword: Yup.string()
        .required("repeate password is a required field.")
        .when("password", {
          is: (val: any) => (val && val.length > 0 ? true : false),
          then: Yup.string().oneOf([Yup.ref("password")], "passwords do not match."),
        }),
    }),
    forgotPassword: Yup.object().shape({
      personalId: Yup.string().required("პირადი ნომერი სავალდებულო ველია"),
    }),
    login: Yup.object().shape({
      personalId: Yup.string().required("პირადი ნომერი სალადებულო ველია."),
      password: Yup.string().required("პაროლი სალადებულო ველია."),
    }),
  };
};
