import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, createTheme, Grid, GridProps, IconButton, IconButtonProps, styled, ThemeProvider, Tooltip, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import VersionsFormDialog from "../../Components/Dialogs/VersionsFormDialog";
import { FilterHeader } from "../../Components/Filter/FilterHeader";
import Pagination from "../../Components/Pagination";
import SearchDateFilter from "../../Components/SearchDateFilter";
import VersionsTable from "../../Components/Tables/VersionsTable";
import { filterLists, ReduxState, versionsStructureList } from "../../environment/environment";
import { useDidMountEffect } from "../../helpers/customHooks";
import { permissionList, permValid } from "../../helpers/permCheckers";
import { QueryOptions } from "../../helpers/query.options";
import { generateParamsFromUrl, urlChanger } from "../../helpers/utilities";
import { setSnack } from "../../models/application/applicationActions";
import { versionFilterService } from "../../models/filter/service";
import { register } from "../../models/user/authActions";
import { versionService } from "../../models/Version/service";
import { Version } from "../../models/Version/Version";

const ResponseiveGrid = styled((props: GridProps) => <Grid {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  overflow: "auto",
  // [theme.breakpoints.down("sm")]: {
  //   flexDirection: "row",
  // }
}));

const VersionsPage = (props: any) => {
  const { auth, app, setSnack, palette, mode } = props;
  const [versionPer] = useState(permissionList(auth, "Version"));
  const [version, setVersion] = useState<Version | null>();
  const [open, setOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();
  const pageName = "versions";
  const [options, setOptions] = useState<any>(new QueryOptions());
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [productList, setVersionList] = useState<Version[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [count, setCount] = useState(1);
  const [filterItems, setFilterItems] = useState<any[]>([]);
  const [filterItemsList, setFilterItemsList] = useState<any[]>([]);
  const [urlValues, setUrlValues] = useState({
    pageIndex: 1,
    pageSize: 30,
    sort: "created",
    search: "",
    order: "desc",
    created_from: null,
    created_to: null,
  });

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const handleOrder = (state: string) => {
    urlChanger(history, pageName, "order", state);
  };

  useDidMountEffect(() => {
    if (options) {
      getVersions(options);
    }
  }, [JSON.stringify(options), submitted]);

  useDidMountEffect(() => {
    loadFilter(options);
  }, [JSON.stringify(options)]);

  const loadFilter = (options: QueryOptions) => {
    versionFilterService.listDict(options).then((resp: any) => {
      const array = Object.keys(resp)
        .map((k) => {
          if (resp[k]) {
            return {
              key: k,
              items: resp[k].map((item: any) => {
                return { ...item, key: k };
              }),
            };
          } else return undefined;
        })
        .filter((item) => item?.key);
      setFilterItems(array);
      getSubObjectListFromObjectList(array);
    });
  };

  const handleUrlValues = (setBy: string, value: unknown) => {
    if (typeof value === "string" || typeof value === "number") {
      setUrlValues((prev) => {
        return { ...prev, [setBy]: value };
      });
    }
  };

  const handleOnChange = (item: any) => {
    urlChanger(history, pageName, item.key, item.value.length ? item.value : "", 1);
  };
  const handleFilterDelete = (item: any) => {
    let loc: any = window.location.search;
    loc = loc
      .split("&")
      .filter((r: any) => r.split("=")[1].indexOf(item.id) > -1 && r.split("=")[0].indexOf(item.key) > -1)
      .toString()
      .split("=")[1]
      .split(",")
      .filter((itm: any) => itm !== item.id)
      .toString();
    urlChanger(history, pageName, item.key, loc, 1);
  };

  const handleOnToggle = (items: any[], name: string) => {
    let key = "";
    let params = "";
    if (items.length) {
      key = items[0].key;
      params = items.map((item) => item.id).toString();
      urlChanger(history, pageName, key, params, 1);
    } else {
      urlChanger(history, pageName, items[0].key, items[0].id, 1);
    }
  };

  const clearFilterRedirect = () => {
    let loc = location.search;
    let arrParams = loc
      .split("&")
      .filter((item) => filterLists[pageName].toString().indexOf(item.split("=")[0]) === -1)
      .join("&");
    history.push({ pathname: pageName, search: arrParams });
  };

  useEffect(() => {
    let arr: any = [];
    const params = generateParamsFromUrl(location, pageName);
    Object.entries(params).forEach(([key, value]) => {
      if (filterLists[pageName].filter((e: string) => e === key).length) {
        if (typeof value === "string") {
          value.split(",").forEach((item) => {
            arr.push({ id: item, name: item, key: key });
          });
        }
        if (key === "search" || key === "created_from" || key === "created_to" || key === "birth_date" || key === "pageIndex") {
          handleUrlValues(key, value ? value : "");
        }
      }
      if (key === "pageIndex") {
        handleUrlValues(key, value ? value : "");
      }
    });

    setSelectedFilters(arr);
    setOptions(params);
  }, [location]);

  const getVersions = (options: QueryOptions) => {
    versionService.listWithCounter(options).then((r) => {
      setVersionList(r.current);
      setCount(r.count);
    });
  };

  const getSubObjectListFromObjectList = (filterItems: any[]) => {
    const arr: any[] = [];
    filterItems.map((item) => item.items.map((item: any) => arr.push(item)));
    setFilterItemsList(arr);
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
    typography: {
      fontSize: 13,
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 13,
          },
        },
      },
    },
  });

  const theme = useTheme();

  return (
    <ThemeProvider theme={localTheme}>
      <ResponseiveGrid container direction={"row"} alignContent={"flex-start"}>
        <Grid container item lg={12} md={12} xs={12} padding={"20px"}>
          <SearchDateFilter
            setSearch={(item: any) => {
              if (history.location.search.indexOf("search") > -1) {
                urlChanger(history, pageName, "search", item.searchValue, 1);
              } else if (history.location.search.indexOf("search") < 0 && item.searchValue.length) {
                urlChanger(history, pageName, "search", item.searchValue, 1);
              }
            }}
            setFrom={(item: any) => urlChanger(history, pageName, "created_from", item)}
            setTo={(item: any) => urlChanger(history, pageName, "created_to", item)}
            search={urlValues.search}
            from={urlValues.created_from}
            to={urlValues.created_to}
          >
            <Tooltip title={!permValid(versionPer, "write") ? "disabled" : ""}>
              <Button
                onClick={() => {
                  permValid(versionPer, "write") && setVersion(null);
                  permValid(versionPer, "write") && setOpen(true);
                }}
                sx={{
                  [theme.breakpoints.down("sm")]: {
                    width: "80vw",
                    margin: 0,
                  },
                  height: 40,
                  backgroundColor: permValid(versionPer, "write") ? palette.background.paperLight : "lightgrey",
                  fontSize: "12px",
                  color: permValid(versionPer, "write") ? "white" : "grey",
                  marginLeft: "20px",
                  marginTop: "14px",
                  "&:hover": {
                    backgroundColor: permValid(versionPer, "write") ? palette.background.paperLight : "lightgrey",
                  },
                }}
              >
                შექმენი ვერსია
                <AddIcon />
              </Button>
            </Tooltip>
            {selectedFilters?.length > 0 && (
              <Button
                sx={{
                  margin: "0px 20px",
                  [theme.breakpoints.down("sm")]: {
                    width: "80vw",
                    margin: 0
                  },
                }}
                onClick={() => {
                  setSelectedFilters([]);
                  clearFilterRedirect();
                }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
              >
                ფილტრის მოხსნა
              </Button>
            )}
          </SearchDateFilter>
        </Grid>
        <Grid item lg={12} md={12} xs={12} sx={{ minHeight: "42px", padding: "0px 20px" }}>
          {selectedFilters?.length > 0 && (
            <Box>
              <FilterHeader
                removeAll={() => setSelectedFilters([])}
                items={[
                  ...selectedFilters.map((item) =>
                    filterItemsList.find((e) => e.id === item.name) ? filterItemsList.filter((e) => e.id === item.name && e.key === item.key)[0] : item
                  ),
                ]}
                handleDelete={handleFilterDelete}
              />
            </Box>
          )}
        </Grid>
        <VersionsFormDialog setSubmitted={setSubmitted} onAlert={onAlert} item={version!} open={open} handleDialog={(bool: any) => setOpen(bool)} />

        <Grid item xs={12} lg={12} sx={{ padding: "0px 20px" }}>
          <VersionsTable
            tableName={"versionsTable"}
            handleSubmit={setSubmitted}
            tableList={productList}
            handleOrder={handleOrder}
            handleOnToggle={handleOnToggle}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            tableStructureList={versionsStructureList}
            filterItems={filterItems}
          />
          {count && (
            <Pagination
              paginationTitle={"versions"}
              count={count}
              pageSize={urlValues.pageSize}
              setPageIndex={(item: any) => {
                handleUrlValues("pageIndex", item);
                urlChanger(history, pageName, "pageIndex", `${item}`);
              }}
              pageIndex={urlValues.pageIndex}
              palette={palette}
            />
          )}
        </Grid>
      </ResponseiveGrid>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps, { register, setSnack })(VersionsPage);
