import { Grid, Table } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import Pagination from "../../Components/Pagination";
import { filterLists, inVersionTasksStructureList } from "../../environment/environment";
import { useDidMountEffect } from "../../helpers/customHooks";
import { QueryOptions } from "../../helpers/query.options";
import { generateParamsFromUrl, urlChanger } from "../../helpers/utilities";
import { setSnack } from "../../models/application/applicationActions";
import { versionFilterService } from "../../models/filter/service";
import { taskService } from "../../models/Task/service";
import { Task } from "../../models/Task/Task";
import MainTableHeaderBodyContainer from "../MainTableHeaderBodyContainer";

const TabTask = (props: any) => {
  const { palette, pageName } = props;

  const history = useHistory();
  const location = useLocation();
  const [options, setOptions] = useState<any>(new QueryOptions());
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [taskList, setTaskList] = useState<Task[]>([]);
  const [count, setCount] = useState(1);
  const [filterItems, setFilterItems] = useState<any[]>([]);
  const [urlValues, setUrlValues] = useState({
    pageIndex: 1,
    pageSize: 30,
    sort: "created",
    search: "",
    order: "desc",
  });
  const handleOrder = (state: string) => {
    urlChanger(history, pageName, "order", state);
  };

  useDidMountEffect(() => {
    getTasks(options);
    loadFilter(options);
  }, [JSON.stringify(options)]);

  const loadFilter = (options: QueryOptions) => {
    versionFilterService.listDict(options).then((resp: any) => {
      const array = Object.keys(resp)
        .map((k) => {
          if (resp[k]) {
            return {
              key: k,
              items: resp[k].map((item: any) => {
                return { ...item, key: k };
              }),
            };
          } else return undefined;
        })
        .filter((item) => item?.key);
      setFilterItems(array);
    });
  };

  const handleUrlValues = (setBy: string, value: unknown) => {
    if (typeof value === "string" || typeof value === "number") {
      setUrlValues((prev) => {
        return { ...prev, [setBy]: value };
      });
    }
  };

  const handleOnChange = (item: any) => {
    urlChanger(history, pageName, item.key, item.value.length ? item.value : "", 1);
  };

  const handleOnToggle = (items: any[]) => {
    let key = "";
    let params = "";
    if (items.length) {
      key = items[0].key;
      params = items.map((item) => item.id).toString();
      urlChanger(history, pageName, key, params, 1);
    } else {
      urlChanger(history, pageName, items[0].key, items[0].id, 1);
    }
  };

  useEffect(() => {
    let arr: any = [];
    const params = generateParamsFromUrl(location, "inVersionTasksTable");
    Object.entries(params).forEach(([key, value]) => {
      if (filterLists["inVersionTasksTable"].filter((e: string) => e === key).length) {
        if (typeof value === "string") {
          value.split(",").forEach((item) => {
            arr.push({ id: item, name: item, key: key });
          });
        }
        if (key === "search" || key === "created_from" || key === "created_to" || key === "birth_date" || key === "pageIndex") {
          handleUrlValues(key, value ? value : "");
        }
      }
      if (key === "pageIndex") {
        handleUrlValues(key, value ? value : "");
      }
    });

    setSelectedFilters(arr);
    setOptions(params);
  }, [location]);

  const getTasks = (options: QueryOptions) => {
    taskService.listWithCounter(options).then((r) => {
      setTaskList(r.current);
      setCount(r.count);
    });
  };

  const localList = JSON.parse(localStorage.getItem("inVersionTasksStructure") || "{}");
  const [cachedList, setCachedList] = useState<{ key: string; list: any[] }>({ key: "", list: [] });
  const [task, setTask] = useState<Task | null>(null);
  const [open, setOpen] = useState(false);
  const [filteredList, setFilteredList] = useState<any[]>(Object.keys(localList).length ? localList : inVersionTasksStructureList);

  const getOverallTrueFalseFromFilteredList = () => {
    return filteredList.filter((e) => e.enabled === true).length ? true : false;
  };

  const handleCachedList = (item: { key: string; list: any[] }) => {
    setCachedList(item);
  };

  const handleEdit = (state: boolean, item: any) => {
    setOpen(state);
    setTask(item);
  };

  const handleFilteredList = (state: any[]) => {
    setFilteredList(state);
  };

  return (
    <Grid container item>
      <Grid item overflow={"overlay"}>
        <Table sx={{ width: getOverallTrueFalseFromFilteredList() ? "100%" : "fit-content" }}>
          <MainTableHeaderBodyContainer
            padding="checkbox"
            size="small"
            align="center"
            handleEdit={handleEdit}
            filterItems={filterItems}
            headerList={filteredList}
            handleOrder={handleOrder}
            handleOnToggle={handleOnToggle}
            handleCachedList={handleCachedList}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            cachedList={cachedList}
            handleFilteredList={handleFilteredList}
            updater={new Task()}
            service={taskService}
            copySuccessMessage={"Id copied successfully"}
            tableList={taskList}
            tableName={"tasksTable"}
            pathname={`/tasks/${task?.id}`}
            detailsLinkToolVisible={false}
            copyToolVisible={false}
            editToolVisible={false}
          />
        </Table>
      </Grid>
      {count && (
        <Pagination
          count={count}
          pageSize={urlValues.pageSize}
          setPageIndex={(item: any) => {
            handleUrlValues("pageIndex", item);
            urlChanger(history, pageName, "pageIndex", `${item}`);
          }}
          pageIndex={urlValues.pageIndex}
          palette={palette}
        />
      )}
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { setSnack })(TabTask);
