import UsernameComponent from "./UsernameComponent";
import MessageFlowComponent from "./MessageFlowComponent";
import MessageInputComponent from "./MessageInputComponent";
import EmptyUsernameComponent from "./EmptyUsernameComponent";
import EmptyMessageFlowComponent from "./EmptyMessageFlowComponent";
import { useEffect, useState } from "react";
import { Box, Grid } from "@mui/material";

const ChatComponent = (props: any) => {
  const { chat, onOpen, chatPer, search, currUserName, messageDeleteActionHandler, messageSenderUpdaterHandler } = props;

  const [editMode, setEditMode] = useState(false);
  const [editText, setEditText] = useState<string | null>(null);
  const [uid, setUid] = useState<string | null>(null);
  const [replyText, setReplyText] = useState<string | null>(null);
  const [replierName, setReplierName] = useState<string | null>(null);

  const logedInUser = () => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser")!).fullName;
    const username = chat?.user?.name;

    return username;
  };

  useEffect(() => {
    setEditMode(false);
    setEditText(null);
    setUid(null);
    setReplyText(null);
    setReplierName(null);
  }, [chat?.id]);

  return (
    <Grid
      container
      direction={"column"}
      sx={{
        borderLeft: "solid 1px lightgrey",
      }}
    >
      {chat ? <UsernameComponent onOpen={onOpen} username={logedInUser()} imageLink={chat?.user?.image} /> : <EmptyUsernameComponent />}
      {chat ? (
        <MessageFlowComponent
          messageDeleteActionHandler={messageDeleteActionHandler}
          setReplierName={setReplierName}
          setReplyText={setReplyText}
          userName={logedInUser()}
          currUserName={currUserName}
          uid={uid}
          setUid={setUid}
          setEditText={setEditText}
          setEditMode={setEditMode}
          messages={chat.messages}
          imageLink={chat?.user?.image}
          chatPer={chatPer}
          search={search}
        />
      ) : (
        <EmptyMessageFlowComponent />
      )}
      <MessageInputComponent
        replierName={replierName}
        setReplierName={setReplierName}
        setReplyText={setReplyText}
        replyText={replyText}
        setEditText={setEditText}
        editText={editText}
        setEditMode={setEditMode}
        editMode={editMode}
        chat={chat}
        uid={uid}
        messageSenderUpdaterHandler={messageSenderUpdaterHandler}
      />
    </Grid>
  );
};

export default ChatComponent;
