import { CategorySerializer } from '../category/serializer'
import { ProjectSerializer } from '../project/serializer'
import { Serializer } from '../serializer'
import { StatusSerializer } from '../status/serializer'
import { TaskSerializer } from '../Task/serializer'
import { TaskComponentSerializer } from '../taskcomponent/serializer'
import { UserSerializer } from '../user/serializer'
import { Issue, IssueType } from './Issue'


export class IssueTypeSerializer implements Serializer {
    fromJson(json: any) {
        const type = new IssueType()
        type.id = json.id      
        type.name = json.name        
        return type
    }

    toJson(item: IssueType) {
        const obj: any = {}
        return obj
    }
}
export class IssueSerializer implements Serializer {
    
    fromJson(json: any) {
        const issue = new Issue()
        issue.name = json.name
        issue.id = json.id
        const projectSerializer = new ProjectSerializer()
        issue.project = projectSerializer.fromJson(json.project)
        issue.description = json.description
        issue.type = json.type
        const componentSerializer = new TaskComponentSerializer()
        issue.component = componentSerializer.fromJson(json.component)
        issue.images = json.images
        if (json.videos) {
            issue.videos = json.videos.map((r: any) => {
                return `http://localhost:8060/assets/images/issues/${r.filename}`
            })
        }
        if (json.task) {
            const taskSerializer = new TaskSerializer()
            issue.task = taskSerializer.fromJson(json.task)
        }
        issue.created = json.created
        const categorySerializer = new CategorySerializer()
        issue.category = categorySerializer.fromJson(json.category)
        if (json.status) {
            const statusSerializer = new StatusSerializer()
            issue.status = statusSerializer.fromJson(json.status)
        }
        const userSerializer = new UserSerializer()
        issue.user = userSerializer.fromJson(json.user)
        issue.created = json.created 
        issue.is_enabled = json.is_enabled
        return issue
    }

    toJson(item: Issue) {
        const obj: any = {
        }

        if(item.project)obj.project = item.project.id
        if(item.name)obj.name = item.name
        if(item.id)obj.id = item.id
        if(item.description)obj.description = item.description
        if(item.status)obj.status = item.status.id
        if(item.type)obj.type = item.type.id
        if(item.images)obj.image = item.images
        if(item.category)obj.category = item.category.id
        if(item.component)obj.component = item.component.id
        if(item.is_enabled)obj.is_enabled = item.is_enabled
        return obj
    }
}
