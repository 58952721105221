import { LOGIN, LOGOUT, LOGIN_ERROR, CHANGE_USER_IMAGE, CHANGE_USERNAME } from './authActions'

const initialState = {
    user: null,
    loggedIn: false,
    errorStatus: null,
    imageURI: null,
    username: null
}

export const authenticationReducer = (state = initialState, action: { type: any; content: { user: any; loggedIn: any; errorStatus:any, imageURI: string; username: string, roles: any } }) => {
    switch (action.type) {
        case LOGIN:{
            const { user, loggedIn, imageURI, username, roles } = action.content
            return { ...state, user, loggedIn, imageURI, username, roles }
        }
        case LOGIN_ERROR:{
            const { errorStatus } = action.content
            return { ...initialState, errorStatus }
        }
        case LOGOUT:
            return initialState
        case CHANGE_USER_IMAGE: 
            const { imageURI } = action.content
            return { ...state, imageURI: imageURI }
        case CHANGE_USERNAME: 
            const { username } = action.content
            return { ...state, username: username }
        default:
            return state
    }
}