import { Serializer } from '../serializer'
import { Project } from './project'


export class ProjectSerializer implements Serializer {
    fromJson(json: any) {
        const project = new Project()
        project.name = json.name
        project.id = json.id
        project.description = json.description
        
        return project
    }

    toJson(item: Project) {
        const obj: any = {}
        
        return obj
    }
}
