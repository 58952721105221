import { Close } from "@mui/icons-material";
import { Dialog, DialogContent } from "@mui/material";
import IssueComponentCreatorForm from "../Forms/IssueComponentCreatorForm";

export default function IssueComponentCreatorDialog(props: any) {
  const { open, handleOpenState, createComponent } = props;
  return (
    <Dialog open={open} fullWidth={true} maxWidth={"xs"}>
      <DialogContent sx={{ overflow: "hidden", backgroundColor: "background.default" }}>
        <div
          onClick={handleOpenState(false)}
          style={{
            position: "absolute",
            right: 7,
            top: 7,
            cursor: "pointer",
          }}
        >
          <Close />
        </div>
        <IssueComponentCreatorForm createComponent={createComponent} handleOpenState={handleOpenState} />
      </DialogContent>
    </Dialog>
  );
}
