import { NovaPoshta } from './NovaPoshta';
import { Serializer } from './../serializer';
import { Resource } from '../resource';

export class NovaPoshtaSerializer implements Serializer {
    fromJson(json: any) {
        const novaPoshta = new NovaPoshta()
        novaPoshta.name = json.description_rus
        novaPoshta.id = json.id
        return novaPoshta
    }

    toJson(resource: Resource) {
        return resource
    }
}

export const novaPoshtaSerialier = new NovaPoshtaSerializer()