export const COLORS = {
  status: {
    to_do: "#EB5C3D",
    in_progress: "#F0B210",
    completed: "#36D868",
    to_discuss: "#9C519F",
    testing: "#d9bf0c",
    default: "#ffffff",
    done: "rgb(67, 147, 73)",
  },
  priority: {
    very_low: "blue",
    low: "green",
    medium: "yellow",
    high: "orange",
    very_high: "red",
    default: "black",
  },
};
