import { ResourceProvider } from "../../providers/resource.provider";
import { SuccessManagerSerializer } from "./serializer";
import { SuccessManagerItem } from "./SuccessManagers";



class SuccessManagerService extends ResourceProvider<SuccessManagerItem> {
    constructor() {
        super(
            "rest_success_group",
            new SuccessManagerSerializer()
        )
    }
}

export const successManagerService = new SuccessManagerService()