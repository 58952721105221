import { ResourceProvider } from "../../providers/resource.provider";
import { Status } from "../Version/Version";
import { PrioritySerializer } from "./serializer";



export class priorityProvider extends ResourceProvider<Status> {
    constructor() {
        super("rest_priority", new PrioritySerializer())
    }
}

export const priorityService = new priorityProvider()
