import { Resource } from "../resource";

export interface OfferResponse {
    count: number;
    current: Offer[];
    has_next: boolean;
    next: Offer[];
}

export class Offer extends Resource {
    id: string | null | undefined;
    interactionUid: string | null | undefined;
}