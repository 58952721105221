import { ProductPromotion } from './ProductPromotion';
import { Resource } from '../resource';
import { Serializer } from './../serializer';
import { userSerializer } from '../user/serializer';
import { productSerializer } from '../Product/serializer';


export class ProductPromotionSerializer implements Serializer {
    fromJson(json: any): ProductPromotion {
        const item = new ProductPromotion()
        item.id = json._id
        item.ageFrom = json.age_from
        item.ageTo = json.age_to
        item.created = json.created
        item.expired = json.expires
        item.dailyBudget = json.daily_budget
        item.durationDays = json.duration_days
        item.expires = json.expires
        item.gender = json.gender
        if (json.instances) {
            item.balance = json.instances?.balance
        }
        item.numberOfComments = json.number_of_comments
        item.numberOfFeedsViews = json.number_of_feeds_views
        item.numberOfFilterViews = json.number_of_filter_views
        item.numberOfFollows = json.number_of_follows
        item.numberOfHits = json.number_of_hits
        item.numberOfLikes = json.number_of_likes
        item.numberOfProductHits = json.number_of_product_hits
        item.numberOfProfileHits = json.number_of_profile_hits
        item.numberOfSaves = json.number_of_saves
        if (json.product) {
            item.productName = productSerializer.fromJson(json.product).name
            item.brandName = productSerializer.fromJson(json.product).brand
            item.productId = productSerializer.fromJson(json.product).id
            item.specialPrice = productSerializer.fromJson(json.product).specialPrice
            item.image = userSerializer.fromJson(json.product).image
        }
        item.status = json.status
        item.uid = json.uid
        item.updated = json.updated
        if (json.user) {
            item.promotionBalance = userSerializer.fromJson(json.user).promotionBalance
        }
        item.ratio = json.ratio
        item.totalBudget = json.total_budget
        item.totalRest = json.total_rest
        item.totalSpent = json.total_spent
        return item
    }
    toJson(resource: Resource) {
        throw new Error('Method not implemented.');
    }

}

export const productPromotionSerializer = new ProductPromotionSerializer()