import { Box, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { environment } from '../../../environment/environment'
import { chatStyle, urlify, returnAccordingToEmoji } from '../../../helpers/utilities'
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import ReplyRoundedIcon from '@mui/icons-material/ReplyRounded';

export const UserSideMessageBox = (props: any) => {
    const { index, messages, e, imageLink, item, setReplierName, setUid, setReplyText, setEditMode, setEditText, currUserName, userName } = props

    const [image, setImage] = useState<string | any>(null)
    const [visible, setVisible] = useState(false);

    const replyButtonClickHandler = (item: any) => {
        setReplierName(item.owner === 'admin' ? item.portal_user?.full_name === currUserName ? "You" : item.portal_user?.full_name : userName)
        setUid(item.uid);
        setReplyText(item.text);
        setEditMode(false);
        setEditText(null);
    }

    useEffect(() => {
        if (imageLink) {
            setImage(environment.usertImages + imageLink + `.${'jpg' || 'png'}`)
        }
    }, [imageLink])

    return (
        <Box
            onMouseEnter={() => setVisible(true)}
            onMouseLeave={() => setVisible(false)}
            sx={{
                width: '100%',
                height: 'auto',
                display: 'flex',
                justifyContent: 'flex-start',
                padding: chatStyle(index, "user", messages?.filter((d: any) => new Date(d.created).toDateString() === e))[2]?.toString(),
                boxSizing: 'border-box',
            }}>
            <Box sx={{ display: 'flex', fontSize: '0.9em', flexDirection: 'column' }}>
                <Box sx={{
                    display: 'flex',
                    height: '100%',
                    justifyContent: 'flex-end',
                    alignItems: 'center',
                    gap: '10px',
                    flexDirection: 'row-reverse'
                }}>
                    {visible &&
                        <IconButton onClick={() => replyButtonClickHandler(item)}>
                            <ReplyRoundedIcon

                                sx={{ color: 'text.cardInfo', cursor: 'pointer' }}
                            />
                        </IconButton>
                    }
                    <Box sx={{ display: 'flex' }}>
                        <Box sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'flex-end'
                        }}>
                            <Box sx={{
                                width: '30px',
                                height: '30px',
                                backgroundColor: 'background.primary',
                                marginRight: '10px',
                                borderRadius: '15px',
                                contain: 'content'
                            }}>
                                {chatStyle(index, "user", messages?.filter((d: any) => new Date(d.created).toDateString() === e))[1] ?
                                    imageLink ? <img
                                        style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                        src={image} alt="" id="user" onError={() => { setImage(null) }} /> :
                                        <AccountCircleIcon style={{ fontSize: '30px', color: "pink" }} /> : null
                                }
                            </Box>
                        </Box>
                        <Box sx={{
                            display: "flex",
                            alignItems: 'center',
                            height: returnAccordingToEmoji(item.text, 'fontSize', 'fit-content'),
                            contain: 'content',
                            fontSize: 35,
                            textAlign: 'left',
                            margin: '0',
                            padding: returnAccordingToEmoji(item.text, 'padding', '10px'),
                            maxWidth: '350px',
                            backgroundColor: returnAccordingToEmoji(item.text, 'background', 'lightgrey')?.toLocaleString(),
                            wordBreak: 'break-word',
                            borderRadius: returnAccordingToEmoji(
                                item.text,
                                'radius',
                                chatStyle(index, "user", messages?.filter((d: any) => new Date(d.created).toDateString() === e))[0]?.toString()
                            )
                        }}>
                            {urlify(item.text, '#000')}
                        </Box>
                    </Box>
                </Box>
                {chatStyle(index, "user", messages?.filter((d: any) => new Date(d.created).toDateString() === e))[1] &&
                    <Box
                        sx={{
                            textAlign: 'left',
                            paddingLeft: '45px',
                            paddingTop: '5px',
                            fontStyle: 'italic',
                            fontSize: '0.8em',
                            color: 'gray'
                        }}>
                        {new Date(item.created).toLocaleTimeString().slice(0, 5)}
                    </Box>}
            </Box>
        </Box>
    )
}
