import { ResourceProvider } from "../../providers/resource.provider";
import { VersionSerializer } from "./serializet";
import { Version } from "./Version";



export class VersionProvider extends ResourceProvider<Version> {
    constructor() {
        super("rest_version", new VersionSerializer())
    }
}


export const versionService = new VersionProvider()
