import { Autocomplete, Button, DialogTitle, FormControl, Grid, InputLabel, ListItem, MenuItem, Paper, Select, TextField } from '@mui/material'
import { Form, Formik } from 'formik'
import { useEffect, useState } from 'react'
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { validationSchemas } from "../../helpers/validations";

export const onSearch$: any = new Subject().pipe(
    debounceTime(300)
)

const CustomListItem = (props: any) => {

    const { element, option } = props

    const [hover, setHover] = useState(false);

    return <ListItem
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        {...element}
        style={{ backgroundColor: hover ? 'rgba(0,0,0,.3)' : 'transparent' }}>
        {option.name}
    </ListItem>
}

export const ProductForm = (props: any) => {
    const { product, postForm, searchOnChange, lists, formikRef } = props

    const [inputValue, setInputValue] = useState<{ [key: string]: string }>({
        brand: '',
        section: '',
        category: '',
        subcategory: '',
        color: '',
        size: '',
        condition: '',
    });

    useEffect(() => {
        onSearch$.subscribe(
            (debounced: any) => {
                searchOnChange(debounced)
            }
        );
    }, [])

    const CustomPaper = (props: any) => {
        return <Paper
            elevation={8}
            {...props}
            sx={{
                backgroundColor: 'background.default',
                "& .MuiAutocomplete-option": {
                    backgroundColor: 'background.default'
                }
            }} />;
    };

    const initVals = {
        name: '',
        brand: { id: '', sort: undefined, name: '' },
        description: '',
        price: 0,
        specialPrice: 0,
        ownerPricce: 0,
        isEnabled: 'Disabled',
        stock: 0,
        country: '',
        section: { id: '', sort: undefined, name: '' },
        category: { id: '', sort: undefined, name: '' },
        subcategory: { id: '', sort: undefined, name: '' },
        size: { id: '', sort: undefined, name: '' },
        color: { id: '', sort: undefined, name: '' },
        condition: { id: '', sort: undefined, name: '' },
    }

    const capitalize = (s: string): string => (s && s[0].toUpperCase() + s.slice(1)) || ""

    const formList = [
        { label: 'Name', value: 'name', type: 'textfield', xs: 12, lg: 6, depList: [] },
        { label: 'Brand', value: 'brand', type: 'autocomplete', xs: 12, lg: 6, depList: lists.brandList },
        { label: 'Description', value: 'description', type: 'textfield', xs: 12, lg: 10, depList: [] },
        { label: 'Stock', value: 'stock', type: 'textfield', xs: 12, lg: 2, depList: [] },
        { label: 'Price', value: 'price', type: 'textfield', xs: 12, lg: 4, depList: [] },
        { label: 'Special Price', value: 'specialPrice', type: 'textfield', xs: 12, lg: 4, depList: [] },
        { label: 'Owner Price', value: 'ownerPrice', type: 'textfield', xs: 12, lg: 4, depList: [] },
        { label: 'Enabled/Disabled', value: 'isEnabled', type: 'select', xs: 12, lg: 6, depList: ['Enabled', 'Disabled'] },
        { label: 'Country', value: 'country', type: 'select', xs: 12, lg: 6, depList: ['Georgia', 'Ukraine'] },
        { label: 'Section', value: 'section', type: 'autocomplete', xs: 12, lg: 4, depList: lists.sectionList },
        { label: 'Category', value: 'category', type: 'autocomplete', xs: 12, lg: 4, depList: lists.categoryList },
        { label: 'Subcategory', value: 'subcategory', type: 'autocomplete', xs: 12, lg: 4, depList: lists.subcategoryList },
        { label: 'Size', value: 'size', type: 'autocomplete', xs: 12, lg: 6, depList: lists.sizeList },
        { label: 'Color', value: 'color', type: 'autocomplete', xs: 12, lg: 6, depList: lists.colorList },
        { label: 'Condition', value: 'condition', type: 'autocomplete', xs: 12, lg: 6, depList: lists.conditionList },
    ]

    return (
        <Formik
            innerRef={formikRef}
            initialValues={
                product ? { ...product, brand: { id: product.brandId, sort: undefined, name: product.brand } } : initVals
            }
            onSubmit={postForm}
        >
            {({ values, setFieldValue, handleSubmit, handleChange, handleBlur }: any) => (
                <Form onSubmit={(values) => {
                    handleSubmit(values);
                }}>
                    <Grid container spacing={2} sx={{ padding: '20px' }} color='text.primary'>
                        {
                            formList.map((item, index) => {
                                switch (item.type) {
                                    case "textfield":
                                        return (
                                            <Grid item xs={item.xs} lg={item.lg} key={String(index) + item.value}>
                                                <TextField
                                                    sx={{
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: 'text.primary'
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: 'text.primary'
                                                        },
                                                        "& .MuiInputBase-root": {
                                                            color: 'text.primary',
                                                            border: `1px text.primary solid`
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: `text.primary`,
                                                            borderWidth: '2px'
                                                        },
                                                    }}
                                                    name={item.value}
                                                    margin="dense"
                                                    variant="outlined"
                                                    id={item.value}
                                                    value={values[item.value] || ""}
                                                    label={item.label}
                                                    type={item.value}
                                                    fullWidth
                                                    onChange={handleChange}
                                                    onBlur={handleBlur}
                                                />
                                            </Grid>
                                        )
                                    case "select":
                                        return (
                                            <Grid item xs={item.xs} lg={item.lg} key={String(index) + item.value}>
                                                <FormControl
                                                    sx={{
                                                        width: '100%',
                                                        "& .MuiInputLabel-root.Mui-focused": {
                                                            color: 'text.primary'
                                                        },
                                                        "& .MuiInputLabel-root": {
                                                            color: 'text.primary'
                                                        },
                                                        "& .MuiInputBase-root": {
                                                            color: 'text.primary',
                                                            border: `1px text.primary solid`
                                                        },
                                                        "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: "text.primary",
                                                            borderWidth: '1px'
                                                        },
                                                        "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                            borderColor: `text.primary`,
                                                            borderWidth: '2px'
                                                        },
                                                    }}
                                                    variant="outlined"
                                                >
                                                    <InputLabel>{item.label}</InputLabel>
                                                    <Select
                                                        MenuProps={{
                                                            sx: {
                                                                "& .MuiPaper-root": {
                                                                    backgroundColor: 'background.default'
                                                                },

                                                                "& .MuiMenuItem-root.Mui-selected:hover": {
                                                                    backgroundColor: 'rgba(0,0,0,.3)'
                                                                },
                                                                "& .MuiMenuItem-root.Mui-selected": {
                                                                    backgroundColor: 'rgba(0,0,0,.3)',
                                                                },
                                                            }
                                                        }}
                                                        id={item.value}
                                                        name={item.value}
                                                        variant="outlined"
                                                        label={item.label}
                                                        value={capitalize(values[item.value]) || ''}
                                                        onChange={handleChange}
                                                    >
                                                        {item.depList.map((item: string, index: number) => {
                                                            return <MenuItem key={index} value={item}>{item}</MenuItem>
                                                        })}
                                                    </Select>
                                                </FormControl>
                                            </Grid>
                                        )
                                    case "autocomplete":
                                        return (
                                            <Grid item xs={item.xs} lg={item.lg} key={String(index) + item.value}>
                                                <Autocomplete
                                                    value={values[item.value] ?? ""}
                                                    onChange={(_, value) => {
                                                        setFieldValue(item.value, value)
                                                    }}
                                                    onInputChange={(_, newInputValue) => {
                                                        setInputValue((prev: any) => {
                                                            return { ...prev, [item.value]: newInputValue }
                                                        });
                                                    }}
                                                    options={item.depList || []}
                                                    getOptionLabel={(option) => option.name || ""}
                                                    isOptionEqualToValue={(option, value) => option.name !== value.name || option.name === value.name}
                                                    PaperComponent={CustomPaper}
                                                    inputValue={inputValue[item.value]}
                                                    renderOption={(props, option) => {
                                                        return (
                                                            <CustomListItem element={props} option={option} key={option.id} />
                                                        );
                                                    }}
                                                    renderInput={(params) => <TextField
                                                        {...params}
                                                        sx={{
                                                            "& .MuiInputLabel-root.Mui-focused": {
                                                                color: 'text.primary'
                                                            },
                                                            "& .MuiInputLabel-root": {
                                                                color: 'text.primary'
                                                            },
                                                            "& .MuiInputBase-root": {
                                                                color: 'text.primary',
                                                                border: `1px text.primary solid`
                                                            },
                                                            "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                                                borderColor: `text.primary`,
                                                                borderWidth: '2px'
                                                            },
                                                        }}
                                                        onChange={(evt) => onSearch$.next({ searchValue: evt.target.value, id: item.value })}
                                                        name={item.value}
                                                        margin="dense"
                                                        variant="outlined"
                                                        id={item.value}
                                                        label={item.label}
                                                        type={item.value + "don't change"}
                                                        fullWidth
                                                    />}
                                                />
                                            </Grid>
                                        )
                                    default:
                                        break;
                                }
                            })
                        }
                    </Grid>
                </Form>
            )}
        </Formik >
    )
}
