import { productSerializer } from '../Product/serializer';
import { ProductSave } from './ProductSave';
import { Resource } from '../resource';
import { Serializer } from '../serializer';

export class SaveSerializer implements Serializer {
    fromJson(json: any) {
        const save = new ProductSave()
        save.date = json.date
        save.id = json.id
        save.disabled = json.disabled ? "Disabled" : "Enabled"
        save.user = json.user
        if (json.product) {
            save.image = productSerializer.fromJson(json.product).image
            save.productCountry = productSerializer.fromJson(json.product).country
            save.productId = productSerializer.fromJson(json.product).id
            save.productUser = productSerializer.fromJson(json.product).ownerName
        }
        return save
    }

    toJson(resource: Resource) {
        throw (new Error("hahahahaaa"))
    }
}

export const saveSerializer = new SaveSerializer()


