import { QueryOptions } from "../../helpers/query.options";
import { logout as LOGOUT } from "../../models/user/authActions";
import { logout, userService } from "../../models/user/service";
import { Address } from "../Address/Address";
import { Department } from "../department/department";
import { Resource } from "../resource";
import { SuccessManagerItem } from "../successManagers/SuccessManagers";

export class UserPermissions extends Resource {
  read: boolean | any;
  write: boolean | any;
  delete: boolean | any;
  update: boolean | any;
  export: boolean | any;
}

export class User extends Resource {
  bankAccountNumber: string | null | undefined;
  department: Department | null | undefined;
  country: string | null | undefined;
  currentCard: string | null | undefined;
  successManager: string | null | undefined;
  phone: string | null | undefined;
  image: string | null | undefined;
  fullName: string | null | undefined;
  firstName: string | null | undefined;
  lastName: string | null | undefined;
  email: string | null | undefined;
  password: string | null | undefined;
  confirmPassword: string | null | undefined;
  accessToken: string | null | undefined;
  refreshToken: string | null | undefined;
  roles: object[] | null | undefined;
  addresses: Address[] | any;
  averageRating: number | any;
  birthDate: string | null | undefined;
  boughtProducts: number | any;
  boughtProductsPrice: number | any;
  created: string | any;
  followersCount: null | any;
  followingCount: null | any;
  isCreator: null | any;
  isFashionActivist: null | any;
  listingsCount: null | any;
  name: string | any;
  soldProducts: number | any;
  soldProductsPrice: number | any;
  successManagerGroup: SuccessManagerItem | any;
  updated: null | any;
  username: string | any;
  appVersion: string | null | undefined;
  pushToken: string | null | undefined;
  isActive: boolean | null | undefined;
  promotionBalance: number | null | undefined;
  color: string | null | undefined;

  setToken(token: string) {
    this.accessToken = token;
    this.save();
  }

  update(accessToken: string) {
    this.accessToken = accessToken;
  }

  delete() {
    localStorage.removeItem("currentUser");
    localStorage.removeItem("paletteState");
    localStorage.clear();
    this.accessToken = undefined;
  }

  save() {
    localStorage.setItem("currentUser", JSON.stringify(this));
  }

  async load() {
    let parsedUser: any = await localStorage.getItem("currentUser");
    if (parsedUser) {
      parsedUser = JSON.parse(parsedUser);
    }
    if (parsedUser) {
      this.id = parsedUser.id;
      this.phone = parsedUser.phone;
      this.firstName = parsedUser.firstName;
      this.fullName = parsedUser.fullName;
      this.lastName = parsedUser.lastName;
      this.accessToken = parsedUser.accessToken;
      this.email = parsedUser.email;
      this.roles = parsedUser.roles;
    }

    return this;
  }

  updateUser(user: User) {
    this.id = user?.id;
    this.firstName = user.firstName;
    this.accessToken = user.accessToken;
    this.refreshToken = user.refreshToken;
    this.lastName = user.lastName;
    this.fullName = user.fullName;
    this.phone = user.phone;
    this.email = user.email;
    this.roles = user.roles;
    this.save();
    return this;
  }

  JWTParser = (token: any) => {
    try {
      return JSON.parse(atob(token.split(".")[1]));
    } catch (e) {
      console.error(e);
    }
  };

  logOut = () => {
    logout().then(() => {
      const user = new User();
      user.delete();
      LOGOUT();
      localStorage.setItem("logout-event", "logout" + Math.random());
      window.location.href = "/";
    });
  };

  expire = (): void => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    if (currentUser) {
      const Decoded = this.JWTParser(currentUser.accessToken);
      const Time = Decoded.exp * 1000;
      if (Time < Date.now()) {
        this.logOut();
      }
    }
  };

  multiSessionBan = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    userService.listWithCounter(options).then((r) => {});
  };

  expTime = (): number => {
    const currentUser = JSON.parse(localStorage.getItem("currentUser") || "{}");
    if (currentUser) {
      const Decoded = this.JWTParser(currentUser.accessToken);
      return Decoded.exp;
    }

    return 0;
  };
}

export class UserProfileInfoCardEdit extends Resource {
  app_user: string | any;
  param_name: string | undefined;
  param_new_value: string | undefined;
}
