import { NameId, Resource } from './resource'

export interface Serializer {
	fromJson(json: any): Resource
	toJson(resource: Resource): any
}

export class CommonNameSerializer implements Serializer {
    fromJson(json: any) {
        const item = new NameId()
        item.id = json.id
		item.name = json.name
        return item
    }

    toJson(item: any) {
        const obj: any = {}
        
        return obj
    }
}

export const nameIdSerializer = new CommonNameSerializer()