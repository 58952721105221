import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { Offer } from "./Offer";

class OfferSerializer implements Serializer {
    fromJson(json: any) {
        const offer = new Offer();
        offer.id = json.id;
        offer.interactionUid = json.interaction_uid
        return offer;
    }
    toJson(resource: Resource) {
        return resource
    }

}

export const offerSerializer = new OfferSerializer()