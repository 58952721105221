import { applyMiddleware, combineReducers, createStore, compose } from "redux";
import thunk from "redux-thunk";
import { authenticationReducer } from '../models/user/authReducer'
import { applicationReducer } from '../models/application/applicationReducer'
import { setGlobalTheme } from "../models/globalTheme/globalThemeReducer";

declare global {
    interface Window {
        __REDUX_DEVTOOLS_EXTENSION_COMPOSE__?: typeof compose;
    }
}

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const rootReducer = combineReducers({
    auth: authenticationReducer,
    app: applicationReducer,
    theme: setGlobalTheme
})

export const store = createStore(
    rootReducer,
    composeEnhancers(applyMiddleware(thunk))
);
