import { Box, Grid, Table, TableContainer } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { permissionList } from "../../helpers/permCheckers";
import { setComponents, setSnack } from "../../models/application/applicationActions";
import { Issue } from "../../models/issue/Issue";
import { issueService } from "../../models/issue/service";
import IssuesFormDialog from "../Dialogs/IssuesFormDialog";
import MainTableHeaderBodyContainer from "../MainTableHeaderBodyContainer";

const IssuesTable = (props: any) => {
  const {
    setSnack,
    app,
    auth,
    setComponents,
    tableName,
    handleSubmit,
    tableList,
    handleOrder,
    handleOnToggle,
    handleOnChange,
    selectedFilters,
    filterItems,
    tableStructureList,
  } = props;

  const [componentPer] = useState(permissionList(auth, "Component"));
  const localList = JSON.parse(localStorage.getItem(tableName) || "{}");
  const [cachedList, setCachedList] = useState<{ key: string; list: any[] }>({ key: "", list: [] });
  const [issue, setIssue] = useState<Issue | null>(null);
  const [open, setOpen] = useState(false);
  const location = useLocation();
  const [filteredList, setFilteredList] = useState<any[]>(Object.keys(localList).length ? localList : tableStructureList);

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const getOverallTrueFalseFromFilteredList = () => {
    return filteredList.filter((e) => e.enabled === true).length ? true : false;
  };

  const handleCachedList = (item: { key: string; list: any[] }) => {
    setCachedList(item);
  };

  const handleEdit = (state: boolean, item: any) => {
    setOpen(state);
    setIssue(item);
  };

  const handleFilteredList = (state: any[]) => {
    setFilteredList(state);
  };

  return (
    <Grid item overflow={"overlay"}>
      <TableContainer>
        <Table sx={{ width: getOverallTrueFalseFromFilteredList() ? "100%" : "fit-content" }} stickyHeader={true}>
          <MainTableHeaderBodyContainer
            padding="checkbox"
            size="small"
            align="center"
            handleEdit={handleEdit}
            filterItems={filterItems}
            headerList={filteredList}
            handleOrder={handleOrder}
            handleOnToggle={handleOnToggle}
            handleCachedList={handleCachedList}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            cachedList={cachedList}
            handleFilteredList={handleFilteredList}
            handleSubmit={handleSubmit}
            updater={new Issue()}
            service={issueService}
            copySuccessMessage={"Id copied successfully"}
            tableList={tableList}
            tableName={tableName}
            pathname={`issueDetails`}
          />
        </Table>
      </TableContainer>
      <IssuesFormDialog
        setSubmitted={handleSubmit}
        setComponents={setComponents}
        onAlert={onAlert}
        item={issue!}
        open={open}
        handleDialog={(bool: any) => setOpen(bool)}
        componentPer={componentPer}
      />
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { setSnack, setComponents })(IssuesTable);
