import { TabContext, TabList, TabPanel } from "@mui/lab";
import { Box, createTheme, Grid, Tab, ThemeProvider } from "@mui/material";
import { SyntheticEvent, useEffect, useLayoutEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { UserProfileSection } from "../../Components/User/UserProfileSection";
import { ReduxState } from "../../environment/environment";
import { userProfileService } from "../../models/user/service";
import { User } from "../../models/user/user";
import CartOrderPage from "../CartOrder/CartOrderPage";
import EngagedProductsLogsPage from "../EngagedProductsLogs/EngagedProductsLogsPage";
import LogsPage from "../Logs/LogsPage";
import ProductPromotionPage from "../ProductPromotion/ProductPromotionPage";
import ProductReviewPage from "../ProductReview/ProductReviewPage";
import ProductsSummaryPage from "../Products/ProductsSummary";
import ProductSavesPage from "../ProductSaves/ProductSavesPage";
import ServiceReviewPage from "../ServiceReview/ServiceReviewPage";
import UserCommentsPage from "../UserComments/UserCommentsPage";

const UsersDetailsPage = (props: any) => {
  const [userProfile, setUserProfile] = useState<User | null>();
  const [value, setValue] = useState("logs");
  const [constantWidth, setConstantWidth] = useState(0);
  const [submitted, setSubmitted] = useState(false);

  const location = useLocation();
  const history = useHistory();

  const handleChange = (event: SyntheticEvent, newValue: string) => {
    setValue(newValue);
  };

  const loadUserProfile = (id: string | null) => {
    userProfileService.read(id as string).then((profile) => {
      setUserProfile(profile);
    });
  };

  useLayoutEffect(() => {
    setConstantWidth(window.screen.width);

    return () => setConstantWidth(0);
  }, []);

  useEffect(() => {
    loadUserProfile(location.pathname.split("/")[2]);
  }, [location.pathname, submitted]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    const value: string = query.get("tab")!;
    setValue(value);
  }, [location]);

  const tabList = [
    { component: <LogsPage />, value: "logs" },
    { component: <ProductsSummaryPage />, value: "products_summary" },
    { component: <EngagedProductsLogsPage />, value: "engaged_products" },
    { component: <UserCommentsPage />, value: "user_comments" },
    { component: <ProductSavesPage />, value: "product_saves" },
    { component: <ProductPromotionPage />, value: "product_promotion" },
    { component: <CartOrderPage />, value: "cart_order" },
    { component: <ServiceReviewPage />, value: "service_review" },
    { component: <ProductReviewPage />, value: "product_review" },
  ];
  const tabValueList = [
    { name: "Logs", id: "logs", sort: "created" },
    { name: "Products", id: "products_summary", sort: "uploaded" },
    { name: "Engaged Products", id: "engaged_products", sort: "created" },
    { name: "User Comments", id: "user_comments", sort: "date" },
    { name: "Product Saves", id: "product_saves", sort: "date" },
    { name: "Product Promotion", id: "product_promotion", sort: "created" },
    { name: "Cart Order", id: "cart_order", sort: "created" },
    { name: "Service Review", id: "service_review", sort: "created" },
    { name: "Product Review", id: "product_review", sort: "created" },
  ];

  const localTheme = createTheme({
    palette: {
      mode: props.mode,
    },
  });

  return (
    <ThemeProvider theme={localTheme}>
      <Grid
        container
        sx={{
          flexDirection: "row",
          display: "flex",
          flex: 1,
          height: "calc(100vh - 120px)",
          width: "100vw",
          background: "bacground.default",
        }}
      >
        <Grid
          item
          sx={{
            flex: 1,
            height: "100%",
            backgroundColor: "background.default",
            width: `${(constantWidth / 100) * 22.5}px)`,
            maxWidth: `${(constantWidth / 100) * 22.5}px)`,
            borderRight: 1.5,
            borderColor: "text.primary",
          }}
        >
          <UserProfileSection userProfile={{ ...userProfile, isActive: true }} userProfileUpdateHandler={() => setSubmitted((prev) => !prev)} />
        </Grid>
        <Grid
          item
          sx={{
            width: `calc(100vw - ${(constantWidth / 100) * 22.5}px)`,
            maxWidth: `calc(100vw - ${(constantWidth / 100) * 22.5}px)`,
            height: "100%",
          }}
        >
          <Grid container item lg={12} height="100%">
            <TabContext value={value}>
              <Box
                sx={{
                  borderBottom: 1,
                  borderTop: 1,
                  borderColor: "gray",
                  width: "100%",
                  height: "fit-content",
                  color: "text.primary",
                  backgroundColor: "background.default",
                }}
              >
                <TabList
                  onChange={handleChange}
                  aria-label="lab API tab example"
                  textColor="inherit"
                  sx={{
                    ".MuiTabs-indicator": {
                      backgroundColor: "text.primary",
                    },
                  }}
                >
                  {tabValueList.map((item, index) => (
                    <Tab
                      key={index}
                      sx={{
                        fontSize: "12px",
                        "&.MuiButtonBase-root": {
                          padding: "0px 15px",
                          width: "40px",
                        },
                      }}
                      label={item.name}
                      value={item.id}
                      onClick={() => {
                        history.push({
                          search: location.search
                            .split("&")
                            .map((itm) => (itm.split("=")[0] === "tab" ? [`tab=${item.id}`] : itm.split("=")[0] === "sort" ? [`sort=${item.sort}`] : itm))
                            .join("&"),
                        });
                      }}
                    />
                  ))}
                </TabList>
              </Box>
              {tabList.map((item, index) => (
                <TabPanel key={index} value={item.value} sx={{ width: "100%", height: "calc(100% - 50px)", padding: "0" }}>
                  {item.component}
                </TabPanel>
              ))}
            </TabContext>
          </Grid>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { app } = state;
  return {
    mode: app.currentTheme,
  };
};

export default connect(mapStateToProps)(UsersDetailsPage);
