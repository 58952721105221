import { nameIdSerializer } from './../serializer';
import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { userSerializer } from "../user/serializer";
import { Product, ProductOverride } from "./Product";
import { brandSerializer } from '../Brands/serializer';
import { IDNAMESerializer } from '../filter/serializer';

class ProductSerializer implements Serializer {
    fromJson(json: any) {
        const product = new Product();

        product.brandName = json.brand_name
        if (json.brand) {
            product.brand = brandSerializer.fromJson(json.brand).name
            product.brandId = brandSerializer.fromJson(json.brand).id
        }
        product.name = json.name
        if (json.condition)
            product.condition = nameIdSerializer.fromJson(json.condition)
        product.country = json.country
        product.id = json.id
        product.image = json.main_file_name || json.image
        product.images = json.images
        product.created = json.created_at || json.created
        if (json.product_user)
            product.productUser = userSerializer.fromJson(json.product_user)
        product.description = json.description
        product.section = json.section
        if (json.category)
            product.category = nameIdSerializer.fromJson(json.category)
        if (json.subcategory)
            product.subcategory = nameIdSerializer.fromJson(json.subcategory)
        if (json.section)
            product.section = nameIdSerializer.fromJson(json.section)
        if (json.color)
            product.color = nameIdSerializer.fromJson(json.color)
        if (json.size)
            product.size = nameIdSerializer.fromJson(json.size)
        product.department = json.department
        product.ownerPrice = json.owner_price
        product.price = json.price
        product.specialPrice = json.special_price
        if (json.user) {
            product.ownerEmail = userSerializer.fromJson(json.user).email
            product.ownerName = userSerializer.fromJson(json.user).name ? userSerializer.fromJson(json.user).name : json.user
            product.ownerPhone = userSerializer.fromJson(json.user).phone
        }
        product.isEnabled = json.is_enabled === false ? "Disabled" : "Enabled"
        product.stock = json.stock
        product.ownerManager = json.user_manager

        return product;
    }
    toJson(resource: Resource) {
        return resource
    }

}

export const productSerializer = new ProductSerializer()

export class ProductOverrideSerializer implements Serializer {
    fromJson(json: any) {
        return json
    }

    toJson(resource: ProductOverride) {
        const obj: any = {}
        if (resource.id)
            obj.product_id = resource.id
        if (resource.name)
            obj.name = resource.name
        if (resource.brand)
            obj.brand = resource.brand
        if (resource.price)
            obj.price = resource.price
        if (resource.specialPrice)
            obj.special_price = resource.specialPrice
        if (resource.description)
            obj.description = resource.description
        if (resource.stock)
            obj.stock = resource.stock
        if (resource.country)
            obj.country = resource.country
        if (resource.isEnabled)
            obj.is_enabled = resource.isEnabled
        if (resource.ownerPrice)
            obj.owner_price = resource.ownerPrice
        if (resource.section)
            obj.section = resource.section
        if (resource.condition)
            obj.condition = resource.condition
        if (resource.category)
            obj.category = resource.category
        if (resource.subcategory)
            obj.subcategory = resource.subcategory
        if (resource.size)
            obj.size = resource.size
        if (resource.color)
            obj.color = resource.color
        return obj
    }
}

export const productOverrideSerializer = new ProductOverrideSerializer()