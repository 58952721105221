import DeleteIcon from "@mui/icons-material/Delete";
import { Box, Button, createTheme, Grid, GridProps, styled, ThemeProvider, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { FilterHeader } from "../../Components/Filter/FilterHeader";
import Pagination from "../../Components/Pagination";
import SearchDateFilter from "../../Components/SearchDateFilter";
import OrdersTable from "../../Components/Tables/OrdersTable";
import { filterLists, ordersStructureList, ReduxState } from "../../environment/environment";
import { useDidMountEffect } from "../../helpers/customHooks";
import { QueryOptions } from "../../helpers/query.options";
import { generateParamsFromUrl, urlChanger } from "../../helpers/utilities";
import { setSnack } from "../../models/application/applicationActions";
import { brandService } from "../../models/Brands/service";
import { Order, OrderResponse } from "../../models/Order/Order";
import { orderService } from "../../models/Order/service";
import { register } from "../../models/user/authActions";
import { userProfileConciseService } from "../../models/user/service";

const ResponsiveGrid = styled((props: GridProps) => <Grid {...props} />)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  overflow: "auto",
  // [theme.breakpoints.down("sm")]: {
  //   flexDirection: "row",
  // }
}));

const OrdersPage = (props: any) => {
  const { palette, mode } = props;
  const history = useHistory();
  const location = useLocation();
  const pageName = "orders";
  const [options, setOptions] = useState<any>(new QueryOptions());
  const [orderList, setOrderList] = useState<Order[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<any[]>([]);
  const [submitted, setSubmitted] = useState(false);
  const [count, setCount] = useState(1);
  const [urlValues, setUrlValues] = useState({
    pageIndex: 1,
    pageSize: 30,
    sort: "created",
    search: "",
    order: "desc",
    created_from: null,
    created_to: null,
  });
  const [filterSearch, setFilterSearch] = useState<any>([
    { id: "user", searchValue: "", service: userProfileConciseService },
    { id: "seller", searchValue: "", service: userProfileConciseService },
    { id: "brand", searchValue: "", service: brandService },
  ]);

  const handleOrder = (state: string) => {
    urlChanger(history, pageName, "order", state);
  };

  useDidMountEffect(() => {
    if (options) {
      getOrders(options);
    }
  }, [JSON.stringify(options), submitted]);

  const handleUrlValues = (setBy: string, value: unknown) => {
    if (typeof value === "string" || typeof value === "number") {
      setUrlValues((prev) => {
        return { ...prev, [setBy]: value };
      });
    }
  };

  const handleOnChange = (item: any) => {
    urlChanger(history, pageName, item.key, item.value.length ? item.value : "", 1);
  };
  const handleFilterDelete = (item: any) => {
    let loc: any = window.location.search;
    loc = loc
      .split("&")
      .filter((r: any) => r.split("=")[1].indexOf(item.id) > -1 && r.split("=")[0].indexOf(item.key) > -1)
      .toString()
      .split("=")[1]
      .split(",")
      .filter((itm: any) => itm !== item.id)
      .toString();
    urlChanger(history, pageName, item.key, loc, 1);
  };

  const handleOnToggle = (items: any[], name: string) => {
    let key = "";
    let params = "";
    if (items.length) {
      key = items[0].key;
      params = items.map((item) => item.id).toString();
      urlChanger(history, pageName, key, params, 1);
    } else {
      urlChanger(history, pageName, items[0].key, items[0].id, 1);
    }
  };

  const clearFilterRedirect = () => {
    let loc = location.search;
    let arrParams = loc
      .split("&")
      .filter((item) => filterLists[pageName].toString().indexOf(item.split("=")[0]) === -1)
      .join("&");
    history.push({ pathname: pageName, search: arrParams });
  };

  useEffect(() => {
    let arr: any = [];
    const params = generateParamsFromUrl(location, pageName);
    Object.entries(params).forEach(([key, value]) => {
      if (filterLists[pageName].filter((e: string) => e === key).length) {
        if (typeof value === "string") {
          value.split(",").forEach((item) => {
            arr.push({ id: item, name: item, key: key });
          });
        }
        if (key === "search" || key === "created_from" || key === "created_to" || key === "birth_date" || key === "pageIndex") {
          handleUrlValues(key, value ? value : "");
        }
        if (key === "user") {
          setFilterSearch((prev: any) => {
            return [
              ...prev.filter((itm: any) => itm.id !== key),
              {
                id: key,
                searchValue: value as string,
                service: prev.filter((itm: any) => itm.id === key)[0].service,
              },
            ];
          });
        }
        if (key === "seller") {
          setFilterSearch((prev: any) => {
            return [
              ...prev.filter((itm: any) => itm.id !== key),
              {
                id: key,
                searchValue: value as string,
                service: prev.filter((itm: any) => itm.id === key)[0].service,
              },
            ];
          });
        }
        if (key === "brand") {
          setFilterSearch((prev: any) => {
            return [
              ...prev.filter((itm: any) => itm.id !== key),
              {
                id: key,
                searchValue: value as string,
                service: prev.filter((itm: any) => itm.id === key)[0].service,
              },
            ];
          });
        }
      }
      if (key === "pageIndex") {
        handleUrlValues(key, value ? value : "");
      }
    });

    setSelectedFilters(arr);
    setOptions(params);
  }, [location]);

  const getOrders = (options: QueryOptions) => {
    orderService.listWithCounter(options).then((r: OrderResponse) => {
      setOrderList(r.current);
      setCount(r.count);
    });
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
    typography: {
      fontSize: 13,
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 13,
          },
        },
      },
    },
  });

  const theme = useTheme();

  return (
    <ThemeProvider theme={localTheme}>
      <ResponsiveGrid container direction={"row"} alignContent={"flex-start"}>
        <Grid container item lg={12} md={12} xs={12} padding={"20px"}>
          <SearchDateFilter
            setSearch={(item: any) => {
              if (history.location.search.indexOf("search") > -1) {
                urlChanger(history, pageName, "search", item.searchValue, 1);
              } else if (history.location.search.indexOf("search") < 0 && item.searchValue.length) {
                urlChanger(history, pageName, "search", item.searchValue, 1);
              }
            }}
            setFrom={(item: any) => urlChanger(history, pageName, "created_from", item)}
            setTo={(item: any) => urlChanger(history, pageName, "created_to", item)}
            search={urlValues.search}
            from={urlValues.created_from}
            to={urlValues.created_to}
          >
            <></>
            {selectedFilters?.length > 0 && (
              <Button
                sx={{
                  margin: "0px 20px",
                  [theme.breakpoints.down("sm")]: {
                    width: "80vw",
                    margin: 0,
                  },
                }}
                onClick={() => {
                  clearFilterRedirect();
                }}
                variant="contained"
                color="secondary"
                startIcon={<DeleteIcon />}
              >
                ფილტრის მოხსნა
              </Button>
            )}
          </SearchDateFilter>
        </Grid>
        <Grid item xs={12} lg={12} sx={{ minHeight: "42px", padding: "0px 20px" }}>
          {selectedFilters?.length > 0 && <FilterHeader items={[...selectedFilters]} handleDelete={handleFilterDelete} />}
        </Grid>

        <Grid item xs={12} lg={12} sx={{ padding: "0px 20px", width: "100%" }}>
          <OrdersTable
            tableName={"ordersTable"}
            handleSubmit={setSubmitted}
            tableList={orderList}
            handleOrder={handleOrder}
            handleOnToggle={handleOnToggle}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            tableStructureList={ordersStructureList}
            options={options}
            filterSearch={filterSearch}
            setFilterSearch={setFilterSearch}
          />
          {count && (
            <Pagination
              count={count}
              pageSize={urlValues.pageSize}
              setPageIndex={(item: any) => {
                handleUrlValues("pageIndex", item);
                urlChanger(history, pageName, "pageIndex", `${item}`);
              }}
              pageIndex={urlValues.pageIndex}
              palette={palette}
            />
          )}
        </Grid>
      </ResponsiveGrid>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    palette: state.theme.palette,
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps, { register, setSnack })(OrdersPage);
