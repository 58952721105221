import { AccountCircle, StarBorder } from "@mui/icons-material";
import { Grid, Rating } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { environment } from "../../environment/environment";
import { User } from "../../models/user/user";
import { TypographyItem } from "../Typography/TypographyItem";

interface UserProfileProps {
  userProfile?: User;
}

export const UserProfileUserBaseInfo = (props: UserProfileProps) => {
  const { userProfile } = props;
  const [image, setImage] = useState<string | any>(null);

  useEffect(() => {
    if (userProfile?.id) {
      setImage(userProfile.image);
    } else {
      setImage(null);
    }
  }, [userProfile?.id]);

  const RenderRating = useCallback(() => {
    return (
      <Rating
        precision={0.5}
        name="size-medium"
        value={userProfile?.averageRating}
        sx={{ pointerEvents: "none", color: "#e53f25", outlineColor: "#e53f25", fontSize: "30px" }}
        size={"large"}
        emptyIcon={<StarBorder fontSize="inherit" sx={{ color: "#e53f25" }} />}
      />
    );
  }, [userProfile]);

  return (
    <Grid item sx={{ minWidth: "50%", width: "auto" }} flex={7} display="flex" flexDirection="column" alignItems="center">
      <Link
        onClick={(e) => (!userProfile?.isActive ? e.preventDefault() : () => {})}
        target={"_blank"}
        to={{ pathname: `/userDetails/${userProfile?.id}`, search: environment.baseQueryParams + "&tab=logs" }}
        style={{ width: "100%", height: "100%", cursor: "pointer", textDecoration: "none" }}
      >
        <Grid item sx={{ width: "100%" }} flex={5.5} display="flex" flexDirection={"column"} justifyContent="center" alignItems={"center"}>
          <Grid
            item
            sx={{
              width: "100px",
              height: "100px",
              background: "grey",
              borderRadius: "50px",
              contain: "content",
            }}
          >
            {image ? (
              <img
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
                onError={() => {
                  setImage(null);
                }}
                src={image}
                alt=""
              />
            ) : (
              <AccountCircle style={{ fontSize: "100px", color: "pink" }} />
            )}
          </Grid>
        </Grid>
        <Grid container item sx={{ width: "100%" }} display="flex" flexDirection="column" alignItems="center" justifyContent="space-around" flex={4.5}>
          <Grid item>
            <TypographyItem sx={{ wordBreak: "break-word" }} fontSize={21} text={userProfile?.username} fontWeight="500" />
          </Grid>
          {userProfile?.name && (
            <Grid item>
              <TypographyItem sx={{ wordBreak: "break-word" }} fontSize={20} text={`${userProfile?.name}`} fontWeight="500" />
            </Grid>
          )}
          <Grid item>
            <RenderRating />
          </Grid>
        </Grid>
      </Link>
    </Grid>
  );
};
