import { createTheme, PaletteMode, SxProps, TableBody, TableCell, TableCellProps, TableHead, TableRow, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { cloneElement, isValidElement } from "react";
import { connect } from "react-redux";
import { App, ReduxState } from "../environment/environment";
import { setSnack } from "../models/application/applicationActions";
import { Resource } from "../models/resource";
import { ResourceProvider } from "../providers/resource.provider";
import Tablebody from "./Tablebody";
import TableHeader from "./TableHeader";

interface MainTableHeaderBodyContainerProps extends TableCellProps {
  app?: App;
  mode?: PaletteMode;
  sx?: SxProps;
  headerList?: unknown[];
  handleFilteredList?: (state: unknown[]) => void;
  tableName?: string;
  copyToolVisible?: boolean;
  editToolVisible?: boolean;
  detailsLinkToolVisible?: boolean;
  handleOrder?: (state: string) => void;
  filterItems?: any[];
  handleOnToggle?: (items: any[]) => void;
  handleCachedList?: (item: { key: string; list: any[] }) => void;
  handleEdit?: (state: boolean, item: never) => void;
  children?: JSX.Element;
  updater?: Resource;
  service?: ResourceProvider<Resource>;
  handleSubmit?: unknown;
  handleOnChange?: (item: unknown) => void;
  tableList?: unknown[];
  cachedList?: { key: string; list: any[] };
  selectedFilters?: unknown[];
  filterSearch?: unknown[];
  filterSearchHandler?: (state: object) => void;
  copySuccessMessage?: string;
  dispatch?: unknown;
  pathname?: string;
  search?: string;
  iconSx?: SxProps;
  setSnack: any;
  customTableCellItems?: { title: string; element: JSX.Element }[];
}

const MainTableHeaderBodyContainer = (props: MainTableHeaderBodyContainerProps) => {
  const {
    app,
    sx,
    headerList,
    handleFilteredList = () => {},
    tableName,
    copyToolVisible = true,
    editToolVisible = true,
    detailsLinkToolVisible = true,
    handleOrder = () => {},
    filterItems,
    handleOnToggle = () => {},
    handleCachedList = () => {},
    handleEdit = () => {},
    updater,
    service,
    handleSubmit = () => {},
    handleOnChange = () => {},
    tableList,
    cachedList,
    selectedFilters,
    filterSearch,
    filterSearchHandler = () => {},
    copySuccessMessage,
    mode,
    dispatch,
    search,
    pathname,
    iconSx = {
      "&.MuiSvgIcon-root": {
        fontSize: 20,
      },
    },
    customTableCellItems,
    setSnack,
    ...rest
  } = props;

  const onAlert = (text: string) => setSnack({ ...app!.snack, open: true, text: text });

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
    components: {
      MuiTableBody: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? grey[800] : grey[200],
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? grey[900] : grey[600],
          },
        },
      },
    },
  });

  return (
    <ThemeProvider theme={localTheme}>
      <TableHead>
        <TableRow>
          <TableHeader
            setOrder={handleOrder}
            headerList={headerList}
            filterItems={filterItems}
            onToggle={handleOnToggle}
            setCachedList={handleCachedList}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            cachedList={cachedList}
            sx={sx}
            setFilterSearch={filterSearchHandler}
            filterSearch={filterSearch}
            handleFilteredList={handleFilteredList}
            tableName={tableName}
            copyToolVisible={copyToolVisible}
            editToolVisible={editToolVisible}
            detailsLinkToolVisible={detailsLinkToolVisible}
            props={rest}
          />
          {customTableCellItems?.map((itm, index) => (
            <TableCell
              key={index}
              align="center"
              padding="normal"
              size="small"
              sx={{
                fontWeight: "bold",
                color: "white",
              }}
            >
              {itm.title}
            </TableCell>
          ))}
        </TableRow>
      </TableHead>
      <TableBody>
        {tableList?.map((item: any, index: number) => (
          <TableRow key={index}>
            <Tablebody
              pathname={pathname}
              search={search}
              tableItem={item}
              dependencyList={headerList}
              onAlert={onAlert}
              setSubmitted={handleSubmit}
              updater={updater}
              service={service}
              item={item}
              detailsLinkToolVisible={detailsLinkToolVisible}
              copyToolVisible={copyToolVisible}
              editToolVisible={editToolVisible}
              handleEdit={handleEdit}
              copySuccessMessage={copySuccessMessage}
              props={rest}
              iconSx={iconSx}
            />
            {customTableCellItems?.map((itm, index) => {
              if (isValidElement(itm.element)) {
                return (
                  <TableCell
                    key={index}
                    {...rest}
                    sx={{
                      fontWeight: "bold",
                    }}
                  >
                    {cloneElement(itm.element as JSX.Element, { ...item.addresses, item, handleEdit })}
                  </TableCell>
                );
              }
            })}
          </TableRow>
        ))}
      </TableBody>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  const { app } = state;
  return {
    mode: app.currentTheme,
    app: app,
  };
};

export default connect(mapStateToProps, { setSnack })(MainTableHeaderBodyContainer);
