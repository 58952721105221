import { Resource } from "../resource"


export class Status extends Resource {
    description: string| any
    name:        string| any
    uid:        string| any
}
export class Version extends Resource {
    name: string | any
    uid:  string| any
    created: Date | any
    startDate: Date | any
    releaseDate: Date | any
    progress: string | any
    is_enabled: Boolean | any
    version_android: string | any
    version_ios: string | any
}


