import { Button, createStyles, makeStyles, ThemeProvider } from "@material-ui/core";
import ListItem from "@material-ui/core/ListItem";
import ListItemText from "@material-ui/core/ListItemText";
import DeleteIcon from "@material-ui/icons/Delete";
import EditIcon from "@material-ui/icons/Edit";
import { Box, Grid, Tooltip } from "@mui/material";
import { Fragment, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { ConfirmationDialog } from "../../Components/Dialogs/ConfirmationDialog";
import IssuesFormDialog from "../../Components/Dialogs/IssuesFormDialog";
import { environment } from "../../environment/environment";
import { Image, ImageGroup } from "../../FullScreenImages/FullScreenImage";
import { permissionList, permValid } from "../../helpers/permCheckers";
import { generateParamsFromUrl, urlifyForTable } from "../../helpers/utilities";
import { setComponents, setSnack } from "../../models/application/applicationActions";
import { Issue } from "../../models/issue/Issue";
import { issueService } from "../../models/issue/service";
import { register } from "../../models/user/authActions";
const TaskDetailsPage = (props: any) => {
  const ListStyles = makeStyles(() =>
    createStyles({
      root: {
        width: "100%",
        height: "calc(100vh - 120px)",
        backgroundColor: palette.background.default,
        position: "absolute",
        display: "flex",
      },
    })
  );

  const { auth, app, palette, muiTheme, setSnack } = props;

  const { id } = useParams<any>();

  const location: any = useLocation();

  const [issuePer] = useState(permissionList(auth, "Issue"));
  const [componentPer] = useState(permissionList(auth, "Component"));
  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [issue, setIssue] = useState<Issue | any>();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<any>({ text: "", open: false, title: "", issue: null });
  const [issues, setIssues] = useState<Issue[]>([]);
  const classes = ListStyles();
  const [image, setImage] = useState<any>(null);

  const uploadInputRef: any = useRef(null);

  const handleDialog = (val: boolean) => {
    setOpen(val);
    setTrigger(!trigger);
  };

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const onReload = () => setCounter(counter + 1);

  const handleDelete = (issue: Issue) => {
    setConfirmation({ text: "ნამდვილად გსურთ წაშლა?", open: true, title: "საკითხის წაშლა", issue });
  };

  const loadIssue = (id: string) => {
    issueService.read(id).then((iss: Issue) => {
      setIssue(iss);
    });
  };
  const getIssues = () => {
    issueService.listWithCounter(getParams()).then((r: any) => {
      setIssues(r.current);
    });
  };

  const getParams = () => {
    if (location.search) {
      const params = generateParamsFromUrl(location, "tasks");
      return params;
    } else return null;
  };

  useEffect(() => {
    if (id) {
      loadIssue(id);
    }

    getIssues();
    //eslint-disable-next-line
  }, [trigger, id]);

  const onDrop = (event: any) => {
    const [file] = event.target.files;
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        setImage(URL.createObjectURL(file));

        issueService.update({ ...issue, images: file }).then((r: Issue) => {});
      }
    }
  };

  return (
    <Box className={classes.root}>
      {issue && (
        <SimpleBar
          style={{
            height: "100%",
            width: "360px",
            backgroundColor: palette.background.default,
            borderRight: "solid 2px grey",
          }}
        >
          <ConfirmationDialog
            open={confirmation.open}
            text={confirmation.text}
            title={confirmation.title}
            callback={(c: any) => {
              if (c) {
                issueService.delete(confirmation?.issue?.id).then((r: Issue) => {
                  onAlert(`საკითხი ${confirmation?.issue?.name} წაიშალა წარმატებით::success`);
                  onReload();
                  window.location.href = "/issues";
                });
              }
              setConfirmation({ text: "", open: false, title: "", issue: null });
            }}
          />
          <Grid
            aria-label="contacts"
            style={{
              height: "auto",
              background: palette.background.default,
              color: palette.text.primary,
            }}
          >
            {[...issues].map((item, index) => {
              return (
                <ThemeProvider key={index} theme={muiTheme}>
                  <Link
                    style={{ textDecoration: "none", width: "100%", height: "100%", color: palette.text.primary }}
                    to={{
                      pathname: `/issueDetails/${item.id}?${(location.search || location.state.url).split("?")[1]}`,
                      state: { url: location.search || location.state.url },
                    }}
                  >
                    <ListItem selected={item.id === issue.id ? true : false}>
                      <ListItemText
                        style={{ cursor: "pointer" }}
                        primary={item.name}
                        secondary={item.created}
                        onClick={() => {
                          setIssue(item);
                        }}
                      />
                    </ListItem>
                  </Link>
                </ThemeProvider>
              );
            })}
          </Grid>
        </SimpleBar>
      )}
      <Grid
        container
        sx={{
          width: "100%",
          margin: "0px 50px 0px 50px",
          display: "flex",
          overflow: "auto",
        }}
      >
        <Grid
          item
          xs={9}
          lg={9}
          sx={{
            padding: "20px",
            color: palette.text.primary,
          }}
        >
          <h2 style={{ textAlign: "left" }}>{issue?.name}</h2>
          <h5 style={{ textAlign: "left" }}>{issue?.description ? urlifyForTable(issue?.description) : ""}</h5>
          <div style={{ textAlign: "left" }}>
            <span style={{ fontWeight: "400" }}>ავტორი: </span>
            <span style={{ fontWeight: "bold" }}>
              {issue?.user.firstName} {issue?.user.lastName}
            </span>
          </div>
          <div style={{ textAlign: "left" }}>
            <span style={{ fontWeight: "400" }}>ტიპი: </span>
            <span style={{ fontWeight: "bold" }}>{issue?.type.name}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            <span style={{ fontWeight: "400" }}>კატეგორია: </span>
            <span style={{ fontWeight: "bold" }}>{issue?.category.name}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            <span style={{ fontWeight: "400" }}>პროექტი: </span>
            <span style={{ fontWeight: "bold" }}>{issue?.project.name}</span>
          </div>
          <div style={{ textAlign: "left" }}>
            <span style={{ fontWeight: "400" }}>კომპონენტი: </span>
            <span style={{ fontWeight: "bold" }}>{issue?.component.name}</span>
          </div>
          <div style={{ width: "100%", padding: 40 }}>
            <h3>სურათები</h3>
            {issue?.images !== null && (
              <ImageGroup>
                <ul className="images" style={{ display: "grid", gridTemplateColumns: "auto auto auto" }}>
                  {issue?.images?.map((img: any, index: number) => (
                    <li key={index}>
                      <Image style={{ width: "200px" }} src={environment.IssueImages + img.filename} alt="issue" />
                    </li>
                  ))}
                </ul>
              </ImageGroup>
            )}
            <img src={image} style={{ width: "100%" }} alt="issue-pic" />
          </div>
        </Grid>
        <Grid
          item
          style={{
            padding: 20,
            alignItems: "flex-start",
            display: "flex",
            flexDirection: "column",
            height: "180px",
            justifyContent: "space-between",
          }}
        >
          <Fragment>
            <input ref={uploadInputRef} type="file" accept="image/*" style={{ display: "none" }} onChange={onDrop} />
            <Button
              onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
              style={{
                background: palette.background.paperLight,
                color: palette.text.primary,
              }}
              variant="contained"
            >
              Upload
            </Button>
          </Fragment>
          <Tooltip title={!permValid(issuePer, "update") ? "disabled" : ""}>
            <Button
              onClick={() => {
                permValid(issuePer, "update") && setIssue(issue);
                permValid(issuePer, "update") && handleDialog(true);
              }}
              style={{
                background: permValid(issuePer, "update") ? palette.background.paperLight : "lightgrey",
              }}
              variant="contained"
            >
              <EditIcon
                style={{
                  cursor: "pointer",
                  color: permValid(issuePer, "update") ? palette.text.primary : "grey",
                }}
              />
            </Button>
          </Tooltip>

          <Tooltip title={!permValid(issuePer, "delete") ? "disabled" : ""}>
            <Button
              onClick={() => permValid(issuePer, "delete") && handleDelete(issue)}
              style={{
                background: permValid(issuePer, "delete") ? palette.background.paperLight : "lightgrey",
              }}
              variant="contained"
            >
              <DeleteIcon
                style={{
                  cursor: "pointer",
                  color: permValid(issuePer, "delete") ? palette.text.primary : "grey",
                }}
              />
            </Button>
          </Tooltip>
        </Grid>
        <IssuesFormDialog
          setSubmitted={() => {}}
          setComponents={setComponents}
          onAlert={onAlert}
          item={issue}
          open={open}
          handleDialog={handleDialog}
          componentPer={componentPer}
        />
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { register, setSnack, setComponents })(TaskDetailsPage);
