import { novaPoshtaSerialier } from './../NovaPoshta/serialzer';

import { nameIdSerializer, Serializer } from "../serializer";
import { Address, Location } from "./Address";

export class AddressSerializer implements Serializer {
  fromJson(json: any) {
    const address = new Address();
    address.addressName = json.address_name
    address.apartment = json.apartment
    address.buildingId = json.building_id
    address.city = json.city
    address.comment = json.comment
    if (json.country) {
      address.country = nameIdSerializer.fromJson(json.country)
    }
    address.entrance = json.entrance
    address.firstName = json.first_name
    address.floor = json.floor
    address.googlePlaceId = json.google_place_id
    address.isMain = json.is_main
    address.lastName = json.last_name
    if (json.location) {
      address.location = locationSerializer.fromJson(json.location)
    }
    if (address.novaPoshtaWarehouse)
      address.novaPoshtaWarehouse = novaPoshtaSerialier.fromJson(json.nova_poshta_warehouse)
    address.personalId = json.personal_id
    address.phone = json.phone
    address.postalCode = json.postal_code
    address.recipient = json.recipient
    address.street = json.street
    address.type = json.type
    address.uid = json.uid
    return address
  }

  toJson(address: Address) {
    const obj: any = {};

    if (address.uid) {
      obj.uid = address.uid
    }
    if (address.appUser) {
      obj.app_user = address.appUser
    }
    if (address.addressName) {
      obj.address_name = address.addressName
    }
    if (address.comment) {
      obj.comment = address.comment
    }
    if (address.floor) {
      obj.floor = address.floor
    }
    if (address.buildingId) {
      obj.building_id = address.buildingId
    }
    if (address.street) {
      obj.street = address.street
    }
    if (address.city) {
      obj.city = address.city
    }
    if (address.phone) {
      obj.phone = address.phone
    }
    if (address.floor) {
      obj.floor = address.floor
    }
    if (address.apartment) {
      obj.apartment = address.apartment
    }
    if (address.googlePlaceId) {
      obj.google_place_id = address.googlePlaceId
    }
    if (address.novaPoshtaWarehouse) {
      obj.nova_poshta_warehouse_id = address.novaPoshtaWarehouse
    }
    if (address.entrance) {
      obj.entrance = address.entrance
    }
    if (address.isMain) {
      obj.is_main = address.isMain
    }
    if (address.recipient) {
      obj.recipient = address.recipient
    }
    if (address.personalId) {
      obj.personal_id = address.personalId
    }
    if (address.postalCode) {
      obj.postal_code = address.postalCode
    }
    if (address.country) {
      obj.country = address.country
    }
    if (address.firstName) {
      obj.first_name = address.firstName
    }
    if (address.lastName) {
      obj.last_name = address.lastName
    }
    obj.id = address.id
    return obj;
  }
}
export class LocationSerializer implements Serializer {
  fromJson(json: any) {
    const location = new Location();
    location.address = json.address
    location.lat = json.lat
    location.lng = json.lng
    return location
  }

  toJson(item: any) {
    const obj: any = {};
    return obj;
  }
}


export const locationSerializer = new LocationSerializer()
export const addressSerializer = new AddressSerializer();
