import { Edit, MoreVert, Visibility } from "@mui/icons-material";
import { Box, createTheme, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, PaletteMode, ThemeProvider, Typography } from "@mui/material";
import { grey } from "@mui/material/colors";
import * as React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { Auth, ReduxState } from "../environment/environment";

interface DottedMenuProps {
  versionID: string;
  testerID: string;
  versionName: string;
  mode: PaletteMode;
  auth: Auth;
}

const DottedMenu = (props: DottedMenuProps) => {
  const { versionID, testerID, versionName, mode, auth } = props;
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
  });

  return (
    <ThemeProvider theme={localTheme}>
      <Box>
        <IconButton
          aria-label="more"
          id="long-button"
          aria-controls={open ? "long-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleClick}
        >
          <MoreVert />
        </IconButton>
        <Menu
          id="long-menu"
          MenuListProps={{
            "aria-labelledby": "long-button",
          }}
          anchorEl={anchorEl}
          open={open}
          onClose={handleClose}
          PaperProps={{
            style: {
              height: "auto",
              width: "25ch",
            },
          }}
        >
          <MenuList>
            <Link
              to={`/test/edit?version=${versionID}&tester=${testerID}&name=${versionName}&evt=${"auto"}`}
              style={{ textDecoration: "none", color: "inherit", pointerEvents: auth.user.id === testerID ? "auto" : "none" }}
            >
              <MenuItem disabled={auth.user.id === testerID ? false : true}>
                <ListItemIcon>
                  <Edit fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
                <Typography variant="body2" color="secondary">
                  {auth.user.id === testerID ? "Enabled" : "Disabled"}
                </Typography>
              </MenuItem>
            </Link>
            <Link
              to={`/test/edit?version=${versionID}&tester=${testerID}&name=${versionName}&evt=${"none"}`}
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <MenuItem>
                <ListItemIcon>
                  <Visibility fontSize="small" />
                </ListItemIcon>
                <ListItemText>Details</ListItemText>
                <Typography variant="body2" color="secondary">
                  Enabled
                </Typography>
              </MenuItem>
            </Link>
          </MenuList>
        </Menu>
      </Box>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth,
    roles: state.auth.user.roles,
    app: state.app,
    palette: state.theme.palette,
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps)(DottedMenu);
