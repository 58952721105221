import { Box, Button } from "@mui/material";
import { useState } from "react";
import { CommentProfile } from "./Profiles/CommentProfile";
import { CommentsTextField } from "./TextFields/CommentsTextField";

export const ReplyComponent = ({ palette, muiTheme, id, getComments }: any) => {
  const openState = JSON.parse(sessionStorage.getItem("commentOpenState")!);

  const [open, setOpen] = useState(openState || false);

  return (
    <Box>
      <Button
        onClick={() => {
          setOpen(() => {
            sessionStorage.setItem("commentOpenState", JSON.stringify(true));
            return true;
          });
        }}
      >
        REPLY
      </Button>
      <Box style={{ display: open ? "flex" : "none", marginBottom: "30px, " }}>
        <Box style={{ width: "40px", height: "100%" }}>
          <CommentProfile margin="10px 0 0 0" />
        </Box>
        <CommentsTextField
          palette={palette}
          muiTheme={muiTheme}
          id={id}
          name="reply"
          setOpen={setOpen}
          componentIs="reply"
          getComments={getComments}
          open={open}
        />
      </Box>
    </Box>
  );
};
