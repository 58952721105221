import { Send } from "@mui/icons-material";
import CloseIcon from "@mui/icons-material/Close";
import SentimentSatisfiedAltIcon from "@mui/icons-material/SentimentSatisfiedAlt";
import { Box, Button, Popper, TextareaAutosize } from "@mui/material";
import Picker from "emoji-picker-react";
import { useEffect, useRef, useState } from "react";
import { ChatMessage, ChatMessageUpdate } from "../../models/chat/chatMessages";
import { chatMessageUpdateService, messageService } from "../../models/chat/service";

const MessageSenderForm = (props: any) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { id, uid, setEditMode, editMode, setEditText, editText, replyText, setReplyText, messageSenderUpdaterHandler } = props;
  const [chosenEmoji, setChosenEmoji] = useState<any>(null);
  const [message, setMessage] = useState("");
  const [editModeText, setEditModeText] = useState<string | any>(null);

  const onEmojiClick = (event: any, emojiObject: any) => {
    editMode ? setEditModeText((message: any) => message + "" + emojiObject.emoji) : setMessage((message) => message + "" + emojiObject.emoji);
  };
  const open = Boolean(anchorEl);
  const ud = open ? "simple-popper" : undefined;

  const myField: any = useRef(null);

  const clicked = () => {
    myField.current.focus();
  };

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };

  const scrollToBottom = () => {
    setTimeout(
      () =>
        document.getElementById("scrollView")?.scrollTo({
          top: document.getElementById("scrollView")!.scrollHeight! + 200,
          behavior: "smooth",
        }),
      200
    );
  };

  const sendMessage = () => {
    const msg = new ChatMessage();
    if (id) {
      if (message && !editMode) {
        msg.id = id;
        msg.text = message;
        if (replyText) msg.original_message = uid;
        messageService.update(msg).then((r: any) => {
          setMessage("");
          setReplyText(null);
          if (r) {
            messageSenderUpdaterHandler();
          }
        });
      }
    }
  };

  const sendEditMessage = () => {
    const msg = new ChatMessageUpdate();
    if (uid) {
      if (editModeText && editMode && editText) {
        msg.text = editModeText;
        msg.id = uid;
        chatMessageUpdateService.update(msg).then((r: any) => {
          if (r) {
            setEditText(null);
            setEditModeText(null);
            setEditMode(false);
            messageSenderUpdaterHandler();
          }
        });
      }
    }
  };

  useEffect(() => {
    const listener = (event: any) => {
      if (event.keyCode === 13 && !event.shiftKey) {
        sendMessage();
        sendEditMessage();
        event.preventDefault();
      }
    };
    document.addEventListener("keydown", listener);
    return () => {
      document.removeEventListener("keydown", listener);
    };
  }, [message, editMode, editText, editModeText]);

  useEffect(() => {
    scrollToBottom();
  }, [message]);

  useEffect(() => {
    setEditModeText(editText);
  }, [editText, editMode]);

  return (
    <Box
      onClick={clicked}
      onSubmit={() => {}}
      style={{
        display: "flex",
        alignItems: "end",
        justifyContent: "space-between",
        width: "100%",
        pointerEvents: id ? "auto" : "none",
      }}
    >
      <Box sx={{ display: "flex", width: "70%", alignItems: "center", color: "text.primary" }}>
        <Popper id={ud} open={open} anchorEl={anchorEl}>
          <Box
            sx={{
              border: 0,
              p: 1,
              bgcolor: "#fbfbfb",
              position: "relative",
              flex: 1,
              borderRadius: 2,
              backgroundColor: "text.primary",
            }}
          >
            <Button
              disabled={id ? false : true}
              style={{
                position: "absolute",
                top: 5,
                right: 5,
                border: "0px",
                backgroundColor: "transparent",
                cursor: "pointer",
              }}
              aria-describedby={id}
              onClick={handleClick}
            >
              <CloseIcon />
            </Button>

            <Box
              sx={{
                zIndex: 3,
                border: 0,
                p: 0,
                bgcolor: "background.paper",
                position: "relative",
                flex: 1,
                marginTop: 3,
              }}
            >
              <Picker onEmojiClick={onEmojiClick} />
            </Box>
          </Box>
        </Popper>
        <Box sx={{ marginRight: 1, pointerEvents: id ? "auto" : "none" }}>
          <Button aria-describedby={id} onClick={handleClick} style={{ border: "0px", backgroundColor: "transparent" }}>
            <SentimentSatisfiedAltIcon sx={{ color: "text.primary" }} />
          </Button>
          <span>{chosenEmoji?.emoji}</span>
        </Box>
        <TextareaAutosize
          onClick={() => setAnchorEl(null)}
          onChange={(e: any) => (editMode ? setEditModeText(e.target.value.split(":")[1]?.replace(" ", "")) : setMessage(e.target.value))}
          value={editMode ? `Edit: ${editModeText ? editModeText : ""}` : message}
          placeholder="Aa..."
          autoFocus={id ? true : false}
          ref={myField}
          style={{
            width: "inherit",
            marginBottom: "7px",
            outline: "none",
            border: "none",
            resize: "none",
            pointerEvents: id ? "auto" : "none",
            fontFamily: "FiraGo",
            color: "inherit",
            backgroundColor: "inherit",
          }}
          maxRows={10}
        />
      </Box>
      {editMode && (
        <CloseIcon
          sx={{ marginBottom: "5px", cursor: "pointer", color: "text.primary" }}
          onClick={() => {
            setEditMode(false);
            setEditModeText(null);
          }}
        />
      )}
      <Button
        style={{ marginRight: "10px" }}
        onClick={(evt) => {
          setAnchorEl(null);
          sendMessage();
          sendEditMessage();
        }}
      >
        <Send />
      </Button>
    </Box>
  );
};

export default MessageSenderForm;
