import { Box, Grid } from "@mui/material";

const EmptyUsernameComponent = () => {
	return (
		<Box
			id="header"
			sx={{
				height: "64px",
				width: "100%",
				display: "flex",
				alignItems: "center",
				borderBottom: "solid 1px lightgrey",
				backgroundColor: "background.default",
			}}
		>
			<Grid
				item
				container
				alignItems="center"
				xs={12}
				sx={{
					height: "fit-content",
					padding: "10px",
				}}
			>
				<Grid
					item
					sx={{
						height: "40px",
						width: "40px",
						borderRadius: "20px",
						backgroundColor: "grey",
					}}
				>
					<img src="" alt="" id="user" />
				</Grid>
				<Box
					sx={{
						minWidth: "150px",
						background: "lightgrey",
						margin: "3px",
						borderRadius: "10px",
						color: "lightgrey",
					}}
				>
					.
				</Box>
			</Grid>
		</Box>
	);
};

export default EmptyUsernameComponent;
