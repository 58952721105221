import { Box } from "@mui/material";

const EmptyMessageFlowComponent = () => {
	return (
		<Box
			sx={{ flex: "5", minHeight: "400px", backgroundColor: "background.default" }}
			style={{ overflowY: "auto" }}
		>
			<Box
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					justifyContent: "flex-end",
					padding: "7px 10px 7px 10px",
					alignItems: "center",
					boxSizing: "border-box",
				}}
			>
				<Box sx={{ display: "flex", fontSize: "0.9em", flexDirection: "column" }}>
					<Box style={{ display: "flex" }}>
						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
							}}
						></Box>
						<Box
							style={{
								margin: "0",
								padding: "10px",
								minWidth: "150px",
								minHeight: "20px",
								backgroundColor: "grey",
								borderRadius: "25px 25px 2px 25px",
							}}
						></Box>
					</Box>
					<Box
						style={{
							justifyContent: "flex-end",
							display: "flex",
							textAlign: "center",
							fontStyle: "italic",
							fontSize: "0.8em",
							color: "grey",
						}}
					>
						<Box
							style={{
								width: "80px",
								background: "lightgrey",
								margin: "3px",
								borderRadius: "10px",
								color: "lightgrey",
							}}
						>
							.
						</Box>
					</Box>
				</Box>
			</Box>
			<Box
				sx={{
					width: "100%",
					height: "auto",
					display: "flex",
					justifyContent: "flex-start",
					padding: "7px 10px 7px 10px",
					boxSizing: "border-box",
				}}
			>
				<Box sx={{ display: "flex", fontSize: "0.9em", flexDirection: "column" }}>
					<Box style={{ display: "flex" }}>
						<Box
							style={{
								display: "flex",
								flexDirection: "column",
								justifyContent: "flex-end",
							}}
						>
							<Box
								style={{
									minWidth: "30px",
									height: "30px",
									backgroundColor: "grey",
									marginRight: "10px",
									borderRadius: "15px",
								}}
							>
								<img src="" alt="" id="user" />
							</Box>
						</Box>
						<Box
							style={{
								textAlign: "justify",
								margin: "0",
								padding: "10px",
								minWidth: "150px",
								minHeight: "20px",
								backgroundColor: "lightgrey",
								borderRadius: "25px 25px 25px 2px",
							}}
						></Box>
					</Box>
					<Box
						style={{
							textAlign: "left",
							paddingLeft: "38px",
							justifyContent: "flex-start",
							display: "flex",
							fontStyle: "italic",
							fontSize: "0.8em",
							color: "grey",
						}}
					>
						<Box
							style={{
								width: "80px",
								background: "lightgrey",
								margin: "3px",
								borderRadius: "10px",
								color: "lightgrey",
							}}
						>
							.
						</Box>
					</Box>
				</Box>
			</Box>
			<h1
				style={{
					width: "100%",
					padding: "100px 0 100px 0",
					fontStyle: "italic",
					color: "grey",
				}}
			>
				No Chat Selected
			</h1>
		</Box>
	);
};

export default EmptyMessageFlowComponent;
