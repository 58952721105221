import { Search } from "@material-ui/icons";
import { InputAdornment, ListSubheader, TextField } from "@mui/material";
import { useEffect, useState } from "react";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

export const onSearch$: any = new Subject().pipe(debounceTime(800));

export const SearchComponent = (props: any) => {
  const { setOpen, valueRef, open, searchOnChange } = props;
  const [value, setValue] = useState("");

  useEffect(() => {
    onSearch$.subscribe((debounced: string) => {
      searchOnChange(`${debounced}`);
    });
  }, []);

  return (
    <ListSubheader
      onBlur={() => setOpen(false)}
      sx={{
        padding: "15px",
        backgroundColor: "background.default",
        backgroundImage: "linear-gradient(rgba(255, 255, 255, 0.12), rgba(255, 255, 255, 0.12))",
      }}
    >
      <TextField
        sx={{
          "& .MuiInputBase-root": {
            color: "text.primary",
            border: `1px text.primary solid`,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
            borderColor: `text.primary`,
            borderWidth: "2px",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            borderColor: "text.primary",
          },
          // focused color for input with variant='standard'
          "& .MuiInput-underline:after": {
            border: "#444",
          },
        }}
        ref={valueRef}
        onClick={() => {
          setOpen(true);
        }}
        focused={open}
        size="medium"
        placeholder="Type to search..."
        fullWidth
        variant="outlined"
        InputProps={{
          startAdornment: (
            <InputAdornment position="start" sx={{ height: "50px", color: "text.primary" }}>
              <Search />
            </InputAdornment>
          ),
        }}
        value={value}
        onChange={(e) => {
          setValue(e.target.value);
          onSearch$.next(e.target.value);
        }}
        onKeyDown={(e) => {
          if (e.key !== "Escape") {
            e.stopPropagation();
          }
        }}
      />
    </ListSubheader>
  );
};
