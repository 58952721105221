import { ResourceProvider } from "../../providers/resource.provider";
import { EmployeeProfile } from "./EmployeeProfile";
import { employeeProfileSerializer } from "./serializer";

class EmployeeProfileProvider extends ResourceProvider<EmployeeProfile> {
  constructor() {
    super("rest_employee_profile", employeeProfileSerializer);
  }
}

export const employeeProfileService = new EmployeeProfileProvider();
