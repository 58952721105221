import { Delete, Edit } from "@mui/icons-material";
import { Box, Button, createTheme, Grid, ListItemButton, ListItemText, ThemeProvider, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation } from "react-router-dom";
import { ConfirmationDialog } from "../../Components/Dialogs/ConfirmationDialog";
import VersionsFormDialog from "../../Components/Dialogs/VersionsFormDialog";
import { TypographyItem } from "../../Components/Typography/TypographyItem";
import VersionTabs from "../../Components/VersionTabs";
import { environment, ReduxState } from "../../environment/environment";
import { permissionList, permValid } from "../../helpers/permCheckers";
import { QueryOptions } from "../../helpers/query.options";
import { setSnack } from "../../models/application/applicationActions";
import { register } from "../../models/user/authActions";
import { versionService } from "../../models/Version/service";
import { Version } from "../../models/Version/Version";

const VersionDetailsPage = (props: any) => {
  const { auth, app, palette, setSnack, mode } = props;

  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [version, setVersion] = useState<Version | any>();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<any>({ text: "", open: false, title: "", version: null });
  const [versions, setVersions] = useState<Version[]>([]);
  const [versionPer] = useState(permissionList(auth, "Version"));
  const [Id, setId] = useState("");
  const location = useLocation();

  const handleDialog = (val: boolean) => {
    setOpen(val);
    setTrigger(!trigger);
  };

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    setId(params.get("version")!);
  }, [location]);

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const onReload = () => setCounter(counter + 1);

  const handleDelete = (version: Version) => {
    setConfirmation([{ text: "ნამდვილად გსურთ წაშლა?", open: true, title: "ვერსიის წაშლა", version }]);
  };

  const loadVersion = (id: string) => {
    versionService.read(id).then((tsk: Version) => {
      setVersion(tsk);
    });
  };
  const getVersions = () => {
    const options = new QueryOptions();
    options.sort = "created";
    options.order = "desc";
    versionService.listWithCounter(options).then((r: any) => {
      setVersions(r.current);
    });
  };

  useEffect(() => {
    if (Id) {
      loadVersion(Id);
    }

    getVersions();
    //eslint-disable-next-line
  }, [trigger, Id]);

  const localTheme = createTheme({
    palette: {
      mode: mode,
      text: {
        primary: mode === "dark" ? "#ffffff" : "#000000",
      },
    },
  });

  return (
    <ThemeProvider theme={localTheme}>
      <Grid
        container
        spacing={2}
        direction={window.screen.width > 950 ? "row" : "column"}
        minHeight={"100vh"}
        paddingBottom={"120px"}
        overflow={"auto"}
        sx={{ backgroundColor: "background.default" }}
      >
        {window.screen.width > 950 && (
          <Grid item xs={0} md={0} lg={2}>
            {[...versions]?.map((item, index) => {
              return (
                <Link
                  style={{ textDecoration: "none", width: "100%", height: "100%", color: palette.text.primary }}
                  to={{ pathname: `/versionDetails`, search: environment.baseQueryParams + "&tab=taskTable" + `&version=${item?.id}` }}
                >
                  <ListItemButton selected={item?.id === version?.id ? true : false}>
                    <ListItemText
                      style={{ cursor: "pointer" }}
                      primary={item.name}
                      secondary={item.created}
                      onClick={() => {
                        setVersion(item);
                      }}
                    />
                  </ListItemButton>
                </Link>
              );
            })}
          </Grid>
        )}
        <Grid container item xs={8} lg={8}>
          <VersionTabs palette={palette} id={Id} item={version} />
        </Grid>
        <Grid item xs={4} lg={2} alignItems={"center"}>
          <TypographyItem style={{ textAlign: "left" }} fontSize={50} fontWeight={"bold"} text={version?.name} />
          <Typography color={"text.primary"} variant="h5" sx={{ textAlign: "left" }}>
            {version?.uid}
          </Typography>
          <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
            <Box m={1} color={"text.primary"} component={"span"} sx={{ fontWeight: "400" }}>
              შექმნის თარიღი:{" "}
            </Box>
            <Box m={1} color={"text.primary"} component={"span"} sx={{ fontWeight: "bold" }}>
              {version?.created}
            </Box>
          </Box>
          <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
            <Box color={"text.primary"} m={1} component={"span"} sx={{ fontWeight: "400" }}>
              დაწყების თარიღი:{" "}
            </Box>
            <Box color={"text.primary"} m={1} component={"span"} sx={{ fontWeight: "bold" }}>
              {version?.startDate}
            </Box>
          </Box>
          <Box sx={{ textAlign: "left", display: "flex", flexDirection: "column" }}>
            <Box m={1} color={"text.primary"} component={"span"} sx={{ fontWeight: "400" }}>
              გაშვების თარიღი:{" "}
            </Box>
            <Typography color={"text.primary"} sx={{ fontWeight: "bold" }}>
              {version?.startDate}
            </Typography>
          </Box>
          <Tooltip title={!permValid(versionPer, "update") ? "disabled" : ""}>
            <Button
              onClick={() => {
                permValid(versionPer, "update") && setVersion(version);
                permValid(versionPer, "update") && handleDialog(true);
              }}
              style={{
                background: permValid(versionPer, "update") ? palette.background.paperLight : "lightgrey",
              }}
              variant="contained"
            >
              <Edit style={{ cursor: "pointer", color: permValid(versionPer, "update") ? palette.text.primary : "grey" }} />
            </Button>
          </Tooltip>

          <Tooltip title={!permValid(versionPer, "delete") ? "disabled" : ""}>
            <Button
              onClick={() => permValid(versionPer, "delete") && handleDelete(version)}
              style={{
                background: permValid(versionPer, "delete") ? palette.background.paperLight : "lightgrey",
              }}
              variant="contained"
            >
              <Delete style={{ cursor: "pointer", color: permValid(versionPer, "delete") ? palette.text.primary : "grey" }} />
            </Button>
          </Tooltip>
        </Grid>
        <Grid item lg={0} md={0} xs={0}>
          <ConfirmationDialog
            open={confirmation.open}
            text={confirmation.text}
            title={confirmation.title}
            callback={(c: any) => {
              if (c) {
                versionService.delete(confirmation?.version?.id).then((r: Version) => {
                  onAlert(`ვერსია ${confirmation?.version?.name} წაიშალა წარმატებით::success`);
                  onReload();
                  window.location.href = "/versions";
                });
              }
              setConfirmation({ text: "", open: false, title: "", issue: null });
            }}
          />
          <VersionsFormDialog setSubmitted={() => {}} onAlert={onAlert} item={version} open={open} handleDialog={handleDialog} />
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: ReduxState) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps, { register, setSnack })(VersionDetailsPage);
