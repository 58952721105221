import ArrowDownward from "@material-ui/icons/ArrowDownward";
import { Box, createTheme, TableCell, ThemeProvider } from "@mui/material";
import { grey } from "@mui/material/colors";
import { useState } from "react";
import { connect } from "react-redux";
import { ReduxState } from "../environment/environment";
import { FilterSelectBox } from "./Filter/FilterSelectBox";
import { TableHeaderSearchComponent } from "./TableHeaderSearchComponent";
import TableSettings from "./TableSettings";

function TableHeader({
  headerList,
  filterItems,
  onToggle,
  setCachedList,
  handleOnChange,
  selectedFilters,
  cachedList,
  setOrder,
  setFilterSearch = () => {},
  filterSearch,
  mode,
  handleFilteredList,
  tableName,
  sx,
  copyToolVisible,
  editToolVisible,
  detailsLinkToolVisible,
  props,
}: any) {
  const [rotateIcon, setRotateIcon] = useState(false);

  const getItem = (key: string) => {
    const [item]: any = filterItems?.filter((item: any) => item.key === key);
    if (item) {
      return item;
    }
    return false;
  };

  const handleToggle = (bool: boolean, name: string) => {
    if (bool) {
      const [item] = filterItems?.filter((i: any) => i.key === name);
      if (item) {
        onToggle(item.items, name);
        setCachedList(item.items);
      }
    } else {
      const [item] = filterItems?.filter((i: any) => i.key === name);
      onToggle([{ key: item.items[0].key, id: "" }], name);
    }
  };

  const handleCachedListChange = (item: any, key: string) => {
    if (item?.items) {
      if (item?.items?.map((r: any) => r.id).indexOf(item.id) > -1) {
        setCachedList({ key, list: item.items?.filter((r: any) => r.id !== item.id) });
      } else {
        setCachedList({ key, list: [...item?.items, item] });
      }
    }
  };

  const RenderDynamicHeaderTitle = (getBy: string, name: string) => {
    const getter: string = (() => {
      if (getBy.includes(".")) {
        return getBy.split(".")[getBy.split(".").length - 1];
      } else return getBy;
    })();

    return (
      <FilterSelectBox
        onToggle={(bool: boolean) => handleToggle(bool, getter)}
        selected={selectedFilters?.filter((item: any) => item.key === getter)}
        items={cachedList?.key === getter ? cachedList.list : getItem(getter).items}
        onChange={(item: any) => {
          handleOnChange(item);
          if (!selectedFilters?.filter((item: any) => item.key === getter).length) {
            handleCachedListChange(getItem(getter), getter);
          }
        }}
        name={name}
      />
    );
  };

  const RenderAlternateDynamicHeaderTitle = (getBy: string) => {
    return (
      <TableHeaderSearchComponent
        key={getBy}
        getBy={getBy}
        name={filterSearch?.filter((item: any) => item.id === (getBy === "buyer" ? "user" : getBy))[0].searchValue}
        onChange={(item: any) => {
          handleOnChange(item);
        }}
        setFilterSearch={setFilterSearch}
        filterItems={filterItems}
      />
    );
  };

  const RotatingIcon = () => {
    const handleRotate = () => setRotateIcon(!rotateIcon);

    const handleOperation = () => {
      handleRotate();
      setOrder(rotateIcon ? "desc" : "asc");
    };

    const rotate = rotateIcon ? "rotate(180deg)" : "rotate(0)";

    return <ArrowDownward style={{ transform: rotate, transition: "all 0.2s linear" }} onClick={handleOperation} />;
  };

  const TableHeaderCases = (item: any) => {
    switch (item.type) {
      case "dynamic":
        return RenderDynamicHeaderTitle(item.getBy, item.name);
      case "alternateDynamic":
        return RenderAlternateDynamicHeaderTitle(item.getBy);
      case "nonDynamic":
        return item.name && item.name;
      case "date":
        return (
          <Box sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
            {item.name && item.name}
            {RotatingIcon()}
          </Box>
        );
      default:
        break;
    }
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
    typography: {
      fontSize: 13,
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 13,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          root: {
            backgroundColor: mode === "dark" ? grey[900] : grey[600],
          },
        },
      },
    },
  });

  const tableHeaderCells = () => {
    return headerList?.map((item: any, index: number) => {
      return (
        <TableCell
          key={index}
          sx={{
            display: item.enabled ? "table-cell" : "none",
            color: "#fff",
            fontWeight: "bold",
          }}
          align="center"
          padding="normal"
          size="small"
        >
          {TableHeaderCases(item)}
        </TableCell>
      );
    });
  };
  return (
    <ThemeProvider theme={localTheme}>
      <TableCell {...props} sx={{ ...sx }}>
        <TableSettings list={headerList} setFilteredList={handleFilteredList} tableName={tableName}></TableSettings>
      </TableCell>
      <TableCell {...props} sx={{ ...sx, display: copyToolVisible && detailsLinkToolVisible ? "table-cell" : "none" }} />
      <TableCell align="center" sx={{ display: editToolVisible && detailsLinkToolVisible ? "table-cell" : "none" }} />
      {tableHeaderCells()}
    </ThemeProvider>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { app } = state;
  return {
    mode: app.currentTheme,
  };
};

export default connect(mapStateToProps)(TableHeader);
