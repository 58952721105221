import { Box, Grid, Table } from "@mui/material";
import { useState } from "react";
import { connect } from "react-redux";
import { setSnack } from "../../models/application/applicationActions";
import { Review } from "../../models/Review/Review";
import { productReviewService } from "../../models/Review/service";
import MainTableHeaderBodyContainer from "../MainTableHeaderBodyContainer";

const ProductReviewTable = (props: any) => {
  const { tableName, handleSubmit, tableList, handleOrder, handleOnToggle, handleOnChange, selectedFilters, tableStructureList, filterItems } = props;

  const localList = JSON.parse(localStorage.getItem(tableName) || "{}");

  const [cachedList, setCachedList] = useState<{ key: string; list: any[] }>({ key: "", list: [] });
  const [filteredList, setFilteredList] = useState<any[]>(Object.keys(localList).length ? localList : tableStructureList);

  const getOverallTrueFalseFromFilteredList = () => {
    return filteredList.filter((e) => e.enabled === true).length ? true : false;
  };

  const handleCachedList = (item: { key: string; list: any[] }) => {
    setCachedList(item);
  };

  const handleFilteredList = (state: any[]) => {
    setFilteredList(state);
  };

  return (
    <Box>
      <Grid item overflow={"overlay"}>
        <Table sx={{ width: getOverallTrueFalseFromFilteredList() ? "100%" : "fit-content" }} stickyHeader>
          <MainTableHeaderBodyContainer
            padding="checkbox"
            size="small"
            align="center"
            headerList={filteredList}
            handleOrder={handleOrder}
            handleOnToggle={handleOnToggle}
            handleCachedList={handleCachedList}
            handleOnChange={handleOnChange}
            selectedFilters={selectedFilters}
            cachedList={cachedList}
            handleFilteredList={handleFilteredList}
            handleSubmit={handleSubmit}
            updater={new Review()}
            service={productReviewService}
            tableList={tableList}
            tableName={tableName}
            detailsLinkToolVisible={false}
            copyToolVisible={false}
            editToolVisible={false}
            filterItems={filterItems}
          />
        </Table>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state?.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { setSnack })(ProductReviewTable);
