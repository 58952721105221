const SET_PALETTE: string = "SET_PALETTE";

interface Palette {
    palette: object,
    theme: string,
    muiTheme: any
}

let theme: string = localStorage.getItem('paletteState') || "light";

export const setGlobalTheme = (
    state: object = {...{}, theme}, action: { type: string, payload: Palette }
) => {
    switch (action.type) {
        case SET_PALETTE: {
            return {
                 ...state, 
                palette: action.payload.palette,
                theme: action.payload.theme,
                muiTheme: action.payload.muiTheme
             }
        }
        default:
            return state;
    };
};