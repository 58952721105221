import { Delete, Edit, KeyboardArrowUpSharp } from "@mui/icons-material";
import { Box, Button, Grid, ListItem, ListItemText, Tooltip } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Fragment, useCallback, useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { Link, useLocation, useParams } from "react-router-dom";
import SimpleBar from "simplebar-react";
import { CommentsComponent } from "../../Components/CommentsComponent";
import { ConfirmationDialog } from "../../Components/Dialogs/ConfirmationDialog";
import TasksFormDialog from "../../Components/Dialogs/TasksFormDialog";
import { environment } from "../../environment/environment";
import { permissionList, permValid } from "../../helpers/permCheckers";
import { generateParamsFromUrl, getPriorityColor, getStatusColor, urlifyForTable } from "../../helpers/utilities";
import { setSnack } from "../../models/application/applicationActions";
import { taskService } from "../../models/Task/service";
import { Task } from "../../models/Task/Task";
import { register } from "../../models/user/authActions";

const TaskDetailsPage = (props: any) => {
  const { auth, app, palette, muiTheme, setSnack } = props;

  const ListStyles = makeStyles({
    root: {
      width: "100%",
      height: "calc(100vh - 120px)",
      backgroundColor: palette.background.default,
      position: "absolute",
      display: "flex",
    },
  });

  const { id } = useParams<any>();

  const location: any = useLocation();

  const [open, setOpen] = useState(false);
  const [counter, setCounter] = useState(0);
  const [task, setTask] = useState<Task | any>();
  const [trigger, setTrigger] = useState<boolean>(false);
  const [confirmation, setConfirmation] = useState<any>({
    text: "",
    open: false,
    title: "",
    task: null,
  });
  const [image, setImage] = useState<any>(null);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [taskPer] = useState(permissionList(auth, "Task"));
  const [loaded, setLoaded] = useState(false);

  const uploadInputRef: any = useRef(null);
  const classes = ListStyles();

  const handleDialog = (val: boolean) => {
    setOpen(val);
    setTrigger(!trigger);
  };

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const onReload = () => setCounter(counter + 1);

  // const onOpenTask = (id: string) => history.push({ pathname: `/task/${location.state?.url.replace("/tasks?", `${id}?`)}`, state: { url: location.state?.url } })

  const handleDelete = (task: Task) => {
    setConfirmation({
      text: "ნამდვილად გსურთ წაშლა?",
      open: true,
      title: "ტასკის წაშლა",
      task,
    });
  };

  const loadTask = (id: string) => {
    taskService.read(id).then((tsk: Task) => {
      setTask(tsk);
    });
  };
  const getTasks = () => {
    taskService.listWithCounter(getParams()).then((r: any) => {
      setTasks(r.current);
    });
  };

  useEffect(() => {
    if (id) {
      loadTask(id);
      setLoaded(!loaded);
    }

    getTasks();
    //eslint-disable-next-line
  }, [trigger, id]);

  const getParams = () => {
    if (location.search) {
      const params = generateParamsFromUrl(location, "tasks");

      return params;
    } else return null;
  };

  const onDrop = (event: any) => {
    const [file] = event.target.files;
    if (file) {
      if (file.type === "image/jpeg" || file.type === "image/png") {
        if (file.size < 1500000) {
          setImage(URL.createObjectURL(file));
          taskService.update({ ...task, images: file }).then((r: Task) => {});
        } else {
          alert("სურათის ზომა 3 მბ ზე მერია");
        }
      } else {
      }
    }
  };

  const returnComments = useCallback(() => {
    return <CommentsComponent palette={palette} muiTheme={muiTheme} id={id} loaded={loaded} setLoaded={setLoaded} />;
  }, [id, palette]);

  return (
    <Box className={classes.root}>
      {task && (
        <SimpleBar
          style={{
            height: "100%",
            width: "360px",
            backgroundColor: palette.background.default,
            borderRight: "solid 2px grey",
          }}
        >
          <ConfirmationDialog
            open={confirmation.open}
            text={confirmation.text}
            title={confirmation.title}
            callback={(c: any) => {
              if (c) {
                taskService.delete(confirmation?.task?.id).then((r: Task) => {
                  onAlert(`ტასკი ${confirmation?.task?.name} წაიშალა წარმატებით::success`);
                  onReload();
                  window.location.href = `/tasks${environment.baseQueryParams}`;
                });
              }
              setConfirmation({
                text: "",
                open: false,
                title: "",
                issue: null,
              });
            }}
          />
          <Grid
            aria-label="contacts"
            style={{
              height: "auto",
              background: palette.background.default,
              color: palette.text.primary,
            }}
          >
            {[...tasks].map((item, index) => {
              return (
                <Link
                  key={index}
                  style={{ textDecoration: "none", width: "100%", height: "100%", color: palette.text.primary }}
                  to={{ pathname: `/taskDetails/${item.id}`, search: location.search }}
                >
                  <ListItem
                    style={{
                      background: getStatusColor(item),
                      borderLeftWidth: 0,
                      borderRightWidth: 0,
                      border: item.id === task.id ? "2px solid black" : "2px solid transparent",
                    }}
                    selected={item.id === task.id ? true : false}
                  >
                    <ListItemText
                      style={{ cursor: "pointer" }}
                      primary={item.name}
                      secondary={item.created}
                      onClick={() => {
                        setTask(item);
                      }}
                    />
                    <div
                      style={{
                        borderRadius: "50%",
                        padding: 10,
                        maxHeight: 24,
                        border: "0px solid black",
                        backgroundColor: "white",
                      }}
                    >
                      <KeyboardArrowUpSharp style={{ fill: getPriorityColor(item) }} />
                    </div>
                  </ListItem>
                </Link>
              );
            })}
          </Grid>
        </SimpleBar>
      )}
      <SimpleBar style={{ width: "100%", height: "100%" }}>
        <Box
          sx={{
            width: "100%",
            margin: "0px 50px 0px 50px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Grid
            style={{
              height: "100%",
              padding: 20,
              alignItems: "flex-start",
              color: palette.text.primary,
            }}
            container
            direction="row"
          >
            <Grid item>
              <h2 style={{ textAlign: "left" }}>{task?.name}</h2>
              <h5 style={{ textAlign: "left" }}>{task?.description ? urlifyForTable(task?.description) : ""}</h5>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>ავტორი: </span>
                <span style={{ fontWeight: "bold" }}>{task?.user.full_name}</span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>ტიპი: </span>
                <span style={{ fontWeight: "bold" }}>{task?.type.name}</span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>კატეგორია: </span>
                <span style={{ fontWeight: "bold" }}>{task?.category.name}</span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>პროექტი: </span>
                <span style={{ fontWeight: "bold" }}>{task?.project.name}</span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>კომპონენტი: </span>
                <span style={{ fontWeight: "bold" }}>{task?.component.name}</span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>შექმნის თარიღი: </span>
                <span style={{ fontWeight: "bold" }}>{task?.created}</span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>პრიორიტეტი: </span>
                <span style={{ fontWeight: "bold" }}>{task?.priority.name}</span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>სტატუსი: </span>
                <span style={{ fontWeight: "bold" }}>{task?.status.name}</span>
              </div>
              <div style={{ textAlign: "left" }}>
                <span style={{ fontWeight: "400" }}>ვერსია: </span>
                <span style={{ fontWeight: "bold" }}>{task?.version?.name}</span>
              </div>
              <div style={{ width: 200, padding: 20 }}>
                <h3>სურათები</h3>
                <ul style={{ flexDirection: "row" }}>
                  {task?.images?.map((img: any) => {
                    return (
                      <li style={{ flexDirection: "column" }}>
                        <img src={img} style={{ width: "100%" }} alt="task-pictures" />
                      </li>
                    );
                  })}
                </ul>
                <img src={image} style={{ width: "100%" }} alt="" />
              </div>
            </Grid>
            {returnComments()}
          </Grid>
          <Grid
            item
            style={{
              padding: 20,
              alignItems: "flex-start",
              display: "flex",
              flexDirection: "column",
              height: "180px",
              justifyContent: "space-between",
            }}
          >
            <Fragment>
              <input ref={uploadInputRef} type="file" accept="image/*" style={{ display: "none" }} onChange={onDrop} />
              <Button
                onClick={() => uploadInputRef.current && uploadInputRef.current.click()}
                style={{
                  background: palette.background.paperLight,
                  color: palette.text.primary,
                }}
                variant="contained"
              >
                Upload
              </Button>
            </Fragment>
            <Tooltip title={!permValid(taskPer, "update") ? "disabled" : ""}>
              <Button
                onClick={() => {
                  permValid(taskPer, "update") && setTask(task);
                  permValid(taskPer, "update") && handleDialog(true);
                }}
                style={{
                  background: permValid(taskPer, "update") ? palette.background.paperLight : "lightgrey",
                }}
                variant="contained"
              >
                <Edit
                  style={{
                    cursor: "pointer",
                    color: permValid(taskPer, "update") ? palette.text.primary : "grey",
                  }}
                />
              </Button>
            </Tooltip>

            <Tooltip title={!permValid(taskPer, "delete") ? "disabled" : ""}>
              <Button
                onClick={() => permValid(taskPer, "delete") && handleDelete(task)}
                style={{
                  background: permValid(taskPer, "delete") ? palette.background.paperLight : "lightgrey",
                }}
                variant="contained"
              >
                <Delete
                  style={{
                    cursor: "pointer",
                    color: permValid(taskPer, "delete") ? palette.text.primary : "grey",
                  }}
                />
              </Button>
            </Tooltip>
          </Grid>
          <Grid item xs={12} lg={2} style={{ padding: 20 }}>
            <TasksFormDialog setSubmitted={() => {}} onAlert={onAlert} item={task} open={open} app={app} handleDialog={handleDialog} />
          </Grid>
        </Box>
      </SimpleBar>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
    palette: state.theme.palette,
    muiTheme: state.theme.muiTheme,
  };
};

export default connect(mapStateToProps, { register, setSnack })(TaskDetailsPage);
