import { Box, Chip, Divider, Grid } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { Virtuoso } from "react-virtuoso";
import { AdminSideMessageBox } from "./AdminSideMessageBox";
import { UserSideMessageBox } from "./UserSideMessageBox";

const MessageFlowComponent = (props: any) => {
  const { messages, imageLink, setEditMode, setEditText, setUid, chatPer, currUserName, userName, setReplyText, setReplierName, messageDeleteActionHandler } =
    props;

  const [Dates, setDates] = useState<any>([]);

  const filterDatesByDay = () => {
    const Filter1 = messages.map((e: any) => new Date(e.created).toDateString());

    setDates([...new Set(Filter1)]);
  };

  useEffect(() => {
    filterDatesByDay();
  }, [messages]);

  const virtuoso = useRef(null);

  return (
    <Grid item container flexGrow={1} sx={{ backgroundColor: "background.default" }}>
      <Virtuoso
        style={{ width: "100%", height: "auto" }}
        ref={virtuoso}
        initialTopMostItemIndex={Dates.length - 1}
        id="scrollView"
        data={Dates}
        totalCount={Dates.length}
        followOutput
        itemContent={(idx, e) => (
          <Box key={idx}>
            <Divider sx={{ margin: "20px" }}>
              <Chip label={e} />
            </Divider>
            {messages
              ?.filter((d: any) => new Date(d.created).toDateString() === e)
              .map((item: any, index: number) => {
                const currUser = JSON.parse(localStorage.getItem("currentUser")!).fullName;
                return item.owner === "admin" ? (
                  <AdminSideMessageBox
                    messageDeleteActionHandler={messageDeleteActionHandler}
                    setReplierName={setReplierName}
                    setReplyText={setReplyText}
                    userName={userName}
                    currUserName={currUserName}
                    chatPer={chatPer}
                    setUid={setUid}
                    key={index}
                    index={index}
                    setEditText={setEditText}
                    setEditMode={setEditMode}
                    messages={messages}
                    item={item}
                    currUser={currUser}
                    e={e}
                  />
                ) : (
                  <UserSideMessageBox
                    setReplierName={setReplierName}
                    setUid={setUid}
                    setReplyText={setReplyText}
                    setEditMode={setEditMode}
                    setEditText={setEditText}
                    currUserName={currUserName}
                    userName={userName}
                    key={index + "ss"}
                    index={index}
                    messages={messages}
                    imageLink={imageLink}
                    e={e}
                    item={item}
                  />
                );
              })}
          </Box>
        )}
      />
    </Grid>
  );
};

export default MessageFlowComponent;
