import { Country } from './Country';
import { Serializer } from './../serializer';
import { Resource } from '../resource';

export class CountrySerializer implements Serializer {
    fromJson(json: any) {
        const country = new Country()
        country.name = json.name
        country.id = json.id
        return country
    }

    toJson(resource: Resource) {
        return resource
    }
}

export const countrySerialier = new CountrySerializer()