import { Close } from "@mui/icons-material";
import { Alert, Box, Grid, IconButton, Snackbar, Theme } from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { useLocation } from "react-router-dom";
import { checkPermissions } from "../../helpers/permCheckers";
import { PushService } from "../../helpers/pushService";
import { QueryOptions } from "../../helpers/query.options";
import {
  setAssignee,
  setCategories,
  setChatBadge,
  setComponents,
  setFeatures,
  setIssues,
  setIssueTypes,
  setPriorities,
  setProjects,
  setSnack,
  setStatuses,
  setTestStatuses,
  setUserList,
  setVersion
} from "../../models/application/applicationActions";
import { Badge } from "../../models/badge/Badge";
import { badgeService } from "../../models/badge/service";
import { categoryService } from "../../models/category/service";
import { componentService } from "../../models/component/service";
import { featuresService } from "../../models/features/service";
import { issueService, issueTypeService } from "../../models/issue/service";
import { priorityService } from "../../models/priority/service";
import { projectService } from "../../models/project/service";
import { statusService } from "../../models/status/service";
import { testStatusservice } from "../../models/testing/service";
import { login, register } from "../../models/user/authActions";
import { userListService, userService } from "../../models/user/service";
import { User } from "../../models/user/user";
import { versionService } from "../../models/Version/service";
import ToolbarComponent from "../Header/Toolbar";

const Header = (props: any) => {
  const {
    setCategories,
    setIssueTypes,
    setSnack,
    setTestStatuses,
    setProjects,
    setPriorities,
    setStatuses,
    setComponents,
    setAssignee,
    setVersion,
    setFeatures,
    setUserList,
    setIssues,
    app,
    auth,
    setChatBadge,
  } = props;

  const user = new User();

  auth.user !== null &&
    setInterval(() => {
      user.expire();
    }, 1000);

  const [badge, setBadge] = useState<number | undefined>(0);

  const location = useLocation();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const country = params.get("country");
    const interval = setInterval(() => {
      if (location.pathname === "/chat") {
        badgeService.read(`id?country=${country}`).then((e: Badge) => {
          setBadge(e.chat_count);
        });
      }
    }, 10000);

    return () => clearInterval(interval);
  }, [location.search, app?.country]);

  useEffect(() => {
    setChatBadge(badge);
  }, [badge]);

  useEffect(() => {
    const logOutListener = function (event: any) {
      if (event.key === "logout-event") {
        window.location.href = "/";
      }
    };

    const logInListener = function (event: any) {
      if (event.key === "login-event") {
        window.location.href = "/";
      }
    };

    window.addEventListener("storage", logOutListener);
    window.addEventListener("storage", logInListener);

    return () => {
      window.removeEventListener("storage", logOutListener);
      window.removeEventListener("storage", logInListener);
    };
  }, []);

  useEffect(() => {}, [app.categories]);

  const loadCategories = () => {
    const options = new QueryOptions();
    categoryService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setCategories(r.current);
      }
    });
  };
  const loadComponents = () => {
    const options = new QueryOptions();
    options.pageSize = 100;
    componentService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setComponents(r.current);
      }
    });
  };

  const loadUsers = () => {
    const options = new QueryOptions();
    userListService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setUserList(r.current);
      }
    });
  };
  const getIssues = () => {
    const options = new QueryOptions();
    options.pageSize = 100;
    options.sort = "created";
    options.order = "desc";
    issueService.listWithCounter(options).then((r: any) => {
      setIssues(r.current);
    });
  };
  const loadIssueTypes = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    issueTypeService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setIssueTypes(r.current);
      }
    });
  };
  const loadProjects = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    projectService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setProjects(r.current);
      }
    });
  };
  const loadPriorities = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    priorityService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setPriorities(r.current);
      }
    });
  };
  const loadStatuses = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    statusService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setStatuses(r.current);
      }
    });
  };
  const loadTestStatuses = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    testStatusservice.listWithCounter(options).then((r: any) => {
      if (r?.current?.length) {
        setTestStatuses(r?.current);
      }
    });
  };
  const loadAssignees = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    userService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setAssignee(r?.current);
      }
    });
  };
  const loadVersions = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    versionService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setVersion(r.current);
      }
    });
  };
  const loadFeatures = () => {
    const options = new QueryOptions();
    options.sort = "sort";
    options.order = "asc";
    featuresService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setFeatures(r.current);
      }
    });
  };
  useEffect(() => {
    const user = new User();
    user.load().then((currentUser) => {
      if (currentUser.accessToken && currentUser) {
        let [permission] = currentUser?.roles as any;
        if (permission) {
          permission = permission.permissions;
          props.login(currentUser);
          loadUsers();
          getIssues();
          checkPermissions(loadComponents, "Component", permission);
          checkPermissions(loadCategories, "Category", permission);
          checkPermissions(loadTestStatuses, "Status", permission);
          checkPermissions(loadIssueTypes, "Issue", permission);
          checkPermissions(loadProjects, "Project", permission);
          checkPermissions(loadPriorities, "Priority", permission);
          checkPermissions(loadStatuses, "Status", permission);
          checkPermissions(loadAssignees, "Employee", permission);
          checkPermissions(loadVersions, "Version", permission);
          checkPermissions(loadFeatures, "Feature", permission);
        }
      }
    });
    // eslint-disable-next-line
  }, [auth.loggedIn]);

  type Color = "success" | "info" | "warning" | "error";

  const Header = () => {
    return (
      auth.loggedIn && (
        <Grid item container lg>
          {props.auth.loggedIn && <ToolbarComponent />}
          <PushService
            id={auth?.user?.id}
            onMessageReceived={(text: string) => {
              setSnack({ ...app.snack, open: true, text: `${text}::success` });
            }}
          />
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
            open={app?.snack?.open}
            autoHideDuration={3000}
            onClose={() => setSnack({ ...app.snack, open: false })}
            action={
              <React.Fragment>
                <IconButton
                  size="small"
                  aria-label="close"
                  color="inherit"
                  onClick={() => {
                    setSnack({ ...app?.snack, open: false });
                  }}
                >
                  <Close fontSize="small" />
                </IconButton>
              </React.Fragment>
            }
          >
            <Alert severity={`${app?.snack?.text.split("::")[1]}` as Color}>{app?.snack?.text.split("::")[0] || app?.snack?.text}</Alert>
          </Snackbar>
        </Grid>
      )
    );
  };

  return Header();
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    app: state.app,
  };
};

export default connect(mapStateToProps, {
  register,
  login,
  setCategories,
  setIssueTypes,
  setComponents,
  setStatuses,
  setSnack,
  setTestStatuses,
  setProjects,
  setPriorities,
  setAssignee,
  setVersion,
  setFeatures,
  setUserList,
  setIssues,
  setChatBadge,
})(Header);
