import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { EngagedProductsLogs, PageLogs } from "./Logs";

class PageLogsSrializer implements Serializer {
    fromJson(json: any) {
        const pageLogs = new PageLogs()
        pageLogs.batchId = json.batch_id
        pageLogs.created = json.created
        pageLogs.date = json.date
        pageLogs.filterSetId = json.filter_set_id
        pageLogs.user = json.user
        pageLogs.product = json.product
        pageLogs.productId = json.product_id
        pageLogs.promotionId = json.promotion_id
        pageLogs.screen = json.screen
        pageLogs.type = json.type
        pageLogs.userId = json.user_id
        pageLogs.image = json.main_file_name
        pageLogs.productName = json.product_name
        pageLogs.seller = json.seller
        return pageLogs
    }
    toJson(resource: Resource) {
        return resource
    }

}

class EngagedProductsLogsSerializer implements Serializer {
    fromJson(json: any) {
        const item = new EngagedProductsLogs()
        item.id = json.prod_id
        item.image = json.prod_image
        if (json.prod_like_types)
            json.prod_like_types.map((res: any) => {
                switch (res.type) {
                    case "product_save":
                        return item.save = res.count;
                    case "product_like":
                        return item.like = res.count;
                    case "product_open":
                        return item.open = res.count;
                    case "product_initial_offer_start":
                        return item.initialOfferStart = res.count;
                    case "product_initial_offer_finish":
                        return item.initialOfferFinish = res.count;
                    default:
                        break;
                }
            })
        item.total = json.sum
        item.productExists = json.prod_exists
        item.stock = json.stock
        return item
    }
    toJson(resource: Resource) {
        return resource
    }

}

export const engagedProductsLogsSerializer = new EngagedProductsLogsSerializer()
export const pageLogsSerializer = new PageLogsSrializer()