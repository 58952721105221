import { Resource } from '../resource';
import { productSerializer } from './../Product/serializer';
import { Serializer } from './../serializer';
import { userSerializer } from './../user/serializer';
import { UserComment, UserCommentOverride } from './UserComment';

export class UserCommentSerializer implements Serializer {
    fromJson(json: any) {
        const userCommment = new UserComment();
        userCommment.comment = json.text;
        userCommment.counted = json.counted;
        userCommment.isEnabled = json.is_enabled;
        userCommment.created = json.date;
        userCommment.notified = json.notified;
        userCommment.commentId = json._id;
        if (json.product) {
            userCommment.productId = productSerializer.fromJson(json.product).id
            userCommment.productEnabled = productSerializer.fromJson(json.product).isEnabled
            userCommment.productImage = productSerializer.fromJson(json.product).image
            userCommment.productName = productSerializer.fromJson(json.product).name
            userCommment.productInStock = productSerializer.fromJson(json.product).stock
            userCommment.productOwner = productSerializer.fromJson(json.product).ownerName
            userCommment.productOwnerManager = productSerializer.fromJson(json.product).ownerManager
        }
        if (json.user) {
            userCommment.userId = userSerializer.fromJson(json.user).email
            userCommment.userDepartment = userSerializer.fromJson(json.user).department
            userCommment.userSuccessManager = userSerializer.fromJson(json.user).successManager
        }

        return userCommment;
    }

    toJson(resource: Resource) {
        throw new Error("Method not implemented.");
    }
}

export const userCommentSerializer = new UserCommentSerializer()

class UserCommentOverrideSerializer implements Serializer {
    fromJson(json: any) {
        return json
    }

    toJson(resource: UserCommentOverride) {
        const obj: any = {}
        obj.product_comment = resource.productComment
        obj.param_name = resource.paramName
        obj.param_new_value = resource.paramNewValue
        return obj
    }
}

export const userCommentOverrideSerialzier = new UserCommentOverrideSerializer()