import { Category } from "../category/category"
import { Component } from "../component/component"
import { Issue, IssueType } from "../issue/Issue"
import { Priority } from "../priority/priority"
import { Project } from "../project/project"
import { Resource } from "../resource"
import { Status } from "../status/status"
import { User } from "../user/user"
import { Version } from "../Version/Version"


export class TaskType extends Resource{
    name: string | any
    
}

export class Task extends Resource {
    name: string | any 
    description: string | any  
    project:  Project | any 
    type: IssueType | any 
    component: Component | any
    category: Category | any 
    images: File[] | any  
    videos: File[] | any 
    priority: Priority | any 
    status: Status | any
    issue: Issue | any 
    version: Version | any 
    deadlineDate: Date | any 
    minutesEstimated: BigInteger | any 
    minutesSpent: BigInteger | any 
    isEnabled: Boolean | any
    created: Date | any
    assignee: User[] | any 
    user: User | any 
    reviewers: User[] | any 
    testers: User[] | any 
}

