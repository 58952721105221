import { ResourceProvider } from "../../providers/resource.provider";
import { PasswordChange } from "./PasswordChange";
import { passwordChangeSerializer } from "./serializer";

class PasswordChangeProdider extends ResourceProvider<PasswordChange> {
  constructor() {
    super("rest_employee_override", passwordChangeSerializer);
  }
}

export const passwordChangeService = new PasswordChangeProdider();
