import * as React from "react";
import TuneRoundedIcon from "@mui/icons-material/TuneRounded";
import {
	Checkbox,
	FormControl,
	FormControlLabel,
	FormGroup,
	IconButton,
	ListItemText,
	Menu,
	MenuItem,
	MenuList,
	NativeSelect,
	Tooltip,
} from "@mui/material";
import { SuccessManagerSelect } from "./SuccessManagerSelect";

export const DottedMenuForChatFilter = (props: any) => {
	const {
		country,
		successManagersList,
		successManagersFilterActionHandler,
		countryFilterActionHandler,
		successManagerId,
		read,
		readFilterActionHandler
	} = props;
	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
	const open = Boolean(anchorEl);
	const handleClick = (event: React.MouseEvent<HTMLElement>) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	return (
		<div>
			<Tooltip title={<h3>{"Chat Filters"}</h3>}>
				<IconButton
					aria-label="more"
					id="long-button"
					aria-controls={open ? "long-menu" : undefined}
					aria-expanded={open ? "true" : undefined}
					aria-haspopup="true"
					onClick={handleClick}
				>
					<TuneRoundedIcon sx={{ color: "text.primary" }} />
				</IconButton>
			</Tooltip>
			<Menu
				id="long-menu"
				MenuListProps={{
					"aria-labelledby": "long-button",
				}}
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				PaperProps={{
					sx: {
						backgroundColor: "background.default",
						height: "auto",
						width: "35ch",
					},
				}}
			>
				<MenuList>
					<MenuItem>
						<ListItemText sx={{ marginRight: "25px" }}>Country: </ListItemText>
						<FormControl fullWidth>
							<NativeSelect
								onChange={(evt) => {
									countryFilterActionHandler(evt);
								}}
								sx={{
									color: "text.primary",
								}}
								value={country}
								inputProps={{
									name: "country",
									id: "uncontrolled-native",
								}}
							>
								<option value={"georgia"}>Georgia</option>
								<option value={"ukraine"}>Ukraine</option>
								<option value={"armenia"}>Armenia</option>
							</NativeSelect>
						</FormControl>
					</MenuItem>
					<MenuItem disabled>
						<ListItemText sx={{ marginRight: "40px" }}>Succ. Manag.:</ListItemText>
						<SuccessManagerSelect
							successManagersList={successManagersList}
							successManagersFilterActionHandler={successManagersFilterActionHandler}
							successManagerId={successManagerId}
						/>
					</MenuItem>
					<MenuItem>
						<FormGroup>
							<FormControlLabel
								control={
									<Checkbox
										color="success"
										checked={read ? true : false}
										onChange={readFilterActionHandler}
									/>
								} label="Unread Messages" />
						</FormGroup>

					</MenuItem>
				</MenuList>
			</Menu>
		</div>
	);
};
