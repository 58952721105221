import { environment } from "../../environment/environment";
import { addressSerializer } from "../Address/serializer";
import { Serializer } from "../serializer";
import { successManagerSerializer } from "../successManagers/serializer";
import { User, UserPermissions, UserProfileInfoCardEdit } from "./user";

export class UserPermissionsSerializer implements Serializer {
  fromJson(array: any[]) {
    const permissions = new UserPermissions();
    // const roles = ["read", "write", "update", "delete", "export"]
    // for (var x = 0; x < roles.length; x++) {
    //     const [role] = array.filter(p => roles[x] === p.name);

    // }
    return permissions;
  }

  toJson(user: User) {
    const obj: any = {};

    return obj;
  }
}

export class UserSerializer implements Serializer {
  fromJson(json: any) {
    const user = new User();
    user.bankAccountNumber = json.bank_account_number;
    user.department = json.department;
    user.country = json.country;
    user.currentCard = json.current_card;
    user.successManager = json.success_manager;
    if (json.token) {
      user.accessToken = json.token;
    }
    user.refreshToken = json.refreshToken;
    if (json.user) {
      json = json.user;
    }
    user.id = json.user_id ? json.user_id : json.id;
    user.lastName = json.last_name;
    user.firstName = json.first_name;

    user.email = json.email ? json.email : json.id;

    user.fullName = json.full_name;
    user.phone = json.phone;
    user.roles = json.roles;
    if (json.addresses) {
      user.addresses = json.addresses.map((addr: any) => addressSerializer.fromJson(addr));
    }
    user.averageRating = json.average_rating;
    user.birthDate = json.birth_date;
    user.boughtProducts = json.bought_products;
    user.boughtProductsPrice = json.bought_products_price;
    user.created = json.created;
    user.followingCount = json.following_count ? json.following_count : 0;
    user.followersCount = json.followers_count ? json.followers_count : 0;
    user.image = `${environment.phubberProductionPath}/assets/imgs/users/${json.image}.jpg`;
    user.isCreator = json.is_creator;
    user.isFashionActivist = json.is_fashion_activist;
    user.listingsCount = json.listings_count ? json.listings_count : 0;
    user.name = json.name !== "None None" ? json.name : null;
    user.soldProducts = json.sold_products;
    user.soldProductsPrice = json.sold_products_price;
    if (json.success_manager_group) {
      user.successManagerGroup = successManagerSerializer.fromJson(json.success_manager_group);
    }
    user.updated = json.updated;
    user.username = json.username;
    user.appVersion = json.app_version;
    user.currentCard = json.current_card;
    user.isActive = json.is_active;
    user.promotionBalance = json.promotion_balance;
    user.color = json.color;
    return user;
  }

  toJson(user: User) {
    const obj: any = {};
    if (user.firstName) {
      obj.first_name = user.firstName;
    }
    if (user.lastName) {
      obj.last_name = user.lastName;
    }
    if (user.image) {
      obj.image = user.image;
    }
    if (user.email) {
      obj.email = user.email;
    }
    if (user.phone) {
      obj.phone = user.phone;
    }
    if (user.password) {
      obj.password = user.password;
    }
    if (user.id) {
      obj.id = user.id;
    }
    if (user.bankAccountNumber) {
      obj.bank_account_number = user.bankAccountNumber;
    }
    if (user.pushToken) {
      obj.push_token = user.pushToken;
    }

    return obj;
  }
}

export class UserProfileInfoCardEditSerializer implements Serializer {
  fromJson(json: any) {
    const data = new UserProfileInfoCardEdit();
    data.app_user = json.app_user;
    return data;
  }

  toJson(item: UserProfileInfoCardEdit) {
    const obj: any = {};
    obj.app_user = item.app_user;
    obj.param_name = item.param_name;
    obj.param_new_value = item.param_new_value;

    return obj;
  }
}

export const userPermissionsSerializer = new UserPermissionsSerializer();
export const userSerializer = new UserSerializer();
