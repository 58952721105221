import { Address } from "../Address/Address";
import { Offer } from "../Offer/Offer";
import { Product } from "../Product/Product";
import { Resource } from "../resource";
import { User } from "../user/user";

export interface OrderResponse {
    count: number;
    current: Order[];
    has_next: boolean;
    next: Order[];
}

export class Order extends Resource {
    brandName: string | null | undefined;
    address: Address | null | undefined;
    campaign: unknown | null | undefined;
    completedLogged: boolean | null | undefined;
    country: string | null | undefined;
    coupon: number | null | undefined;
    created: string | null | undefined;
    currency: string | null | undefined;
    discount: boolean | null | undefined;
    exchangeRate: number | null | undefined;
    id: string | null | undefined;
    initiatedLogged: boolean | null | undefined;
    method: string | null | undefined;
    offer: Offer | null | undefined;
    ownerPrice: number | null | undefined;
    packaging: string | null | undefined;
    paidWithSolo: boolean | null | undefined;
    parentCartId: string | null | undefined;
    paymentCurrency: string | null | undefined;
    paymentProcessing: string | null | undefined;
    paymentState: string | null | undefined;
    pickupAddress: unknown | null | undefined;
    processingState: string | null | undefined;
    product: Product | null | undefined;
    sellerAddress: Address | null | undefined;
    shippingMethod: null | undefined | unknown;
    shippingType: string | null | undefined;
    size: string | null | undefined;
    soldNotified: boolean | null | undefined;
    status: string | null | undefined;
    total: number | null | undefined;
    totalOriginal: number | null | undefined;
    totalToPay: number | null | undefined;
    uid: string | null | undefined;
    user: User | null | undefined;
} 

export class OrderOverride extends Resource {
    order: string | null | undefined
    paramName: string | null | undefined
    paramNewValue: string | null | undefined
}
