import { ResourceProvider } from "../../providers/resource.provider";
import { Issue, IssueType } from "./Issue";
import { IssueSerializer, IssueTypeSerializer } from "./serializer";

export class IssueTypeProvider extends ResourceProvider<IssueType> {
    constructor() {
        super("rest_issue_type", new IssueTypeSerializer())
    }
}

export class IssueProvider extends ResourceProvider<Issue> {
    constructor() {
        super("rest_issue", new IssueSerializer())
    }
}

export const issueTypeService = new IssueTypeProvider()
export const issueService = new IssueProvider()
