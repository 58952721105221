import { ResourceProvider } from "../../providers/resource.provider";
import { EngagedProductsLogs, PageLogs } from "./Logs";
import { engagedProductsLogsSerializer, pageLogsSerializer } from "./serializer";

class PageLogsServiceProvider extends ResourceProvider<PageLogs> {
    constructor() {
        super(
            "rest_log",
            pageLogsSerializer
        )
    }
}
class EngagedProductsLogsProvider extends ResourceProvider<EngagedProductsLogs> {
    constructor() {
        super(
            "rest_engaged_product",
            engagedProductsLogsSerializer
        )
    }
}

export const engagedProductsLogsService = new EngagedProductsLogsProvider()
export const pageLogsService = new PageLogsServiceProvider()