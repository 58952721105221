import { reviewSerializer } from './serializer';
import { ResourceProvider } from '../../providers/resource.provider';
import { Review } from './Review';

export class ServiceReviewProvider extends ResourceProvider<Review> {
    constructor() {
        super(
            "rest_service_review",
            reviewSerializer
        )
    }
}

export class ProductReviewProvider extends ResourceProvider<Review> {
    constructor() {
        super(
            "rest_product_review",
            reviewSerializer
        )
    }
}

export const serviceReviewService = new ServiceReviewProvider()
export const productReviewService = new ProductReviewProvider()