import { Grid } from '@mui/material'
import { UserProfileSocialStats } from './UserProfileSocialStats';
import { UserProfileUserBaseInfo } from './UserProfileUserBaseInfo';

export const UserProfileUpperSection = (props: any) => {
    const { userProfile } = props
    return (
        <Grid container item display={'flex'} flexDirection='column' sx={{ width: '100%', background: 'transparent' }} flex={5} alignItems='center'>
            <UserProfileUserBaseInfo
                userProfile={userProfile} />
            <UserProfileSocialStats userProfile={userProfile} />
        </Grid>
    )
}
