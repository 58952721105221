import { CloseRounded } from "@mui/icons-material";
import { Button, Dialog, DialogActions, DialogContent, Stack, Typography } from "@mui/material";
import { useRef } from "react";
import { connect } from "react-redux";
import { setSnack } from "../../models/application/applicationActions";
import { PasswordChange } from "../../models/PasswordChange/PasswordChange";
import { passwordChangeService } from "../../models/PasswordChange/service";
import { UserContextMenuForm } from "../Forms/UserContextMenuForm";

const UserContextMenuDialog = (props: any) => {
  const { open, setOpen, app, setSnack } = props;

  const onAlert = (text: string) => setSnack({ ...app.snack, open: true, text: text });

  const formikRef: any = useRef();

  const handleSubmit = () => {
    if (formikRef.current) formikRef.current.handleSubmit();
  };

  const submitForm = (state: { password: string; currentPassword: string }) => {
    const item = new PasswordChange();
    item.paramName = "password_hash";
    item.paramNewValue = state.password;
    passwordChangeService.create(item).then((r) => {
      if (r?.name === "AjaxError") {
        onAlert(`password did not change::error`);
      } else {
        onAlert(`password changed successfully::success`);
        setOpen(false);
      }
    });
  };

  return (
    <Dialog
      onKeyDown={(e) => (e.key === "Escape" ? setOpen(false) : null)}
      PaperProps={{
        sx: {
          borderRadius: "16px",
          width: "308px",
          padding: 0,
          position: "relative",
        },
      }}
      open={open}
      fullWidth
      maxWidth={"xs"}
    >
      <CloseRounded onClick={() => setOpen(false)} sx={{ position: "absolute", fontSize: "20px", top: 10, right: 10, cursor: "pointer" }} />
      <Stack padding={"36.5px 24px"} direction="column" gap={"24px"}>
        <Typography textAlign={"center"} fontSize={"16px"} fontWeight={600}>
          Change Passwrod
        </Typography>
        <DialogContent sx={{ padding: 0 }}>
          <UserContextMenuForm formikRef={formikRef} submitForm={submitForm} />
        </DialogContent>
        <DialogActions sx={{ padding: 0 }}>
          <Button
            onClick={handleSubmit}
            fullWidth
            sx={{
              textTransform: "none",
              backgroundColor: "rgba(68, 61, 246, 1)",
              borderRadius: "8px",
              "&.MuiButtonBase-root:hover": {
                backgroundColor: "rgba(68, 100, 246, 1)",
              },
              color: "white",
            }}
          >
            Change Password
          </Button>
        </DialogActions>
      </Stack>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => {
  return {
    mode: state.app.currentTheme,
    app: state.app,
  };
};

export default connect(mapStateToProps, { setSnack })(UserContextMenuDialog);
