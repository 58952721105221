import { ResourceProvider } from "../../providers/resource.provider";
import { Address } from "./Address";
import { addressSerializer, AddressSerializer } from "./serializer";

export class AddressProvider extends ResourceProvider<Address> {
    constructor() {
        super(
            'rest_user_address',
            new AddressSerializer())
    }
}

export const addressService = new AddressProvider()

export class AddressOverrideProvider extends ResourceProvider<Address> {
    constructor() {
        super(
            'rest_app_user_address_override',
            addressSerializer
        )
    }
}

export const addressOverrideService = new AddressOverrideProvider()