import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CopyIcon from "@mui/icons-material/CopyAll";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, Tooltip } from "@mui/material";
import { CSSProperties, useState } from "react";
import { Link } from "react-router-dom";
import { environment } from "../environment/environment";
import { paymentToGeo } from "../helpers/localTranslationDict";
import { Order } from "../models/Order/Order";
import { TypographyItem } from "./Typography/TypographyItem";

interface OrderMoreOnHoverProps {
  title: string;
  Case: string;
  item: Order;
  index: string;
  onAlert?: (text: string) => any;
}

interface CustomLinkProps {
  id?: string;
  email?: string;
  children?: any;
  style?: CSSProperties | undefined;
  isActive?: boolean;
}

const CustomLink = (props: CustomLinkProps) => {
  const { id, email, style, children, isActive } = props;
  if (isActive)
    return (
      <Link target="_blank" style={style} to={{ pathname: `/userDetails/${id}`, search: environment.baseQueryParams + "&tab=logs" }}>
        {children}
      </Link>
    );
  else return <Box sx={style}>{children}</Box>;
};

export const OrderMoreOnHover = (props: OrderMoreOnHoverProps) => {
  const { Case, index, item, onAlert = () => {} } = props;

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openOrderHover = Boolean(anchorEl);

  const handleClick = () => {
    setAnchorEl(document.getElementById(index));
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hoverableOrderCases = (Case: string, item: Order) => {
    switch (Case) {
      case "product":
        const productList = [
          { title: "პროდუქტის ID", value: item.product?.id },
          { title: "პროდუქტი", value: item.product?.name },
          { title: "პროდუქტის მდგომარეობა", value: item.product?.condition?.name }, //
          { title: "ზომა", value: item.product?.size?.name }, //
          { title: "ბრენდი", value: item.product?.brandName },
          { title: "აღწერა", value: item.product?.description },
          { title: "სექცია", value: item.product?.section?.name }, //
          { title: "კატეგორია", value: item.product?.category?.name }, //
          { title: "საბ-კატეგორია", value: item.product?.subcategory?.name }, //
          { title: "ფერი", value: item.product?.color?.name }, //
          { title: "დეპარტამენტი", value: item.product?.department },
        ];
        return <MenuList>{productList.map((e: any, index: number) => orderPopperItem(e.title, e.value, index))}</MenuList>;
      case "offer":
        if (!item.offer)
          return (
            <MenuList>
              <Box sx={{ color: "text.primary" }}>შეთავაზების ინფორმაცია არ არის</Box>
            </MenuList>
          );
        else {
          const offerList = [
            { title: "შეთავაზების ID", value: item.offer.id },
            { title: "ინტერაქციის ID", value: item.offer.interactionUid },
          ];
          return <MenuList>{offerList.map((e: any, index: number) => orderPopperItem(e.title, e.value, index))}</MenuList>;
        }
      case "buyer":
        //To Be Updated
        const buyerList = [
          { title: "მომხმარებლის ქვეყანა", value: item.user?.country },
          { title: "მყიდველის ელ-ფოსტა", value: item.user?.id },
          {
            title: "მყიდველის სრული სახელი",
            value: item.user?.firstName + " " + item.user?.lastName,
          },
          { title: "მყიდველის ID", value: item.user?.id },
          { title: "მყიდველის საქსეს მენეჯერი", value: item.user?.successManager },
          { title: "მყიდველის ტელეფონის ნომერი", value: item.user?.phone },
          { title: "მყიდველის ქალაქი", value: item.address?.city },
          { title: "მყიდველის ქუჩა", value: item.address?.street },
          { title: "მყიდველის შენობის ID", value: item.address?.buildingId },
          { title: "მყიდველის ბანკის ანგარიში", value: item.user?.bankAccountNumber },
          { title: "მყიდველის ბარათის ნომერი", value: item.user?.currentCard },
        ];
        return <MenuList>{buyerList.map((e: any, index: number) => orderPopperItem(e.title, e.value, index))}</MenuList>;
      case "seller":
        if (!item.product?.productUser)
          return (
            <MenuList>
              <Box sx={{ color: "text.primary" }}>გამყიდველის ინფორმაცია არ არის</Box>
            </MenuList>
          );
        else {
          const sellerList = [
            { title: "გამყიდველის ელ-ფოსტა", value: item.product?.productUser?.id },
            {
              title: "გამყიდველის სრული სახელი",
              value: item.product?.productUser?.firstName + " " + item.product?.productUser?.lastName,
            },
            { title: "გამყიდველის ID", value: item.product?.productUser?.id },
            {
              title: "გამყიდველის საქსეს მენეჯერი",
              value: item.product?.productUser?.successManager,
            },
            { title: "გამყიდველის ტელეფონის ნომერი", value: item.product?.productUser?.phone },
            {
              title: "გამყიდველის ბანკის ანგარიში",
              value: item.product?.productUser?.bankAccountNumber,
            },
            { title: "გამყიდველის ქალაქი", value: item.sellerAddress?.city },
            { title: "გამყიდველის ქუჩა", value: item.sellerAddress?.street },
            { title: "გამყიდველის შენობის ID", value: item.sellerAddress?.buildingId },
            { title: "გამყიდველის ბარათის ნომერი", value: item.product?.productUser?.currentCard },
          ];
          return <MenuList>{sellerList.map((e: any, index: number) => orderPopperItem(e.title, e.value, index))}</MenuList>;
        }

      case "method":
        const paymentList = [
          { value: item.paymentState, title: "გადახდის მდგომარეობა" },
          { value: item.method, title: "გადახდის მეთოდი" },
          { value: item.paidWithSolo, title: "ვიზით გადახდილი" },
          { value: item.paymentProcessing, title: "გადახდის დამუშავება" },
        ];
        return <MenuList>{paymentList.map((e: any, index: number) => orderPopperItem(e.title, e.value, index))}</MenuList>;

      case "total":
        const totalList = [
          { value: item.totalOriginal, title: "ჯამი ორიგინალი" },
          { value: item.total, title: "ჯამი (გადახდილი)" },
          { value: item.ownerPrice, title: "მფლობელის ფასი" },
          { value: item.currency, title: "ვალუტა" },
        ];
        return <MenuList>{totalList.map((e: any, index: number) => orderPopperItem(e.title, e.value, index))}</MenuList>;

      default:
        break;
    }
  };

  const orderCasesWithManyFieldsToDisplay = (List: any, linkIf: boolean = false, id: string = "", email: string = "") => {
    return (
      <MenuList sx={{ textAlign: "left", display: "flex" }} onMouseLeave={handleClose}>
        <CustomLink
          isActive={linkIf}
          id={id}
          email={email}
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            textDecoration: "none",
          }}
        >
          {List.map((e: any, idx: number) => (
            <MenuItem id={idx === 0 ? index : ""} key={index + idx + "singleOrderItem"} sx={{ gap: "30px", padding: "3px" }}>
              {e.title && e.name && (
                <Tooltip title={e.title ? <TypographyItem text={e.title} color={"white"} fontWeight={400} /> : ""}>
                  <ListItemText>
                    <TypographyItem text={e.name} color={"text.primary"} fontWeight={500} />
                  </ListItemText>
                </Tooltip>
              )}
              {e.itm ? (
                <Box
                  sx={{
                    width: "auto",
                    color: "text.primary",
                    fontFamily: "FiraGo",
                    fontWeight: e.bolder ? "600" : "400",
                    fontSize: 15,
                    textAlign: "right",
                  }}
                >
                  {e.itm}
                </Box>
              ) : (
                <RemoveCircleIcon
                  sx={{
                    fontSize: "18px",
                    fontWeight: "500",
                    color: "text.cardInfo",
                  }}
                />
              )}
            </MenuItem>
          ))}
        </CustomLink>
        <InfoOutlinedIcon onMouseEnter={handleClick} sx={{ fontSize: "18px", cursor: "pointer" }} />
      </MenuList>
    );
  };

  const orderCases = (Case: string, item: Order) => {
    switch (Case) {
      case "method":
        return orderListItem(paymentToGeo[item.method as string], index);
      case "total":
        const totalList = [
          {
            itm: item.total + `${item.currency === "gel" ? "₾" : "₴"}`,
            name: "ჯამი",
            title: "ჯამი (გადახდილი)",
          },
          {
            itm: item.ownerPrice + `${item.currency === "gel" ? "₾" : "₴"}`,
            name: "მფ.ფა",
            title: "მფლობელის ფასი",
          },
        ];
        return orderCasesWithManyFieldsToDisplay(totalList);

      case "offer":
        return item.offer ? (
          <CheckCircleRoundedIcon sx={{ fontSize: "18px" }} id={index} onMouseEnter={handleClick} onMouseLeave={handleClose} />
        ) : (
          <RemoveCircleIcon sx={{ fontSize: "18px" }} id={index} onMouseEnter={handleClick} onMouseLeave={handleClose} />
        );

      case "product":
        return orderListItem(item.product?.name, index);
      case "buyer":
        const buyerList = [
          { itm: item.user?.id, bolder: true },
          { itm: `${item.user?.successManager}`, bolder: false },
          { itm: `${item.user?.phone}`, bolder: false },
        ];
        return orderCasesWithManyFieldsToDisplay(buyerList, true, item.user?.id!, item.user?.email!);
      case "seller":
        const sellerList = [
          { itm: item.product?.productUser?.id, bolder: true },
          { itm: `${item.product?.productUser?.successManager}`, bolder: false },
          { itm: `${item.product?.productUser?.phone}`, bolder: false },
        ];
        return orderCasesWithManyFieldsToDisplay(sellerList, true, item.product?.productUser?.id!, item.product?.productUser?.email!);
      default:
        return "soon...";
    }
  };

  const orderListItem = (value: any, index: any) => {
    return (
      <MenuList sx={{ display: "flex", justifyContent: "center", width: "auto", position: "relative" }}>
        <MenuItem
          onMouseLeave={handleClose}
          id={index}
          sx={{
            gap: "20px",
            padding: "3px",
            width: "auto",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box
            sx={{
              fontSize: 13,
              width: "165px",
              whiteSpace: "normal",
              wordBreak: "break-word",
              display: "-webkit-box",
              WebkitBoxOrient: "vertical",
              WebkitLineClamp: 2,
              overflow: "hidden",
              textOverflow: "ellipsis",
              color: "text.primary",
              fontFamily: "FiraGo",
              fontWeight: "500",
              padding: "0 20px 0 20px",
            }}
          >
            {value}
          </Box>
          <InfoOutlinedIcon
            sx={{
              fontSize: "18px",
              position: "absolute",
              right: "5px",
            }}
            onMouseEnter={handleClick}
          />
        </MenuItem>
      </MenuList>
    );
  };

  const orderPopperItem = (title: string, value: any, index: number) => {
    return (
      <MenuItem sx={{ gap: "20px" }} key={index}>
        <ListItemText>
          <TypographyItem text={title} />
        </ListItemText>
        {value ? (
          <TypographyItem
            sx={{
              width: "200px",
              whiteSpace: "normal",
              textAlign: "right",
              wordBreak: "break-word",
            }}
            text={value || ""}
            fontWeight={500}
          />
        ) : (
          <RemoveCircleIcon
            sx={{
              fontSize: "15px",
              fontWeight: "500",
              color: "text.cardInfo",
            }}
          />
        )}
        <ListItemIcon>
          <CopyIcon
            onClick={() => {
              navigator.clipboard.writeText(value);
              onAlert(
                `ინფორმაცია ${value === null || value === undefined || value === false ? "ვერ" : ""} დაკოპირდა : ${value}::${
                  value === null || value === undefined || value === false ? "error" : "success"
                }`
              );
            }}
          />
        </ListItemIcon>
      </MenuItem>
    );
  };

  return (
    <Box>
      <Box sx={{ width: "auto" }}>{orderCases(Case, item)}</Box>
      <Menu
        onMouseEnter={handleClick}
        onClose={handleClose}
        open={openOrderHover}
        anchorEl={anchorEl}
        sx={{ zIndex: 3, display: "flex", justifyContent: "space-evenly" }}
      >
        {hoverableOrderCases(Case, item)}
      </Menu>
    </Box>
  );
};
