import { Resource } from "../resource";
import { Task } from "../Task/Task";
import { User } from "../user/user";

export class Comments extends Resource {
    id: string | undefined
    text: string | undefined
    create: Date | undefined
    is_enabled: Boolean | undefined
}

export class Features extends Resource {
    name: string | undefined
    description: string | undefined
    comments: Comments | any
    docs: string | undefined
    tasks: Task | undefined
    images: any | undefined
    created: Date | undefined
    user: User | undefined
    is_enabled: Boolean | undefined
}