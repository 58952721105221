import { AccountBoxOutlined } from "@mui/icons-material";
import { Grid, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";

interface EmployeeProfilePageProfileProps {
  displayHeight?: number;
  displayWidth?: number;
  displayEmail?: string;
  displayName?: string;
}

export const EmployeeProfilePageProfile = (props: EmployeeProfilePageProfileProps) => {
  const { displayHeight = 50, displayWidth = 50, displayEmail, displayName } = props;

  const displayImageSrc = "";
  const [image, setImage] = useState<string | any>(null);

  useEffect(() => {
    if (displayImageSrc) {
      setImage(() => {
        if (displayImageSrc === "null") return null;
        else return displayImageSrc;
      });
    }

    return () => {
      setImage(null);
    };
  }, [displayImageSrc]);

  return (
    <Grid
      item
      container
      boxSizing={"border-box"}
      direction={"row"}
      gap={"5px"}
      justifyContent={"flex-start"}
      alignItems={"center"}
      height={`${displayHeight}px`}
    >
      {image ? (
        <Grid
          component={"img"}
          onError={() => setImage(null)}
          src={image}
          sx={{
            height: `${displayHeight}px`,
            width: `${displayWidth}px`,
            objectFit: "cover",
          }}
        />
      ) : (
        <AccountBoxOutlined
          sx={{
            border: "2px solid grey",
            borderRadius: 2,
            fontSize: `${displayHeight}px` || `${displayWidth}px`,
            color: "text.primary",
          }}
        />
      )}
      <Grid item lg xs container justifyContent={"center"} gap={"10px"} direction="column" height={"100%"}>
        <Typography
          sx={{
            lineHeight: "20px",
            fontSize: "1.1em",
            width: "fit-content",
            textAlign: "left",
            maxWidth: "100%",
          }}
          fontWeight={"600"}
          variant={"subtitle1"}
        >
          {displayName}
        </Typography>
        <Tooltip title={displayEmail}>
          <Typography
            sx={{
              lineHeight: "20px",
              fontSize: "0.9em",
              textAlign: "left",
              WebkitLineClamp: 1,
              textOverflow: "ellipsis",
              width: "fit-content",
              maxWidth: "100%",
              display: "-webkit-box",
              wordBreak: "break-word",
              overflow: "hidden",
              whiteSpace: "normal",
              WebkitBoxOrient: "vertical",
            }}
          >
            {displayEmail}
          </Typography>
        </Tooltip>
      </Grid>
    </Grid>
  );
};
