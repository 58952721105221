import { ResourceProvider } from "../../providers/resource.provider";
import { Product, ProductOverride } from "./Product";
import { productOverrideSerializer, productSerializer } from "./serializer";

class ProductServiceProvider extends ResourceProvider<Product> {
    constructor() {
        super("rest_product", productSerializer);
    }
}

export const productService = new ProductServiceProvider()

class ProductOverrideProvider extends ResourceProvider<ProductOverride> {
    constructor() {
        super(
            "rest_product_data_override",
            productOverrideSerializer
        )
    }
}

export const productOverrideService = new ProductOverrideProvider()