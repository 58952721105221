import CheckCircleRoundedIcon from "@mui/icons-material/CheckCircleRounded";
import CircleIcon from "@mui/icons-material/Circle";
import EditIcon from "@mui/icons-material/Edit";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import { Box, IconButton, ListItemIcon, ListItemText, Menu, MenuItem, MenuList, TableCell } from "@mui/material";
import _ from "lodash";
import { useState } from "react";
import { Link } from "react-router-dom";
import { environment } from "../environment/environment";
import { countryToGeo, shippingToGeo } from "../helpers/localTranslationDict";
import { Order, OrderOverride } from "../models/Order/Order";
import { orderOverrideService } from "../models/Order/service";
import { ConfirmationDialog } from "./Dialogs/ConfirmationDialog";
import { ImageViewerMain } from "./ImageViewer/ImageViewerMain";
import { OrderMoreOnHover } from "./OrderMoreOnHover";
import { TypographyItem } from "./Typography/TypographyItem";

const OrderMenuOverride = (props: any) => {
  const { value, overridableList, orderId, onAlert, paramName, setSubmitted, index } = props;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [width, setWidth] = useState(0);
  const open = Boolean(anchorEl);
  const [confirmation, setConfirmation] = useState<any>({
    text: "",
    open: false,
    title: "",
    order: null,
  });
  const handleClick = () => {
    const target = document.getElementById(paramName + index);
    const width = target?.offsetWidth;
    setWidth(width!);
    setAnchorEl(target);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOverride = (item: string) => {
    setConfirmation({
      text: `Do you wish to update ${paramName}?`,
      open: true,
      title: `${paramName} Updating`,
      order: item,
    });
  };
  return (
    <Box>
      <MenuItem sx={{ cursor: "default" }} id={paramName + index}>
        <ListItemText>{value}</ListItemText>
        <ListItemIcon>
          <IconButton onClick={handleClick}>
            <EditIcon sx={{ fontSize: "18px" }} />
          </IconButton>
        </ListItemIcon>
      </MenuItem>
      <Menu
        id="long-menu"
        MenuListProps={{
          "aria-labelledby": "long-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        PaperProps={{
          sx: {
            backgroundColor: "background.default",
            height: "auto",
            width: "fit-content",
            minWidth: `${width}px`,
          },
        }}
      >
        <MenuList>
          {overridableList?.map((item: any, index: number) => (
            <MenuItem key={index} onClick={() => handleOverride(item)}>
              <TypographyItem text={item} color={"text.primary"} fontWeight={500} fontSize={15} />
            </MenuItem>
          ))}
          <ConfirmationDialog
            open={confirmation.open}
            text={confirmation.text}
            title={confirmation.title}
            callback={(c: any) => {
              if (c) {
                const orderOverride = new OrderOverride();
                orderOverride.order = orderId;
                orderOverride.paramName = paramName;
                orderOverride.paramNewValue = confirmation.order;
                orderOverrideService.create(orderOverride).then((r: any) => {
                  if (r?.name === "AjaxError") {
                    onAlert(`order did not update::error`);
                  } else {
                    onAlert(`order updated successfully::success`);
                    setSubmitted((prev: any) => !prev);
                    handleClose();
                  }
                });
              }
              setConfirmation({
                text: "",
                open: false,
                title: "",
                order: null,
              });
            }}
          />
        </MenuList>
      </Menu>
    </Box>
  );
};

export const OrdersTableColumn = (props: any) => {
  const { table, item, palette, idx, onAlert, setSubmitted, processingStateList, statusList } = props;

  const oneLinerRenderer = (item: any) => {
    return <TypographyItem text={item} color={"text.primary"} fontWeight={500} />;
  };

  const NonToolTipCases = (Case: string, item: Order | any, index: string) => {
    switch (Case) {
      case "uid":
        return item.uid ? oneLinerRenderer(item.uid) : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "buyerColor":
        return item.user?.color ? <CircleIcon sx={{ color: `${item.user?.color}`, fontSize: "30px" }} /> : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "sellerColor":
        return item.product?.productUser?.color ? (
          <CircleIcon sx={{ color: `${item.product?.productUser?.color}`, fontSize: "30px" }} />
        ) : (
          <RemoveCircleIcon sx={{ fontSize: "18px" }} />
        );
      case "image":
        return item.product?.image ? (
          <Box
            sx={{
              contain: "content",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <ImageViewerMain displayImageSrc={`${environment.productImages}${item.product?.image}`} />
          </Box>
        ) : (
          <RemoveCircleIcon sx={{ fontSize: "18px" }} />
        );
      case "processingState":
        return item?.processingState ? (
          <OrderMenuOverride
            value={oneLinerRenderer(item.processingState)}
            overridableList={processingStateList}
            orderId={item.uid}
            onAlert={onAlert}
            paramName={"processing_state"}
            setSubmitted={setSubmitted}
            index={index}
          />
        ) : (
          <RemoveCircleIcon sx={{ fontSize: "18px" }} />
        );
      case "shippingType":
        return item?.shippingType ? oneLinerRenderer(shippingToGeo[item.shippingType]) : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "discount":
        return item?.discount ? <CheckCircleRoundedIcon sx={{ fontSize: "18px" }} /> : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "status":
        return item?.status ? (
          <OrderMenuOverride
            value={oneLinerRenderer(item.status)}
            overridableList={statusList}
            orderId={item.uid}
            onAlert={onAlert}
            paramName={"status"}
            setSubmitted={setSubmitted}
            index={index}
          />
        ) : (
          <RemoveCircleIcon sx={{ fontSize: "18px" }} />
        );
      case "created":
        return item?.created ? (
          <Box sx={{ width: "150px" }}>
            {oneLinerRenderer(new Date(item.created).toLocaleDateString())}
            {oneLinerRenderer(new Date(item.created).toLocaleString("en-US", { hour: "2-digit", minute: "2-digit" }))}
          </Box>
        ) : (
          <RemoveCircleIcon sx={{ fontSize: "18px" }} />
        );
      case "country":
        return _.get(item, Case) ? oneLinerRenderer(countryToGeo[_.get(item, Case)!]) : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "productUser.country":
        return _.get(item.product, Case) ? oneLinerRenderer(countryToGeo[_.get(item?.product, Case)]) : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "user.country":
        return _.get(item, Case) ? oneLinerRenderer(countryToGeo[_.get(item, Case)]) : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "coupon":
        return item?.coupon ? oneLinerRenderer(item?.coupon) : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "packaging":
        return item?.packaging ? oneLinerRenderer(item.packaging) : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      case "id":
        return item.product?.id ? (
          <Link
            target="_blank"
            style={{ cursor: "pointer", textDecoration: "none" }}
            to={{
              pathname: "/products",
              search: `${environment.baseQueryParams.replace("created", "uploaded")}&search=${item.product.id}`,
            }}
          >
            {oneLinerRenderer(item.product?.id)}
          </Link>
        ) : (
          <RemoveCircleIcon sx={{ fontSize: "18px" }} />
        );
      case "brand":
        return item.product?.brandName ? oneLinerRenderer(item.product?.brandName) : <RemoveCircleIcon sx={{ fontSize: "18px" }} />;
      default:
        break;
    }
  };
  return table?.map((e: any, index: string) => {
    if (!e.tooltip && e.enabled) {
      return (
        <TableCell
          padding="checkbox"
          size="small"
          align="center"
          key={index + "row"}
          sx={{
            color: palette.text.primary,
            width: "auto",
          }}
        >
          {NonToolTipCases(e.getBy, item, idx)}
        </TableCell>
      );
    } else if (e.enabled) {
      return (
        <TableCell key={index} sx={{ width: "auto" }} padding="checkbox" size="small" align="center">
          <OrderMoreOnHover index={index + "row" + idx} title={"Hover For Details..."} Case={e.getBy} item={item} onAlert={onAlert} />
        </TableCell>
      );
    } else return null;
  });
};
