import CloseIcon from "@mui/icons-material/Close";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { QueryOptions } from "../../helpers/query.options";
import { addressOverrideService } from "../../models/Address/service";
import { cityService } from "../../models/City/service";
import { countryService } from "../../models/Country/service";
import { novaPoshtaService } from "../../models/NovaPoshta/service";
import { AddressForm } from "../Forms/AddressForm";

const AddressFormDialog = (props: any) => {
  const { open, handleDialog, address, onAlert, setSubmitted, title, userId } = props;

  const formikRef: any = useRef();

  const handleSubmit = () => {
    if (formikRef.current) {
      formikRef.current.handleSubmit();
    }
  };

  const [addressInnerLists, setAddressInnerLists] = useState<{ [key: string]: any[] }>({
    novaPoshta: [],
    country: [],
    city: [],
  });

  useEffect(() => {
    if (open) {
      const options = new QueryOptions();
      novaPoshtaService.listWithCounter(options).then((res) => {
        setAddressInnerLists((prev) => {
          return { ...prev, novaPoshta: res.current };
        });
      });
      countryService.listWithCounter(options).then((res) => {
        setAddressInnerLists((prev) => {
          return { ...prev, country: res.current };
        });
      });
      cityService.listWithCounter(options).then((res: any) => {
        setAddressInnerLists((prev) => {
          return { ...prev, city: res.current };
        });
      });
    }
  }, [open]);

  const handleSearchOnChange = (search: string) => {
    const options = new QueryOptions();
    options.pageSize = 25;
    if (search?.length) {
      options.search = search;
    }
    novaPoshtaService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setAddressInnerLists((prev) => {
          return { ...prev, novaPoshta: r.current };
        });
      }
    });
  };

  const postForm = (values: any) => {
    const addrs: any = {};
    addrs.addressName = values.addressName;
    addrs.country = values.country?.id;
    addrs.city = values.city?.id;
    addrs.street = values.street;
    addrs.entrance = values.entrance;
    addrs.floor = values.floor;
    addrs.apartment = values.apartment;
    addrs.firstName = values.firstName;
    addrs.lastName = values.lastName;
    addrs.buildingId = values.buildingId;
    addrs.googlePlaceId = values.googlePlaceId;
    addrs.personalId = values.personalId;
    addrs.novaPoshtaWarehouse = values.novaPoshtaWarehouse?.id;
    addrs.comment = values.comment;
    addrs.isMain = values.isMain;
    addrs.phone = values.phone;
    addrs.postalCode = values.postalCode;
    addrs.recipient = values.recipient;
    addrs.type = values.type;
    addrs.appUser = userId;
    if (address?.uid) {
      addrs.id = address.uid;
      addressOverrideService.update(addrs).then((r: any) => {
        if (r?.name === "AjaxError") {
          onAlert(`address did not update::error`);
        } else {
          onAlert(`address updated successfully::success`);
          setSubmitted((prev: any) => !prev);
          handleDialog(false);
        }
      });
    } else {
      addressOverrideService.create(addrs).then((r: any) => {
        if (r?.name === "AjaxError") {
          onAlert(`address did not create::error`);
        } else {
          onAlert(`address created successfully::success`);
          setSubmitted((prev: any) => !prev);
          handleDialog(false);
        }
      });
    }
  };

  return (
    <Dialog open={open} fullWidth={true} maxWidth={"md"}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <Box
          onClick={() => handleDialog(false)}
          sx={{
            position: "absolute",
            right: 10,
            top: 10,
            cursor: "pointer",
            color: "text.primary",
          }}
        >
          <CloseIcon color="inherit" />
        </Box>
        <AddressForm
          address={address}
          postForm={postForm}
          searchOnChange={handleSearchOnChange}
          novaPoshta={addressInnerLists?.novaPoshta}
          formikRef={formikRef}
          country={addressInnerLists?.country}
          city={addressInnerLists?.city}
        />
      </DialogContent>
      <DialogActions>
        <Button color="secondary" onClick={() => handleDialog(false)}>
          Exit
        </Button>
        <Button onClick={handleSubmit} color="secondary" type="submit" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
  };
};

export default connect(mapStateToProps)(AddressFormDialog);
