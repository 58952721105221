import { Grid, Box, TextField, Button } from '@mui/material';
import { Form, Formik } from 'formik';

export default function IssueComponentCreatorForm({ createComponent, handleOpenState }: any) {
    return (
        <Formik
            initialValues={{
                id: '',
                firtName: '',
                lastName: ''
            }}
            onSubmit={createComponent}
        >
            {({
                setFieldValue,
                handleBlur,
                handleSubmit,
                errors
            }: any) => (
                <Form onSubmit={(values) => {
                    handleSubmit(values)
                }}>
                    <Grid
                        spacing={0}
                        item
                        container
                        direction='column'
                    >
                        <Grid item lg={12} xs={12} container>
                            <TextField
                                sx={{
                                    "& .MuiInputLabel-root.Mui-focused": {
                                        color: 'text.primary'
                                    },
                                    "& .MuiInputLabel-root": {
                                        color: 'text.primary'
                                    },
                                    "& .MuiInputBase-root": {
                                        color: 'text.primary',
                                        border: `1px text.primary solid`
                                    },
                                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        borderColor: `text.primary`,
                                        borderWidth: '2px'
                                    },
                                }}
                                required={true}
                                name="component"
                                autoFocus
                                margin="dense"
                                variant="outlined"
                                id="component"
                                label="კომპონენტი"
                                type="text"
                                fullWidth
                                onChange={(event: any) => {
                                    setFieldValue("name", event.target.value);

                                }}
                            />
                        </Grid>
                        <Grid
                            item
                            lg={3}
                            xs={3}
                            sx={{
                                display: 'flex',
                                flexDirection: 'column',
                            }}
                        >
                            <Button
                                sx={{
                                    color: 'text.primary',
                                    borderColor: 'text.cardInfo',
                                    "&:hover": {
                                        borderColor: 'text.cardInfo',
                                        backgroundColor: 'lightgrey'
                                    },
                                    marginBottom: '10px'
                                }}
                                variant='outlined'
                                onClick={handleOpenState(false)}
                            >
                                გამოსვლა
                            </Button>
                            <Button
                                type="submit"
                                style={{
                                    backgroundColor: "#4169E1",
                                    color: "white",
                                }}
                                variant="contained">
                                დამახსოვრება
                            </Button>
                        </Grid>
                    </Grid>
                </Form>
            )}
        </Formik>
    )
}
