import { Serializer } from "../serializer";
import { Badge } from "./Badge";

export class BadgeSerializer implements Serializer {
    fromJson(json: Badge) {
        const item = new Badge()
        item.chat_count = json.chat_count
        item.message = json.message
        return item;
    }
    toJson(item: Badge) {
        return item
    }

}