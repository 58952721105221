import { HighlightOff, Search } from "@mui/icons-material";
import { Box, createTheme, Divider, Grid, GridProps, InputAdornment, Stack, StackProps, styled, TextField, ThemeProvider, useTheme } from "@mui/material";
import { grey } from "@mui/material/colors";
import { DesktopDatePicker, DesktopDatePickerProps, LocalizationProvider, MobileDatePicker, MobileDatePickerProps } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { ChangeEvent, useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { connect } from "react-redux";
import { Subject } from "rxjs";
import { debounceTime } from "rxjs/operators";

const ResponseiveGrid = styled((props: GridProps & { contenttype: "mobile" | "desktop" | "visible" }) => <Grid {...props} />)(({ theme, contenttype }) => ({
  ...(contenttype === "desktop" && {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    [theme.breakpoints.down("md")]: {
      // width: "50%",
    },
  }),
  ...(contenttype === "mobile" && {
    [theme.breakpoints.up("sm")]: {
      display: "none",
    },
  }),
}));

const ResponseiveGridContainer = styled((props: GridProps & { withBirthday?: boolean }) => <Grid {...props} />)(({ theme, withBirthday = false }) => ({
  display: "grid",
  gap: 20,
  gridTemplateColumns: "repeat(5, auto)",
  gridTemplateAreas: `'a b d e f'`,
  ...(withBirthday && {
    gridTemplateColumns: "repeat(6, auto)",
    gridTemplateAreas: `'a b c d e f'`,
  }),
  justifyContent: "start",
  [theme.breakpoints.down("md")]: {
    gridTemplateColumns: "repeat(12, auto)",
    gridTemplateRows: "auto",
    gridTemplateAreas: `'a a a a b b b b c c c c' 'd d d d e e e e f f f f'`,
  },
  [theme.breakpoints.down("sm")]: {
    gridTemplateColumns: "100%",
    gridTemplateRows: "auto",
    gridTemplateAreas: `'a' 'b' 'c' 'd' 'e' 'f'`,
    justifyContent: "start",
  },
}));

interface AnimatedComponentProps {
  isVisible?: boolean;
  children: JSX.Element;
}

const AnimatedComponent = (props: AnimatedComponentProps) => {
  const { isVisible = false, children } = props;
  return (
    <Animated animationIn={"slideInRight"} animationOut={"slideOutRight"} animationInDuration={200} animationOutDuration={150} isVisible={isVisible}>
      {children}
    </Animated>
  );
};

const SearchDateFilter = (props: any) => {
  const { setSearch, setFrom, setTo, setBirthDay, birthDay, from, to, withBirthDay = false, search, mode, children = [null, null] } = props;

  const [onSearch$] = useState<any>(new Subject().pipe(debounceTime(800)));
  const [searchOnHold, setSearchOnHold] = useState("");

  useEffect(() => {
    setSearchOnHold(search);
  }, [search]);

  useEffect(() => {
    onSearch$.subscribe((debounced: { [key: string]: string }) => {
      setSearch({ searchValue: debounced });
    });
  }, []);

  const handleFromDateChange = (value: any, keyboardInputValue?: string | undefined) => {
    setFrom(value.toLocaleDateString());
  };

  const handleToDateChange = (value: any, keyboardInputValue?: string | undefined) => {
    setTo(value.toLocaleDateString());
  };

  const handleBirthDayDateChange = (value: any, keyboardInputValue?: string | undefined) => {
    setBirthDay(value.toLocaleDateString());
  };

  const onSearchChange = (event: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    setSearchOnHold(event.target.value);
    onSearch$.next(event.target.value);
  };

  const localTheme = createTheme({
    palette: {
      mode: mode,
      primary: {
        main: mode === "dark" ? grey[100] : grey[900],
      },
    },
    typography: {
      fontSize: 13,
    },
    components: {
      MuiSelect: {
        styleOverrides: {
          select: {
            fontSize: 13,
          },
        },
      },
    },
  });

  const theme = useTheme()

  return (
    <ThemeProvider theme={localTheme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <ResponseiveGridContainer container item>
          <ResponseiveGrid gridArea={"a"} contenttype="visible">
            {children[0]}
          </ResponseiveGrid>
          <Grid gridArea={"b"}>
            <TextField
              value={searchOnHold || ""}
              sx={{
                [theme.breakpoints.down("sm")]: {
                  width: "80vw"
                },
                "& .MuiInputLabel-root": {
                  color: "text.primary",
                },
                "& .MuiInput-underline:before": {
                  borderBottomColor: "text.primary",
                },
                "& .MuiInput-root:after": {
                  borderBottomColor: "text.primary",
                },
                "& .MuiInput-root:hover:not(.Mui-disabled):before": {
                  borderBottomColor: "text.primary",
                },
                "& .MuiInputLabel-root.Mui-focused": {
                  color: "text.primary",
                  fontWeight: "bold",
                },
              }}
              label="Search"
              variant="standard"
              onChange={onSearchChange}
              onKeyDown={(evt) => {
                if (evt.key === "Enter") {
                  evt.preventDefault();
                }
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
                endAdornment: (
                  <InputAdornment position="start" sx={{ width: "40px", overflow: "hidden", height: "100%" }}>
                    <Box>
                      {!searchOnHold.length ? (
                        <></>
                      ) : (
                        <AnimatedComponent isVisible={searchOnHold.length ? true : false}>
                          <HighlightOff
                            sx={{ cursor: "pointer" }}
                            onClick={() => {
                              onSearch$.next("");
                              setSearchOnHold("");
                            }}
                          />
                        </AnimatedComponent>
                      )}
                    </Box>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>
          {withBirthDay && (
            <ResponseiveGrid gridArea={"c"} contenttype="desktop">
              <DesktopDatePicker
                label={"Birthday"}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} onMouseDown={(e) => e.preventDefault()} />}
                onChange={handleBirthDayDateChange}
                value={birthDay ? birthDay.split("/").reverse().join("-") : new Date()}
              />
            </ResponseiveGrid>
          )}
          <ResponseiveGrid gridArea={"d"} contenttype="desktop">
            <DesktopDatePicker
              label={"From..."}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} onMouseDown={(e) => e.preventDefault()} />}
              onChange={handleFromDateChange}
              value={from ? from.split("/").reverse().join("-") : new Date()}
            />
          </ResponseiveGrid>
          <ResponseiveGrid gridArea={"e"} contenttype="desktop">
            <DesktopDatePicker
              label={"To..."}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} onMouseDown={(e) => e.preventDefault()} />}
              onChange={handleToDateChange}
              value={to ? to.split("/").reverse().join("-") : new Date()}
            />
          </ResponseiveGrid>
          {withBirthDay && (
            <ResponseiveGrid gridArea={"c"} contenttype="mobile">
              <MobileDatePicker
                InputProps={{
                  sx: {
                    width: "80vw",
                  },
                }}
                label={"Birthday"}
                inputFormat="dd/MM/yyyy"
                renderInput={(params) => <TextField {...params} onMouseDown={(e) => e.preventDefault()} />}
                onChange={handleBirthDayDateChange}
                value={birthDay ? birthDay.split("/").reverse().join("-") : new Date()}
              />
            </ResponseiveGrid>
          )}
          <ResponseiveGrid gridArea={"d"} contenttype="mobile">
            <MobileDatePicker
              InputProps={{
                sx: {
                  width: "80vw",
                },
              }}
              label={"From..."}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} onMouseDown={(e) => e.preventDefault()} />}
              onChange={handleFromDateChange}
              value={from ? from.split("/").reverse().join("-") : new Date()}
            />
          </ResponseiveGrid>
          <ResponseiveGrid gridArea={"e"} contenttype="mobile">
            <MobileDatePicker
              InputProps={{
                sx: {
                  width: "80vw",
                },
              }}
              label={"To..."}
              inputFormat="dd/MM/yyyy"
              renderInput={(params) => <TextField {...params} onMouseDown={(e) => e.preventDefault()} />}
              onChange={handleToDateChange}
              value={to ? to.split("/").reverse().join("-") : new Date()}
            />
          </ResponseiveGrid>
          <ResponseiveGrid gridArea={"f"} contenttype="visible">
            {children[1]}
          </ResponseiveGrid>
        </ResponseiveGridContainer>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    roles: state.auth.user.roles,
    app: state.app,
    palette: state.theme.palette,
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps)(SearchDateFilter);
