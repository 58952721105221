import { ResourceProvider } from "../../providers/resource.provider";
import { IDNAMESerializer } from "../filter/serializer";
import { Attribute } from "./Attribute";

class AttributeProvider extends ResourceProvider<Attribute> {
    constructor() {
        super(
            "rest_attribute",
            new IDNAMESerializer()
        )
    }
}

export const attributeService = new AttributeProvider()