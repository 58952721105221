import { Box, Grid, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { connect } from "react-redux";
import { User } from "../../models/user/user";
import { Version } from "../../models/Version/Version";
import TestingStatuses from "../../pages/Testing/TestingStatuses";

interface VersionProps {
  testers: User[];
  theme: any;
  version: any;
}

const TestingTableList = (props: VersionProps) => {
  const { testers, version } = props;

  return (
    <Box>
      <Grid item xs={12} lg={12} sx={{ padding: "20px" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center"></TableCell>
              <TableCell align="center">Version Testing Status</TableCell>
              {testers
                ?.sort((a: User, b: User) => a?.fullName?.localeCompare(b?.fullName as string) as number)
                ?.map((item: User, index: number) => (
                  <TableCell align="center" key={index}>
                    {item.fullName}
                  </TableCell>
                ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {version
              .filter((e: Version) => e.name !== "BACKLOG")
              .map((item: Version, index: number) => (
                <TableRow key={index}>
                  <TableCell align="right" sx={{ color: "text.primary" }}>
                    {item.name}
                  </TableCell>
                  <TestingStatuses
                    name={item.name}
                    id={item.id}
                    testers={testers.sort((a: User, b: User) => a.fullName?.localeCompare(b.fullName as string) as number)}
                  />
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </Grid>
    </Box>
  );
};

const mapStateToProps = (state: any) => {
  return {
    auth: state.auth,
    roles: state.auth.user.roles,
    app: state.app,
    palette: state.theme.palette,
    theme: state.theme.theme,
  };
};

export default connect(mapStateToProps)(TestingTableList);
