import { AccountCircle } from "@mui/icons-material";
import { Card, CardActionArea, CardContent, Grid, PaletteMode, Tooltip, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

interface UserContextMenuProfileProps {
  displayHeight?: number;
  displayWidth?: number;
  displayName?: string;
  displayEmail?: string;
  mode: PaletteMode;
}

const UserContextMenuProfile = (props: UserContextMenuProfileProps) => {
  const { displayHeight, displayWidth, displayName, displayEmail, mode } = props;

  const displayImageSrc = "";
  const [image, setImage] = useState<string | any>(null);

  useEffect(() => {
    if (displayImageSrc) {
      setImage(() => {
        if (displayImageSrc === "null") return null;
        else return displayImageSrc;
      });
    }

    return () => {
      setImage(null);
    };
  }, [displayImageSrc]);

  return (
    <Grid component={Card} elevation={5} container item>
      <Grid component={Link} sx={{ textDecoration: "none", color: "text.primary" }} to={{ pathname: "/employeeProfile" }}>
        <Grid container item component={CardActionArea} padding={0.5}>
          <Grid container item component={CardContent} padding={0} display={"flex"} alignItems={"center"}>
            <Grid container item lg xs md>
              {image ? (
                <Grid
                  component={"img"}
                  onError={() => setImage(null)}
                  src={image}
                  sx={{
                    height: `${displayHeight}px`,
                    width: `${displayWidth}px`,
                    objectFit: "cover",
                  }}
                />
              ) : (
                <AccountCircle
                  sx={{
                    color: "text.primary",
                    cursor: "pointer",
                    "&.MuiSvgIcon-root": {
                      fontSize: `${displayWidth}px`,
                    },
                  }}
                />
              )}
            </Grid>
            <Grid container item lg xs md>
              <Grid container item direction={"column"}>
                <Grid item>
                  <Typography
                    sx={{
                      width: "190px",
                      maxWidth: "190px",
                    }}
                    fontWeight={"600"}
                    variant={"subtitle1"}
                  >
                    {displayName}
                  </Typography>
                </Grid>
                <Grid item>
                  <Tooltip title={displayEmail}>
                    <Typography
                      sx={{
                        WebkitLineClamp: 1,
                        textOverflow: "ellipsis",
                        width: "190px",
                        maxWidth: "190px",
                        display: "-webkit-box",
                        wordBreak: "break-word",
                        overflow: "hidden",
                        whiteSpace: "normal",
                        WebkitBoxOrient: "vertical",
                      }}
                    >
                      {displayEmail}
                    </Typography>
                  </Tooltip>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

const mapStateToProps = (state: any) => {
  return {
    mode: state.app.currentTheme,
  };
};

export default connect(mapStateToProps)(UserContextMenuProfile);
