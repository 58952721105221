import { ResourceProvider } from "../../providers/resource.provider";
import { Order, OrderOverride } from "./Order";
import { orderOverrideSerializer, orderSerializer } from "./serializer";

class OrderSellerProvider extends ResourceProvider<Order> {
    constructor() {
        super("rest_order_seller", orderSerializer);
    }
}

class OrderBuyerProvider extends ResourceProvider<Order> {
    constructor() {
        super("rest_order_buyer", orderSerializer);
    }
}

class OrdersProvider extends ResourceProvider<Order> {
    constructor() {
        super(
            'rest_order',
            orderSerializer
        )
    }
}
class OrderOverrideProvider extends ResourceProvider<OrderOverride> {
    constructor() {
        super(
            'rest_order_override',
            orderOverrideSerializer
        )
    }
}

export const orderSellerSercice = new OrderSellerProvider();
export const orderBuyerSercice = new OrderBuyerProvider();
export const orderService = new OrdersProvider()
export const orderOverrideService = new OrderOverrideProvider()
