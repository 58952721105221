import { Typography } from "@mui/material";
import '../../App.css';


export const TypographyItem = (props: any) => {
    const { text, fontSize = 13, id = "none",
        onClick,
        sx,
        uppercase = false, fontWeight = '400', color = "text.primary", fontFamily = 'FiraGo', className, style = {} } = props
    return <Typography
        id={id}
        onClick={onClick ? onClick : () => { }}
        className={uppercase ? "uppercase" : className}
        variant="subtitle1"
        sx={{
            ...sx,
            ...style,
            display: 'flex',
            fontWeight,
            flexDirection: 'column',
            fontSize,
            fontFamily,
            color
        }}>{text} </Typography>
}