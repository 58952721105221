import { Children } from "./Comments";
import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { UserSerializer } from "../user/serializer";
import { Comments } from "./Comments";

export class CommentsSerializer implements Serializer {
    fromJson(json: any) {
        const comments = new Comments();
        const userSerializer = new UserSerializer();
        comments.children = json.children
        comments.created = json.created
        comments.id = json.id
        comments.is_enabled = json.is_enabled
        comments.parent = json.parent
        comments.task = json.task
        comments.text = json.text
        comments.user = userSerializer.fromJson(json.user)
    
        return comments
    }
    toJson(comments: Comments) {
        const obj: any = {
            text: comments.text,
            parent: comments.parent ? comments.parent : null,
            task: comments.task ? comments.task : null
        }

        return obj
    }

}

export class ChildrenSerializer implements Serializer {
    fromJson(json: any) {
        const children = new Children()
        const childrenSerializer = new ChildrenSerializer()
        children.children = childrenSerializer.fromJson(json.children)
        children.created = json.created
        children.id = json.id
        children.is_enabled = json.is_enabled
        children.text = json.text
        children.user = json.user

        return children
    }
    toJson(resource: Resource) {
        return resource
    }

}