import { Box, Grid } from "@mui/material";
import { useEffect, useState } from "react";
import { Animated } from "react-animated-css";
import { UserProfileSection } from "../User/UserProfileSection";

export const UserProfileAnimator = (props: any) => {
  const { chat, userProfileUpdateHandler, direction, userProfile } = props;

  const [loaded, setLoaded] = useState(false);
  const [delay] = useState(200);

  useEffect(() => {
    if (userProfile) {
      setLoaded(false);
      setTimeout(() => {
        setLoaded(true);
      }, delay);
    }
  }, [userProfile]);

  return (
    <Grid
      container
      direction={"column"}
      component={Animated}
      sx={{
        backgroundColor: "background.default",
        "&.animated": {
          height: "calc(100vh - 64px)",
          overflow: "auto",
        },
      }}
      animationIn={direction === "right" ? "slideInLeft" : "slideInRight"}
      animationOut={direction === "right" ? "slideOutRight" : "slideOutLeft"}
      isVisible={loaded}
      animationInDuration={500}
    >
      {userProfile && <UserProfileSection userProfile={userProfile} chat={chat} userProfileUpdateHandler={userProfileUpdateHandler} />}
    </Grid>
  );
};
