import { Resource } from "../resource";
import { Serializer } from "../serializer";
import { Department } from "./department";

class DepartmentSerializer implements Serializer {
  fromJson(json: any): Department {
    const dpItem = new Department();
    dpItem.departmentName = json.name;
    dpItem.departmentId = json.id;
    return dpItem;
  }
  toJson(resource: Resource) {
    throw new Error("Method not implemented.");
  }
}

export const departmentSerializer = new DepartmentSerializer();
