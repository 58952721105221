import { ResourceProvider } from "../../providers/resource.provider";
import { Comments } from "./Comments";
import { CommentsSerializer } from "./serializer";

export class CommentsProvider extends ResourceProvider<Comments> {
    constructor() {
        super(
            'rest_task_comment',
            new CommentsSerializer()
        )
    }
}

export const commentService = new CommentsProvider()