import { Resource } from "../resource";
import { Serializer } from "./../serializer";
import { userSerializer } from "./../user/serializer";
import { CartOrder } from "./CartOrder";

export class CartOrderSerializer implements Serializer {
  fromJson(json: any): CartOrder {
    const item = new CartOrder();
    item.address = json.address;
    item.bundleDiscount = json.bundle_discount;
    item.country = json.country;
    item.coupon = json.coupon;
    item.couponDiscount = json.coupon_discount;
    item.created = json.created;
    item.currency = json.currency;
    item.giftPackaging = json.gift_packaging;
    item.grandTotal = json.grand_total;
    item.id = json.id;
    item.installmentDiscount = json.installment_discount;
    item.method = json.method;
    item.paidWithSolo = json.paid_with_solo;
    item.paymentMethod = json.payment_method;
    item.phubbalanceDiscount = json.phubbalance_discount;
    item.phubbalanceUsed = json.phubbalance_used;
    item.pickupAddress = json.pickup_address;
    item.pickupDiscount = json.pickup_discount;
    item.sellerDiscount = json.seller_discount;
    item.shippingMethod = json.shipping_method;
    item.shippingType = json.shipping_type;
    item.status = json.status;
    item.subtotal = json.subtotal;
    item.total = json.total;
    item.type = json.type;
    item.productCount = json.product_count;
    item.user = userSerializer.fromJson(json.user).email;
    return item;
  }
  toJson(resource: Resource) {
    throw new Error("Method not implemented.");
  }
}

export const cartOrderSrializer = new CartOrderSerializer();
