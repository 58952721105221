import { Add } from "@mui/icons-material";
import { Button, DialogTitle, FormControl, FormHelperText, Grid, InputLabel, MenuItem, Select, TextField, Tooltip } from "@mui/material";
import { Form, Formik } from "formik";
import { useEffect, useRef, useState } from "react";
import { connect } from "react-redux";
import { App, ReduxState } from "../../environment/environment";
import { permValid } from "../../helpers/permCheckers";
import { QueryOptions } from "../../helpers/query.options";
import { validationSchemas } from "../../helpers/validations";
import { Category } from "../../models/category/category";
import { componentService } from "../../models/component/service";
import { Issue } from "../../models/issue/Issue";
import { Priority } from "../../models/priority/priority";
import { Project } from "../../models/project/project";
import IssueComponentCreatorDialog from "../Dialogs/IssueComponentCreatorDialog";
import { SearchComponent } from "../SearchComponent";

interface IssueFormProps {
  item: Issue;
  createComponent: Function;
  setRespStateHandler: () => void;
  getValues: (values: any) => void;
  handleOpenState: Function;
  componentOpenState: boolean;
  handleDialog: Function;
  componentPer: string[];
  app: App;
}

function IssueForm({
  item,
  createComponent,
  setRespStateHandler,
  getValues,
  handleOpenState,
  componentOpenState,
  handleDialog,
  componentPer,
  app,
}: IssueFormProps) {
  const [components, setComponents] = useState<any[]>([]);
  const [open, setOpen] = useState(false);
  const [value, setValue] = useState("");

  const valueRef: any = useRef(null);

  const loadComponents = (search: string) => {
    const options = new QueryOptions();
    options.pageSize = 1000;
    options.search = search;
    componentService.listWithCounter(options).then((r) => {
      if (r?.current?.length) {
        setComponents(r.current);
      }
    });
  };

  useEffect(() => {
    const listener = (event: any) => {
      event.preventDefault();
      valueRef.current?.focus();
    };
    open ? window.addEventListener("keydown", listener, false) : window.removeEventListener("keydown", listener, false);

    return () => {
      window.removeEventListener("keydown", listener, false);
    };
  }, [open]);

  return (
    <Formik
      validationSchema={validationSchemas([], []).issueSchema}
      initialValues={
        item
          ? {
              ...item,
            }
          : { name: "", description: "", status: null, category: null, component: null, project: "", type: "" }
      }
      onSubmit={getValues}
    >
      {({ values, handleSubmit, setFieldValue, handleChange, handleBlur, errors, touched }: any) => (
        <Form
          onSubmit={(values) => {
            handleSubmit(values);
          }}
        >
          <Grid container spacing={2} style={{ padding: 20 }}>
            <Grid item xs={12} lg={12}>
              <DialogTitle>ახალი საკითხი</DialogTitle>
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                sx={{
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "text.primary",
                  },
                  "& .MuiInputLabel-root": {
                    color: "text.primary",
                  },
                  "& .MuiInputBase-root": {
                    color: "text.primary",
                    border: `1px text.primary solid`,
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: `text.primary`,
                    borderWidth: "2px",
                  },
                }}
                name="name"
                margin="dense"
                variant="outlined"
                id="name"
                defaultValue={values.name}
                label="დასახელება"
                type="name"
                fullWidth
                onChange={handleChange}
                onBlur={handleBlur}
                error={errors.name && touched.name ? true : false}
                helperText={errors.name && touched.name ? errors.name : null}
              />
            </Grid>
            <Grid item xs={12} lg={12}>
              <TextField
                sx={{
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "text.primary",
                  },
                  "& .MuiInputLabel-root": {
                    color: "text.primary",
                  },
                  "& .MuiInputBase-root": {
                    color: "text.primary",
                    border: `1px text.primary solid`,
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: `text.primary`,
                    borderWidth: "2px",
                  },
                }}
                name="description"
                multiline={true}
                margin="dense"
                variant="outlined"
                id="Description"
                defaultValue={values.description}
                label="აღწერა"
                type="description"
                fullWidth
                onChange={(event: any) => {
                  setFieldValue("description", event.target.value);
                }}
                error={errors.description && touched.description ? true : false}
                helperText={errors.description && touched.description ? errors.description : null}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "text.primary",
                  },
                  "& .MuiInputLabel-root": {
                    color: "text.primary",
                  },
                  "& .MuiInputBase-root": {
                    color: "text.primary",
                    border: `1px text.primary solid`,
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "text.primary",
                    borderWidth: "1px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: `text.primary`,
                    borderWidth: "2px",
                  },
                }}
                variant="outlined"
                error={errors.category && touched.category ? true : false}
              >
                <InputLabel>კატეგორია</InputLabel>
                <Select
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        backgroundColor: "background.default",
                      },
                    },
                  }}
                  id="category"
                  name="category"
                  label="კატეგორია"
                  variant="outlined"
                  value={values.category?.id || ""}
                  onChange={(event, value) => {
                    const [item] = app?.categories?.filter((option: any) => `${option.id}` === event.target.value);
                    if (item) {
                      setFieldValue("category", item);
                    }
                  }}
                >
                  {app.categories.map((item: Category, index: number) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.category && touched.category ? errors.category : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "text.primary",
                  },
                  "& .MuiInputLabel-root": {
                    color: "text.primary",
                  },
                  "& .MuiInputBase-root": {
                    color: "text.primary",
                    border: `1px text.primary solid`,
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "text.primary",
                    borderWidth: "1px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: `text.primary`,
                    borderWidth: "2px",
                  },
                }}
                variant="outlined"
                error={errors.project && touched.project ? true : false}
              >
                <InputLabel>პროექტი</InputLabel>
                <Select
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        backgroundColor: "background.default",
                      },
                    },
                  }}
                  name="project"
                  label="პროექტი"
                  id="project"
                  value={values.project?.id || ""}
                  onChange={(event, value) => {
                    const [item] = app?.projects?.filter((option: any) => `${option.id}` === event.target.value);
                    if (item) {
                      setFieldValue("project", item);
                    }
                  }}
                >
                  {app.projects.map((item: Project, index: number) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.project && touched.project ? errors.project : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "text.primary",
                  },
                  "& .MuiInputLabel-root": {
                    color: "text.primary",
                  },
                  "& .MuiInputBase-root": {
                    color: "text.primary",
                    border: `1px text.primary solid`,
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "text.primary",
                    borderWidth: "1px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: `text.primary`,
                    borderWidth: "2px",
                  },
                }}
                variant="outlined"
                error={errors.status && touched.status ? true : false}
              >
                <InputLabel id="">სტატუსი</InputLabel>
                <Select
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        backgroundColor: "background.default",
                      },
                    },
                  }}
                  name="status"
                  id="status"
                  label="status"
                  value={values.status?.id || ""}
                  onChange={(event: any) => {
                    const [item] = app?.statuses?.filter((option: any) => `${option.id}` === event.target.value);
                    if (item) {
                      setFieldValue("status", item);
                    }
                  }}
                >
                  {app.statuses.map((item: Priority, index: number) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.status && touched.status ? errors.status : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "text.primary",
                  },
                  "& .MuiInputLabel-root": {
                    color: "text.primary",
                  },
                  "& .MuiInputBase-root": {
                    color: "text.primary",
                    border: `1px text.primary solid`,
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "text.primary",
                    borderWidth: "1px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: `text.primary`,
                    borderWidth: "2px",
                  },
                }}
                variant="outlined"
                error={errors.type && touched.type ? true : false}
              >
                <InputLabel id=""> სახეობა</InputLabel>
                <Select
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        backgroundColor: "background.default",
                      },
                    },
                  }}
                  name="type"
                  id="type"
                  label="type"
                  value={values.type?.id || ""}
                  onChange={(event, value) => {
                    const [item] = app?.issueTypes?.filter((option: any) => `${option.id}` === event.target.value);
                    if (item) {
                      setFieldValue("type", item);
                    }
                  }}
                >
                  {app.issueTypes.map((item: Priority, index: number) => {
                    return (
                      <MenuItem key={index} value={item.id}>
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                <FormHelperText>{errors.type && touched.type ? errors.type : null}</FormHelperText>
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={6}>
              <FormControl
                sx={{
                  width: "100%",
                  "& .MuiInputLabel-root.Mui-focused": {
                    color: "text.primary",
                  },
                  "& .MuiInputLabel-root": {
                    color: "text.primary",
                  },
                  "& .MuiInputBase-root": {
                    color: "text.primary",
                    border: `1px text.primary solid`,
                  },
                  "& .MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline": {
                    borderColor: "text.primary",
                    borderWidth: "1px",
                  },
                  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    borderColor: `text.primary`,
                    borderWidth: "2px",
                  },
                }}
                variant="outlined"
                error={errors.component && touched.component ? true : false}
              >
                <InputLabel id="">კომპონენტი</InputLabel>
                <Select
                  onClose={() => {
                    setOpen(false);
                    setValue("");
                  }}
                  onOpen={() => {
                    setValue("62ac5a559ff4dc5677477a2f");
                  }}
                  MenuProps={{
                    sx: {
                      "& .MuiPaper-root": {
                        backgroundColor: "background.default",
                      },
                      "& .MuiListSubheader-root": {
                        backgroundColor: "background.default",
                      },
                    },
                  }}
                  name="component"
                  id="component"
                  label="component"
                  value={
                    (components.length ? components : app.components).filter((item: any) => item.id === values.component?.id).length
                      ? values.component?.id
                      : value
                  }
                  onChange={(event, value) => {
                    const [item] = app?.components?.filter((option: any) => `${option.id}` === event.target.value);

                    if (item) {
                      setFieldValue("component", item);
                    }
                  }}
                >
                  <SearchComponent setOpen={setOpen} valueRef={valueRef} open={open} searchOnChange={loadComponents} />
                  <Tooltip title={!permValid(componentPer, "write") ? "disabled" : ""}>
                    <MenuItem onFocus={() => setOpen(false)} onClick={setRespStateHandler}>
                      <Add
                        sx={{
                          cursor: "pointer",
                          color: permValid(componentPer, "write") ? "" : "lightgrey",
                        }}
                      />
                    </MenuItem>
                  </Tooltip>
                  {(components.length ? components : app.components).map((item: Priority, index: number) => {
                    return (
                      <MenuItem
                        onClick={() => {
                          setOpen(false);
                          setValue(item.id);
                        }}
                        key={index}
                        value={item.id || ""}
                      >
                        {item.name}
                      </MenuItem>
                    );
                  })}
                </Select>
                {/**
                 * აქ არის განთასებული კომპონენტის პირის შექმნის დიალოგი
                 */}
                {componentOpenState && (
                  <IssueComponentCreatorDialog open={componentOpenState} handleOpenState={handleOpenState} createComponent={createComponent} />
                )}
                <FormHelperText>{errors.component && touched.component ? errors.component : null}</FormHelperText>
              </FormControl>
            </Grid>
            <Grid item xs={12} lg={6}></Grid>
            <Grid item xs={12} lg={6}>
              <Button onClick={() => handleDialog(false)}>გამოსვლა</Button>
              <Button
                type="submit"
                style={{
                  backgroundColor: "#4169E1",
                  color: "white",
                }}
                variant="contained"
              >
                დამახსოვრება
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
}

const mapStateToProps = (state: ReduxState) => {
  const { app } = state;
  return {
    app: app,
  };
};

export default connect(mapStateToProps)(IssueForm);
