import { Resource } from "../resource";

export class VersionLoc extends Resource {
    id: string | undefined
    name: string | undefined
}

export class Testers extends Resource {
    statuses: any[] | undefined
    tester: any | undefined
    'true/false': boolean | undefined
}

export class TestingPageListItem extends Resource {
    status: boolean | undefined
    testers: Testers | undefined
    version: VersionLoc | undefined
    count: number | undefined
}