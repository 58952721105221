import { Box, Grid } from "@mui/material";
import { Provider } from "react-redux";
import { BrowserRouter as Router } from "react-router-dom";
import "./App.css";
import SimpleBottomNavigation from "./Components/Header/Footer/Footer";
import Header from "./Components/Header/Header";
import MuiThemeProvider from "./providers/MuiThemeProvider";
import ThemeProvider from "./providers/ThemeProvider";
import { store } from "./redux/store";
import Routing from "./routing";

function App() {
  return (
    <Provider store={store}>
      <MuiThemeProvider>
        <ThemeProvider>
          <Grid container className="App" sx={{ height: "100%", display: "grid", gridTemplateRows: "min-content", backgroundColor: "background.default" }}>
            <Router forceRefresh={false}>
              <Header />
              <Routing style={{ overflow: "hidden" }} />
            </Router>
            {/* <SimpleBottomNavigation /> */}
          </Grid>
        </ThemeProvider>
      </MuiThemeProvider>
    </Provider>
  );
}

export default App;
