import { Chip } from "@mui/material";

export const FilterChip = (props: any) => {
  const { item, handleDelete, items } = props;
  return (
    <Chip
      sx={{
        marginRight: 0.6,
        marginBottom: 0.6,
      }}
      label={
        <span>
          {`${item?.key}`}
          <b>{` : ${item?.name ? item?.name : item?.id}`}</b>
        </span>
      }
      clickable
      color="primary"
      onDelete={() => handleDelete(items?.length ? items?.filter((itm: any) => itm.key === item.key)[0] : item)}
    />
  );
};
