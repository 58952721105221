import { ResourceProvider } from "../../providers/resource.provider";
import { Brand } from "./Brand";
import { brandSerializer } from "./serializer";

export class BrandServiceProvider extends ResourceProvider<Brand> {
    constructor() {
        super(
            "rest_brand",
            brandSerializer
        )
    }
}

export const brandService = new BrandServiceProvider()