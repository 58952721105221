import { FormControl, FormLabel, Grid, TextField } from "@mui/material";
import { Form, Formik } from "formik";
import { validationSchemas } from "../../helpers/validations";

export const UserContextMenuForm = (props: any) => {
  const { formikRef, submitForm } = props;
  const initVals = {
    password: "",
    confirmPassword: "",
  };

  return (
    <Formik innerRef={formikRef} onSubmit={submitForm} initialValues={initVals} validationSchema={validationSchemas([], []).restorePassword} validateOnChange>
      {({ handleSubmit, handleChange, handleBlur, errors, touched, values }) => (
        <Form onSubmit={handleSubmit}>
          <FormControl sx={{ gap: "12px" }}>
            <FormLabel>Enter new password</FormLabel>
            <Grid container width={"100%"} gap={"12px"}>
              <Grid item lg={12}>
                <TextField
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px",
                    },
                  }}
                  id="password"
                  name="password"
                  type={"password"}
                  onChange={handleChange}
                  onBlur={handleBlur}
                  placeholder="New password"
                  size={"small"}
                  fullWidth
                  value={values.password}
                  variant={"outlined"}
                  error={errors.password && touched.password ? true : false}
                  helperText={errors.password && touched.password ? errors.password : null}
                />
              </Grid>
              <Grid item lg={12}>
                <TextField
                  disabled={values.password.length ? false : true}
                  sx={{
                    "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderWidth: "1px",
                    },
                  }}
                  id="confirmPassword"
                  name="confirmPassword"
                  type={"password"}
                  onChange={handleChange}
                  placeholder="Repeate password"
                  size={"small"}
                  fullWidth
                  value={values.confirmPassword}
                  variant={"outlined"}
                  onBlur={handleBlur}
                  error={errors.confirmPassword && touched.confirmPassword ? true : false}
                  helperText={errors.confirmPassword && touched.confirmPassword ? errors.confirmPassword : null}
                />
              </Grid>
            </Grid>
          </FormControl>
        </Form>
      )}
    </Formik>
  );
};
