import { Serializer } from '../serializer';
import { Features } from './features';

export class FeaturesSerializer implements Serializer {
    fromJson(json: any): Features {
        const features = new Features();
        features.name = json.name;
        features.description = json.description;
        features.comments = json.comments;
        features.docs = json.docs;
        // if (json.tasks) {
        //     const taskSerializer = new TaskSerializer()
        //     features.tasks = taskSerializer.fromJson(json.tasks)
        // }
        features.tasks = json.tasks;
        features.images = json.images;
        features.created = json.created;
        // const userSerializer = new UserSerializer()
        // features.user = userSerializer.fromJson(json.user);
        features.user = json.user;
        features.is_enabled = json.is_enabled;

        return features;
    };

    toJson(item: Features) {
        return item
    };

};